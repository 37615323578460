import React, { useState } from 'react'
import Styles from './Stars.module.css'
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { FiStar } from 'react-icons/fi'
import starFull from '../../../assets/img/star6.png'
import starHalf from '../../../assets/img/star8.png'
import starEmpty from '../../../assets/img/star7.png'
import { StylesProvider } from '@material-ui/core'

function Stars(props) {
    let value = props.rating
    let dec = value - Math.floor(value)
    return (
        <div className={Styles.starRatingContainer}>
           
            {[...Array(5)].map((star) => {
                if(value-dec===0 && dec!=0){
                    value=value-1
                    return (
                        <div className={Styles.star}>
                            <img src={starHalf} className={Styles.starImgHalf} />
                        </div> 
                    )
                }
                else if(value<=0)
                {
                    value = value - 1
                    return (
                        <div className={Styles.star}>
                            <img src={starEmpty} className={Styles.starImg} />
                        </div>   
                    )
                }
                else {
                    value=value-1
                    return (
                        <div className={Styles.star}>
                            <img src={starFull} className={Styles.starImg} />
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default Stars