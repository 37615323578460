import React,{useEffect} from 'react'
import Styles from './checkBox.module.css'
import FormGroup from '@material-ui/core/FormGroup'
import Divider from '@material-ui/core/Divider'
import {useDispatch} from 'react-redux'
import {handleFilters} from '../../../actions/actionCreator'
import {UPDATE_FILTER_ITEM} from '../../../constants/actions'
import {useParams} from 'react-router-dom'


function CheckBoxFilter(props) {  
    const header = props.parameters[0].header
    const [checklist, setChecklist] = React.useState([]);
    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        setChecklist([])
    }, [id]);

    useEffect(() => {
        dispatch(header === 'Industry' ? handleFilters(checklist, UPDATE_FILTER_ITEM, 'industries') : handleFilters(checklist,UPDATE_FILTER_ITEM, 'jobCategories') )
     }, [checklist]);

    const handleChange = (e) => {
        let options = [...checklist]
        let index
        if (e.target.checked) {
            options.push(e.target.value)
        } else {
            index = options.indexOf(e.target.value)
            options.splice(index, 1)
        }
        setChecklist(options)          
    } 
    const handleClearAll = () =>{
        setChecklist([])
    }  

    

    return (
        <div> 
            <div className={Styles.body}>
                <div className={Styles.header}>
                    <span className={Styles.header_title}>{header}</span>
                    {checklist.length ? <span className={Styles.clear} onClick={handleClearAll}>Clear</span> : <span className={Styles.no_action_clear}>Clear</span>}
                </div> 
                <FormGroup>
                    {props.parameters[1].params.map(industry => (
                        <React.Fragment>
                            <div className={checklist.includes(industry) ? Styles.checkedd : 'null'}>
                                <input id={`${header}${industry}`}  value={industry} className={Styles.check} type="checkbox" name={`${header}${industry}`} checked={checklist.includes(industry)} onChange={handleChange}/>
                                <label for={`${header}${industry}`} className={Styles.checklabel}>{industry}</label>
                            </div>
                        </React.Fragment>
                    ))}
                </FormGroup>
                
            </div>
            {props.parameters[2].divider ? <Divider/> : null}        
        </div>
  );
}
export default CheckBoxFilter