import React from 'react'
import Styles from './ActivityTabSims.module.css'
import WhiteBox from '../WhiteBox/WhiteBox'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import LineChart from '../../commonComponents/GraphComponents/LineChart'
import HorizontalProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import HorizontalBenchmarkGroupBar from '../../commonComponents/GraphComponents/HorizontalBenchmarkGroupBar'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import AOS from 'aos';
import 'aos/dist/aos.css';
import {dataForLineChart2, dataForPieChart, dataForHorizontalProgressBar1, dataForHorizontalBenchmarkGroupBar} from '../../reducers/activityTabiLead/activityTabiLeadReducer'

function ActivityTabSims(props) {
	return (
        <div className={Styles.mainContainer}>
            <div className={Styles.headerItem} data-aos="fade-up" data-aos-duration="350"  data-aos-once="true">
                <SimpleBanner title={props.title}/>
            </div>

            <div className={Styles.row1Container} data-aos="fade-up" data-aos-duration="350" data-aos-delay="350" data-aos-once="true">
                <div className={Styles.twoRowsSpan}>
					<WhiteBox>
						<div className={Styles.graphTitle}>
							<GraphTitle title={<span> COMPLETION <br/> (% OF LEARNERS)</span>}/>
						</div>
						<div>
							<PieChart {...dataForPieChart}/>
							<br/>
						</div>
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						<div className={Styles.graphTitle}>
							<GraphTitle title="NO. OF LEARNERS WHO COMPLETED"/>
						</div>
						<div className={Styles.text1}>
							140
						</div>
					</WhiteBox>
				</div>
				<div className= {Styles.otherStatsContainer} >
					<div className={ Styles.otherStatsHeader }>
						&emsp;OTHER STATISTICS
					</div>
					<div>
						<WhiteBox>
							<div className={Styles.graphTitle}>
								<GraphTitle title="ENROLLED LEARNERS"/>
							</div>
							<div className={Styles.text1}>
								160
							</div>
						</WhiteBox>
					</div>
					<div>
						<WhiteBox>
							<div className={Styles.graphTitle}>
								<GraphTitle title="AVERAGE TIME SPENT"/>
							</div>
							<div className={Styles.text1}>
								60<text style={{fontSize: '10px'}}>mins</text>
							</div>
						</WhiteBox>
					</div>
				</div>
				<div>
					<WhiteBox>
						<div className={Styles.graphTitle}>
							<GraphTitle title="AVERAGE COURSE PROGRESS"/>
						</div>
						<div className={Styles.insideWhiteBox4Columns}>
							<div className={Styles.text1}>
								<text>90</text>
								<text style={{fontSize: '10px'}}>%</text>
							</div>
							<div className={Styles.threeColumnsSpan}>
								<HorizontalProgressBar {...dataForHorizontalProgressBar1}/>
							</div>
						</div>
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						<div className={Styles.graphTitle}>
							<GraphTitle title="LEARNERS WHO SIGNED UP"/>
						</div>
						<div className={Styles.insideWhiteBox4Columns}>
							<div className={Styles.text1}>
								150
							</div>
							<div className={Styles.threeColumnsSpan} style={{borderLeft: '2px solid #F5EFFB'}}>
								<HorizontalBenchmarkGroupBar {...dataForHorizontalBenchmarkGroupBar}/>
							</div>
						</div>
					</WhiteBox>
				</div>
            </div>

			<div className={Styles.row2Container}data-aos="fade-up" data-aos-duration="350" data-aos-delay="700" data-aos-once="true">
				<div className={Styles.twoColumnsSpan}>
					<WhiteBox>
						<div className={Styles.graphTitle}>
							<GraphTitle title="TREND OF LEARNER CONSUMPTION"/>
						</div>
						<br/>
						<div>
							<LineChart {...dataForLineChart2} />
						</div>
						<br/>
					</WhiteBox>
				</div>
            </div>
        </div>
        )
}

export default ActivityTabSims ;