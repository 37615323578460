import React from 'react';
import './App.css';
import { Provider } from 'react-redux'
import Routes from './app/routes/routes'
import store from './app/store'
// import filterPanel from './app/components/filterPanel/selectDropdownFilter'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";


function App() {
  AOS.init(
    {
      easing: 'ease-in-sine'
    }
  )

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_HASURA_URL,
    headers: {
      'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET
    }
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Routes />     

      </Provider>
    </ApolloProvider>
  );
}

export default App;
