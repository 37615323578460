import React from "react";
import Styles from "./Metric.module.css";
import Title from "../GraphTitle/Title";
function Metric(props){
    return(
        <div className={Styles.outerBox}>
            <div className={Styles.graphTitle}>
                <Title title={props.title || 'NETWORK UTILIZATION'} infoBody={props.infoBody} />
            </div>
            <div className={Styles.innerBox}>
                <div className={Styles.groupBox} >
                    <div className={Styles.percentage}>
                        {props.groupPercent}%
                    </div>
                    <div className={Styles.text}>
                        Group
                    </div>
                </div>
                <div className={Styles.benchBox}>
                    <div className={Styles.percentage}>
                        {props.benchmarkPercent}%
                    </div>
                    <div className={Styles.text}>
                        Benchmark
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Metric;