import React from 'react';
import getRoundedRect from '../../utils/GraphUtils'
import {LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend'
import { scaleOrdinal } from '@vx/scale'
import { Spring } from 'react-spring/renderprops'

function HorizontalStackedBar(props) {
    const data = props.data[0];
    let datakeys = [];
    for(let k in data) datakeys.push(k); //To get all the keys in json, i.e. 'Consumed' & 'Available'

    const colorPalette = ['#2F4585', '#9F7DFF'] ;
    const ordinalColorScale = scaleOrdinal({
        domain: datakeys,
        range: colorPalette
    });

    const Total = data[datakeys[0]] + data[datakeys[1]];
    const width = props.width;  
    const height = props.height; 

    let unit = 1;
            if(window.screen.width > 1910 && window.screen.width < 2550){
                //for FHD Screens
                unit = 1.5;
            }else if(window.screen.width >= 2551){
                //for QHD Screens
                unit = 2;
            }

    const width1 = data[datakeys[0]] / Total * width;
    const width2 = data[datakeys[1]] / Total * width;
    const x1 = 0; 
    const x2 = width1;
    const radius = 4*unit;
    return (
        <div>
            <div style={{justifyContent: 'center', display: 'flex', marginTop: '5px'}}>
            <svg width={width} height={height} >
                <Spring
                    config={{ duration: 2500 }}
                    from={{ x1Value : 0, x2Value : 0, width1Value: 0, width2Value: 0 }}
                    to={{ x1Value : x1, x2Value : x2, width1Value: width1, width2Value: width2 }}
                >
                    {slide =>
                        <>
                        <path
                            d={getRoundedRect(slide.x1Value, 0, slide.width1Value , height, radius, 'left')}
                            style={{ fill: colorPalette[0] }}
                        />
                        <path
                            d={getRoundedRect(slide.x2Value, 0, slide.width2Value , height, radius, 'right')}
                            style={{ fill: colorPalette[1] }}
                        />
                        </>
                    }
                </Spring>
            </svg>
            </div>
            <LegendOrdinal scale={ordinalColorScale}>
            {labels => {
                return (
                    <div style={{justifyContent:'center', display: 'flex', flexDirection: 'column', margin: `0px ${40*unit}px`}}>
                        {labels.map((label, i) => {
                        const size = 8*unit;
                            return (
                                <>
                                <LegendItem
                                    key={`legend-quantile-${i}`}
                                    margin={`${2*unit}px ${0*unit}px`}
                                >
                                {
                                    i==0 && 
                                    <svg width={size} height={size}>
                                        <circle cx={size/2} cy={size/2} r={size/2}  fill={colorPalette[i]} />
                                    </svg>
                                }
                                    <LegendLabel
                                        style={{
                                            fontFamily: 'Nunito',
                                            fontSize: `${10*unit}px`,
                                            color: '#2F4585',
                                            marginLeft: `${10*unit}px`
                                        }}
                                    >
                                        {data[datakeys[i]]+' '+label.text}
                                    </LegendLabel>
                                </LegendItem>
                                </>
                            );
                        })}
                    </div>
                );
            }}
            </LegendOrdinal>
        </div>
    );
}

export default HorizontalStackedBar