import React from "react";
import {Component2} from "../Competency/Rated";
import Styles from "./CourseOrder.module.css";

function CourseOrder(props){
    return(
        <div className={Styles.container}>
            <div className={Styles.heading}>
                {props.obj.title.toUpperCase()}
            </div>
            {
                props.obj.course.map((value)=>{
                    return(
                        <div>
                            <div className={Styles.subHeading}>{value.title}</div>
                            <Component2 arr={value.lead}/>
                        </div>
                    )
                })
            }
        </div>

    )
}

export default CourseOrder;