import React from 'react';
import Styles from './GraphComponents.module.css'
import { Pie } from '@vx/shape'
import { Group } from '@vx/group'
import {LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend'
import { scaleOrdinal } from '@vx/scale'
import { useTooltip, Tooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';

const colorPaletteOuter = ['#FF8B72', '#F7F7F7']
const colorPaletteInner = ['#9F7DFF', '#F6F6F6']
const colorPaletteLegend = ['#9F7DFF', '#FF8B72']

const ordinalColorScale = scaleOrdinal({
    domain: ['Group', 'Benchmark'],
    range: colorPaletteLegend
});

function PieChart(props) {
    const width = props.width; 
    const height = props.height; 
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const margin = { top: 30*unit, bottom: 30*unit, left: 30*unit, right: 30*unit };
    const data = [props.scoreOuter, props.scoreInner];

    const radiusOuter = Math.min(width,height)/2 - margin.left
    const radiusInner = radiusOuter - 20*unit;
    const ringThickness = 15*unit; 
    const innerRadiusText = radiusInner - 25*unit; 
    const OuterRadiusText = radiusOuter + 20*unit; 
    const fontSize = 10*unit;
    const centerY = (height - margin.top + margin.bottom) / 2;
    const centerX = (width - margin.left + margin.right)/ 2;
    
    const {
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, datum) => {
        showTooltip({
            tooltipLeft,
            tooltipTop,
            tooltipData: datum
        });
    };

    return (
        <div>
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height} 
            onMouseOver={handleMouseOver}
            onMouseOut={hideTooltip}
        >
                <Group top={centerY} left={centerX}>
                <Spring
                    config={{ duration: 2500 }}
                    from={{ value : 0 }}
                    to={{ value: props.scoreOuter }}
                >
                    {transform =>
                        <Pie
                            data={[transform.value , props.TotalOuter-transform.value]}
                            outerRadius={radiusOuter}
                            innerRadius={radiusOuter - ringThickness}
                            cornerRadius={0}
                            padAngle={0}
                            pieSort={null}
                        >
                            {pie => {
                                return pie.arcs.map((arc, i) => {
                                    return (
                                        <g key={`progress-${i}`}  >
                                            <path d={pie.path(arc)} fill={colorPaletteOuter[i]} />
                                        </g>
                                    );
                                });
                            }}   
                        </Pie>
                    }
                </Spring>
                <Pie
                    data={[props.scoreOuter, props.TotalOuter-props.scoreOuter]}
                    outerRadius={OuterRadiusText}
                    innerRadius={OuterRadiusText - fontSize}
                    cornerRadius={0}
                    padAngle={0}
                    pieSort={null}
                >
                    {pie => {
                        return pie.arcs.map((arc, i) => {
                            const opacityValue = [ 1 , 0 ]
                            const avgRadius = ((OuterRadiusText) + (OuterRadiusText - fontSize)) / 2;
                            const theta = (props.scoreOuter/props.TotalOuter)*360;
                            const radian = (theta - 90) * Math.PI/180
                            const xForText = avgRadius * Math.cos(radian)
                            const yForText = avgRadius * Math.sin(radian)
                        return (
                            <g key={`progress-${i}`}  >
                                <text
                                    fontFamily= {'Nunito'}
                                    fill={colorPaletteOuter[i]}
                                    fillOpacity={opacityValue[i]}
                                    x={xForText}
                                    y={yForText}
                                    fontSize={fontSize}
                                    textAnchor="middle"
                                    transform="translate(0, 5)"
                                >
                                    { props.scoreOuter + "%" }
                                </text>
                            </g>
                        );
                        });
                    }}   
                </Pie>
                <Spring
                    delay={500}
                    config={{ duration: 2500 }}
                    from={{ value : 0 }}
                    to={{ value: props.scoreInner }}
                >
                    {transform =>
                        <Pie
                            data={[transform.value , props.TotalInner-transform.value]}
                            outerRadius={radiusInner}
                            innerRadius={radiusInner - ringThickness}
                            cornerRadius={0}
                            padAngle={0}
                            pieSort={null}
                        >
                            {pie => {
                                return pie.arcs.map((arc, i) => {
                                return (
                                    <g key={`progress-${i}`}  >
                                        <path d={pie.path(arc)} fill={colorPaletteInner[i]} />
                                    </g>
                                );
                                });
                            }}   
                        </Pie>
                    }
                </Spring>
                <Pie
                    data={[props.scoreInner, props.TotalInner-props.scoreInner]}
                    outerRadius={innerRadiusText}
                    innerRadius={innerRadiusText - ringThickness}
                    cornerRadius={0}
                    padAngle={0}
                    pieSort={null}
                >
                    {pie => {
                        return pie.arcs.map((arc, i) => {
                            const opacityValue = [ 1 , 0 ]
                            const avgRadius = ((innerRadiusText) + (innerRadiusText - ringThickness)) / 2;
                            const theta = (props.scoreInner/props.TotalInner)* 360;
                            const radian = (theta - 90) * (Math.PI/180)
                            const xForText = avgRadius * Math.cos(radian)
                            const yForText = avgRadius * Math.sin(radian) 
                        return (
                            <g key={`progress-${i}`}  >
                                <text
                                    fontFamily= {'Nunito'}
                                    fill={colorPaletteInner[i]}
                                    fillOpacity={opacityValue[i]}
                                    x={xForText}
                                    y={yForText}
                                    fontSize={fontSize}
                                    textAnchor="middle"
                                    transform="translate(0, 5)"
                                >
                                    { props.scoreInner + "%" }
                                </text>
                            </g>
                        );
                        });
                    }}   
                </Pie>  
            </Group>
        </svg>
        {tooltipOpen && (
            <Tooltip
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft}
                className={Styles.tooltipBlur}
            >
                <div className={Styles.tooltipText}>
                    Group: {data[1]}% <br/>
                    Benchmark: {data[0]}%
                </div> 
            </Tooltip>
        )}
        </div>
        <LegendOrdinal scale={ordinalColorScale}>
        {labels => {
            return (
                <div style={{justifyContent:'center', display: 'flex', flexDirection: 'rows'}}>
                    {labels.map((label, i) => {
                    const size = 8*unit;
                    return (
                        <LegendItem
                            key={`legend-quantile-${i}`}
                            margin={`0 ${5}px`}
                        >
                        <svg width={size} height={size}>
                            <circle cx={size/2} cy={size/2} r={size/2}  fill={colorPaletteLegend[i]} />
                        </svg>
                            <LegendLabel
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: `${10*unit}px`,
                                    color: '#2F4585',
                                    marginLeft: `${10*unit}px`
                                }}
                            >
                                {label.text}
                            </LegendLabel>
                        </LegendItem>
                    );
                    })}
                </div>
            );
        }}
        </LegendOrdinal>
        </div>
    );
}

export default PieChart;