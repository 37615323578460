import React, { useEffect, useState } from 'react';
import Styles from './PerformanceTab.module.css'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import NoOfUsers from '../../commonComponents/textComponent/NoOfUsers/User'
import WhiteBox from '../WhiteBox/WhiteBox'
import CompetencyBox from '../../commonComponents/textComponent/Competency/mainModule'
import BarGraph from '../../commonComponents/GraphComponents/BarGraph'
import ProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import BannerLoader from '../../commonComponents/Loaders/PerformanceTabAll/BannerLoader'
import UsersLoader from '../../commonComponents/Loaders/PerformanceTabAll/UsersLoader'
import AverageBenchmarkLoader from '../../commonComponents/Loaders/PerformanceTabAll/AverageBenchmarkLoader'
import DistributionLoader from '../../commonComponents/Loaders/PerformanceTabAll/DistributionLoader'
import CompetencyLoader from '../../commonComponents/Loaders/PerformanceTabAll/CompetencyLoader'
import TopPerformerLoader from '../../commonComponents/Loaders/PerformanceTabAll/TopPerformers'
import TopPerformerTitleLoader from '../../commonComponents/Loaders/PerformanceTabAll/TopPerformerTitleLoader'
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { UPDATE_AC_COMPETENCY_SCORES, UPDATE_AC_DISTRIBUTIONS, UPDATE_AC_TOP_PERFORMERS, UPDATE_AC_COMPETENCIES, UPDATE_AC_USERS, UPDATE_DOMAIN_COMPETENCY_ID } from '../../constants/actions';
import { array } from 'prop-types';
import { isLeafType } from 'graphql';

function PerformanceTab(props) {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { orgId } = useParams()
    
    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const performanceTabState = useSelector(state => state.performanceTab.allCourses)
    const acState = useSelector(state => state.performanceTab)
    const topPerformersLength = performanceTabState.topPerformersArray.length
    const orgLevelState = useSelector(state => state.orgLevelReducer)
    const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)

    let formatQuery = ''
	let formatToSearch = []
	let formatToSearchString = [] 
	formatToSearch = filterCheckedListState.learningFormats;
	if(formatToSearch[0] === "All Formats"){
		// i.e. when 'All Formats' is selected in Learning Format
		formatQuery = ''
	}
	else{
		formatToSearch.forEach((eachFormat) =>{
			formatToSearchString.push('"'+eachFormat+'"')
		})
		formatQuery = `_in: [${formatToSearchString}]`
	}

	let dealQuery = ''
	let dealToSearch = []
	let dealToSearchString = [] 
	dealToSearch = filterCheckedListState.deals;
	if(dealToSearch[0] === "All Deals"){
		// i.e. when 'All Deals' is selected in Deal
		dealQuery = ''
	}
	else{
		dealToSearch.forEach((eachDeal) =>{
			dealToSearchString.push('"'+eachDeal+'"')
		})
		dealQuery = `_in: [${dealToSearchString}]`
	}

	let batchQuery = ''
	let batchToSearch = []
	let batchToSearchString = [] 
	batchToSearch = filterCheckedListState.batches;
	if(batchToSearch[0] === "All Batches"){
		// i.e. when 'All Batches' is selected in Batch
		batchQuery = ''
	}
	else{
		batchToSearch.forEach((eachBatch) =>{
			batchToSearchString.push('"'+eachBatch+'"')
		})
		batchQuery = `_in: [${batchToSearchString}]`
	}

	let industryQuery = ''
	let industryToSearch = []
	let industryToSearchString = [] 
	industryToSearch = filterCheckedListState.industries;
	if(industryToSearch[0] === undefined){ 
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > INDUSTRY
		industryQuery = ''
	}
	else{
		industryToSearch.forEach((eachIndustry) =>{
			industryToSearchString.push('"'+eachIndustry+'"')
		})
		industryQuery = `_in: [${industryToSearchString}]`
	}

	let jobCatagoryQuery = ''
	let jobCatagoryToSearch = []
	let jobCatagoryToSearchString = [] 
	jobCatagoryToSearch = filterCheckedListState.jobCategories;
	if(jobCatagoryToSearch[0] === undefined){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > JOB CATAGORY
		jobCatagoryQuery = ''
	}
	else{
		jobCatagoryToSearch.forEach((eachjobCatagory) =>{
			jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
		})
		jobCatagoryQuery = `_in: [${jobCatagoryToSearchString}]`
	}

	let workExpQuery = ''
	let workExpToSearch = []
	let workExpToSearchString = [] 
	workExpToSearch = filterCheckedListState.workExperiences;
	if(workExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > Work Experience
		workExpQuery = ''
	}
	else{
		workExpToSearch.forEach((eachworkExp) =>{
			workExpToSearchString.push('"'+eachworkExp+'"')
		})
		workExpQuery = `_in: [${workExpToSearchString}]`
	}

	let manageExpQuery = ''
	let manageExpToSearch = []
	let manageExpToSearchString = [] 
	manageExpToSearch = filterCheckedListState.peopleManagementExperiences;
	if(manageExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > People Management Experience
		manageExpQuery = ''
	}
	else{
		manageExpToSearch.forEach((eachManageExp) =>{
			manageExpToSearchString.push('"'+eachManageExp+'"')
		})
		manageExpQuery = `_in: [${manageExpToSearchString}]`
	}



    const usersQuery = gql`
        query MyQuery($orgId: bigint!) {
            usersCount: journey_complete_user_details_aggregate(distinct_on: [user_id], where: {journey_progress: {_eq: "100"}, _and: {createdbyorgidRelation: {createdbyorgid: {_eq: $orgId}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}, isdeleted: {_eq: "0"}, journeyIDTagRelation: {format: {${formatQuery}}}, dealUserIDRelation: {DealUserRelation: {name: {${dealQuery}}}}, commondbUsersCompleteUsers: {industry: {${industryQuery}}, experiencelevel: {${workExpQuery}}, usercategory: {${jobCatagoryQuery}}, managingexperience: {${manageExpQuery}}}}, isdeleted: {_eq: "0"}}) {
                aggregate {
                  count(columns: user_id)
                }
              }
        }
    `

    const competencyScoresQuery = gql`
        query MyQuery($orgId: bigint!) {
            groupAvg: dashboard_sim_performance(distinct_on: user_id, where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: $orgId}, deal_name: {${dealQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
                user_id
                overall_competency_score
                simPerformanceFilters{
                    journey_tags
                  }
            } 
            benchmarkAvg: dashboard_sim_performance(distinct_on: user_id, where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, simPerformanceFilters: {is_deleted: {_eq: "0"}}}) {
                user_id
                overall_competency_score
                simPerformanceFilters{
                    journey_tags
                  }
              }  
        }       
    `

    const distributionsQuery = gql`
    query MyQuery($orgId: bigint!) {
        distributionsArray: dashboard_sim_performance(distinct_on: user_id, where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: $orgId}, deal_name: {${dealQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}){
          user_id
        overall_competency_score
        simPerformanceFilters{
            journey_tags
          }
      }    
    }   
    `

    const topPerformersQuery = gql`
    query MyQuery($orgId: bigint!) {
        topPerformersArray: dashboard_sim_performance(distinct_on: user_id, where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: $orgId}, deal_name: {${dealQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
          user_id
          overall_competency_score
          user_email
          simPerformanceFilters{
            journey_tags
          }
          simPerformanceFilterUid{
            user_firstname
            user_lastname
          }
        }
      }
    `

    const competenciesQuery = gql`
    query MyQuery($orgId: bigint!) {
        competencies: dashboard_sim_performance(distinct_on: user_id, where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: $orgId}, deal_name: {${dealQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}, order_by: {user_id: asc}) {
          user_id
          competencies
          simPerformanceFilters{
            journey_tags
          }
        }
      }
    `
                             
    const { loading: usersLoading, error: usersError, data: usersData } = useQuery(usersQuery, { variables: { orgId: orgId }},{fetchPolicy: 'no-cache'})
    const { loading: competencyScoresLoading, error: competencyScoresError, data: competencyScoresData } = useQuery(competencyScoresQuery, { variables: { orgId: orgId }},{fetchPolicy: 'no-cache'})
    const { loading: distributionsLoading, error: distributionsError, data: distributionsData } = useQuery(distributionsQuery, { variables: { orgId: orgId }},{fetchPolicy: 'no-cache'})
    const { loading: topPerformersLoading, error: topPerformersError, data: topPerformersData } = useQuery(topPerformersQuery, { variables: { orgId: orgId }},{fetchPolicy: 'no-cache'})  
    const { loading: competenciesLoading, error: competenciesError, data: competenciesData } = useQuery(competenciesQuery, { variables: { orgId: orgId }},{fetchPolicy: 'no-cache'})  

    useEffect(() => {
        if(!usersLoading && !usersError && usersData!=null)
        {
            dispatch({ type: UPDATE_AC_USERS, payload: usersData })
        }
    }, [usersLoading, usersError, usersData])  

    useEffect(() => {
        if(!competencyScoresLoading && !competencyScoresError && competencyScoresData!=null) {
            dispatch({ type: UPDATE_AC_COMPETENCY_SCORES, payload: competencyScoresData })
        }
    }, [competencyScoresLoading, competencyScoresError, competencyScoresData])

    useEffect(() => {
        if(!distributionsLoading && !distributionsError && distributionsData!=null) {
            dispatch({ type: UPDATE_AC_DISTRIBUTIONS, payload: distributionsData })
        }
    }, [distributionsLoading, distributionsError, distributionsData])

    useEffect(() => {
        if(!topPerformersLoading && !topPerformersError && topPerformersData!=null)
        {
            dispatch({ type: UPDATE_AC_TOP_PERFORMERS, payload: topPerformersData })
        }
    }, [topPerformersLoading, topPerformersError, topPerformersData])

    useEffect(() => {
        if(!competenciesLoading && !competenciesError && competenciesData!=null)
        {
            dispatch({ type: UPDATE_AC_COMPETENCIES, payload: competenciesData })
        }
    }, [competenciesLoading, competenciesError, competenciesData])

    if(competencyScoresError)
    {
        return <div>Error1..</div>
    }
    if(usersError)
    {
        console.log(usersError)
        return <div>Error2..</div>
    }
    if(distributionsError)
    {
        return <div>Error3..</div>
    }
    if(topPerformersError) {
        return <div>Error4..</div>
    }
    if(competenciesError)
    {
        return <div>Error5..</div>
    }

    const calculateDomainCompetency = (compName) => {
        let arr = []
        let journeyInstId
        let reference = [{'key': 'cq', 'values': ["influencing"]}, {'key': 'agile', 'values': ["customer_centricity", "change_agility", "collaboration", "result_focus"]}]
        let courseArray = orgLevelState.header.courses
        for(let i=0 ; i<reference.length ; i++)
        {
            for(let j=0 ; j<reference[i].values.length ; j++)
            {
                if(compName==reference[i].values[j])
                {
                    if(courseArray[0])
                    {
                        for(let k=0 ; k<courseArray[0].leadingNowArray.length ; k++)
                        {
                            if(reference[i].key==courseArray[0].leadingNowArray[k].tag)
                            {
                                journeyInstId = courseArray[0].leadingNowArray[k].id
                            }
                        }
                    }
                    if(courseArray[1])
                    {
                        for(let k=0 ; k<courseArray[1].leadingNextArray.length ; k++)
                        {
                            if(reference[i].key==courseArray[1].leadingNextArray[k].tag)
                            {
                                journeyInstId = courseArray[1].leadingNextArray[k].id
                            }
                        }
                    }
                    if(courseArray[2])
                    {
                        for(let k=0 ; k<courseArray[2].othersArray.length ; k++)
                        {
                            if(reference[i].key==courseArray[2].othersArray[k].tag)
                            {
                                journeyInstId = courseArray[2].othersArray[k].id
                            }
                        }   
                    }
                }
            }
        }
        return journeyInstId
    }

    //console.log(acState.competenciesAC.totalCompetenciesArray[1].lowCompetenciesArray)

    return (
                <div className={Styles.gridWrapper}>
                    <div className={Styles.heading} data-aos="fade-up" data-aos-once="true" data-aos-delay="350" data-aos-offset="10">
                        {
                            loading && 
                            <>
                                <BannerLoader />
                            </>
                        }
                        {
                            !loading && 
                            <BannerWithBackground description="This section refers to the performance of the learners in this organization across all the courses." /> 
                        }
                        
                    </div>
                    <div className={Styles.gridWrapper1} data-aos="fade-up" data-aos-once="true" data-aos-delay="700">
                        <div>
                            {
                                usersLoading && 
                                <>
                                    <UsersLoader />
                                </>
                            }
                            {
                                !usersLoading && 
                                <NoOfUsers infoBody="Total number of learners who have completed atleast one course" title="COMPLETED LEARNERS" count={acState.usersAC.learners} />
                            }
                        </div>
                        <div className={Styles.averageWhitebox}>
                            <WhiteBox>
                                <div className={Styles.averageContainer}>
                                    {
                                        competencyScoresLoading && 
                                        <>
                                            <AverageBenchmarkLoader />
                                        </>
                                    }
                                    {
                                        !competencyScoresLoading && 
                                        <>
                                            <div className={Styles.groupAverageHeading}>
                                                <GraphTitle title="GROUP AVERAGE" infoBody="Average of the scores of all the learners in this organization across all the courses" />
                                            </div>
                                            <div className={Styles.groupAverageScore}>
                                                <div className={Styles.scoreReceived}>{acState.competencyScoresAC.dataForHorizontalProgressBar1.score}</div>
                                                <div className={Styles.totalScore}>/10</div>
                                            </div>
                                            <div className={Styles.progressBar}>
                                                <ProgressBar {...acState.competencyScoresAC.dataForHorizontalProgressBar1} />
                                            </div>
                                        </>
                                    }
                                    <div className={Styles.lineDifferentiator}></div>
                                    {
                                        competencyScoresLoading && 
                                        <>
                                            <AverageBenchmarkLoader />
                                        </>
                                    }
                                    {
                                        !competencyScoresLoading && 
                                        <>
                                            <div className={Styles.groupAverageHeading}>
                                                <GraphTitle title="BENCHMARK" infoBody="Average of the scores of all the learners in the Universe across all the courses.*Universe refers to the entire set of learners who have accessed these courses until now" />
                                            </div>
                                            <div className={Styles.groupAverageScore}>
                                                <div className={Styles.scoreReceived}>{acState.competencyScoresAC.dataForHorizontalProgressBar2.score}</div>
                                                <div className={Styles.totalScore}>/10</div>
                                            </div>
                                            <div className={Styles.progressBar}>
                                                <ProgressBar {...acState.competencyScoresAC.dataForHorizontalProgressBar2} />
                                            </div>
                                        </>
                                    }
                                </div>
                            </WhiteBox>
                        </div>
                        <div className={Styles.barGraphContainer}>
                            <WhiteBox>
                                {
                                    distributionsLoading && 
                                    <DistributionLoader />
                                }
                                {
                                    !distributionsLoading && 
                                    <>
                                        <div className={Styles.barGraphHeading}>
                                            <GraphTitle title="DISTRIBUTION OF LEARNERS ACROSS PROFICIENCIES" infoBody="Distribution (%) of the learners in each of the five proficiency levels across all the courses" />
                                        </div>
                                        <div className={Styles.barGraphComponent}>
                                            <BarGraph {...acState.distributionsAC.dataForBarGraph1} />
                                        </div>
                                    </>
                                }
                            </WhiteBox>
                        </div>
                    </div>
                    <div className={Styles.gridWrapper3} data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <div className={Styles.topPerformersContainer}>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    {
                                        loading && 
                                        <TopPerformerTitleLoader />
                                    }
                                    {
                                        !loading && 
                                        <>
                                            <GraphTitle title={`TOP ${Math.ceil((topPerformersLength) / 10) * 10} PERFORMERS`} infoBody="Lists the top performers based on their overall score across all the courses" />
                                        </>
                                    }
                                </div>
                                <div className={Styles.tableContainer}>
                                    <table className={Styles.topPerformersList}>
                                        {
                                            acState.topPerformersAC.topPerformersArray.map(item => {
                                                return (
                                                        <React.Fragment key={item.userId}>
                                                            <tr className={Styles.eachPerformer}>
                                                                {
                                                                    topPerformersLoading && 
                                                                    <TopPerformerLoader />
                                                                }
                                                                {
                                                                    !topPerformersLoading && 
                                                                    <>
                                                                        <td className={Styles.performerName}>{item.name}</td>
                                                                        <td className={Styles.performerEmail}>{item.email}</td>
                                                                        <td className={Styles.performerScoreContainer}>
                                                                            <div className={Styles.performerScore}>{item.score}</div>
                                                                            <div className={Styles.performerTotal}>/10</div>
                                                                        </td>
                                                                    </>
                                                                }
                                                            </tr>
                                                            <tr>
                                                                <td><div className={Styles.topPerformersEachItemLine}></div></td>
                                                                <td><div className={Styles.topPerformersEachItemLine}></div></td>
                                                                <td><div className={Styles.topPerformersEachItemLine}></div></td>
                                                            </tr>
                                                            
                                                        </React.Fragment>
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            </WhiteBox>
                        </div>
                        <div className={Styles.gridWrapperComp}>
                            <div className={Styles.gridWrapper2}>
                                <div className={Styles.headingText}>
                                    <GraphTitle title="TOP COMPETENCIES" infoBody="Top competencies across all the courses in which learners have achieved high scores (score higher than 5)" />
                                </div>
                                {
                                    acState.competenciesAC.totalCompetenciesArray[0].topCompetenciesArray.map(item => {
                                        return (
                                            <div className={Styles.competencyBox} key={item.compName}>
                                                {
                                                    competenciesLoading && 
                                                    <CompetencyLoader />
                                                }
                                                {
                                                    !competenciesLoading && 
                                                    <CompetencyBox title={item.compName} jid={calculateDomainCompetency(item.compName)} />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>  
                            <div className={Styles.gridWrapper2}>
                                <div className={Styles.headingText}>
                                    <GraphTitle title="COMPETENCIES THAT NEED IMPROVEMENT" infoBody="Competencies across all the courses in which learners have achieved low scores (score lower than 5)" />
                                </div>
                                {
                                    acState.competenciesAC.totalCompetenciesArray[1].lowCompetenciesArray.map(item => {
                                        return (
                                            <div className={Styles.competencyBox} key={item.compName}>
                                                {
                                                    competenciesLoading && 
                                                    <CompetencyLoader />
                                                }
                                                {
                                                    !competenciesLoading && 
                                                    <CompetencyBox title={item.compName} jid={calculateDomainCompetency(item.compName)} />
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default PerformanceTab
