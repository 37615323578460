import { 
    UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB, UPDATE_LINE_CHART_ACTIVITY_TAB, 
    UPDATE_PIE_CHART_ACTIVITY_TAB, UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB,
    UPDATE_HORIZONTAL_BENCHMARKGROUPBAR2_ACTIVITY_TAB, UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB, 
    UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB, UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB, UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB,
    UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB, UPDATE_NO_OF_LEARNERS_WHOACCESSED_ACTIVITY_TAB,
    UPDATE_AVG_NO_OF_COURSES_ACCESSED_ACTIVITY_TAB, UPDATE_AVG_NO_OF_COURSES_COMPLETED_ACTIVITY_TAB, UPDATE_LEARNERS_ALL_ORG_ACTIVITY_TAB,
    UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_CQ, UPDATE_LINE_CHART_ACTIVITY_TAB_CQ, UPDATE_PIE_CHART_ACTIVITY_TAB_CQ, 
    UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_CQ, UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_CQ, UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_CQ,    
    
    UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_IL, UPDATE_LINE_CHART_ACTIVITY_TAB_IL, UPDATE_PIE_CHART_ACTIVITY_TAB_IL, 
    UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_IL, UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_IL, UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_IL,
    UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_IL, UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_IL, UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_IL,
    UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_CQ, UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_CQ, UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_CQ, UPDATE_BANNER_DESC_ACTIVITY_TAB_CQ,

    UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_Trust, UPDATE_LINE_CHART_ACTIVITY_TAB_Trust, UPDATE_PIE_CHART_ACTIVITY_TAB_Trust, 
    UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_Trust, UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_Trust, UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_Trust,
    UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_Trust, UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_Trust, UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_Trust,

    UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_COACHINGSIM, UPDATE_LINE_CHART_ACTIVITY_TAB_COACHINGSIM, UPDATE_PIE_CHART_ACTIVITY_TAB_COACHINGSIM, 
    UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_COACHINGSIM, UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_COACHINGSIM, UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_COACHINGSIM,
    UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_COACHINGSIM, UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_COACHINGSIM, UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_COACHINGSIM,

    UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_AGILE,UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_AGILE,UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_AGILE,
	UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_AGILE,UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_AGILE,UPDATE_PIE_CHART_ACTIVITY_TAB_AGILE,UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_AGILE,
	UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_AGILE, UPDATE_LINE_CHART_ACTIVITY_TAB_AGILE, UPDATE_BANNER_DESC_ACTIVITY_TAB_AGILE
} from '../../constants/actions'

let allCoursesHorizontalProgressBar = {'Total': 100, 'score': 0 };
let cqHorizontalProgressBar = {'Total': 100, 'score': 0 };

let allCoursesLineChart = {
    'data':[],                    
}
let cqLineChart = {
    'data':[],                    
}

for(let i = 1; i <= 100; i++ ){
    allCoursesLineChart.data.push(
        {'Days':i, '% Of Users': Math.floor(0)},
    );
    cqLineChart.data.push(
        {'Days':i, '% Of Users': Math.floor(0)},
    );
}

let allCoursesPieChart = {'TotalInner': 100, 'scoreInner': 0, 'TotalOuter': 100, 'scoreOuter': 0};
let cqPieChart = {'TotalInner': 100, 'scoreInner': 0, 'TotalOuter': 100, 'scoreOuter': 0};


let allCoursesHorizontalBenchmarkGroupBar = { 
                                                'data': [
                                                    { Title: 'Group', '% Of Users': 0 },
                                                    { Title: 'Benchmark', '% Of Users': 0 },
                                                ],
}
let cqHorizontalBenchmarkGroupBar = { 
                                                'data': [
                                                    { Title: 'Group', '% Of Users': 0 },
                                                    { Title: 'Benchmark', '% Of Users': 0 },
                                                ],
}

let allCoursesHorizontalBenchmarkGroupBar2 = { 
    'data': [
        { Title: 'Group', '% Of Users': 0 },
        { Title: 'Benchmark', '% Of Users': 0 },
    ],
}

if(window.screen.width > 1910 && window.screen.width < 2550){
    //for FHD Screens
    allCoursesPieChart['height']= 265; allCoursesPieChart['width']= 265;
    allCoursesHorizontalBenchmarkGroupBar['height'] = 93; allCoursesHorizontalBenchmarkGroupBar['width'] = 412;
    allCoursesHorizontalBenchmarkGroupBar2['height'] = 93; allCoursesHorizontalBenchmarkGroupBar2['width'] = 412;
    allCoursesHorizontalProgressBar['height'] = 15; allCoursesHorizontalProgressBar['width'] = 218; 
    allCoursesLineChart['height'] = 235 ; allCoursesLineChart['width'] = 705; 
    cqPieChart['height']= 265; cqPieChart['width']= 265;
    cqHorizontalBenchmarkGroupBar['height'] = 93; cqHorizontalBenchmarkGroupBar['width'] = 412;
    cqHorizontalProgressBar['height'] = 15; cqHorizontalProgressBar['width'] = 218; 
    cqLineChart['height'] = 235 ; cqLineChart['width'] = 1388; 
}else if(window.screen.width >= 2551){
    //for QHD Screens
    allCoursesPieChart['height']= 340; allCoursesPieChart['width']= 340;
    allCoursesHorizontalBenchmarkGroupBar['height'] = 110; allCoursesHorizontalBenchmarkGroupBar['width'] = 550;
    allCoursesHorizontalBenchmarkGroupBar2['height'] = 110; allCoursesHorizontalBenchmarkGroupBar2['width'] = 550;
    allCoursesHorizontalProgressBar['height'] = 20; allCoursesHorizontalProgressBar['width'] = 300; 
    allCoursesLineChart['height'] = 335 ; allCoursesLineChart['width'] = 950; 
    cqPieChart['height']= 340; cqPieChart['width']= 340;
    cqHorizontalBenchmarkGroupBar['height'] = 110; cqHorizontalBenchmarkGroupBar['width'] = 550;
    cqHorizontalProgressBar['height'] = 20; cqHorizontalProgressBar['width'] = 300; 
    cqLineChart['height'] = 335 ; cqLineChart['width'] = 1850; 
}else{
    //for HD Screens
    allCoursesPieChart['height']= 175; allCoursesPieChart['width']= 175;
    allCoursesHorizontalBenchmarkGroupBar['height'] = 62; allCoursesHorizontalBenchmarkGroupBar['width'] = 275;
    allCoursesHorizontalBenchmarkGroupBar2['height'] = 62; allCoursesHorizontalBenchmarkGroupBar2['width'] = 275;
    allCoursesHorizontalProgressBar['height'] = 10; allCoursesHorizontalProgressBar['width'] = 145; 
    allCoursesLineChart['height'] = 157 ; allCoursesLineChart['width'] = 470; 
    cqPieChart['height']= 175; cqPieChart['width']= 175;
    cqHorizontalBenchmarkGroupBar['height'] = 62; cqHorizontalBenchmarkGroupBar['width'] = 275;
    cqHorizontalProgressBar['height'] = 10; cqHorizontalProgressBar['width'] = 145; 
    cqLineChart['height'] = 157 ; cqLineChart['width'] = 925; 
}

const initialState = {
    allCourses: {
        dataForHorizontalProgressBar: allCoursesHorizontalProgressBar,
        dataForLineChart: allCoursesLineChart,
        dataForPieChart: allCoursesPieChart,
        dataForHorizontalBenchmarkGroupBar: allCoursesHorizontalBenchmarkGroupBar,
        dataForHorizontalBenchmarkGroupBar2: allCoursesHorizontalBenchmarkGroupBar2,
        noOfCompletedLearners: 1,
        enrolledLearners: 1,
        averageTimeSpent: 1,
        avgCourseProgress: 1,
        noOfLearnersSignedUp: 1,
        noOfLearnersWhoAccessed: 1,
        avgNoOfCoursesAccessed: 1,
        avgNoOfCoursesCompleted: 1,
        totalLearnersAllOrg: 1,
    },
    cq: {
        dataForHorizontalProgressBarCQ: cqHorizontalProgressBar,
        dataForPieChartCQ: cqPieChart,
        dataForHorizontalBenchmarkGroupBarCQ:cqHorizontalBenchmarkGroupBar,
        dataForLineChartCQ: cqLineChart,
        noOfCompletedLearnersCQ: 1,
        enrolledLearnersCQ: 1,
        averageTimeSpentCQ: 1,
        avgCourseProgressCQ: 1,
        noOfLearnersSignedUpCQ: 1,
        bannerDesc: {
            description:'',
            name: '',
            domainCategory: []
        }

    },
    iLead: {
        dataForHorizontalProgressBariLead: cqHorizontalProgressBar,
        dataForPieChartiLead: cqPieChart,
        dataForHorizontalBenchmarkGroupBariLead:cqHorizontalBenchmarkGroupBar,
        dataForLineChartiLead: cqLineChart,
        noOfCompletedLearnersiLead: 1,
        enrolledLearnersiLead: 1,
        averageTimeSpentiLead: 1,
        avgCourseProgressiLead: 1,
        noOfLearnersSignedUpiLead: 1,
    },
    Trust: {
        dataForHorizontalProgressBarTrust: cqHorizontalProgressBar,
        dataForPieChartTrust: cqPieChart,
        dataForHorizontalBenchmarkGroupBarTrust:cqHorizontalBenchmarkGroupBar,
        dataForLineChartTrust: cqLineChart,
        noOfCompletedLearnersTrust: 1,
        enrolledLearnersTrust: 1,
        averageTimeSpentTrust: 1,
        avgCourseProgressTrust: 1,
        noOfLearnersSignedUpTrust: 1,
    },
    CoachingSim: {
        dataForHorizontalProgressBarCoachingSim: cqHorizontalProgressBar,
        dataForPieChartCoachingSim: cqPieChart,
        dataForHorizontalBenchmarkGroupBarCoachingSim:cqHorizontalBenchmarkGroupBar,
        dataForLineChartCoachingSim: cqLineChart,
        noOfCompletedLearnersCoachingSim: 1,
        enrolledLearnersCoachingSim: 1,
        averageTimeSpentCoachingSim: 1,
        avgCourseProgressCoachingSim: 1,
        noOfLearnersSignedUpCoachingSim: 1,
    },
    Agile: {
        dataForHorizontalProgressBarAGILE: cqHorizontalProgressBar,
        dataForPieChartAGILE: cqPieChart,
        dataForHorizontalBenchmarkGroupBarAGILE:cqHorizontalBenchmarkGroupBar,
        dataForLineChartAGILE: cqLineChart,
        noOfCompletedLearnersAGILE: 1,
        enrolledLearnersAGILE: 1,
        averageTimeSpentAGILE: 1,
        avgCourseProgressAGILE: 1,
        noOfLearnersSignedUpAGILE: 1,
        bannerDesc: {
            description:'',
            name: '',
            domainCategory: []
        }
    }
}

const bannerFirstDescription = (dataArray) => {
    if(dataArray.length==0)
    {
        return ""
    }
    return dataArray[0].filterString[0].langvalue
}

const bannerFirstName = (dataArray) => {
    if(dataArray.length==0)
    {
        return ""
    }
    return dataArray[0].filterStringName[0].langvalue
}

const bannerDomainArray = (dataArray) => {
    let arr = []
    if(dataArray.length==0)
    {
        return arr
    }
    if(dataArray[0].journey_domain && dataArray[0].journey_categories)
    {
        arr.push(dataArray[0].journey_domain)
        arr.push(dataArray[0].journey_categories)
    }
    return arr
}

const calculateAverageLearners = (array) => {
    if(array.length==0)
    {
        return 0
    }
    let resArray = []
    for(let i=0 ; i<array.length ; i++)
    {
        let j=resArray.findIndex(item => item.userid == array[i].user_id)
        if(j!=-1)
        {
            resArray[j].count = resArray[j].count + 1
        }
        else {
            resArray.push({'userid':  array[i].user_id, 'count':1})
        }
    }
    if(resArray.length==0)
    {
        return 0
    }
    let total=0
    for(let i=0 ; i<resArray.length ; i++)
    {
        total = total + resArray[i].count
    }
    total = Math.round((total/resArray.length)*10)/10
    return total
}

export function activityTabReducer(state=initialState, action) {
    switch(action.type) {
        case UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    dataForHorizontalProgressBar:{
                        ...state.allCourses.dataForHorizontalProgressBar,
                        score : action.payload
                    } 
                }
            }
        case UPDATE_LINE_CHART_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    dataForLineChart: {
                        ...state.allCourses.dataForLineChart,
                        data: action.payload
                    }
                }
            }
        case UPDATE_PIE_CHART_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    dataForPieChart:{
                        ...state.allCourses.dataForPieChart,
                        scoreInner: action.payload[0],
                        scoreOuter: action.payload[1]
                    }
                }
            }
        case UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    dataForHorizontalBenchmarkGroupBar:{
                        ...state.allCourses.dataForHorizontalBenchmarkGroupBar,
                        data:[{
                                ...state.allCourses.dataForHorizontalBenchmarkGroupBar.data[0],
                                '% Of Users':action.payload[0]
                            },
                            {
                                ...state.allCourses.dataForHorizontalBenchmarkGroupBar.data[1],
                                '% Of Users':action.payload[1]
                            }
                        ]
                    }
                }
            }
        case UPDATE_HORIZONTAL_BENCHMARKGROUPBAR2_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    dataForHorizontalBenchmarkGroupBar2:{
                        ...state.allCourses.dataForHorizontalBenchmarkGroupBar2,
                        data:[{
                                ...state.allCourses.dataForHorizontalBenchmarkGroupBar2.data[0],
                                '% Of Users':action.payload[0]
                            },
                            {
                                ...state.allCourses.dataForHorizontalBenchmarkGroupBar2.data[1],
                                '% Of Users':action.payload[1]
                            }
                        ]
                    }
                }
            }
        case UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    noOfCompletedLearners: action.payload
                }
            }
        case UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    enrolledLearners: action.payload
                }
            }
        case UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    averageTimeSpent: action.payload
                }
            }
        case UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    avgCourseProgress: action.payload
                }
            }
        case UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    noOfLearnersSignedUp: action.payload
                }
            }
        case UPDATE_NO_OF_LEARNERS_WHOACCESSED_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    noOfLearnersWhoAccessed: action.payload
                }
            }
        case UPDATE_AVG_NO_OF_COURSES_ACCESSED_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    avgNoOfCoursesAccessed: calculateAverageLearners(action.payload.learnersAccessed) 
                }
            }
        case UPDATE_AVG_NO_OF_COURSES_COMPLETED_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    avgNoOfCoursesCompleted: calculateAverageLearners(action.payload.learnersCompleted)
                }
            }

        case UPDATE_LEARNERS_ALL_ORG_ACTIVITY_TAB:
            return {
                ...state,
                allCourses:{
                    ...state.allCourses,
                    totalLearnersAllOrg: action.payload
                }
            }

        case UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    dataForHorizontalProgressBarCQ:{
                        ...state.cq.dataForHorizontalProgressBarCQ,
                        score : action.payload
                    } 
                }
            }
        case UPDATE_LINE_CHART_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    dataForLineChartCQ: {
                        ...state.cq.dataForLineChartCQ,
                        data: action.payload
                    }
                }
            }
        case UPDATE_PIE_CHART_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    dataForPieChartCQ:{
                        ...state.cq.dataForPieChartCQ,
                        scoreInner: action.payload[0],
                        scoreOuter: action.payload[1]
                    }
                }
            }
        case UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    dataForHorizontalBenchmarkGroupBarCQ:{
                        ...state.cq.dataForHorizontalBenchmarkGroupBarCQ,
                        data:[{
                                ...state.cq.dataForHorizontalBenchmarkGroupBarCQ.data[0],
                                '% Of Users':action.payload[0]
                            },
                            {
                                ...state.cq.dataForHorizontalBenchmarkGroupBarCQ.data[1],
                                '% Of Users':action.payload[1]
                            }
                        ]
                    }
                }
            }
        case UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    noOfCompletedLearnersCQ: action.payload
                }
            }
        case UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    enrolledLearnersCQ: action.payload
                }
            }
        case UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    averageTimeSpentCQ: action.payload
                }
            }
        case UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    avgCourseProgressCQ: action.payload
                }
            }
        case UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    noOfLearnersSignedUpCQ: action.payload
                }
            }
        case UPDATE_BANNER_DESC_ACTIVITY_TAB_CQ:
            return {
                ...state,
                cq:{
                    ...state.cq,
                    bannerDesc: {
                        description: bannerFirstDescription(action.payload),
                        name: bannerFirstName(action.payload),
                        domainCategory: bannerDomainArray(action.payload)
                    }
                }
            }
        case UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    dataForHorizontalProgressBariLead:{
                        ...state.iLead.dataForHorizontalProgressBariLead,
                        score : action.payload
                    } 
                }
            }
        case UPDATE_LINE_CHART_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    dataForLineChartiLead: {
                        ...state.iLead.dataForLineChartiLead,
                        data: action.payload
                    }
                }
            }
        case UPDATE_PIE_CHART_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    dataForPieChartiLead:{
                        ...state.iLead.dataForPieChartiLead,
                        scoreInner: action.payload[0],
                        scoreOuter: action.payload[1]
                    }
                }
            }
        case UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    dataForHorizontalBenchmarkGroupBariLead:{
                        ...state.iLead.dataForHorizontalBenchmarkGroupBariLead,
                        data:[{
                                ...state.iLead.dataForHorizontalBenchmarkGroupBariLead.data[0],
                                '% Of Users':action.payload[0]
                            },
                            {
                                ...state.iLead.dataForHorizontalBenchmarkGroupBariLead.data[1],
                                '% Of Users':action.payload[1]
                            }
                        ]
                    }
                }
            }
        case UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    noOfCompletedLearnersiLead: action.payload
                }
            }
        case UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    enrolledLearnersiLead: action.payload
                }
            }
        case UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    averageTimeSpentiLead: action.payload
                }
            }
        case UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    avgCourseProgressiLead: action.payload
                }
            }
        case UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_IL:
            return {
                ...state,
                iLead:{
                    ...state.iLead,
                    noOfLearnersSignedUpiLead: action.payload
                }
            }
            
        case UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    dataForHorizontalProgressBarTrust:{
                        ...state.Trust.dataForHorizontalProgressBarTrust,
                        score : action.payload
                    } 
                }
            }
        case UPDATE_LINE_CHART_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    dataForLineChartTrust: {
                        ...state.Trust.dataForLineChartTrust,
                        data: action.payload
                    }
                }
            }
        case UPDATE_PIE_CHART_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    dataForPieChartTrust:{
                        ...state.Trust.dataForPieChartTrust,
                        scoreInner: action.payload[0],
                        scoreOuter: action.payload[1]
                    }
                }
            }
        case UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    dataForHorizontalBenchmarkGroupBarTrust:{
                        ...state.Trust.dataForHorizontalBenchmarkGroupBarTrust,
                        data:[{
                                ...state.Trust.dataForHorizontalBenchmarkGroupBarTrust.data[0],
                                '% Of Users':action.payload[0]
                            },
                            {
                                ...state.Trust.dataForHorizontalBenchmarkGroupBarTrust.data[1],
                                '% Of Users':action.payload[1]
                            }
                        ]
                    }
                }
            }
        case UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    noOfCompletedLearnersTrust: action.payload
                }
            }
        case UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    enrolledLearnersTrust: action.payload
                }
            }
        case UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    averageTimeSpentTrust: action.payload
                }
            }
        case UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    avgCourseProgressTrust: action.payload
                }
            }
        case UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_Trust:
            return {
                ...state,
                Trust:{
                    ...state.Trust,
                    noOfLearnersSignedUpTrust: action.payload
                }
            }

        case UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    dataForHorizontalProgressBarCoachingSim:{
                        ...state.CoachingSim.dataForHorizontalProgressBarCoachingSim,
                        score : action.payload
                    } 
                }
        }
        case UPDATE_LINE_CHART_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    dataForLineChartCoachingSim: {
                        ...state.CoachingSim.dataForLineChartCoachingSim,
                        data: action.payload
                    }
                }
            }
        case UPDATE_PIE_CHART_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    dataForPieChartCoachingSim:{
                        ...state.CoachingSim.dataForPieChartCoachingSim,
                        scoreInner: action.payload[0],
                        scoreOuter: action.payload[1]
                    }
                }
            }
        case UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    dataForHorizontalBenchmarkGroupBarCoachingSim:{
                        ...state.CoachingSim.dataForHorizontalBenchmarkGroupBarCoachingSim,
                        data:[{
                                ...state.CoachingSim.dataForHorizontalBenchmarkGroupBarCoachingSim.data[0],
                                '% Of Users':action.payload[0]
                            },
                            {
                                ...state.CoachingSim.dataForHorizontalBenchmarkGroupBarCoachingSim.data[1],
                                '% Of Users':action.payload[1]
                            }
                        ]
                    }
                }
            }
        case UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    noOfCompletedLearnersCoachingSim: action.payload
                }
            }
        case UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    enrolledLearnersCoachingSim: action.payload
                }
            }
        case UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    averageTimeSpentCoachingSim: action.payload
                }
            }
        case UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    avgCourseProgressCoachingSim: action.payload
                }
            }
        case UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_COACHINGSIM:
            return {
                ...state,
                CoachingSim:{
                    ...state.CoachingSim,
                    noOfLearnersSignedUpCoachingSim: action.payload
                }
            }
        
        case UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    dataForHorizontalProgressBarAGILE:{
                        ...state.Agile.dataForHorizontalProgressBarAGILE,
                        score : action.payload
                    } 
                }
            }
        case UPDATE_LINE_CHART_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    dataForLineChartAGILE: {
                        ...state.Agile.dataForLineChartAGILE,
                        data: action.payload
                    }
                }
            }
        case UPDATE_PIE_CHART_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    dataForPieChartAGILE:{
                        ...state.Agile.dataForPieChartAGILE,
                        scoreInner: action.payload[0],
                        scoreOuter: action.payload[1]
                    }
                }
            }
        case UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    dataForHorizontalBenchmarkGroupBarAGILE:{
                        ...state.Agile.dataForHorizontalBenchmarkGroupBarAGILE,
                        data:[{
                                ...state.Agile.dataForHorizontalBenchmarkGroupBarAGILE.data[0],
                                '% Of Users':action.payload[0]
                            },
                            {
                                ...state.Agile.dataForHorizontalBenchmarkGroupBarAGILE.data[1],
                                '% Of Users':action.payload[1]
                            }
                        ]
                    }
                }
            }
        case UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    noOfCompletedLearnersAGILE: action.payload
                }
            }
        case UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    enrolledLearnersAGILE: action.payload
                }
            }
        case UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    averageTimeSpentAGILE: action.payload
                }
            }
        case UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    avgCourseProgressAGILE: action.payload
                }
            }
        case UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    noOfLearnersSignedUpAGILE: action.payload
                }
            }
        case UPDATE_BANNER_DESC_ACTIVITY_TAB_AGILE:
            return {
                ...state,
                Agile:{
                    ...state.Agile,
                    bannerDesc: {
                        description: bannerFirstDescription(action.payload),
                        name: bannerFirstName(action.payload),
                        domainCategory: bannerDomainArray(action.payload)
                    }
                }
            }
        
            
        default:
            return state
    }
}