import React, { useEffect, useState } from 'react'
import Styles from './PerformanceTabAgile.module.css'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import NoOfUsers from '../../commonComponents/textComponent/NoOfUsers/User'
import WhiteBox from '../WhiteBox/WhiteBox'
import BarGraph from '../../commonComponents/GraphComponents/BarGraph'
import ProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GroupHorizontalBar from '../../commonComponents/GraphComponents/GroupHorizontalBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import TargetAchieved from '../../commonComponents/textComponent/TargetAchieved/TargetAchievedPercent'
import GroupVerticalBar from '../../commonComponents/GraphComponents/GroupVerticalBar'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import BarBenchmark from '../../commonComponents/GraphComponents/BarBenchmarkGroup'
import BarBenchmarkNumber from '../../commonComponents/GraphComponents/BarBenchmarkNumber'
import BarGraphGroupBenchmark from '../../commonComponents/GraphComponents/BarGraphGroupBenchmark'
import BarGraphLegend from '../../commonComponents/GraphComponents/BarGraphLegend'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import CompetencyList from '../../commonComponents/textComponent/CompetenciesList/CourseCompitency'
import BarAccuracy from '../../commonComponents/GraphComponents/BarAccuracyProportionFrequency'
import TitleDropdown from '../../commonComponents/textComponent/TitleDropdown/TitleDropdown' 
import BannerLoader from '../../commonComponents/Loaders/PerformanceTabAll/BannerLoader'
import UsersLoader from '../../commonComponents/Loaders/PerformanceTabAll/UsersLoader'
import AverageBenchmarkLoader from '../../commonComponents/Loaders/PerformanceTabAll/AverageBenchmarkLoader'
import DistributionWhiteboxLoader from '../../commonComponents/Loaders/PerformanceTabAll/DistributionWhiteboxLoader'
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { useHistory, useParams } from 'react-router-dom'
import RowWiseLoader from '../../commonComponents/Loaders/PerformanceTabAll/RowWiseLoader'
import { useSelector, useDispatch } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';
import BarGraphLegend1 from '../../commonComponents/GraphComponents/BarGraphLegend1'
import GroupVerticalLegend from '../../commonComponents/GraphComponents/GroupVerticalLegend'
import { UPDATE_AS_USERS, UPDATE_IL_DISTRIBUTIONS, UPDATE_IL_BANNER_FIRST, UPDATE_IL_LEADERSHIP, UPDATE_IL_LEARNERS, UPDATE_CS_COMPETENCY_SCORES, UPDTAE_CS_DISTRIBUTIONS, UPDATE_CS_GROW, UPDATE_AS_COMPETENCY_SCORES, UPDATE_AS_DISTRIBUTIONS, UPDATE_AS_SKILLS, UPDATE_AS_BANNER_FIRST } from '../../constants/actions'


function PerformanceTabAgile(props) {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { orgId, id } = useParams()
    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const state = useSelector(state => state.performanceTab.iLead)
    const ilState = useSelector(state => state.performanceTab)
    const orgLevelState = useSelector(state => state.orgLevelReducer)
	const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)
    const [titleCourse, setTitleCourse] = useState('Customer Centricity')

    let conditionalDistribution 
        if(titleCourse==='Customer Centricity')
        {   
            //console.log(1)
            conditionalDistribution = <BarGraph {...ilState.distributionsAS.dataForBarGraphCentricity} />
        }
        else if(titleCourse==='Change Agility')
        {
            //console.log(2)
            conditionalDistribution = <BarGraph {...ilState.distributionsAS.dataForBarGraphAgility} />
        }
        else if(titleCourse==='Collaboration')
        {
            //console.log(3)
            conditionalDistribution = <BarGraph {...ilState.distributionsAS.dataForBarGraphCollaboration} />
        }
        else {
            //console.log(5)
            conditionalDistribution = <BarGraph {...ilState.distributionsAS.dataForBarGraphFocus} />
        }

        let formatQuery = ''
        let formatToSearch = []
        let formatToSearchString = [] 
        formatToSearch = filterCheckedListState.learningFormats;
        if(formatToSearch[0] === "All Formats"){
            // i.e. when 'All Formats' is selected in Learning Format
            formatQuery = ''
        }
        else{
            formatToSearch.forEach((eachFormat) =>{
                formatToSearchString.push('"'+eachFormat+'"')
            })
            formatQuery = `_in: [${formatToSearchString}]`
        }
    
        let dealQuery = ''
        let dealToSearch = []
        let dealToSearchString = [] 
        dealToSearch = filterCheckedListState.deals;
        if(dealToSearch[0] === "All Deals"){
            // i.e. when 'All Deals' is selected in Deal
            dealQuery = ''
        }
        else{
            dealToSearch.forEach((eachDeal) =>{
                dealToSearchString.push('"'+eachDeal+'"')
            })
            dealQuery = `_in: [${dealToSearchString}]`
        }
    
        let batchQuery = ''
        let batchToSearch = []
        let batchToSearchString = [] 
        batchToSearch = filterCheckedListState.batches;
        if(batchToSearch[0] === "All Batches"){
            // i.e. when 'All Batches' is selected in Batch
            batchQuery = ''
        }
        else{
            batchToSearch.forEach((eachBatch) =>{
                batchToSearchString.push('"'+eachBatch+'"')
            })
            batchQuery = `_in: [${batchToSearchString}]`
        }
    
        let industryQuery = ''
        let industryToSearch = []
        let industryToSearchString = [] 
        industryToSearch = filterCheckedListState.industries;
        if(industryToSearch[0] === undefined){ 
            // i.e. when 'ALL' is selected in DEMOGRAPHICS > INDUSTRY
            industryQuery = ''
        }
        else{
            industryToSearch.forEach((eachIndustry) =>{
                industryToSearchString.push('"'+eachIndustry+'"')
            })
            industryQuery = `_in: [${industryToSearchString}]`
        }
    
        let jobCatagoryQuery = ''
        let jobCatagoryToSearch = []
        let jobCatagoryToSearchString = [] 
        jobCatagoryToSearch = filterCheckedListState.jobCategories;
        if(jobCatagoryToSearch[0] === undefined){
            // i.e. when 'ALL' is selected in DEMOGRAPHICS > JOB CATAGORY
            jobCatagoryQuery = ''
        }
        else{
            jobCatagoryToSearch.forEach((eachjobCatagory) =>{
                jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
            })
            jobCatagoryQuery = `_in: [${jobCatagoryToSearchString}]`
        }
    
        let workExpQuery = ''
        let workExpToSearch = []
        let workExpToSearchString = [] 
        workExpToSearch = filterCheckedListState.workExperiences;
        if(workExpToSearch[0] === "All"){
            // i.e. when 'ALL' is selected in DEMOGRAPHICS > Work Experience
            workExpQuery = ''
        }
        else{
            workExpToSearch.forEach((eachworkExp) =>{
                workExpToSearchString.push('"'+eachworkExp+'"')
            })
            workExpQuery = `_in: [${workExpToSearchString}]`
        }
    
        let manageExpQuery = ''
        let manageExpToSearch = []
        let manageExpToSearchString = [] 
        manageExpToSearch = filterCheckedListState.peopleManagementExperiences;
        if(manageExpToSearch[0] === "All"){
            // i.e. when 'ALL' is selected in DEMOGRAPHICS > People Management Experience
            manageExpQuery = ''
        }
        else{
            manageExpToSearch.forEach((eachManageExp) =>{
                manageExpToSearchString.push('"'+eachManageExp+'"')
            })
            manageExpQuery = `_in: [${manageExpToSearchString}]`
        }    

    const bannerFirstQuery = gql`
    query MyQuery {
        bannerFirstDetails: dashboard_filters(distinct_on: journey_instance_id, where: {organization_id: {_eq: ${orgId}}, journey_instance_id: {_eq: ${id}}})
        {
          journey_domain
          journey_categories
          filterString{
            langvalue
          }
          filterStringName{
            langvalue
          }
        }
      }
    `    

    // const usersQuery = gql`
    // query MyQuery {
    //     users: dashboard_sim_performance(distinct_on: [user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
    //         user_id
    //         simPerformanceFilters{
    //           journey_tags
    //         }
    //     }
    //   }
    // `    

    const usersQuery = gql`
	query MyQuery {
		
		learnersSignedUp: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {batch_name: {${batchQuery}}}}}}}}}}}, _and: {isdeleted: {_eq: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}`

    const competencyScoresQuery = gql`
    query MyQuery {
        groupAvg: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            overall_competency_score
            simPerformanceFilters{
              journey_tags
            }
        }
        benchmarkAvg : dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}}) {
            overall_competency_score
            simPerformanceFilters{
              journey_tags
            }
          }
        groupGraph: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            competencies
            simPerformanceFilters{
                journey_tags
              }
          }
        benchmarkGraph: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}}) {
            competencies
            simPerformanceFilters{
                journey_tags
              }
          }
        target: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
      }
    `    
     const distributionsQuery = gql`
     query MyQuery {
        distributions: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
                competencies
                simPerformanceFilters{
                    journey_tags
                  }
            }
        efficiencyGroup: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
        }
        efficiencyBenchmark: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
      }
     ` 

     const skillsQuery = gql`
     query MyQuery {
        skill: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
                scores
                simPerformanceFilters{
                    journey_tags
                  }
                }
        skillBenchmark: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "agilesim"}}) {
                    scores
                    simPerformanceFilters{
                        journey_tags
                      }
                  }
      }
     `  

    const { loading: bannerFirstLoading, error: bannerFirstError, data: bannerFirstData } = useQuery(bannerFirstQuery, {fetchPolicy: 'no-cache'}) 
    const { loading: usersLoading, error: usersError, data: usersData } = useQuery(usersQuery, {fetchPolicy: 'no-cache'}) 
    const { loading: competencyScoresLoading, error: competencyScoresError, data: competencyScoresData } = useQuery(competencyScoresQuery, {fetchPolicy: 'no-cache'})
    const { loading: distributionsLoading, error: distributionsError, data: distributionsData } = useQuery(distributionsQuery, {fetchPolicy: 'no-cache'})   
    const { loading: skillsLoading, error: skillsError, data: skillsData } = useQuery(skillsQuery, {fetchPolicy: 'no-cache'})

    useEffect(() => {
        if(!bannerFirstLoading && !bannerFirstError && bannerFirstData!=null)
        {
            dispatch({ type: UPDATE_AS_BANNER_FIRST, payload: bannerFirstData })
        }
    }, [bannerFirstLoading, bannerFirstError, bannerFirstData])  

    useEffect(() => {
        if(!usersLoading && !usersError && usersData!=null)
        {
            console.log(usersData)
            dispatch({ type: UPDATE_AS_USERS, payload: usersData })
        }
    }, [usersLoading, usersError, usersData])  

    useEffect(() => {
        if(!competencyScoresLoading && !competencyScoresError && competencyScoresData!=null)
        {
            dispatch({ type: UPDATE_AS_COMPETENCY_SCORES, payload: competencyScoresData })
        }
    }, [competencyScoresLoading, competencyScoresError, competencyScoresData])

    useEffect(() => {
        if(!distributionsLoading && !distributionsError && distributionsData!=null)
        {
            dispatch({ type:  UPDATE_AS_DISTRIBUTIONS, payload:  distributionsData })
        }
    }, [distributionsLoading, distributionsError, distributionsData])

    useEffect(() => {
        if(!skillsLoading && !skillsError && skillsData!=null)
        {
            dispatch({ type: UPDATE_AS_SKILLS, payload: skillsData })
        }
    }, [skillsLoading, skillsError, skillsData]) 

    if(competencyScoresError, distributionsError, skillsError, usersError, bannerFirstError)
    {
        return <div>Error..</div>
    }

    console.log(ilState.usersAS.totalLearners)
      
    return (
        <div className={Styles.gridWrapper}>
            <div data-aos="fade-up" data-aos-once="true">
                {
                    bannerFirstLoading &&
                    <BannerLoader />
                }
                {
                    !bannerFirstLoading && 
                    <SimpleBanner title={ilState.bannerFirstAS.name} domainCategoryArr={ilState.bannerFirstAS.domainCategory} description={ilState.bannerFirstAS.description} />
                }
            </div>
            <div className={Styles.gridWrapper1} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                <div>
                    {
                        usersLoading &&
                        <UsersLoader />
                    }
                    {
                        !usersLoading &&
                        <NoOfUsers infoBody="Total number of learners who have completed this course" title="COMPLETED LEARNERS" count={ilState.usersAS.totalLearners} />
                    }
                </div>
                <div className={Styles.averageWhitebox}>
                    <WhiteBox>
                        <div className={Styles.averageContainer}>
                            {
                                competencyScoresLoading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !competencyScoresLoading && 
                                <>
                                    <div className={Styles.groupAverageHeading0}>
                                        <GraphTitle title="GROUP AVERAGE" infoBody="Average score of all the learners of this organization in this specific course" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{ilState.competencyScoresAS.dataForHorizontalProgressBar1.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...ilState.competencyScoresAS.dataForHorizontalProgressBar1} />
                                    </div>
                                </>
                            }
                            <div className={Styles.lineDifferentiator}></div>
                            {
                                competencyScoresLoading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !competencyScoresLoading && 
                                <>
                                    <div className={Styles.groupAverageHeading}>
                                    <GraphTitle title="BENCHMARK" infoBody="Average score of all the learners of the Universe in this specific course.*Universe refers to the entire set of learners who have accessed this course until now" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{ilState.competencyScoresAS.dataForHorizontalProgressBar2.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...ilState.competencyScoresAS.dataForHorizontalProgressBar2} />
                                    </div>
                                </>
                            }
                        </div>
                    </WhiteBox>
                </div>
                <div className={Styles.competencyList}>
                    {
                        loading && 
                        <DistributionWhiteboxLoader />
                    }
                    {
                        !loading &&
                        <CompetencyList course={ilState.competenciesAS.competencyArray} />
                    }
                </div>
            </div>
            <div data-aos="fade-up" data-aos-once="true" data-aos-delay="700">
                {
                    loading && 
                    <BannerLoader />
                }
                {
                    !loading && 
                    <BannerWithBackground title="AGILE SIMULATION" />   
                }
            </div>
            {
                competencyScoresLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !competencyScoresLoading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                    <div className={Styles.horizontalGraphContainer}>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle infoBody="Shows the scores of the course competencies of this group in comparison to the universe" />
                            </div>
                            <div className={Styles.groupHorizontalComponent}>
                                <GroupHorizontalBar {...ilState.competencyScoresAS.dataForGroupHorizontalBar} />
                            </div>
                        </WhiteBox>
                    </div>
                    <div className={Styles.gridWrapper3}>
                        <div>
                            <TargetAchieved percent={ilState.competencyScoresAS.targetAchieved} />
                        </div>
                        <div className={Styles.targetsContainer}>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title="PCF DISTRIBUTION" infoBody="This graph shows the spread of this group of learners across three levels of performance (Product Customer Fit - PCF)" />
                                </div>
                                <div className={Styles.groupVerticalComponent}>
                                    <BarGraphLegend1 {...ilState.competencyScoresAS.dataForPCF} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                </div>
                </>
            }
            {
                distributionsLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !distributionsLoading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true">
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title="DISTRIBUTION OF LEARNERS ACROSS COMPETENCIES" infoBody="Distribution (%) of the learners in each of the five proficiency levels in each of the competencies of this course" />
                            </div>
                            <div className={Styles.titleDropdown}>
                                <TitleDropdown arr={ilState.competenciesAS.competencyArray} titleCourse={titleCourse} setTitleCourse={setTitleCourse} />
                            </div>
                            <div className={Styles.barGraphComponent1}> 
                                {conditionalDistribution}
                            </div>
                        </WhiteBox>
                    </div>
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title="RELEVANCE & EFFICIENCY OF USER STORIES" infoBody="Relevance - It is the measure of how many of the total user stories built by all the learners of this organization matched the customers' needs in comparison to the universe.Efficiency - It is the measure of the percentage of user stories built versus the number they had planned across all the learners of this organization in comparison to the universe" />
                            </div>
                            <div className={Styles.pieChartComponent}>
                                <BarBenchmark {...ilState.distributionsAS.dataForBarBenchmarkGroup1} />
                            </div>
                        </WhiteBox>
                    </div>
                </div>
                </>
            }
            {
                skillsLoading &&
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !skillsLoading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true">
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title="TEAM SKILL, TEAM MORALE & CUSTOMER FOCUS" infoBody="Shows the group's percentage spread (% of learners) across skill, morale and customer focus metrics" />
                                </div>
                                <div className={Styles.barAccuracyComponent}>
                                    <GroupVerticalLegend {...ilState.skillAS.dataForGroupVertical} />
                                </div>
                            </WhiteBox>
                        </div>
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title="VELOCITY OF USER STORIES" infoBody="Shows the average number of user stories built across sprints across all the learners of this organization in comparison to the universe" />
                                </div>
                                <div className={Styles.pieChartComponent}>
                                    <BarBenchmarkNumber {...ilState.skillAS.dataForBarBenchmarkGroup2} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default PerformanceTabAgile