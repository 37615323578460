import React from "react";
import Styles from "./Title.module.css";
import InfoComp from './InfoIcon'
import Tooltip from './Tooltip'
import InfoIcon from "./InfoIcon";
import TooltipManual from './TooltipManual'
function Title(props){
    let conditionalComponent = <TooltipManual infoBody={props.infoBody}/>

    if(props.show===false) {
        conditionalComponent=''
    }

    return(
        <div className={Styles.container}>
            <div className={Styles.text}>
                {props.title || 'COMPETENCY SCORES'}
            </div>

            <div>           
                {conditionalComponent}
            </div>
        </div>
    )
}

export default Title;