import React from "react"
import ContentLoader from "react-content-loader"

const PieChartLoader = (props) => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width = props.width; 
    const height = props.height; 
    const svgWidth = width + (60*unit)
    const svgHeight = height + (60*unit)
    
    return(
        <ContentLoader 
            speed={2}
            width={svgWidth}
            height={svgHeight}
            viewBox={"0 0 "+svgWidth+" "+svgHeight}
            backgroundColor="#f3f3f3"
            foregroundColor="#dcdbdb"
        >
            <rect x={20*unit} y={20*unit} rx={5*unit} ry={5*unit} width={width} height={12*unit} />  
            <circle cx={(svgWidth/2)+(2*unit)} cy={(svgHeight/2)+(5*unit)} r={25*unit}/> 
        </ContentLoader>
    )
}

export default PieChartLoader;