import React from 'react';
import { Group } from '@vx/group';
import { GridRows } from '@vx/grid';
import { AxisBottom , AxisLeft } from '@vx/axis';
import { scaleLinear, scaleBand, scaleOrdinal } from '@vx/scale';
import {LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend'
import getRoundedRect from '../../utils/GraphUtils'
import Styles from './GraphComponents.module.css'
import { useTooltip, Tooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';

const colorPalette = ['#464796', '#B888CB'];

function BarAccuracyProportionFrequency(props) {
    const data = props.data;
    const width = props.width; 
    const height = props.height; 
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            //for FHD Screens
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            //for QHD Screens
            unit = 2;
        }
    const margin = { top: 10*unit, bottom: 25*unit, left: 40*unit, right: 0*unit };

    const xMax = width - margin.right;
    const yMax = height - margin.bottom;
    const xMin = margin.left;
    const yMin = margin.top;

    const keysInData = Object.keys(data[0]).filter(item => item !== 'Type');
    const y1 = d => d[keysInData[0]];
    const y2 = d => d[keysInData[1]];
    const x = d => d.Type;

    const xScale = scaleBand({
        rangeRound: [xMin, xMax],
        domain: data.map(x), //To get all the 'Types' for x-Axis
    });

    const yScale = scaleLinear({
        rangeRound: [yMax, yMin],
        domain: [0, 100],
    });

    const ordinalColorScale = scaleOrdinal({
        domain: keysInData, //To get all the domain for Legend
        range: colorPalette
    });

    const compose = (scale, accessor) => data => scale(accessor(data));
    const yPoint1 = compose(yScale, y1);
    const yPoint2 = compose(yScale, y2);
    const xPoint = compose(xScale, x);

    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, data, i) => {
        let tooltipLeft = (i*2 + 1)*(xScale.bandwidth()/2);
        const tooltipTop= yScale(58);
        const tooltipData = {...data};
        if(tooltipLeft + 95*unit > width){
            tooltipLeft = width -  95*unit;
        }
        
        showTooltip({
            tooltipLeft: tooltipLeft,
            tooltipTop: tooltipTop,
            tooltipData: tooltipData
        });
    };

    return (
        <div>
            <div style={{justifyContent: 'center', display: 'flex'}}>
            <svg width={width} height={height} >
                <AxisLeft
                    left={margin.left}
                    scale={yScale}
                    hideAxisLine= {true}
                    hideTicks={true}
                    hideZero={false}
                    tickLabelProps={(value, index) => ({
                        fontSize: 10*unit,
                        textAnchor: 'end',
                        verticalAnchor: 'middle',
                        fill: '#2F4585',
                        fillOpacity: 0.4,
                        fontFamily: 'Nunito',
                        fontStyle: 'normal'
                    })}
                    label={keysInData[0] === 'Proportion' ? '% of users' : '% of deviation'}
                    labelClassName={Styles.leftlabel}
                    labelOffset={20*unit}
                    numTicks={4}
                /> 
                <GridRows
                    left={margin.left}
                    scale={yScale}
                    width={xMax - xMin}
                    height={yMax - yMin}
                    stroke="#EAECF3"
                    numTicks={6}
                />
                <line x1={xMax-1} x2={xMax} y1={yMin} y2={yMax} stroke="#EAECF3" />
                <line x1={xMin-1} x2={xMin} y1={yMin} y2={yMax} stroke="#EAECF3" />
                <AxisBottom                
                    top={yMax - yMin}
                    scale={xScale}
                    hideAxisLine= {true}
                    hideTicks={true}
                    tickLabelProps={(value, index) => ({
                        fontSize: 11*unit,
                        textAnchor: 'middle',
                        fill: '#2F4585',
                        fontFamily: 'Nunito',
                        fontStyle: 'normal',
                        verticalAnchor: 'start'
                    })}
                />
                {data.map((d, i) => {
                    const barWidth = 14*unit;
                    const xBandWidth = xScale.bandwidth()/2
                    return (
                        <Group id={`bar-${i}`}
                            onMouseMove={event =>
                                handleMouseOver(
                                    event,
                                    data[i],
                                    i
                            )}
                            onMouseLeave={hideTooltip}
                            onMouseOut={hideTooltip}
                        >
                            <Spring
                                delay={i*750}
                                config={{duration: 750}}
                                from={{ value: getRoundedRect(xPoint(d) + (xBandWidth - 4*unit - barWidth), yMax, barWidth , 0, 0, 'top') }}
                                to={{ value: getRoundedRect(xPoint(d) + (xBandWidth - 4*unit - barWidth), yPoint1(d), barWidth , yMax -  yPoint1(d), 5*unit, 'top') }}
                            >
                                {props =>
                                    <path
                                        id={`StakeHolderBar-${i}`}
                                        d={props.value}
                                        style={{ fill: colorPalette[0] }}
                                    />
                                }
                            </Spring>
                            <Spring
                                delay={i*750 + 300}
                                config={{duration: 750}}
                                from={{ value: getRoundedRect(xPoint(d) + (xBandWidth + 4*unit ), yMax, barWidth , 0, 0, 'top') }}
                                to={{ value: getRoundedRect(xPoint(d) + (xBandWidth + 4*unit ), yPoint2(d), barWidth , yMax -  yPoint2(d), 5*unit, 'top') }}
                            >
                                {props =>
                                    <path
                                        id={`TimeSpentBar-${i}`}
                                        d={props.value}
                                        style={{ fill: colorPalette[1] }}
                                    />
                                }
                            </Spring>
                        </Group>
                    );
                })}
                {tooltipOpen && (
                    <>
                    <rect
                        y={tooltipTop }
                        x={tooltipLeft }
                        width = {95*unit} 
                        height = {40*unit} 
                        fill = 'rgba(250, 250, 250, 0.9)'
                        stroke = 'rgba(0, 0, 0, 0.1)'
                        className={Styles.tooltipBlur}
                        rx={4*unit}
                    />
                    <text
                        textAnchor = 'start'
                        x = {tooltipLeft + 5*unit}
                        y = {tooltipTop + 15*unit}
                        className={Styles.tooltipText}
                    >
                        {keysInData[0]} : {tooltipData[keysInData[0]]}% 
                    </text><br/>
                    <text
                        textAnchor = 'start'
                        x = {tooltipLeft + 5*unit}
                        y = {tooltipTop + 30*unit}
                        className={Styles.tooltipText}
                    >
                        {keysInData[1]}  : {tooltipData[keysInData[1]]}%
                    </text>
                    </>
                )}
            </svg>

            </div>
            <LegendOrdinal scale={ordinalColorScale}>
            {labels => {
                return (
                    <div style={{justifyContent:'center', display: 'flex', flexDirection: 'rows'}}>
                        {labels.map((label, i) => {
                        const size = 8*unit;
                        return (
                            <LegendItem
                                key={`legend-quantile-${i}`}
                                margin={`0 ${5*unit}px`}
                            >
                                <svg width={size} height={size}>
                                    <circle cx={size/2} cy={size/2} r={size/2}  fill={colorPalette[i]} />
                                </svg>
                                <LegendLabel
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: `${10*unit}px`,
                                        color: '#2F4585',
                                        marginLeft: `${10*unit}px`
                                    }}
                                >
                                    {label.text}
                                </LegendLabel>
                            </LegendItem>
                        );
                        })}
                    </div>
                );
            }}
            </LegendOrdinal>
        </div>
    );
}

export default BarAccuracyProportionFrequency;