import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Styles from './OrgSearch.module.css'
import WhiteBox from '../WhiteBox/WhiteBox'
import closeButton from '../../assets/img/Close.png'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { UPDATE_ORG_ID} from '../../constants/actions'

export const NAME_QUERY = gql`
query MyQuery($orgId:bigint!) {
    orgName: journey_org(where: {organizationid: {_eq: $orgId}}) {
        name
      }
  }
`

function OrgSearch(props) {
    let orgArray = ['org1', 'org2', 'org3','org1', 'org2', 'org3', 'org1', 'org2', 'org3', 'org1', 'org2', 'org3', 'org1', 'org2', 'org3', 'org1', 'org2', 'org3', 'org1', 'org2', 'org3']
    const dispatch = useDispatch()
    const history = useHistory()
    const { orgId } = useParams()
    const orgState = useSelector(state => state.orgLevelReducer)
    const [search, setSearch] = useState('')

    const { loading, error, data } = useQuery(NAME_QUERY, { variables: { orgId: orgId }})

    if(loading)
    {
        return <div>Loading..</div>
    }

    if(error)
    {
        return <div>Error</div>
    }

    const closeOrgList = (e) => {
        props.setShowOrgList(false)
    }

    console.log(data.orgName[0].name)

    const changeOrgId = (e) => {
        console.log(e.target.dataset.value1)
        console.log(e.target.dataset.value2)
        props.set_org_id(e.target.dataset.value1)
        props.setOrgName(e.target.dataset.value2)
        props.setShowOrgList(false)
        dispatch({ type: UPDATE_ORG_ID, payload: e.target.dataset.value1 })
        history.push(`/${e.target.dataset.value1}`)
    }

    const handleChange = (e) => {
        e.preventDefault()
        setSearch(e.target.value)
        //console.log(e.target.value)
    }

    const filteredOrgs = orgState.landingNotRequired.orgList.filter(item => {
        //console.log(item.name)
        if(item.name!==null)
        {
            return item.name.toLowerCase().indexOf(search.toLowerCase())!=-1
        }
        
    })

    //console.log(props.org_id)

    return (
        <div className={Styles.orgSearchContainer}>
            <div className={Styles.topBox}>
                <div className={Styles.searchBarContainer}>
                    <input type="text" placeholder="Search Organisation" icon="search" onChange={handleChange} className={Styles.searchBar} />
                </div>
                <img src={closeButton} className={Styles.closeOrgSearch} onClick={closeOrgList} />
            </div>
            <div className={Styles.orgList}>
                <div data-value1={orgId} data-value2={data.orgName[0].name} className={Styles.orgNameSelected} onClick={changeOrgId}>{data.orgName[0].name}</div>
                {
                    filteredOrgs.map(item => { 
                        if(orgId!=item.organizationid)
                        {
                            return (
                                <div data-value1={item.organizationid} data-value2={item.name} className={Styles.orgName} onClick={changeOrgId}>{item.name}</div>
                            )
                        }                        
                    })
                }
            </div>
        </div>
    )
}

export default OrgSearch
