import React from "react"
import ContentLoader from "react-content-loader"
import WhiteBox from '../../../components/WhiteBox/WhiteBox'

const AverageBenchmarkLoader = () => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width = 200*unit;
    const height = 100*unit; 
    
    return(
            <ContentLoader 
                speed={2}
                width={width}
                height={height}
                viewBox={"0 0 "+width+" "+height}
                backgroundColor="#f3f3f3"
                foregroundColor="#dcdbdb"
            >
                <rect x={15*unit} y={20*unit} rx={5*unit} ry={5*unit} width={width - (75*unit)} height={10*unit} />
                <rect x={15*unit} y={50*unit} rx={5*unit} ry={5*unit} width={width - (135*unit)} height={15*unit} />
                <rect x={15*unit} y={75*unit} rx={5*unit} ry={5*unit} width={width - (20*unit)} height={10*unit} />
            </ContentLoader>
        
    )
}

export default AverageBenchmarkLoader;