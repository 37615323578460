import React from 'react';
import ReactWordcloud from 'react-wordcloud';
import Styles from './WordCloud.module.css'
import words from './words';

function FeedbackWordCloud(props) {

    let fontSizes = [15, 52]  
    let padding= 2 
    if(window.innerWidth >= 1920 && window.innerWidth < 2560){
      fontSizes = [21, 63]
      padding= 3
    }
    if(window.innerWidth >= 2560){
      fontSizes = [27, 70]
      padding= 4
    }
  
  const options = { 
    colors: ['#112768', '#464796', '#735DB4', '#A862B1' , '#B888CB'],
    enableTooltip: false,
    deterministic: true,
    randomSeed: 'seed1',
    fontFamily: 'impact',    
    fontSizes: fontSizes,  
    padding: padding,
    rotations: 2,
    rotationAngles: [0, 90],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 1000,
  };
  
  return (
    <div>
      <div className={Styles.feedback_div}>
        <ReactWordcloud options={options} words={props.wordData || words} />
      </div>
    </div>
  );
}
export default FeedbackWordCloud

