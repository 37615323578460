import React from 'react'
import Styles from './StarRatingPurple.module.css'
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { FiStar } from 'react-icons/fi'
import starFull from '../../assets/img/star4.png' 
import starEmpty from '../../assets/img/star2.png'
import starHalf from '../../assets/img/star3.png'

function StarRating(props) {
    let value = props.ratingVal
    let dec = value - Math.floor(value)
    return (
        <div className={Styles.starRatingContainer}>
            <div className={Styles.ratingValue}>{props.ratingVal}</div>
            <div className={Styles.totalRating}>/5</div>
            {[...Array(5)].map((star) => {
                if(value-dec===0 && dec!=0){
                    value=value-1
                    return (
                        <div className={Styles.star}>
                            <img src={starHalf} className={Styles.starImgHalf} />
                        </div> 
                    )
                }
                else if(value<=0)
                {
                    value = value - 1
                    return (
                        <div className={Styles.star}>
                            <img src={starEmpty} className={Styles.starImg} />
                        </div>   
                    )
                }
                else {
                    value=value-1
                    return (
                        <div className={Styles.star}>
                            <img src={starFull} className={Styles.starImg} />
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default StarRating ;