import React from "react";
import  Styles  from "./TargetAchievedPercent.module.css";

function TargetPercent(props){
    return(
        <div className={Styles.outerBox}>
            <div className={Styles.innerBox1}>
                <div className={Styles.textContainer}>
                    <div className={Styles.text}>
                        USERS WHO ACHIEVED    
                    </div> 
                    <div className={Styles.text}>
                        THE TARGET    
                    </div> 
                </div>
            </div>
            <div className={Styles.innerBox2}>
                <div className={Styles.number}>
                    { props.percent }
                    <span className={Styles.percent}>%</span>
                </div>
            </div>
        </div>
    )
}

export default TargetPercent