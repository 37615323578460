import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Dotdotdot from 'react-dotdotdot'
import Styles from './HeaderDropdown.module.css'
import dd1 from '../../assets/img/dd1.png'
import dd2 from '../../assets/img/dd2.png'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { UPDATE_HEADER_ITEMS,UPDATE_SELECTED_COURSE } from '../../constants/actions'

export const HEADER_DROPDOWN_QUERY = gql`
query Myquery($orgId:bigint!)  {
    dropdown: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}) {
        journeyinstanceid
        name
        journeyid
        StringJourneyInstance {
          langvalue
        }
        JourneyJourneyInstance {
          domain
          tags
        }
      }
}
`

function HeaderDropdown(props) {
    const dispatch = useDispatch()
    const { orgId } = useParams()
    const history = useHistory()
    const [listOpen, setListOpen] = useState(false)
    /*const dropDownAraay = ["All Courses", "Leading Now", "Leading Next"]
    const leadingNowArray = ["iLead", "CQ", "Trust Sim", "BYB"]
    const leadingNextArray = ["Clearing the Digital BLUR"]*/

    const headerState = useSelector(state => state.orgLevelReducer)

    const handleClose = () => {
        props.setListOpen(false)
    }

    const handleOpen = () => {
        props.setListOpen(true)
    }

    const handleDdClick = (e) => {
        //console.log(e.target.dataset.id)
        props.setDropdownId(e.target.dataset.id)
        props.setDropdownItem(e.target.dataset.value)
        dispatch({type: UPDATE_SELECTED_COURSE, payload: e.target.dataset.value })
        props.setListOpen(false)
        history.push(`/${orgId}/${props.selectedTab}/${e.target.dataset.value}/${e.target.dataset.tag}/${e.target.dataset.id}`)
    }

    let conditionalComponent = ''
    if(props.listOpen) {
        conditionalComponent =  <div className={Styles.dropdownHeaderContainer} onClick={handleClose}>
                                    <div className={Styles.courseArrowContainer}>
                                        <div className={Styles.course}>COURSE</div>
                                        <img src={dd2} alt="Vector" className={Styles.dropdownArrow} />
                                    </div>
                                    <div className={Styles.dropdownTitle}>
                                        <div className={Styles.title}>
                                            <Dotdotdot clamp="auto">
                                                <div>{props.dropdownItem}</div>
                                            </Dotdotdot>
                                        </div>
                                    </div>
                                </div>
    }
    else {
        conditionalComponent =  <div className={Styles.dropdownHeaderContainer} onClick={handleOpen}>
                                    <div className={Styles.courseArrowContainer}>
                                        <div className={Styles.course}>COURSE</div>
                                        <img src={dd1} alt="Vector" className={Styles.dropdownArrow} />
                                    </div>
                                    <div className={Styles.dropdownTitle}>
                                        <div className={Styles.title}>
                                            <Dotdotdot clamp="auto">
                                                <div>{props.dropdownItem}</div>
                                            </Dotdotdot>
                                        </div>
                                    </div>
                                </div>
    }

    const { loading, error, data } = useQuery(HEADER_DROPDOWN_QUERY, { variables: { orgId: orgId }}) 

    useEffect(() => {
        if(!loading && !error && data!=null){
            //console.log(deals[1].params)
            //console.log('hello')
            dispatch({type: UPDATE_HEADER_ITEMS, payload: data})
        }
    }, [loading, error, data])

    if(loading)
    {
        return <div>LOADING...</div>
    }

    if(error) {
        console.log(error)
        return <div>Error</div>
    }


    return (
            <div className={Styles.dropdownContainer}>
                {conditionalComponent}
                {props.listOpen && <div className={Styles.ddList}>
                    {
                        headerState.header.dropDownAraay.map(item => {
                            if(item.name==='Leading Now')
                            {
                                if(props.dropdownId==item.id) {
                                    return (
                                        <React.Fragment>
                                            <div data-value='Leading Now' data-id={2} data-tag='now' className={Styles.ddSelected}>Leading Now</div>
                                            {
                                                headerState.header.courses[0].leadingNowArray.map(it => {
                                                    return props.dropdownId==it.id ?
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeadingSelected} onClick={handleDdClick}>{it.name}</div>
                                                    :
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeading} onClick={handleDdClick}>{it.name}</div>

                                                })
                                            }
                                            <div className={Styles.ddDiffLine}></div>
                                        </React.Fragment>                                        
                                    )
                                }
                                else {
                                    return (
                                        <React.Fragment>
                                            <div data-value='Leading Now' data-id={2} data-tag='now' className={Styles.ddHeading1}>Leading Now</div>
                                            {
                                                headerState.header.courses[0].leadingNowArray.map(it => {
                                                    return props.dropdownId==it.id ?
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeadingSelected} onClick={handleDdClick}>{it.name}</div>
                                                    :
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeading} onClick={handleDdClick}>{it.name}</div>

                                                })
                                            }
                                            <div className={Styles.ddDiffLine}></div>
                                        </React.Fragment>                                        
                                    )
                                }
                            }
                            else if(item.name==='Leading Next') {
                                if(props.dropdownId==item.id) {
                                    return (
                                        <React.Fragment>
                                            <div data-value='Leading Next' data-id={3} data-tag='next' className={Styles.ddSelected}>Leading Next</div>
                                            {
                                                headerState.header.courses[1].leadingNextArray.map(it => {
                                                    return props.dropdownId==it.id ?
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeadingSelected} onClick={handleDdClick}>{it.name}</div>
                                                    :
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeading} onClick={handleDdClick}>{it.name}</div>

                                                })
                                            }
                                            <div className={Styles.ddDiffLine}></div>
                                        </React.Fragment>                                        
                                    )
                                }
                                else {
                                    return (
                                        <React.Fragment>
                                            <div data-value='Leading Next' data-id={3} data-tag='next' className={Styles.ddHeading1}>Leading Next</div>
                                            {
                                                headerState.header.courses[1].leadingNextArray.map(it => {
                                                    return props.dropdownId==it.id ?
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeadingSelected} onClick={handleDdClick}>{it.name}</div>
                                                    :
                                                    <div data-value={it.name} data-id={it.id} data-tag={it.tag} className={Styles.ddSubHeading} onClick={handleDdClick}>{it.name}</div>

                                                })
                                            }
                                            <div className={Styles.ddDiffLine}></div>
                                        </React.Fragment>                                        
                                    )
                                }
                            }
                            else {
                                return props.dropdownId==item.id ?
                                <div data-value={item.name} data-id={item.id} data-tag={item.tag} className={Styles.ddSelected} onClick={handleDdClick}>{item.name}</div>
                                :
                                <div data-value={item.name} data-id={item.id} data-tag={item.tag} className={Styles.ddHeading} onClick={handleDdClick}>{item.name}</div>
                            }
                            
                        })
                    }
                </div>}
            </div>
    )
}

export default HeaderDropdown