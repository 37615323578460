import React,{useEffect, useState} from "react";
import Styles from './ExperienceTab.module.css'
import BannerWithBackground from "../../commonComponents/textComponent/Banner/BannerWithBackground";
import Feedback from "../../commonComponents/textComponent/AverageFeedback/Feedback";
import Likes from "../../commonComponents/textComponent/Likes/Likes";
import Aggregate from "../../commonComponents/textComponent/Stats/Aggregate";
import CourseOrder from "../../commonComponents/textComponent/CourseOrder/CourseOrder";
import CourseFeedback from '../../commonComponents/textComponent/CourseFeedback/CourseFeedback'
import performanceTab1 from '../../assets/img/performanceTab1.png'
import performanceTab2 from '../../assets/img/performanceTab2.png'
import { useSelector, useDispatch } from "react-redux";
import WhiteBox from "../WhiteBox/WhiteBox";
import Title from "../../commonComponents/textComponent/GraphTitle/Title";
import { Component3 } from "../../commonComponents/textComponent/Competency/Rated";
import {AverageFeedbackQuery,ResponsesQuery,AggregateStatsQuery,CourseFeedbackQuery} from './ExperienceTabQueries';
import { useQuery } from '@apollo/react-hooks'
import AverageFeedbackLoader from '../../commonComponents/Loaders/FeedbackAll/AverageFeedback'
import ResponsesLoader from '../../commonComponents/Loaders/FeedbackAll/Responses'
import CourseFeedbackLoader from '../../commonComponents/Loaders/FeedbackAll/CourseFeedback'
import AggregateLoader from '../../commonComponents/Loaders/FeedbackAll/Aggregate'
import {handleAverageCourseFeedbackRatingFS, handleBenchmarkRatingFS, handleCourseFeedbackFS, handleResponsesFS, handleAggregateFS,handleCourseAndFeedbackFS} from '../../actions/experienceAction'
import {UPDATE_AVERAGE_COURSE_FEEDBACK_RATING,UPDATE_BENCHMARK_RATING, UPDATE_COURSEFEEDBACK,UPDATE_RESPONSE,UPDATE_AGGREGATESTATS,UPDATE_COURSERATING} from '../../constants/actions'

function ExperienceTab(props) {
    const dispatch = useDispatch()
    const {averageCourseFeedback,benchmark,courseRating,response,courseFeedback}=useSelector(state=>state.experienceTab)    
    const {filterUsers,filterBatches,filterJourneyInstance,journeyDomainCategories}=useSelector(state=>state.orgLevelReducer.filterUsersAndJourneyList)
    const {benchmarkFilterUsers,benchmarkFilterBatches}=useSelector(state=>state.orgLevelReducer.benchmarkFilterUsersList)

    const {  loading:Avgloading, error:Avgerror, data:Avgdata, refetch:Avgrefetch} = useQuery(AverageFeedbackQuery, { variables: { users: filterUsers, batches: filterBatches, benchUsers: benchmarkFilterUsers, benchBatches: benchmarkFilterBatches }});  
    const {  loading:Resloading, error:Reserror, data:Resdata,refetch:Resrefetch } = useQuery(ResponsesQuery, { variables: { users: filterUsers, batches: filterBatches }}); 
    const {  loading:Aggloading, error:Aggerror, data:Aggdata, refetch:Aggrefetch  } = useQuery(AggregateStatsQuery, { variables: { journeyinstance: filterJourneyInstance }});   
    const {  loading:CFloading, error:CFerror, data:CFdata ,refetch:CFrefetch} = useQuery(CourseFeedbackQuery, { variables: { journeyinstance: filterJourneyInstance  }});
    
    
    useEffect(() => {
        if(!Avgloading && !Avgerror && Avgdata!=null){  
            dispatch(handleAverageCourseFeedbackRatingFS(Avgdata.averageFeedback, UPDATE_AVERAGE_COURSE_FEEDBACK_RATING))
            dispatch(handleBenchmarkRatingFS(Avgdata.benchMark, UPDATE_BENCHMARK_RATING))
            dispatch(handleCourseFeedbackFS(Avgdata, UPDATE_COURSEFEEDBACK)) 
        }
    }, [Avgloading, Avgerror, Avgdata])

    useEffect(() => {
        if(!Resloading && !Reserror && Resdata!=null){
            dispatch(handleResponsesFS(Resdata, UPDATE_RESPONSE))
        }
    }, [Resloading, Reserror, Resdata])

    useEffect(() => {
        if(!Aggloading && !Aggerror && Aggdata!=null){            
            dispatch(handleAggregateFS(Aggdata,UPDATE_AGGREGATESTATS))
        }
    }, [Aggloading, Aggerror, Aggdata])

    useEffect(() => {
        if(!CFloading && !CFerror && CFdata!=null){            
            dispatch(handleCourseAndFeedbackFS(CFdata,UPDATE_COURSERATING,journeyDomainCategories))
        }
    }, [CFloading, CFerror, CFdata]) 
    return (   
           
        <div className={Styles.gridWrapper}>
            <div className={Styles.heading} data-aos="fade-up" data-aos-once="true" data-aos-duration="500" data-aos-delay="350" >
                <BannerWithBackground description="This section refers to the experience or feedback of the learners in this organization across all the courses." />
            </div>
            
            <div className={Styles.gridBodyWrapper} data-aos="fade-up"  data-aos-once="true" data-aos-duration="600" data-aos-delay="700">
                <div className={Styles.verticalgrid1}>
                    {
                        !Avgdata ?  <AverageFeedbackLoader/> :(

                            <div className={Styles.whiteBox}>
                                <Feedback title='AVERAGE COURSE FEEDBACK'  infoBody="Average feedback rating of all the learners in this organization across all the courses" color='#9F7DFF' rating={averageCourseFeedback}/>
                                <div className={Styles.average_feedback_divider}>
                                    <div className={Styles.line}/>
                                </div>
                                
                                <CourseFeedback feedback={courseFeedback} infoBody="Average course feedback of all the learners in this organization across the metrics - Satisfaction, Impact and Platform" />
                            </div>
                        )
                    }
                </div>
                <div className={Styles.verticalgrid2} >
                    {
                        !Resdata ?  <ResponsesLoader/> :(                            
                                <Likes responses={response} infoBody="Total no of learners who gave feedback for all the course modules (learners' likes & dislikes)" />       
                        )
                    }
                    {
                        !Aggdata ? <AggregateLoader/> :(
                                <Aggregate/>
                            )
                    }                                       
                </div>
                <div className={Styles.verticalgrid3} >
                {
                    !CFdata ?  <CourseFeedbackLoader/> :(     
                        <WhiteBox>
                            <div className={Styles.innerBox}>
                                <Title title="COURSES & FEEDBACK" infoBody="Shows the average feedback scores of all the learners for each course" />
                                <div className={Styles.course_feedback}>
                                    {
                                        courseRating &&
                                        courseRating.map((val,index)=>{
                                            return(
                                                <div>
                                                    <Component3 rating={val.rating} lead={val.lead} title={val.title} />
                                                    {
                                                        index!=courseRating.length-1 &&
                                                        <div className={Styles.line}/>
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            
                        </WhiteBox>
                    )
                }
                </div>
            </div>
            
        </div>
            
    )
}


export default ExperienceTab;