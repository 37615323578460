import React, { useState } from 'react'
import Styles from './Feedback.module.css'
import Stars from "../Stars/Stars";
import { useSelector } from 'react-redux';
import Title from '../GraphTitle/Title';
import starFullPurple from '../../../assets/img/star4.png'
import starHalfPurple from '../../../assets/img/star3.png'
import starEmptyPurple from '../../../assets/img/star2.png'
import StarRating from '../../../components/StarRating/StarRating'
import StarRatingPurple from '../../../components/StarRatingPurple/StarRatingPurple'

function Feedback(props){
    const ratingVal=props.rating || 0

    let conditionalComponent = <div className={Styles.starRatingContainer}>
                                    <Stars rating={ratingVal} />
                                </div>

    if(props.title==='BENCHMARK') {
        conditionalComponent = <div className={Styles.starRatingContainer}>
                                    <StarRating ratingVal={ratingVal} />
                               </div>
    }

    if(props.title==='AVERAGE COURSE FEEDBACK') {
        conditionalComponent = <div className={Styles.starRatingContainer}>
                                    <StarRatingPurple ratingVal={ratingVal} />
                               </div>
    }

    return(
        <div className={Styles.outerBox}>
            <Title title={props.title} infoBody={props.infoBody} />
            {conditionalComponent}
        </div>
    ) 
}

export default Feedback;


