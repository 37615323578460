import React from "react";
import Styles from './CourseCompitency.module.css'
import Title from "../GraphTitle/Title";
import PropTypes from 'prop-types';

function CourseCompitency(props){
    return(
        <div className={Styles.outerBox}>
            <Title title='COMPETENCIES IN THE COURSE' infoBody="Lists the set of competencies measured in this course" />
            <div className={Styles.innerBox}>
                {
                    props.course &&
                    props.course.map((val,ind)=>{
                        return(
                            <div className={Styles.textContainer}>
                                <div className={Styles.text}>
                                    {val}
                                </div>
                            </div>
                        )
                    }
                    )
                }
            </div>
        </div>
    )
}

CourseCompitency.propTypes={
    course:PropTypes.arrayOf(PropTypes.string)
}

export default CourseCompitency