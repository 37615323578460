import React from 'react'
import Styles from './footer.module.css'
import BrandImage from '../../../assets/img/brand.png'
import BrandImageQHD from '../../../assets/img/brandQHD.png'

function Footer(){    
    return(
        <div className={Styles.body}>
            <div className={Styles.footer_filter_panel}>
                <span>A Product By</span>
                <a href="https://www.knolskape.com" target="_blank">
                    
                        <img src={BrandImageQHD} className={Styles.brand_image}/>
                </a>
                <span>© 2020</span>
            </div>  
        </div>
    )
}
export default Footer