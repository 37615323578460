import React,{useState,useEffect} from "react";
import Styles from './FeedbackTabCQ.module.css'
import SimpleBanner from "../../commonComponents/textComponent/Banner/SimpleBanner";
import Feedback from "../../commonComponents/textComponent/AverageFeedback/Feedback";
import Likes from "../../commonComponents/textComponent/Likes/Likes";
import Aggregate from "../../commonComponents/textComponent/Stats/Aggregate";
import CourseOrder from "../../commonComponents/textComponent/CourseOrder/CourseOrder";
import CourseFeedback from "../../commonComponents/textComponent/CourseFeedback/CourseFeedback";
import performanceTab1 from '../../assets/img/performanceTab1.png'
import performanceTab2 from '../../assets/img/performanceTab2.png'
import { useSelector, useDispatch } from "react-redux";
import WhiteBox from "../WhiteBox/WhiteBox";
import Title from "../../commonComponents/textComponent/GraphTitle/Title"
import { Component3 } from "../../commonComponents/textComponent/Competency/Rated"
import WordCloud from '../../commonComponents/textComponent/WordCloud/WordCloud'
import {BannerQuery,AverageFeedbackQuery,ResponsesQuery,WordCloudQuery} from './FeedbackCqQueries';
import { useQuery } from '@apollo/react-hooks'
import {handleBannerFS,handleAverageCourseFeedbackRatingFS, handleBenchmarkRatingFS, handleCourseFeedbackFS, handleResponsesFS, handleWordCloudFS} from '../../actions/experienceAction'
import {UPDATE_BANNER_FT_CQ,UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CQ,UPDATE_BENCHMARK_FT_CQ, UPDATE_COURSEFEEDBACK_FT_CQ,UPDATE_RESPONSE_FT_CQ,UPDATE_WORDCLOUD_FT_CQ} from '../../constants/actions'
import AverageFeedbackLoader from '../../commonComponents/Loaders/FeedbackAll/AverageFeedback'
import ResponsesLoader from '../../commonComponents/Loaders/FeedbackAll/Responses'
import WordCloudLoader from '../../commonComponents/Loaders/FeedbackAll/WordCloud'
import BannerLoader from '../../commonComponents/Loaders/PerformanceTabAll/BannerLoader'
import {useParams} from 'react-router-dom'


function FeedbackTabCQ(props) {
    const dispatch = useDispatch()
    const {banner,averageCourseFeedback,benchMark,response,courseFeedback,wordCloud}=useSelector(state=>state.CQFeedbackTab)
    const {orgId,id} = useParams() 
    const {filterUsers,filterBatches}=useSelector(state=>state.orgLevelReducer.filterUsersAndJourneyList)
    const {benchmarkFilterUsers,benchmarkFilterBatches}=useSelector(state=>state.orgLevelReducer.benchmarkFilterUsersList)

    const {  loading:Bannerloading, error:Bannererror, data:Bannerdata} = useQuery(BannerQuery, { variables: { orgId: orgId , courseId : id}});  
    const {  loading:Avgloading, error:Avgerror, data:Avgdata, refetch:Avgrefetch} = useQuery(AverageFeedbackQuery, { variables: { users: filterUsers, batches: filterBatches,benchmarkusers: benchmarkFilterUsers,benchmarkbatches: benchmarkFilterBatches}});  
    const {  loading:Resloading, error:Reserror, data:Resdata,refetch:Resrefetch } = useQuery(ResponsesQuery, { variables: { users: filterUsers, batches: filterBatches}}); 
    const {  loading:Wordloading, error:Worderror, data:Worddata,refetch:Wordrefetch } = useQuery(WordCloudQuery, { variables: { users: filterUsers, batches: filterBatches}}); 

    
    
    useEffect(() => {
        if(!Bannerloading && !Bannererror && Bannerdata.banner.length){
            dispatch(handleBannerFS(Bannerdata, UPDATE_BANNER_FT_CQ))
        }
    }, [Bannerloading, Bannererror, Bannerdata])

    useEffect(() => {
        if(!Avgloading && !Avgerror && Avgdata!=null){  
            dispatch(handleAverageCourseFeedbackRatingFS(Avgdata.averageFeedback, UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CQ))
            dispatch(handleBenchmarkRatingFS(Avgdata.benchMark, UPDATE_BENCHMARK_FT_CQ))
            dispatch(handleCourseFeedbackFS(Avgdata,UPDATE_COURSEFEEDBACK_FT_CQ)) 
        }
    }, [Avgloading, Avgerror, Avgdata])

    useEffect(() => {
        if(!Resloading && !Reserror && Resdata!=null){
            dispatch(handleResponsesFS(Resdata, UPDATE_RESPONSE_FT_CQ))
        }
    }, [Resloading, Reserror, Resdata])

    useEffect(() => {
        if(!Wordloading && !Worderror && Worddata!=null){
            dispatch(handleWordCloudFS(Worddata, UPDATE_WORDCLOUD_FT_CQ))
        }
    }, [Wordloading, Worderror, Worddata])
    return (
        <div className={Styles.gridWrapper}>
            <div className={Styles.heading} data-aos="fade-up" data-aos-once="true" data-aos-duration="500">
                {
                    Bannerloading ? <BannerLoader/> : (
                        <SimpleBanner title={banner.courseName} description={banner.description} domainCategoryArr={banner.domainCategory} />
                    )
                }
            </div>
            
            <div className={Styles.gridBodyWrapper} data-aos="fade-up"  data-aos-once="true" data-aos-duration="600" data-aos-delay="350">
                <div className={Styles.verticalgrid1}>
                    {   
                        Avgloading ?  <AverageFeedbackLoader/> :(

                            <div className={Styles.whiteBox}>
                                <Feedback infoBody="Average feedback rating of all the learners in this organization in this course" title='AVERAGE COURSE FEEDBACK' color='#9F7DFF' rating={averageCourseFeedback}/>
                                <div className={Styles.average_feedback_divider}>
                                    <div className={Styles.line}/>
                                </div>
                                
                                <CourseFeedback infoBody="Average course feedback of all the learners in this organization across the metrics - Satisfaction, Impact and Platform" feedback={courseFeedback}/>
                            </div>
                        )
                    }
                </div>
                <div className={Styles.verticalgrid2}>
                    {
                       Resloading ?  <ResponsesLoader/> :(                            
                            <Likes infoBody="Total no of learners who gave feedback for this course's modules (learners' likes & dislikes)" responses={response}/>       
                        )
                    }
                </div>
                <div className={Styles.wordcloud_grid}> 
                    {
                        Wordloading ? <WordCloudLoader/> : (
                            <WordCloud wordData={wordCloud}/>
                        )
                    }                       
                    
                </div>                
            </div>            
        </div>        
    )
}

export default FeedbackTabCQ;  