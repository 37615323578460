import React from 'react';
import Popover from '@material-ui/core/Popover';
import InfoOffImg from '../../../assets/img/infoClose.png'
import InfoOnImg from '../../../assets/img/infoOpen.png'
import Styles from "./Title.module.css";


function InfoIcon(props) {
  const [infoOpen, setInfoOpen] = React.useState(null);

  const handleClick = (event) => {    
    setInfoOpen(event.currentTarget);
  };

  const handleClose = () => {
    setInfoOpen(null);
  };

  const open = Boolean(infoOpen);

  return (
    <div>
        <div onClick={handleClick}>
          <img src={open ? InfoOnImg : InfoOffImg} className={Styles.info_icon_image}/>
        </div>
        <div>
        <Popover        
          open={open}
          anchorEl={infoOpen}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{paper : Styles.paper_tooltip_info}}
        >
          <div>
            { props.infoBody || `Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text. “It's not Latin, though it look like...`}
          </div>
        </Popover>
        </div>
    </div>
  );
}

export default InfoIcon
