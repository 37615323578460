import React from 'react';
import { Group } from '@vx/group';
import { GridRows } from '@vx/grid';
import { AxisBottom,AxisLeft } from '@vx/axis';
import {LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend'
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import getRoundedRect from '../../utils/GraphUtils'
import Styles from './GraphComponents.module.css'
import { useTooltip, Tooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';

const colorPalette = ['#464796', '#735DB4', '#9F7DFF'];

function GroupVerticalBarLegend(props) {
    const data = props.data;
    const width = props.width;  
    const height = props.height; 
    let unit = 1;
            if(window.screen.width > 1910 && window.screen.width < 2550){
                //for FHD Screens
                unit = 1.5;
            }else if(window.screen.width >= 2551){
                //for QHD Screens
                unit = 2;
            }
    const margin = { top: 10*unit, bottom: 30*unit, left: 50*unit, right: 0*unit };

    const xMax = width - margin.right;
    const yMax = height  - margin.bottom;
    const xMin = margin.left;
    const yMin = margin.top;

    const y1 = d => d.Low;
    const y2 = d => d.Med;
    const y3 = d => d.High;
    const x = d => d.Type;
    
    const yScale = scaleLinear({
        rangeRound: [yMax, yMin],
        domain: [0,100],
    });
    const xScale = scaleBand({
        rangeRound: [xMin, xMax],
        domain: data.map(x) ,
    });

    const ordinalColorScale = scaleOrdinal({
        domain: ['Low', 'Med', 'High'],
        range: colorPalette
    });
    
    // Compose together the scale and accessor functions to get point functions
    const compose = (scale, accessor) => data => scale(accessor(data));
    const yPoint1 = compose(yScale, y1);
    const yPoint2 = compose(yScale, y2);
    const yPoint3 = compose(yScale, y3);
    const xPoint = compose(xScale, x);

    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, data, i) => {
        let tooltipLeft = (i*2 + 1)*(xScale.bandwidth()/2) ;
        let tooltipTop = yScale(60);
        const tooltipData = {...data};
        
        showTooltip({
            tooltipLeft: tooltipLeft,
            tooltipTop: tooltipTop,
            tooltipData: tooltipData
        });
    };

    return(
    <div>
    <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height}>
            <filter id="blurFilter">
                <feGaussianBlur stdDeviation="5"/>
            </filter>
            <AxisLeft
                left={margin.left}
                scale={yScale}
                hideAxisLine= {true}
                hideTicks={true}
                hideZero={false}
                tickLabelProps={(value, index) => ({
                    fontSize: 10*unit,
                    textAnchor: 'end',
                    verticalAnchor: 'middle',
                    fill: '#2F4585',
                    fillOpacity: 0.4,
                    fontFamily: 'Nunito',
                    fontStyle: 'normal'
                })}
                label={'% Of Users'}
                labelClassName={Styles.leftlabel}
                labelOffset={30*unit}
                numTicks={4}
            /> 
            <GridRows
                left={margin.left}
                scale={yScale}
                width={xMax - xMin}
                height={yMax - yMin}
                stroke="#EAECF3"
                numTicks={6}
            />
            <line x1={xMin} x2={xMax} y1={yMax} y2={yMax} stroke="#EAECF3" />
            <AxisBottom                
                top={yMax}
                scale={xScale}
                hideAxisLine= {true}
                hideTicks={true}
                tickLabelProps={(value, index) => ({
                    fontSize: 11*unit,
                    textAnchor: 'middle',
                    fill: '#2F4585',
                    fontFamily: 'Nunito',
                    fontStyle: 'normal'
                })}
            />
            {data.map((d, i) => {
                const barWidth = 10*unit, borderRad = 5*unit;
                return (
                    <Group id={`bar-${i}`}
                        onMouseMove={event =>
                            handleMouseOver(
                                event,
                                data[i],
                                i
                        )}
                        onMouseLeave={hideTooltip}
                        onMouseOut={hideTooltip}
                    >
                        <Spring
                            delay={i*750}
                            config={{duration: 750}}
                            from={{ value: getRoundedRect(xPoint(data[i]) + (4*xScale.bandwidth()/15), yMax, barWidth , 0, 0, 'top') }}
                            to={{ value: getRoundedRect(xPoint(data[i]) + (4*xScale.bandwidth()/15), yPoint1(d), barWidth , yMax -  yPoint1(d), borderRad, 'top') }}
                        >
                            {props =>
                                <path
                                    id={`GroupBar-${i}`}
                                    d={props.value}
                                    style={{ fill: colorPalette[0] }}
                                />
                            }
                        </Spring>
                        <Spring
                            delay={i*750 + 300}
                            config={{duration: 750}}
                            from={{ value: getRoundedRect(xPoint(d) + (7*xScale.bandwidth()/15), yMax, barWidth , 0, 0, 'top') }}
                            to={{ value: getRoundedRect(xPoint(d) + (7*xScale.bandwidth()/15), yPoint2(d), barWidth , yMax -  yPoint2(d), borderRad, 'top') }}
                        >
                            {props =>
                                <path
                                    id={`TargetBar-${i}`}
                                    d={props.value}
                                    style={{ fill: colorPalette[1] }}
                                />
                            }
                        </Spring>
                        <Spring
                            delay={i*750 + 600}
                            config={{duration: 750}}
                            from={{ value: getRoundedRect(xPoint(d) + (10*xScale.bandwidth()/15), yMax, barWidth , 0, 0, 'top') }}
                            to={{ value: getRoundedRect(xPoint(d) + (10*xScale.bandwidth()/15), yPoint3(d), barWidth , yMax -  yPoint3(d), borderRad, 'top') }}
                        >
                            {props =>
                                <path
                                    id={`BenchBar-${i}`}
                                    d={props.value}
                                    style={{ fill: colorPalette[2] }}
                                />
                            }
                        </Spring>
                    </Group>
                );
            })}
            {tooltipOpen && (
                <> 
                <rect
                    y={tooltipTop }
                    x={tooltipLeft }
                    width = {95*unit} 
                    height = {55*unit} 
                    fill = 'rgba(250, 250, 250, 0.9)'
                    stroke = 'rgba(0, 0, 0, 0.1)'
                    className={Styles.tooltipBlur}
                    rx={4*unit}
                />
                <text
                    textAnchor = 'start'
                    x = {tooltipLeft + 5*unit}
                    y = {tooltipTop + 15*unit}
                    className={Styles.tooltipText}
                >
                    Low : {tooltipData.Low}% 
                </text><br/>
                <text
                    textAnchor = 'start'
                    x = {tooltipLeft + 5*unit}
                    y = {tooltipTop + 30*unit}
                    className={Styles.tooltipText}
                >
                    Med : {tooltipData.Med}% 
                </text><br/>
                <text
                    textAnchor = 'start'
                    x = {tooltipLeft + 5*unit}
                    y = {tooltipTop + 45*unit}
                    className={Styles.tooltipText}
                >
                    High : {tooltipData.High}%
                </text>
                </>
            )}
        </svg>
    </div>
    <div style={{justifyContent: 'center', display: 'flex'}}>
        <LegendOrdinal scale={ordinalColorScale}>
        {labels => {
            return (
                <div style={{justifyContent:'center', display: 'flex', flexDirection: 'rows'}}>
                    {labels.map((label, i) => {
                    const size = 8*unit;
                    return (
                        <LegendItem
                            key={`legend-quantile-${i}`}
                            margin={`0 ${5*unit}px`}
                        >
                        <svg width={size} height={size}>
                            <circle cx={size/2} cy={size/2} r={size/2}  fill={colorPalette[i]} />
                        </svg>
                            <LegendLabel
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: `${10*unit}px`,
                                    color: '#2F4585',
                                    marginLeft: `${10*unit}px`
                                }}
                            >
                                {label.text}
                            </LegendLabel>
                        </LegendItem>
                    );
                    })}
                </div>
            );
            }}
        </LegendOrdinal>
    </div>
    </div>
    );
}

export default GroupVerticalBarLegend;