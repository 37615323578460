import { gql } from "apollo-boost";
export const AverageFeedbackQuery = gql`
    query MyQuery($users : [bigint!]! , $batches: [bigint!]!, $benchUsers: [bigint!]!, $benchBatches: [bigint!]!) {
        averageFeedback: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, userid: {_in: $users}, batchid: {_in: $batches}, isdeleted: {_eq: "0"}}) {
            aggregate {
              avg {
                rating
              }
            }
          }
        
          benchMark: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, isdeleted: {_eq: "0"},userid: {_in: $benchUsers}, batchid: {_in: $benchBatches}}) {
            aggregate {
              avg {
                rating
              }
            }
          }
        
          couseFeedbackSatisfaction: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
            aggregate {
              avg {
                rating
              }
            }
          }
        
          couseFeedbackEngagement:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "impact"}, isdeleted: {_eq: "0"} userid: {_in: $users}, batchid: {_in: $batches}}) {
            aggregate {
                avg {
                rating
                }
            }
        }
        
        couseFeedbackPlatform:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "platform"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
            aggregate {
                avg {
                rating
                }
            }
        }        
        
  }
`
export const ResponsesQuery = gql`
    query MyQuery($users: [bigint!]!, $batches: [bigint!]!) {
      likes: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
        aggregate {
          sum {
            likescount
          }
        }
      }
      disLikes: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
        aggregate {
          sum {
            dislikescount
          }
        }
      }
    }
    ` 
 
export const AggregateStatsQuery = gql`
    query MyQuery($journeyinstance : [bigint!]!) {       

        completionRateUsersWithProgress: journey_journey_instance(where: {journeyinstanceid: {_in: $journeyinstance}}, order_by: {journeyinstanceid: asc}) {
          journeyinstanceid
          name
          StringJourneyInstance {
            langvalue
          }
          UserJourneyInstance_aggregate(where: {journey_progress: {_eq: "100"}, isdeleted: {_eq: "0"}}) {
            aggregate {
              count(distinct: true, columns: user_id)
            }
          }
        }

    
        completionRateAllUsers : journey_journey_instance(where: {journeyinstanceid: {_in: $journeyinstance}}, order_by: {journeyinstanceid: asc}) {
            journeyinstanceid
            name
            StringJourneyInstance {
                langvalue
              }
                UserJourneyInstance_aggregate(where: {isdeleted: {_eq: "0"}}) {
                    aggregate {
                        count(distinct: true, columns: user_id)
                    }
                }
        }

        mostTimeSpent: journey_journey_instance(where: {journeyinstanceid: {_in: $journeyinstance}}, order_by: {journeyinstanceid: asc}) {
          journeyinstanceid
          name
          StringJourneyInstance {
            langvalue
          }
          UserJourneyInstance_aggregate(distinct_on: user_id) {
            aggregate {
              sum {
                journey_instance_timespent
              }
            }
          }
        }

        timeSpentArray: journey_journey_instance(where: {journeyinstanceid: {_in: $journeyinstance}}, order_by: {journeyinstanceid: asc}) {
          journeyinstanceid
          UserJourneyInstance_aggregate(distinct_on: user_id, where: {isdeleted: {_eq: "0"}}) {
            aggregate {
              avg {
                journey_instance_timespent
              }
            }
          }
        }

        maximumNumberOfUsersAleternate: journey_journey_instance(where: {journeyinstanceid: {_in: $journeyinstance}}, order_by: {journeyinstanceid: asc}) {
          journeyinstanceid
          name
          StringJourneyInstance {
            langvalue
          }
          UserJourneyInstance_aggregate(where: {user_is_invitation_accepted: {_eq: 1}, isdeleted: {_eq: "0"}}) {
            aggregate {
              count(distinct: true, columns: user_id)
            }
          }
        }

        maximumNumberOfUsers: journey_journey_instance(where: {journeyinstanceid: {_in: $journeyinstance}}, order_by: {journeyinstanceid: asc}) {
          journeyinstanceid
          name
          StringJourneyInstance {
            langvalue
          }
          UserJourneyInstance_aggregate(distinct_on: user_id, where: {isdeleted: {_eq: "0"}}) {
            aggregate {
              count
            }
          }
        }
    }
` 
export const CourseFeedbackQuery = gql`
      query MyQuery($journeyinstance: [bigint!]!) {
        courseAndFeedback: journey_batch(where: {journeybatchinstance: {journeyinstanceid: {_in: $journeyinstance}}}, order_by: {journeybatchinstance: {journeyinstanceid: asc}}) {
          journeybatchinstance {
            journeyinstanceid
            StringJourneyInstance {
              langvalue
            }
          }
          BatchUsertagentityRelation_aggregate(where: {tagname: {_eq: "satisfaction"}, isdeleted: {_eq: "0"}}) {
            aggregate {
              sum {
                rating
              }
              count(distinct: false)
            }
          }
        }
      }

`
