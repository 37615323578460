import React,{ useState} from 'react';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Styles from './TitleDropdown.module.css'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {createMuiTheme,ThemeProvider} from "@material-ui/core/styles";

function TitleDropdown(props) {
    let arr = [];

    if(props.arr)
    {   
        arr = props.arr
    }
    else {
        arr=[
            'Drive for Results',
            'Ability To Upskill',
            'Ability To Motivate',
            'Contextual Leadership',
            'Enabling Performance',
            'Ability To Upskill',
            'Ability To Motivate',
            'Contextual Leadership',
        ]
    }
    const [course, setCourse] = useState(arr[0]);

    const handleChange = (event) => {
        setCourse(event.target.value) 
        props.setTitleCourse(event.target.value)
    };
    const select = createMuiTheme({
        overrides: {
          MuiSelect: {
            select: {
              "&:focus": {
                backgroundColor: "#f8f8f8",     
                borderRadius:'10px'           
              }
            }
          }
        }
      });
    const selectMenuProps = {        
        PaperProps: {
            style: {
                maxHeight: 131,
                oxRadius:10,                
            }
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center"
        },
        getContentAnchorEl: null
    };
    return (
        <div className={Styles.body}>  
         <ThemeProvider theme={select}>
            <FormControl className ={Styles.formControl} >
                <Select
                    IconComponent = {ExpandMoreOutlinedIcon}
                    value={course}
                    onChange={handleChange}
                    MenuProps={selectMenuProps}
                    className={Styles.dropdown}
                >
                {arr.map((name) => (            
                    <MenuItem key={name} value={name}  className={Styles.menu_item} classes={{selected : Styles.menu_item_selected}} >
                        {                     
                            name
                        }
                    </MenuItem>
                ))}
                </Select>
            </FormControl> 
        </ThemeProvider>    
        </div>
    );
}
export default TitleDropdown
