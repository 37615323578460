import React from "react"
import ContentLoader from "react-content-loader"
import WhiteBox from '../../../components/WhiteBox/WhiteBox'

const UsersLoader = () => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width = 200*unit;
    const height = 200*unit; 
    
    return(
        <WhiteBox>
            <ContentLoader 
                speed={2}
                width={width}
                height={height}
                viewBox={"0 0 "+width+" "+height}
                backgroundColor="#f3f3f3"
                foregroundColor="#dcdbdb"
            >
                <rect x={10*unit} y={20*unit} rx={5*unit} ry={5*unit} width={width - (115*unit)} height={10*unit} />
                <rect x={10*unit} y={40*unit} rx={5*unit} ry={5*unit} width={width - (150*unit)} height={10*unit} />
                <rect x={10*unit} y={70*unit} rx={5*unit} ry={5*unit} width={width - (150*unit)} height={12*unit} />
                <circle cx={(width/2) - (width/16)} cy={height - (height/8)} r={25*unit}/> 
            </ContentLoader>
        </WhiteBox>
        
    )
}

export default UsersLoader;