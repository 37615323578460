import {UPDATE_AVERAGE_COURSE_FEEDBACK_RATING,UPDATE_BENCHMARK_RATING, UPDATE_COURSEFEEDBACK,UPDATE_RESPONSE,UPDATE_AGGREGATESTATS,UPDATE_COURSERATING} from '../../constants/actions'
const initialState = {
    averageCourseFeedback:0,
    benchmark: 0,
    courseFeedback:[
        {
            title:'Satisfaction',
            rating:0,
        },
        {
            title:'Impact',
            rating:0,
        },
        {
            title:'Platform',
            rating:0,
        }
        
    ],

    response:{
        likes:0,
        dislikes:0,
    },

    aggregate:[
        {
            title:'Course With Highest Completion Rate',
            course:'',
            percent:0,
        },
        {
            title:'Average Time Spent on the Platform',
            course:'',
            time:'0',
            hr: '0',
            min: '0'
        },
        {
            title:'Course With Maximum No. of Users',
            course:'',
            count:0
        }
    ],

    courseRating:[]
}

export function experienceTab(state=initialState, action){
    switch(action.type){
        case UPDATE_AVERAGE_COURSE_FEEDBACK_RATING:
            return{
                ...state,
                averageCourseFeedback: action.payload
            }
        case UPDATE_BENCHMARK_RATING:
            return{
                ...state,
                benchmark: action.payload
            }
        case UPDATE_COURSEFEEDBACK:
            return{
                ...state,
                courseFeedback: action.payload
            }
        case UPDATE_RESPONSE:
            return{
                ...state,
                response: action.payload
            }
        case UPDATE_AGGREGATESTATS:
            return{
                ...state,
                aggregate: action.payload
            }
        case UPDATE_COURSERATING:
            return{
                ...state,
                courseRating: action.payload
            }
        
        default:
            return state
    }
}
