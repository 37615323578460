import React, {useEffect} from 'react'
import { useParams} from "react-router";
import Styles from './ActivityTabAgile.module.css'
import WhiteBox from '../WhiteBox/WhiteBox'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import LineChart from '../../commonComponents/GraphComponents/LineChart'
import HorizontalProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import HorizontalBenchmarkGroupBar from '../../commonComponents/GraphComponents/HorizontalBenchmarkGroupBar'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import { useSelector, useDispatch  } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_AGILE,UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_AGILE,UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_AGILE,
	UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_AGILE,UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_AGILE,UPDATE_PIE_CHART_ACTIVITY_TAB_AGILE,UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_AGILE,
	UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_AGILE, UPDATE_LINE_CHART_ACTIVITY_TAB_AGILE, UPDATE_BANNER_DESC_ACTIVITY_TAB_AGILE
} from '../../constants/actions'
import PieChartLoader from '../../commonComponents/Loaders/ActivityTabAll/PieChartLoader'
import LineChartLoader from '../../commonComponents/Loaders/ActivityTabAll/LineChartLoader'
import TripleLineCardLoader from '../../commonComponents/Loaders/ActivityTabAll/TripleLineCardLoader'
import DoubleLineCardLoader from '../../commonComponents/Loaders/ActivityTabAll/DoubleLineCardLoader'
import HorizontalBenchGroupLoader from '../../commonComponents/Loaders/ActivityTabAll/HorizontalBenchGroupLoader'

let unit = 1;
if(window.screen.width > 1910 && window.screen.width < 2550){
	unit = 1.5;
}else if(window.screen.width >= 2551){
	unit = 2;
}

function ActivityTabAgile(props) {
	const activityTabAgileState = useSelector(state => state.activityTabReducer.Agile)
	const orgLevelState = useSelector(state => state.orgLevelReducer)
	const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)
	let { orgId , id } = useParams();
	/*Filters*/
	let formatQuery = ''
	let formatToSearch = []
	let formatToSearchString = [] 
	formatToSearch = filterCheckedListState.learningFormats;
	if(formatToSearch[0] === "All Formats"){
		// i.e. when 'All Formats' is selected in Learning Format
		formatQuery = ''
	}
	else{
		formatToSearch.forEach((eachFormat) =>{
			formatToSearchString.push('"'+eachFormat+'"')
		})
		formatQuery = `_in: [${formatToSearchString}]`
	}

	let dealQuery = ''
	let dealToSearch = []
	let dealToSearchString = [] 
	dealToSearch = filterCheckedListState.deals;
	if(dealToSearch[0] === "All Deals"){
		// i.e. when 'All Deals' is selected in Deal
		dealQuery = ''
	}
	else{
		dealToSearch.forEach((eachDeal) =>{
			dealToSearchString.push('"'+eachDeal+'"')
		})
		dealQuery = `_in: [${dealToSearchString}]`
	}

	let batchQuery = ''
	let batchToSearch = []
	let batchToSearchString = [] 
	batchToSearch = filterCheckedListState.batches;
	if(batchToSearch[0] === "All Batches"){
		// i.e. when 'All Batches' is selected in Batch
		batchQuery = ''
	}
	else{
		batchToSearch.forEach((eachBatch) =>{
			batchToSearchString.push('"'+eachBatch+'"')
		})
		batchQuery = `_in: [${batchToSearchString}]`
	}

	let industryQuery = ''
	let industryToSearch = []
	let industryToSearchString = [] 
	industryToSearch = filterCheckedListState.industries;
	if(industryToSearch[0] === undefined){ 
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > INDUSTRY
		industryQuery = ''
	}
	else{
		industryToSearch.forEach((eachIndustry) =>{
			industryToSearchString.push('"'+eachIndustry+'"')
		})
		industryQuery = `_in: [${industryToSearchString}]`
	}

	let jobCatagoryQuery = ''
	let jobCatagoryToSearch = []
	let jobCatagoryToSearchString = [] 
	jobCatagoryToSearch = filterCheckedListState.jobCategories;
	if(jobCatagoryToSearch[0] === undefined){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > JOB CATAGORY
		jobCatagoryQuery = ''
	}
	else{
		jobCatagoryToSearch.forEach((eachjobCatagory) =>{
			jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
		})
		jobCatagoryQuery = `_in: [${jobCatagoryToSearchString}]`
	}

	let workExpQuery = ''
	let workExpToSearch = []
	let workExpToSearchString = [] 
	workExpToSearch = filterCheckedListState.workExperiences;
	if(workExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > Work Experience
		workExpQuery = ''
	}
	else{
		workExpToSearch.forEach((eachworkExp) =>{
			workExpToSearchString.push('"'+eachworkExp+'"')
		})
		workExpQuery = `_in: [${workExpToSearchString}]`
	}

	let manageExpQuery = ''
	let manageExpToSearch = []
	let manageExpToSearchString = [] 
	manageExpToSearch = filterCheckedListState.peopleManagementExperiences;
	if(manageExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > People Management Experience
		manageExpQuery = ''
	}
	else{
		manageExpToSearch.forEach((eachManageExp) =>{
			manageExpToSearchString.push('"'+eachManageExp+'"')
		})
		manageExpQuery = `_in: [${manageExpToSearchString}]`
	}

	/*Queries*/
	const bannerDescQuery = gql`
    query MyQuery {
		bannerDesc: dashboard_filters(distinct_on: journey_instance_id, where: {organization_id: {_eq: ${orgId}}, journey_instance_id: {_eq: ${id}}})
        {
          journey_domain
          journey_categories
          filterString{
            langvalue
          }
          filterStringName{
            langvalue
          }
        }
      }
    `
	const completedUserQuery = gql`
	query MyQuery {
		
		learnersSignedUp: journey_complete_user_details_aggregate(where: {user_is_invitation_accepted: {_eq: "1"}, _and: {isdeleted: {_in: ["0"]}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {batch_name: {${batchQuery}}}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}) {
			aggregate {
			  	count(columns: user_id, distinct: true)
			}
		}
	}`


	const totalOrgUsersQuery = gql`
	query MyQuery {
		totalLearnersOfOrg: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {batch_name: {${batchQuery}}}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}) {
		  aggregate {
			count(columns: user_id, distinct: true)
		  }
		}
	  }`

	const completedLearnersQuery = gql`
	query MyQuery {
		learnersCompleted: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {batch_name: {${batchQuery}}}}}}}}}}}, _and: {isdeleted: {_eq: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}`

	const avgCourseProgressQuery = gql`
	query MyQuery {
		avgCourseProgress: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {batch_name: {${batchQuery}}}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}, distinct_on: [user_id, batch_id]) {
		  aggregate {
			avg {
			  journey_progress
			}
		  }
		}
	  }`

	const avgTimeSpentQuery = gql`
	query MyQuery {
		avgTimeSpent: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {batch_name: {${batchQuery}}}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}, distinct_on: [user_id, batch_id]) {
			aggregate {
				avg {
					journey_instance_timespent
				}
			}
		}
	}`

	const completionDateQuery = gql`
	query MyQuery {
		completionDate: journey_complete_user_details(where: {journey_progress: {_eq: "100"}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {batch_name: {${batchQuery}}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}, _and: {isdeleted: {_eq: "0"}}}}}}, distinct_on: user_id) {
		  journey_instance_completed_time
		  user_id
		}
	}`
	
	const dispatch = useDispatch()
	const { loading: BDLoading, error: BDError, data: BDData } = useQuery(bannerDescQuery, {fetchPolicy: 'no-cache'})
	const { loading, error, data } = useQuery(completedUserQuery,{fetchPolicy: 'no-cache'});
	const { loading:CLLoading, error:CLError, data:CLData } = useQuery(completedLearnersQuery,{fetchPolicy: 'no-cache'});
	const { loading:ATSLoading, error:ATSError, data:ATSData } = useQuery(avgTimeSpentQuery,{fetchPolicy: 'no-cache'});
	const { loading:CDLoading, error:CDError, data:CDData } = useQuery(completionDateQuery,{fetchPolicy: 'no-cache'});
	const { loading:TLLoading, error:TLError, data:TLData } = useQuery(totalOrgUsersQuery,{fetchPolicy: 'no-cache'});
	const { loading:ACPLoading, error:ACPError, data:ACPData } = useQuery(avgCourseProgressQuery,{fetchPolicy: 'no-cache'});

	useEffect(() => {
        if(!BDLoading && !BDError && BDData!=null)
        {
            dispatch({ type: UPDATE_BANNER_DESC_ACTIVITY_TAB_AGILE, payload: BDData.bannerDesc })
        }
    }, [BDLoading, BDError, BDData])

	useEffect(() => {
        if(!loading && !error && data!=null){
			//console.log(data)
			dispatch({type: UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_AGILE, payload: data.learnersSignedUp.aggregate.count});
			let totalEnrolledLernersAGILEwithFilter = activityTabAgileState.enrolledLearnersAGILE;
			if (totalEnrolledLernersAGILEwithFilter == 0)  totalEnrolledLernersAGILEwithFilter = 1;

			let totalSignedUpLernersAGILEwithFilter = activityTabAgileState.noOfLearnersSignedUpAGILE;
			if (totalSignedUpLernersAGILEwithFilter == 0)  totalSignedUpLernersAGILEwithFilter = 1;
			// dispatch({type: UPDATE_PIE_CHART_ACTIVITY_TAB_AGILE, 
			// 	payload: [
			// 		Math.round((activityTabAgileState.noOfCompletedLearnersAGILE/totalSignedUpLernersAGILEwithFilter)*100),
			// 		Math.round((data.learnersCompletedAllOrg.aggregate.count/data.totalLearnersAllOrg.aggregate.count)*100)
			// 	]
			// });
			dispatch({type: UPDATE_PIE_CHART_ACTIVITY_TAB_AGILE, 
				payload: [
					Math.round((activityTabAgileState.noOfCompletedLearnersAGILE/totalEnrolledLernersAGILEwithFilter)*100),
					Math.round(38)
				]
			});
			// dispatch({type: UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_AGILE, 
			// 	payload: [
			// 		Math.round((data.learnersSignedUp.aggregate.count/totalEnrolledLernersAGILEwithFilter)*100),
			// 		Math.round((data.learnersSignedUpAllOrg.aggregate.count/data.totalLearnersAllOrg.aggregate.count)*100)
			// 	]
			// });
			dispatch({type: UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_AGILE, 
				payload: [
					Math.round((data.learnersSignedUp.aggregate.count/totalEnrolledLernersAGILEwithFilter)*100),
					Math.round(56)
				]
			});
		}
	}, [loading, error, data, activityTabAgileState.noOfLearnersSignedUpAGILE,activityTabAgileState.enrolledLearnersAGILE, activityTabAgileState.noOfCompletedLearnersAGILE])

	useEffect(() => {
        if(!TLLoading && !TLError && TLData!=null){			
			dispatch({type: UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_AGILE, payload: TLData.totalLearnersOfOrg.aggregate.count});
		}
	}, [TLLoading, TLError, TLData])
	useEffect(() => {
        if(!CLLoading && !CLError && CLData!=null){		
			dispatch({type: UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_AGILE, payload: CLData.learnersCompleted.aggregate.count});
		}
	}, [CLLoading, CLError, CLData])
	useEffect(() => {
        if(!ACPLoading && !ACPError && ACPData!=null){
			dispatch({type: UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_AGILE, payload: Math.round(ACPData.avgCourseProgress.aggregate.avg.journey_progress)});
			dispatch({type: UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_AGILE, payload: Math.round(ACPData.avgCourseProgress.aggregate.avg.journey_progress)});
		}
	}, [ACPLoading, ACPError, ACPData])
	useEffect(() => {
        if(!ATSLoading && !ATSError && ATSData!=null){
			dispatch({type: UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_AGILE, payload: Math.round(ATSData.avgTimeSpent.aggregate.avg.journey_instance_timespent/60)});
		}
	}, [ATSLoading, ATSError, ATSData])

	let completionDateObj = {};
	let completionDatePayload = [];
	useEffect(() => {
        if(!CDLoading && !CDError && CDData!=null){
			if(CDData.completionDate.length != 0){
				CDData.completionDate.sort((a, b) => {
						return a.journey_instance_completed_time - b.journey_instance_completed_time;
					}
				)
				let cumulative = 0
				let dayOne = CDData.completionDate[0].journey_instance_completed_time || 0;
				let dayLast = 0;
				CDData.completionDate.forEach(
					(eachObj) =>{
						if(eachObj.journey_instance_completed_time != null ){
							let date = Math.floor((eachObj.journey_instance_completed_time - dayOne)/86400) + 1;
							dayLast = date;
							if (!(date in completionDateObj))
							{
								completionDateObj[date] = cumulative + (100/activityTabAgileState.enrolledLearnersAGILE);
								cumulative += (100/activityTabAgileState.enrolledLearnersAGILE)
							}
							else{
								completionDateObj[date] = completionDateObj[date] + (100/activityTabAgileState.enrolledLearnersAGILE);
								cumulative += (100/activityTabAgileState.enrolledLearnersAGILE)
							}
						}
					}
				);
				
				let previousDayPercentage = 0;
				for(let dates = 0; dates <= dayLast; dates++){
					if(completionDateObj[dates]){
						let currentDaypercentage = parseInt(completionDateObj[dates], 10)
						completionDatePayload.push({'Days':parseInt(dates, 10), '% Of Users': currentDaypercentage });
						previousDayPercentage = currentDaypercentage;
					}
					//Interpolating for missing days (i.e. days with 0 completed user), to have % of completion same as previous day
					else{
						completionDatePayload.push({'Days':parseInt(dates, 10), '% Of Users': previousDayPercentage });
					}
				}
				dispatch({type: UPDATE_LINE_CHART_ACTIVITY_TAB_AGILE, 
					payload: completionDatePayload
				});
			}
			else{
				for(let dates = 0; dates <= 100; dates++){
					completionDatePayload.push({'Days':parseInt(dates, 10), '% Of Users': 0 })
				}
				dispatch({type: UPDATE_LINE_CHART_ACTIVITY_TAB_AGILE, 
					payload: completionDatePayload
				});
			}
		}
	}, [CDLoading, CDError, CDData, activityTabAgileState.enrolledLearnersAGILE])

	if(error,TLError,CLError,ACPError,ATSError, CDError) {
		console.log(error || TLError || CLError || ACPError || ATSError || CDError)
        return <div>Error..</div>
	}

	return (
        <div className={Styles.mainContainer}>
            <div data-aos="fade-up" data-aos-duration="350"  data-aos-once="true">
                <SimpleBanner title={activityTabAgileState.bannerDesc.name} domainCategoryArr={activityTabAgileState.bannerDesc.domainCategory} description={activityTabAgileState.bannerDesc.description} noshow={true} />
            </div>

            <div className={Styles.row1Container} data-aos="fade-up" data-aos-duration="350" data-aos-delay="350" data-aos-once="true">
                <div className={Styles.twoRowsSpan}>
					<WhiteBox>
						{loading &&
							<PieChartLoader {...activityTabAgileState.dataForPieChartAGILE}/>
						}
						{!loading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title={<span> COMPLETION <br/> (% OF LEARNERS)</span>} infoBody="% of enrolled learners who have completed this course.Group: % of enrolled learners in this organization who have completed this course.Benchmark: Average % of enrolled learners in the universe who have completed this course" />
							</div>
							<div>
								<PieChart {...activityTabAgileState.dataForPieChartAGILE}/>
								<br/>
							</div>
						</>
						}
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						{CLLoading &&
							<TripleLineCardLoader width = {230*unit} height = {110*unit} thirdRectY = {65*unit}/>
						}
						{!CLLoading &&
							<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="NO. OF LEARNERS WHO COMPLETED" infoBody="Total number of learners who have completed this course" />
							</div>
							<div className={Styles.text1}>
								{activityTabAgileState.noOfCompletedLearnersAGILE}
							</div>
							</>
						}
					</WhiteBox>
				</div>
				<div className= {Styles.otherStatsContainer} >
					<div className={ Styles.otherStatsHeader }>
						&emsp;OTHER STATISTICS
					</div>
					<div>
						<WhiteBox>
							{ TLLoading &&
								<DoubleLineCardLoader />
							}
							{ !TLLoading &&
							<>
								<div className={Styles.graphTitle}>
									<GraphTitle title="ENROLLED LEARNERS" infoBody="Total number of learners who have enrolled in this course" />
								</div>
								<div className={Styles.text1}>
									{activityTabAgileState.enrolledLearnersAGILE}
								</div>
							</>
							}
						</WhiteBox>
					</div>
					<div>
						<WhiteBox>
							{ ATSLoading &&
								<DoubleLineCardLoader />
							}
							{ !ATSLoading &&
							<>
								<div className={Styles.graphTitle}>
									<GraphTitle title="AVERAGE TIME SPENT" infoBody="Average time spent by all the learners on the platform for this course" />
								</div>
								<div className={Styles.text1}>
									{activityTabAgileState.averageTimeSpentAGILE}<text style={{fontSize: '10px'}}>mins</text>
								</div>
							</>
							}
						</WhiteBox>
					</div>
				</div>
				<div>
					<WhiteBox>
						{ACPLoading &&
							<TripleLineCardLoader width = {230*unit} height = {110*unit} thirdRectY = {65*unit}/>
						}
						{!ACPLoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="AVERAGE COURSE PROGRESS" infoBody="Average course progress across all the learners in the organization" />
							</div>
							<div className={Styles.insideWhiteBox4Columns}>
								<div className={Styles.text1}>
									<text>{activityTabAgileState.avgCourseProgressAGILE}</text>
									<text style={{fontSize: '10px'}}>%</text>
								</div>
								<div className={Styles.threeColumnsSpan}>
									<HorizontalProgressBar {...activityTabAgileState.dataForHorizontalProgressBarAGILE}/>
								</div>
							</div>
						</>
						}
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						{loading &&
							<HorizontalBenchGroupLoader width = {440*unit} height = {115*unit}/>
						}
						{!loading &&
						<>
						<div className={Styles.graphTitle}>
							<GraphTitle title="LEARNERS WHO SIGNED UP" infoBody="Total number of learners who have signed up for this course" />
						</div>
						<div className={Styles.insideWhiteBox4Columns}>
							<div className={Styles.text1}>
								{activityTabAgileState.noOfLearnersSignedUpAGILE}
							</div>
							<div className={Styles.threeColumnsSpan} style={{borderLeft: '2px solid #F5EFFB'}}>
								<HorizontalBenchmarkGroupBar {...activityTabAgileState.dataForHorizontalBenchmarkGroupBarAGILE}/>
							</div>
						</div>
						</>
						}
					</WhiteBox>
				</div>
            </div>

			{/* <div className={Styles.row2Container}data-aos="fade-up" data-aos-duration="350" data-aos-delay="700" data-aos-once="true">
				<div className={Styles.twoColumnsSpan}>
					<WhiteBox>
						{CDLoading &&
							<LineChartLoader {...activityTabAgileState.dataForLineChartAGILE} />
						}
						{!CDLoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="TREND OF LEARNER CONSUMPTION" infoBody="Trend of the % of learners (on each day) who have accessed this course across the overall time period" />
							</div>
							<br/>
							<div>
								<LineChart {...activityTabAgileState.dataForLineChartAGILE} />
							</div>
							<br/>
						</>
						}
					</WhiteBox>
				</div>
            </div> */}
        </div>
        )
}

export default ActivityTabAgile ;