import React from 'react';
import BarGraph from './GraphComponents/BarGraph'
import HorizontalStackedBar from './GraphComponents/HorizontalStackedBar'
import HorizontalProgressBar from './GraphComponents/HorizontalProgressBar'
import CircularProgressBar from './GraphComponents/CircularProgressBar'
import CircularTextRing from './GraphComponents/CircularTextRing'
import LineChart from './GraphComponents/LineChart';
import GroupHorizontalBar from './GraphComponents/GroupHorizontalBar'
import HorizontalBenchmarkGroupBar from './GraphComponents/HorizontalBenchmarkGroupBar'
import GroupVerticalBar from './GraphComponents/GroupVerticalBar'
import PieChart from '../commonComponents/GraphComponents/PieChart'
import PieChartFilled from './GraphComponents/PieChartFilled';
import BarWithLineChart from './GraphComponents/BarWithLineGraph'
import BarAccuracyProportionFrequency from './GraphComponents/BarAccuracyProportionFrequency'
import BarBenchmarkGroup from './GraphComponents/BarBenchmarkGroup.js'
import BarGraphGroupBenchmark from './GraphComponents/BarGraphGroupBenchmark'
import BarGraphLegend from './GraphComponents/BarGraphLegend'
import BarGraphLegend1 from './GraphComponents/BarGraphLegend1'
import GroupVerticalLegend from './GraphComponents/GroupVerticalLegend'

function AllGraphs(props) {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const dataForBarGraph = {
                                width : 450*unit,
                                height : 170*unit,
                                data : [
                                    { exp: 'Novice', '% Of Users': 13, years: '(1,2)', 'countOfUsers': 10 },
                                    { exp: 'Emerging', '% Of Users': 25, years: '(3,4)', 'countOfUsers': 10 },
                                    { exp: 'Competent', '% Of Users': 50, years: '(5,6)', 'countOfUsers': 10 },
                                    { exp: 'Proficient', '% Of Users': 35, years: '(7,8)', 'countOfUsers': 10 },
                                    { exp: 'Role Model', '% Of Users': 28, years: '(9,10)', 'countOfUsers': 10 },
                                ]
    }
    const dataForBarGraph2 = {
                            width : 300*unit,
                            height : 170*unit,
                            data : [
                                { exp: 'Desired Vs. Actual', '% Of Deviation': 45,  },
                                { exp: 'Desired Vs. Intent', '% Of Deviation': 33,  },
                                { exp: 'Intent Vs. Actual', '% Of Deviation': 60,  },
                            ]
    }
    const dataForBarGraph5 = {
        width : 300*unit,
        height : 170*unit,
        data : [
            { exp: 'Group', '$': 45,  },
            { exp: 'Benchmark', '$': 33,  }
        ]
    }
    const dataForBarGraph6 = {
        width : 300*unit,
        height : 170*unit,
        data : [
                    { exp: 'Goal', '% Of Deviation': 45,  },
                    { exp: 'Reality', '% Of Deviation': 33,  },
                    { exp: 'Options', '% Of Deviation': 60,  },
                    { exp: 'Way', '% Of Deviation': 10,  }
              ]
    }
    const dataForBarGraph7 = {
        width : 300*unit,
        height : 170*unit,
        data : [
                    { exp: 'Low', '% Of Deviation': 10,  },
                    { exp: 'Med', '% Of Deviation': 15,  },
                    { exp: 'High', '% Of Deviation': 20,  },
              ]
    }
    const dataForHorizontalProgressBar1 = { width : 270*unit, height : 10*unit, 'Total': 10, 'score': 5.1 };
    const dataForHorizontalProgressBar2 = { width : 270*unit, height : 10*unit, 'Total': 100, 'score': 75 };
    const dataForLineChart = {
                                'width' : 450*unit,
                                'height' : 170*unit,
                                'data':[]                   
    }
    for(let i = 1; i <= 100; i++ ){
        dataForLineChart.data.push(
            {'Days':i, '% Of Users': Math.floor(50+Math.random()*30)},
    );
    }
    const dataForHorizontalStackedBar = {
                                        height: 20*unit,
                                        width: 220*unit,
                                        data: [
                                            {Consumed: 160, Available: 40 },
                                        ]
    }
    const dataForProgressCircle4 = { 'height':50*unit, 'width':50*unit, 'Total': 100, 'score': 84 };
    const dataForProgressCircle5 = { 'height':122*unit, 'width':122*unit, 'Total': 100, 'score': 79 };
    const dataForCircularTextRing = {'height':50*unit, 'width':50*unit, 'count':750}
    const dataForGroupHorizontalBar = { 'height':300*unit, 
                                        'width':550*unit,
                                        'data': [
                                            { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
                                            { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
                                            { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
                                            { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
                                            { Title: 'Drive for Results', Group: 'Novice', Benchmark: 'Proficient' },
                                            { Title: 'Null Users Example', Group: ' ', Benchmark: 'Proficient' },
                                        ],
                                    }
    const dataForHorizontalBenchmarkGroupBar = { 'height':51*unit, 
                                                'width': 240*unit,
                                                'data': [
                                                    { Title: 'Group', '% Of Users': 74 },
                                                    { Title: 'Benchmark', '% Of Users': 90 },
                                                ],
                                    }
    const dataForGroupVerticalBar = {
                                        'height': 200*unit,
                                        'width': 370*unit,
                                        'data' : [
                                            { Type: 'Conversions', Group: 50, Target: 62, Benchmark: 75 },
                                            { Type: 'Revenue', Group: 57, Target: 78, Benchmark: 75 },
                                        ]
    }
    const dataForGroupVerticalBar1 = {
        'height': 200*unit,
        'width': 370*unit,
        'data' : [
            { Type: 'Team Skill', Group: 50, Target: 62, Benchmark: 75 },
            { Type: 'Team Morale', Group: 80, Target: 78, Benchmark: 75 },
            { Type: 'Customer Focus', Group: 25, Target: 62, Benchmark: 75 }
        ]
}
    const dataForPieChart = {'height':200*unit, 'width':200*unit,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80};
    const dataForPieChartFilled = {'height':200*unit, 'width':200*unit, 
                                    'data':[
                                        {'Unachieved': 77, 'Achieved': 23}
                                    ]
                                }
    const dataForPieChartFilled2 = {'height':200*unit, 'width':200*unit, 
                                    'data':[
                                        {'Avg. no. of questions unanswered': 82, 'Avg. no. of questions answered': 18}
                                    ]
                                }
    const dataForBarWithLineChart = {
                                        'height':200*unit, 
                                        'width':450*unit, 
                                        'data':[
                                            { Type: 'Innovators',       'Stakeholders': 18, 'TimeSpent': 22},
                                            { Type: 'Early Adopters',   'Stakeholders': 42, 'TimeSpent': 50},
                                            { Type: 'Early Majority',   'Stakeholders': 80, 'TimeSpent': 80},
                                            { Type: 'Late Majority',    'Stakeholders': 30, 'TimeSpent': 58},
                                            { Type: 'Laggards',         'Stakeholders': 30, 'TimeSpent': 36},
                                        ]
    }
    const dataForBarProportionAccuracy ={
                                            'height':180*unit, 
                                            'width':310*unit, 
                                            'data':[
                                                { Type: 'Directing',    'Proportion': 40, 'Accuracy': 37},
                                                { Type: 'Entrusting',   'Proportion': 24, 'Accuracy': 18},
                                                { Type: 'Guiding',      'Proportion': 56, 'Accuracy': 70},
                                                { Type: 'Partnering',   'Proportion': 58, 'Accuracy': 62},
                                            ]
    }
    const dataForBarFrequencyAccuracy ={
                                            'height':180*unit, 
                                            'width':310*unit, 
                                            'data':[
                                                { Type: 'R', 'Frequency': 60, 'Accuracy': 80},
                                                { Type: 'E', 'Frequency': 60, 'Accuracy': 80},
                                                { Type: 'P', 'Frequency': 60, 'Accuracy': 80},
                                                { Type: 'S', 'Frequency': 60, 'Accuracy': 80},
                                            ]
    }
    const dataForBarBenchmarkGroup = {
                                        'height':190*unit, 
                                        'width':470*unit, 
                                        'data':[
                                            { Type: 'Net Profit ',          'Group': 60, 'Benchmark': 50},
                                            { Type: 'Employee Utilization', 'Group': 80, 'Benchmark': 90},
                                            { Type: 'Market Share',         'Group': 80, 'Benchmark': 80},
                                            { Type: 'Economies Of Sale',    'Group': 30, 'Benchmark': 40},
                                        ]
    }
    const dataForBarBenchmarkGroup2 = {
                                        'height':150*unit, 
                                        'width':350*unit, 
                                        'data':[
                                            { Type: 'STP ',                 'Group': 60, 'Benchmark': 90},
                                            { Type: 'Opportunity Less',     'Group': 58, 'Benchmark': 72},
                                        ]
    }

    return (
        <div>
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarGraph {...dataForBarGraph}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarGraph {...dataForBarGraph2}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <p> Sample For: 5.1/10 </p>
                <HorizontalProgressBar {...dataForHorizontalProgressBar1} />
                <br/>
                <p> Sample For: 75% </p>
                <HorizontalProgressBar {...dataForHorizontalProgressBar2} />
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <LineChart {...dataForLineChart}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <HorizontalStackedBar {...dataForHorizontalStackedBar}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <GroupHorizontalBar {...dataForGroupHorizontalBar}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                
                <CircularProgressBar {...dataForProgressCircle5}/>
            </div>
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                
                <CircularProgressBar {...dataForProgressCircle4}/>
            </div> 
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                
                <CircularTextRing {...dataForCircularTextRing}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <HorizontalBenchmarkGroupBar {...dataForHorizontalBenchmarkGroupBar}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <GroupVerticalBar {...dataForGroupVerticalBar}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <PieChart {...dataForPieChart}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <PieChartFilled {...dataForPieChartFilled}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <PieChartFilled {...dataForPieChartFilled2}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarWithLineChart {...dataForBarWithLineChart}/>
            </div>
            
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarAccuracyProportionFrequency {...dataForBarProportionAccuracy}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarAccuracyProportionFrequency {...dataForBarFrequencyAccuracy}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarBenchmarkGroup {...dataForBarBenchmarkGroup}/>
            </div>

            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarBenchmarkGroup {...dataForBarBenchmarkGroup2}/>
            </div>
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarGraphGroupBenchmark {...dataForBarGraph5}/>
            </div>
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarGraphLegend {...dataForBarGraph6}/>
            </div>
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <BarGraphLegend1 {...dataForBarGraph7}/>
            </div>
            <div style={{borderStyle: 'solid', margin: '20px', padding: '20px', display: 'inline-block'}}>
                <GroupVerticalLegend {...dataForGroupVerticalBar1}/>
            </div>
        </div>
    );
}

export default AllGraphs
