import React from 'react'
import Styles from './GroupIdeal.module.css'
import InfoImg from '../../../assets/img/info.png'
import GraphTitle from '../../textComponent/GraphTitle/Title'

function GroupIdeal(props){ 
    
    const headerTitle= props.data.headerTitle
    const xAxis = props.data.xAxis
    const yAxis = props.data.yAxis
    const graphData = props.data.meetPoints  
    
    const checkboxtype = (index,point,xaxis) =>{
        if(graphData[index][xaxis][0] == point && graphData[index][xAxis[index]][1] == point){
            return 'group_ideal'
        }
        if(graphData[index][xaxis][0] == point){
            return 'group'
        }
        if(graphData[index][xaxis][1] == point){
            return 'ideal'
        }        
    }
    
    const handldivstyle = (type) =>{
        if(type === 'group'){
            return (<td className={Styles.box}> 
                        <div className={Styles.group_box_outer}>
                            <div className={Styles.group_box_circle}></div>
                        </div>
                    </td>)
        }
        if(type === 'ideal'){
            return (<td className={`${Styles.box} ${Styles.ideal_box}`}></td>)
        }
        if(type === 'group_ideal'){
            return (<td className={`${Styles.box} ${Styles.ideal_box}`} >
                        <div className={Styles.group_box_outer}>
                            <div className={Styles.group_box_circle}></div>
                        </div>
                    </td>)
        }
        else{
            return (<td class={Styles.box}></td>)
        }
    }
    //generate columns as per the xaxis labels
    const generateboxes = (point) => {
        let boxes = []
        for(let i=0; i< xAxis.length;i++){ 
            const result = checkboxtype(i,point,xAxis[i])              
            boxes.push(handldivstyle(result))
        }
        return boxes
    }
    //generate columns for xaxis scale line
    const generatexaxis = () => {
        let xaxisBoxes = []
        xaxisBoxes.push(<td className={`${Styles.box} ${Styles.no_border_box}`}></td>)
        for(let i=0;i<xAxis.length;i++){
            xaxisBoxes.push(                
                <td className={`${Styles.box} ${Styles.no_border_box}`} key={xAxis[i]}>{xAxis[i]}</td>              
            )
        }
        return xaxisBoxes
    }
    //generate graph
    const generatebody = () => {        
        let res=[];
        for(let i =0; i < yAxis.length; i++){                           
            res.push(
                <tr>        
                    <td className={Styles.box}>{yAxis[i]}</td>
                    {generateboxes(yAxis[i])}
                </tr>
            )
        }
        res.push(
            <tr>           
                {generatexaxis()}
            </tr>
        )
        
        return res           
    }    
      
    return(
        <div className={Styles.body}> 
            <div className={Styles.group_ideal_whole_div}>
                <div className={Styles.header}>                    
                    <GraphTitle title={headerTitle} infoBody={props.infoBody}/>
                </div>
                <table className={Styles.group_ideal_div} >
                    {generatebody()}
                </table>            
                <div className={Styles.legend_div}>
                    <div className={Styles.legend_div_icon_outer_div}>
                        <div className={Styles.legend_div_group_icon}></div>
                    </div>
                    <div className={Styles.legend_div_group_label}>Group</div>
                    
                    <div className={Styles.legend_div_icon_outer_div} >
                        <div className={Styles.legend_div_ideal_icon} ></div>
                    </div>
                    <div>Ideal</div>
                </div>            
            </div>
        </div>
    );
}
export default GroupIdeal



