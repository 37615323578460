import React from 'react'
import Styles from "./Aggregate.module.css";
import CircularProgressBar from '../../GraphComponents/CircularProgressBar';
import CircularTextRing from '../../GraphComponents/CircularTextRing'
import { useSelector } from "react-redux";

function Aggregate(props){
    const aggregate=useSelector(state=>state.experienceTab.aggregate);
    let width = 44
    let height = 44
    if(window.innerWidth >= 1920 && window.innerWidth < 2560){
        width = 66
        height = 66
    }
    else if(window.innerWidth >= 2560){
        width = 88
        height = 88
    }

    return(
        <div className={Styles.container}>
            <div className={Styles.title}>
                AGGREGATE STATS : MILESTONE
            </div>
            <div>
                {
                    aggregate.map((value)=>{
                        
                        return(
                            <div>
                                <div className={Styles.subTitle}>
                                    {value.title}
                                </div>
                                <div className={Styles.innerBox}>
                                    {
                                       value.min &&
                                       <div className={Styles.timeBox}>
                                           <div className={Styles.time}>
                                               {value.hour} <span className={Styles.text1}>hours</span>  {value.min} <span className={Styles.text1}>min</span>
                                           </div>
                                       </div>
                                    }
                                    {
                                        value.percent &&
                                        <CircularProgressBar height={height} width={width} score={value.percent} Total='100'/>
                                    }
                                    {
                                        value.count &&
                                        <CircularTextRing height={height} width={width} count={value.count}/>  
                                    }
                                    <div className={Styles.text}>
                                        {value.course}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}


export default Aggregate