import React, {useState, useEffect} from 'react';
import InfoOffImg from '../../../assets/img/infoClose.png'
import InfoOnImg from '../../../assets/img/infoOpen.png'
import Styles from "./Title.module.css";

function TooltipManual(props) {
    const [open, setOpen] = useState(false)
    let tooltipConditional = <div>Until ipsum.</div>
        let str = props.infoBody
        let parts = str.split('.')
        //console.log(parts)
        tooltipConditional = <div>
            {
                parts.map((txt) => {
                    return (
                        <div key={txt}>{txt}.</div>
                    )
                })
            }
        </div>

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <div className={Styles.tooltipManualContainer} onMouseEnter={handleOpen} onMouseLeave={handleClose}>
            <img src={open ? InfoOnImg : InfoOffImg} className={Styles.info_icon_image} />
            <span><div className={Styles.tooltipText}>{tooltipConditional}</div></span>
        </div>
    )
}

export default TooltipManual