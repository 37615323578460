import sw from 'remove-stopwords'

const _ = require('lodash')

export function handleAverageCourseFeedbackRatingFS(payload,type){
    const rating = payload.aggregate.avg.rating || 0
    return {
        type: type,
        payload: Math.round(rating * 100) / 100
           
    }
}

export function handleBenchmarkRatingFS(payload,type){
    const rating = payload.aggregate.avg.rating || 0
    return {
        type: type,
        payload: Math.round(rating * 100) / 100       
    }
}

export function handleCourseFeedbackFS(payload,type){
    const courseFeedbackArray = [
        {
            title:'Satisfaction',
            rating:Math.round((payload.couseFeedbackSatisfaction.aggregate.avg.rating || 0) * 100) / 100  
        },
        {
            title:'Impact',
            rating:Math.round((payload.couseFeedbackEngagement.aggregate.avg.rating || 0) * 100) / 100  
        },
        {
            title:'Platform',
            rating:Math.round((payload.couseFeedbackPlatform.aggregate.avg.rating || 0) * 100) / 100  
        }
        
    ]
    return {
        type: type,
        payload: courseFeedbackArray        
    }
}

export function handleResponsesFS(payload,type){
    const responsesObj = {
        likes:payload.likes.aggregate.sum.likescount || 0,
        dislikes:payload.disLikes.aggregate.sum.dislikescount || 0,
    }
    return {
        type: type,
        payload: responsesObj        
    }
}

export function handleAggregateFS(payload,type){
    let highCompletionRate = 0
    let completionRateCourseName = ''
    let mostTime = 0
    let mostTimeCourseName = ''
    let maxUsers = 0
    let maxUsersCourseName = ''
    let min =0
    let hr =0
    let avgTimeSpent=0

    for(let i=0; i<payload.completionRateAllUsers.length; i++){
        let completionRate = Math.round((payload.completionRateUsersWithProgress[i].UserJourneyInstance_aggregate.aggregate.count / payload.completionRateAllUsers[i].UserJourneyInstance_aggregate.aggregate.count)*100)  
        let mTime = payload.mostTimeSpent[i].UserJourneyInstance_aggregate.aggregate.sum.journey_instance_timespent 
        let max = payload.maximumNumberOfUsers[i].UserJourneyInstance_aggregate.aggregate.count 

        if(completionRate && completionRate > highCompletionRate){
            highCompletionRate = completionRate
            completionRateCourseName = payload.completionRateUsersWithProgress[i].StringJourneyInstance.langvalue
        }  
        if(mTime && mTime > mostTime){
            mostTime = mTime
            mostTimeCourseName = payload.mostTimeSpent[i].StringJourneyInstance.langvalue
        }  
        if(max && max > maxUsers){
            maxUsers = max
            maxUsersCourseName = payload.maximumNumberOfUsers[i].StringJourneyInstance.langvalue
        } 

    }

    for(let i=0 ; i<payload.timeSpentArray.length ; i++)
    {
        avgTimeSpent = avgTimeSpent + payload.timeSpentArray[i].UserJourneyInstance_aggregate.aggregate.avg.journey_instance_timespent
    }
    let total3 = payload.timeSpentArray.length
    if(payload.timeSpentArray.length==0)
    {
        hr = '0'
        min = '0'
    }
    else {
        avgTimeSpent = avgTimeSpent/payload.timeSpentArray.length
        hr = Math.floor(avgTimeSpent / 3600)
        min = Math.round((avgTimeSpent % 3600) / 60)
    }    

    let time = Math.floor(mostTime / 60)
    let mostTimeSpent =''
    if(time >= 1 && time <= 999){
        mostTimeSpent = `${time}Mins`
    }
    if(time >= 1000 && time <= 59940){
        mostTimeSpent = `${Math.floor(time/ 60)}Hors`
    }
    if(time > 59940){
        mostTimeSpent = `${Math.floor(time/ 1440)}Days`
    }
    const aggregateArray = [
        {
            title:'Course With Highest Completion Rate',
            course: completionRateCourseName,
            percent:highCompletionRate,
        },
        {
            title:'Average Time Spent on the Platform',
            hour: `${hr}`,
            min: `${min}`
        },
        {
            title:'Course With Maximum Enrolled Learners',
            course: maxUsersCourseName,
            count:maxUsers
        }
    ]
    return {
        type: type,
        payload: aggregateArray        
    }    
}

export function handleCourseAndFeedbackFS(payload,type,journeyDomainCategories){         
    let courseFeedbackData = []    
    let res = []
    for(let i=0; i<payload.courseAndFeedback.length; i++){
        let journeyInstanceId = payload.courseAndFeedback[i].journeybatchinstance.journeyinstanceid
        let courseName = payload.courseAndFeedback[i].journeybatchinstance.StringJourneyInstance.langvalue
        let sum = payload.courseAndFeedback[i].BatchUsertagentityRelation_aggregate.aggregate.sum.rating
        let count = payload.courseAndFeedback[i].BatchUsertagentityRelation_aggregate.aggregate.count
        if(sum){
            let itemIndex = courseFeedbackData.findIndex(item => item.journeyInstanceId == journeyInstanceId)
            if(itemIndex < 0){
                courseFeedbackData.push({'journeyInstanceId': journeyInstanceId,'courseName':courseName,'sum':sum,'count':count})
            } 
            else{
                courseFeedbackData[itemIndex].sum = courseFeedbackData[itemIndex].sum + sum
                courseFeedbackData[itemIndex].count = courseFeedbackData[itemIndex].count + count
            }
            
        }        
        for(let i=0;i<courseFeedbackData.length;i++){
            let dc = journeyDomainCategories[courseFeedbackData[i].journeyInstanceId]
            res[i] = {"title":courseFeedbackData[i].courseName,"lead": dc,"rating":courseFeedbackData[i].sum/courseFeedbackData[i].count}
        }
        
    } 
    return {
        type: type,
        payload: res        
    }
}

 export function handleWordCloudFS(payload, type){
     let str = ''
     for(let i=0;i<payload.wordCloud.length;i++){
         let word = payload.wordCloud[i].textvalue
         if(word != null)
            str += ` ${word}`

     }
     let words = str.replace(/[.,]/g, ' ').split(/\s/);
     const noStopWords = sw.removeStopwords(words)
     let t = []
     for(let i=0;i<noStopWords.length;i++){
         let v = false
        for(let j in t){
            if(t[j].text === noStopWords[i]){
                t[j].value += 1
                v = true
                break
            }        

        }
        if(!v){
            t.push({'text': noStopWords[i], 'value': 1})
        }

     }    
     
    return {
        type: type,
        payload: t        
    } 
 }

 export function handleBannerFS(payload, type){
    let banner = { courseName: '', description:'',domainCategory:[]}
    banner.courseName= payload.banner[0].filterStringName[0].langvalue
    banner.description= payload.banner[0].filterString[0].langvalue
    banner.domainCategory= [payload.banner[0].journey_domain, payload.banner[0].journey_categories]

   return {
       type: type,
       payload: banner       
   } 
}
