import React, { Component } from 'react';
import Styles from './Rated.module.css'
import arrow from '../../../assets/img/arrow.png'

var lead=[
    'Leading next',
    'Leading Business'
]

function Rated(props){
    if(props.rated){
        return(
            <div className={Styles.container1}>
                <div className={Styles.text1}>
                    {props.rating}
                </div>
            </div>
        )
    }
    return null
    
}

function Component2(props){
    return(
        <div className={Styles.alignment1}>
                {
                    props.arr && props.arr.map((val, index)=>{
                        return (
                            <div className={Styles.alignment2}>
                                <div className={Styles.container2}>
                                <div className={Styles.text2}>
                                        {index==0?val.toUpperCase():val}
                                    </div>
                                </div>
                                {
                                    index<props.arr.length-1&& <img src={arrow} className={Styles.arrow}/>
                                }
                            </div>
                        )
                    })
                }
        </div> 
    )
}

function Component3(props){
    return(
        <div >
            <div className={Styles.titleBox}>
                <div className={Styles.heading}>{props.title}</div>
                {
                    props.rating &&
                    <div className={Styles.rating}>{Math.round(props.rating * 100) / 100}</div>
                }
            </div>
            <Component2 arr={props.lead}/>   
        </div>
    )
}

export {Component3,Component2}