import {UPDATE_ORG_NAME,UPDATE_LANDING_PAGE_ITEMS,UPDATE_HEADER_ITEMS,UPDATE_FILTERPANEL_API_ITEMS} from '../constants/actions'

export function handleFilters(payload, type, statevariable){
    return {
        type: type,
        payload: payload,
        variable: statevariable
    }
}

export function handleOrgName(payload){
    return {
        type: UPDATE_ORG_NAME,
        payload: payload        
    }
}

export function handleLandingPageItems(payload){
    return {
        type: UPDATE_LANDING_PAGE_ITEMS,
        payload: payload        
    }
}

export function handleHeaderItems(payload){
    return {
        type: UPDATE_HEADER_ITEMS,
        payload: payload        
    }
}

export function handleFilterPanelApiItems(payload){
    return {
        type: UPDATE_FILTERPANEL_API_ITEMS,
        payload: payload        
    }
}