import React from 'react'
import Styles from './downloadReport.module.css'
import Button from '@material-ui/core/Button';

function DownloadReport(props){
    return(
        <div className={Styles.body}>       
            <div variant="outlined" color="primary" className={Styles.download_but}>
                    <div className={Styles.butText}>Download Report</div>
            </div>
        </div>
    )
}
export default DownloadReport
