import React, {useEffect} from 'react'
import { useParams} from "react-router";
import Styles from './ActivityTab.module.css'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import WhiteBox from '../WhiteBox/WhiteBox'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import LineChart from '../../commonComponents/GraphComponents/LineChart'
import HorizontalProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import HorizontalBenchmarkGroupBar from '../../commonComponents/GraphComponents/HorizontalBenchmarkGroupBar'
import { useSelector, useDispatch  } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB,UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB,UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB,
	UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB,UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB,UPDATE_PIE_CHART_ACTIVITY_TAB,UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB,
	UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB,UPDATE_HORIZONTAL_BENCHMARKGROUPBAR2_ACTIVITY_TAB,UPDATE_NO_OF_LEARNERS_WHOACCESSED_ACTIVITY_TAB,
	UPDATE_AVG_NO_OF_COURSES_ACCESSED_ACTIVITY_TAB, UPDATE_AVG_NO_OF_COURSES_COMPLETED_ACTIVITY_TAB, UPDATE_LINE_CHART_ACTIVITY_TAB, UPDATE_LEARNERS_ALL_ORG_ACTIVITY_TAB
} from '../../constants/actions'
import PieChartLoader from '../../commonComponents/Loaders/ActivityTabAll/PieChartLoader'
import LineChartLoader from '../../commonComponents/Loaders/ActivityTabAll/LineChartLoader'
import TripleLineCardLoader from '../../commonComponents/Loaders/ActivityTabAll/TripleLineCardLoader'
import DoubleLineCardLoader from '../../commonComponents/Loaders/ActivityTabAll/DoubleLineCardLoader'
import HorizontalBenchGroupLoader from '../../commonComponents/Loaders/ActivityTabAll/HorizontalBenchGroupLoader'

let unit = 1;
if(window.screen.width > 1910 && window.screen.width < 2550){
	unit = 1.5;
}else if(window.screen.width >= 2551){
	unit = 2;
}

function ActivityTab(props) {
	const activityTabState = useSelector(state => state.activityTabReducer.allCourses)
	const orgLevelState = useSelector(state => state.orgLevelReducer)
	const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)
	let { orgId } = useParams();
	/*Filters*/
	let formatQuery = ''
	let formatToSearch = []
	let formatToSearchString = [] 
	formatToSearch = filterCheckedListState.learningFormats;
	if(formatToSearch[0] === "All Formats"){
		// i.e. when 'All Formats' is selected in Learning Format
		formatQuery = ''
	}
	else{
		formatToSearch.forEach((eachFormat) =>{
			formatToSearchString.push('"'+eachFormat+'"')
		})
		formatQuery = `_in: [${formatToSearchString}]`
	}

	let dealQuery = ''
	let dealToSearch = []
	let dealToSearchString = [] 
	dealToSearch = filterCheckedListState.deals;
	if(dealToSearch[0] === "All Deals"){
		// i.e. when 'All Deals' is selected in Deal
		dealQuery = ''
	}
	else{
		dealToSearch.forEach((eachDeal) =>{
			dealToSearchString.push('"'+eachDeal+'"')
		})
		dealQuery = `_in: [${dealToSearchString}]`
	}

	let industryQuery = ''
	let industryToSearch = []
	let industryToSearchString = [] 
	industryToSearch = filterCheckedListState.industries;
	if(industryToSearch[0] === undefined){ 
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > INDUSTRY
		industryQuery = ''
	}
	else{
		industryToSearch.forEach((eachIndustry) =>{
			industryToSearchString.push('"'+eachIndustry+'"')
		})
		industryQuery = `_in: [${industryToSearchString}]`
	}

	let jobCatagoryQuery = ''
	let jobCatagoryToSearch = []
	let jobCatagoryToSearchString = [] 
	jobCatagoryToSearch = filterCheckedListState.jobCategories;
	if(jobCatagoryToSearch[0] === undefined){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > JOB CATAGORY
		jobCatagoryQuery = ''
	}
	else{
		jobCatagoryToSearch.forEach((eachjobCatagory) =>{
			jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
		})
		jobCatagoryQuery = `_in: [${jobCatagoryToSearchString}]`
	}

	let workExpQuery = ''
	let workExpToSearch = []
	let workExpToSearchString = [] 
	workExpToSearch = filterCheckedListState.workExperiences;
	if(workExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > Work Experience
		workExpQuery = ''
	}
	else{
		workExpToSearch.forEach((eachworkExp) =>{
			workExpToSearchString.push('"'+eachworkExp+'"')
		})
		workExpQuery = `_in: [${workExpToSearchString}]`
	}

	let manageExpQuery = ''
	let manageExpToSearch = []
	let manageExpToSearchString = [] 
	manageExpToSearch = filterCheckedListState.peopleManagementExperiences;
	if(manageExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > People Management Experience
		manageExpQuery = ''
	}
	else{
		manageExpToSearch.forEach((eachManageExp) =>{
			manageExpToSearchString.push('"'+eachManageExp+'"')
		})
		manageExpQuery = `_in: [${manageExpToSearchString}]`
	}

	/*Queries*/
	/* Completion, Signed up, atleast one course*/ 
	const completedUserQuery = gql`
	query MyQuery {
		learnersCompletedAllOrg: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}, _and: {isdeleted: {_eq: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}`

	const signedUpQuery = gql`
	query MyQuery {
		learnersSignedUp: journey_complete_user_details_aggregate(where: {user_is_invitation_accepted: {_eq: "1"}, _and: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}	
	`

	const signedUpAllOrgQuery = gql`
	query MyQuery {
		learnersSignedUpAllOrg: journey_complete_user_details_aggregate(where: {user_is_invitation_accepted: {_eq: "1"}, _and: {isdeleted: {_eq: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}	
	`

	const accessedQuery = gql`
	query MyQuery {
		learnersWhoAccessed: journey_complete_user_details_aggregate(where: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {journey_progress: {_gt: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}, _and: {isdeleted: {_eq: "0"}}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}	
	`

	const totalUsersAllOrgQuery = gql`
	query MyQuery {
		totalLearnersAllOrg: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}
	`

	/* Enrolled learners*/
	const totalOrgUsersQuery = gql`
	query MyQuery {
		totalLearnersOfOrg: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}) {
		  aggregate {
			count(columns: user_id, distinct: true)
		  }
		}
	  }`

	/* number of completed learners*/  
	const completedLearnersQuery = gql`
	query MyQuery {
		learnersCompleted: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {isdeleted: {_eq: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}) {
			aggregate {
			  count(columns: user_id, distinct: true)
			}
		}
	}`


	/* avg progress*/
	const avgCourseProgressQuery = gql`
	query MyQuery {
		avgCourseProgress: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}, distinct_on: [user_id, batch_id]) {
		  aggregate {
			avg {
			  journey_progress
			}
		  }
		}
	  }`


	/*Time spent*/  
	const avgTimeSpentQuery = gql`
	query MyQuery {
		avgTimeSpent: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}, distinct_on: [user_id, batch_id]) {
			aggregate {
				avg {
					journey_instance_timespent
				}
			}
		}
	}`


	/* trend of learners - to be changed*/
	const completionDateQuery = gql`
	query MyQuery {
		completionDate: journey_complete_user_details(where: {journey_progress: {_eq: "100"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}, _and: {isdeleted: {_eq: "0"}}}}}, distinct_on: user_id) {
		  journey_instance_completed_time
		  user_id
		}
	}`

	/* per learner*/
	const coursesPerLearnerQuery = gql`
	query MyQuery {
		coursesAccessedInstance: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {user_is_invitation_accepted: {_eq: "1"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}, distinct_on: user_id) {
			aggregate {
				count(columns: journey_instance_id)
			}
		}
		coursesFinishedInstance: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}}}}}}}}, _and: {journey_progress: {_eq: "100"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}, distinct_on: user_id) {
			aggregate {
				count(columns: journey_instance_id)
			}
		}
		learnersAccessed: journey_complete_user_details(where: {user_is_invitation_accepted: {_eq: "1"}, createdbyorgidRelation: {createdbyorgid: {_eq: "${orgId}"}}, _and: {isdeleted: {_eq: "0"}, journeyIDTagRelation: {isdeleted: {_eq: "0"}, tags: {_ilike: "%passport%"}}, filtersRelation: {is_deleted: {_eq: "0"}, deal_name: {${dealQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_experience_level: {${workExpQuery}}, user_category: {${jobCatagoryQuery}}, user_managing_experience: {${manageExpQuery}}}}}, distinct_on: [user_id, journey_instance_id]) {
			user_id
			journey_instance_id
		  }
		learnersCompleted: journey_complete_user_details(where: {journey_progress: {_eq: "100"}, createdbyorgidRelation: {createdbyorgid: {_eq: "${orgId}"}}, _and: {isdeleted: {_eq: "0"}, journeyIDTagRelation: {isdeleted: {_eq: "0"}, tags: {_ilike: "%passport%"}}, filtersRelation: {is_deleted: {_eq: "0"}, deal_name: {${dealQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_experience_level: {${workExpQuery}}, user_category: {${jobCatagoryQuery}}, user_managing_experience: {${manageExpQuery}}}}}, distinct_on: [user_id, journey_instance_id]) {
			user_id
			journey_instance_id
		  }
	}`

	const dispatch = useDispatch()
	const { loading, error, data } = useQuery(completedUserQuery,{fetchPolicy: 'no-cache'});
	const { loading:TLALoading, error:TLAError, data:TLAData } = useQuery(totalUsersAllOrgQuery,{fetchPolicy: 'no-cache'});
	const { loading:ACPLoading, error:ACPError, data:ACPData } = useQuery(avgCourseProgressQuery,{fetchPolicy: 'no-cache'});
	const { loading:ATSLoading, error:ATSError, data:ATSData } = useQuery(avgTimeSpentQuery,{fetchPolicy: 'no-cache'});
	const { loading:CDLoading, error:CDError, data:CDData } = useQuery(completionDateQuery,{fetchPolicy: 'no-cache'});
	const { loading:CLLoading, error:CLError, data:CLData } = useQuery(completedLearnersQuery,{fetchPolicy: 'no-cache'});
	const { loading:TLLoading, error:TLError, data:TLData } = useQuery(totalOrgUsersQuery,{fetchPolicy: 'no-cache'});
	const { loading:CPLLoading, error:CPLError, data:CPLData } = useQuery(coursesPerLearnerQuery,{fetchPolicy: 'no-cache'});
	const { loading:SULoading, error:SUError, data:SUData } = useQuery(signedUpQuery,{fetchPolicy: 'no-cache'});
	const { loading:SUALoading, error:SUAError, data:SUAData } = useQuery(signedUpAllOrgQuery,{fetchPolicy: 'no-cache'});
	const { loading:AULoading, error:AUError, data:AUData } = useQuery(accessedQuery,{fetchPolicy: 'no-cache'});

	useEffect(() => {
        if(!TLLoading && !TLError && TLData!=null){			
			dispatch({type: UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB, payload: TLData.totalLearnersOfOrg.aggregate.count});
		}
	}, [TLLoading, TLError, TLData])
	useEffect(() => {
        if(!TLALoading && !TLAError && TLAData!=null){			
			dispatch({type: UPDATE_LEARNERS_ALL_ORG_ACTIVITY_TAB, payload: TLAData.totalLearnersAllOrg.aggregate.count});
		}
	}, [TLALoading, TLAError, TLAData])
	useEffect(() => {
        if(!CLLoading && !CLError && CLData!=null){		
			dispatch({type: UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB, payload: CLData.learnersCompleted.aggregate.count});
		}
	}, [CLLoading, CLError, CLData])

	useEffect(() => {
		if(!SULoading && !SUError && SUData!=null){	
			dispatch({type: UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB, payload: SUData.learnersSignedUp.aggregate.count});
		}
	}, [SULoading, SUError, SUData])

	useEffect(() => {
		if(!SULoading && !SUError && SUData!=null){	
			let totalEnrolledLernerswithFilter = activityTabState.enrolledLearners;
			if (totalEnrolledLernerswithFilter == 0)  totalEnrolledLernerswithFilter = 1;
			let totalLearnersAllOrgwithFilter = activityTabState.totalLearnersAllOrg;
			if (totalLearnersAllOrgwithFilter == 0)  totalLearnersAllOrgwithFilter = 1;	
			// dispatch({type: UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB, 
			// 	payload: [
			// 		Math.round((activityTabState.noOfLearnersSignedUp/totalEnrolledLernerswithFilter)*100),
			// 		Math.round((SUAData.learnersSignedUpAllOrg.aggregate.count/totalLearnersAllOrgwithFilter)*100)
			// 	]
			// });
			dispatch({type: UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB, 
				payload: [
					Math.round((activityTabState.noOfLearnersSignedUp/totalEnrolledLernerswithFilter)*100),
					Math.round(57)
				]
			});
		}
	}, [SULoading, SUError, SUData, activityTabState.enrolledLearners, activityTabState.totalLearnersAllOrg, activityTabState.noOfLearnersSignedUp])

	useEffect(() => {
		if(!AULoading && !AUError && AUData!=null){	
			let totalEnrolledLernerswithFilter = activityTabState.enrolledLearners;
			if (totalEnrolledLernerswithFilter == 0)  totalEnrolledLernerswithFilter = 1;
			let totalLearnersAllOrgwithFilter = activityTabState.totalLearnersAllOrg;
			if (totalLearnersAllOrgwithFilter == 0)  totalLearnersAllOrgwithFilter = 1;	
			dispatch({type: UPDATE_NO_OF_LEARNERS_WHOACCESSED_ACTIVITY_TAB, payload: AUData.learnersWhoAccessed.aggregate.count});
			// dispatch({type: UPDATE_HORIZONTAL_BENCHMARKGROUPBAR2_ACTIVITY_TAB, 
			// 	payload: [
			// 		Math.round((AUData.learnersWhoAccessed.aggregate.count/totalEnrolledLernerswithFilter)*100),
			// 		Math.round((AUData.learnersWhoAccessedAllOrg.aggregate.count/totalLearnersAllOrgwithFilter)*100)
			// 	]
			// });
			dispatch({type: UPDATE_HORIZONTAL_BENCHMARKGROUPBAR2_ACTIVITY_TAB, 
				payload: [
					Math.round((AUData.learnersWhoAccessed.aggregate.count/totalEnrolledLernerswithFilter)*100),
					Math.round(52)
				]
			});
		}
	}, [AULoading, AUError, AUData, activityTabState.enrolledLearners, activityTabState.totalLearnersAllOrg])
	useEffect(() => {
        if(!CPLLoading && !CPLError && CPLData!=null){
			let totalSignedUpLernerswithFilter = activityTabState.enrolledLearners;
			if (totalSignedUpLernerswithFilter == 0)  totalSignedUpLernerswithFilter = 1;
			let totalLearnersAllOrgwithFilter = activityTabState.totalLearnersAllOrg;
			if (totalLearnersAllOrgwithFilter == 0)  totalLearnersAllOrgwithFilter = 1;
			// dispatch({type: UPDATE_PIE_CHART_ACTIVITY_TAB, 
			// 	payload: [
			// 		Math.round((activityTabState.noOfCompletedLearners/totalSignedUpLernerswithFilter)*100),
			// 		Math.round((data.learnersCompletedAllOrg.aggregate.count/totalLearnersAllOrgwithFilter)*100)
			// 	]
			// });
			dispatch({type: UPDATE_PIE_CHART_ACTIVITY_TAB, 
				payload: [
					Math.round((activityTabState.noOfCompletedLearners/totalSignedUpLernerswithFilter)*100),
					Math.round(38)
				]
			});
		}
	}, [CPLLoading, CPLError, CPLData, activityTabState.noOfLearnersSignedUp, activityTabState.totalLearnersAllOrg])

	useEffect(() => {
        if(!ACPLoading && !ACPError && ACPData!=null){
			dispatch({type: UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB, payload: Math.round(ACPData.avgCourseProgress.aggregate.avg.journey_progress)});
			dispatch({type: UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB, payload: Math.round(ACPData.avgCourseProgress.aggregate.avg.journey_progress)});
		}
	}, [ACPLoading, ACPError, ACPData])

	useEffect(() => {
        if(!ATSLoading && !ATSError && ATSData!=null){
			dispatch({type: UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB, payload: Math.round(ATSData.avgTimeSpent.aggregate.avg.journey_instance_timespent/60)});
		}
	}, [ATSLoading, ATSError, ATSData])

	useEffect(() => {
        if(!CPLLoading && !CPLError && CPLData!=null){		
			/*let totalEnrolledLernerswithFilter = activityTabState.enrolledLearners;
			if (activityTabState.enrolledLearners == 0)  totalEnrolledLernerswithFilter = 1;
			let coursesAccessed = CPLData.coursesAccessedInstance.aggregate.count
			if (activityTabState.enrolledLearners == 0)  coursesAccessed = 0;
			let coursesFinished = CPLData.coursesFinishedInstance.aggregate.count
			if (activityTabState.enrolledLearners == 0)  coursesFinished = 0;*/
			dispatch({type: UPDATE_AVG_NO_OF_COURSES_ACCESSED_ACTIVITY_TAB, payload: CPLData });
			dispatch({type: UPDATE_AVG_NO_OF_COURSES_COMPLETED_ACTIVITY_TAB, payload: CPLData });
		}
	}, [CPLLoading, CPLError, CPLData, activityTabState.enrolledLearners])
	

	let completionDateObj = {};
	let completionDatePayload = [];
	useEffect(() => {
        if(!CDLoading && !CDError && CDData!=null){
			if(CDData.completionDate.length != 0){
				CDData.completionDate.sort((a, b) => {
						return a.journey_instance_completed_time - b.journey_instance_completed_time;
					}
				)
				let cumulative = 0
				let dayOne = CDData.completionDate[0].journey_instance_completed_time || 0;
				let dayLast = 0;
				CDData.completionDate.forEach(
					(eachObj) =>{
						if(eachObj.journey_instance_completed_time != null ){
							let date = Math.floor((eachObj.journey_instance_completed_time - dayOne)/86400) + 1;
							dayLast = date;
							if (!(date in completionDateObj))
							{
								completionDateObj[date] = cumulative + (100/activityTabState.enrolledLearners);
								cumulative += (100/activityTabState.enrolledLearners)
							}
							else{
								completionDateObj[date] = completionDateObj[date] + (100/activityTabState.enrolledLearners);
								cumulative += (100/activityTabState.enrolledLearners)
							}
						}
					}
				);
				
				let previousDayPercentage = 0;
				for(let dates = 0; dates <= dayLast; dates++){
					if(completionDateObj[dates]){
						let currentDaypercentage = parseInt(completionDateObj[dates], 10)
						completionDatePayload.push({'Days':parseInt(dates, 10), '% Of Users': currentDaypercentage });
						previousDayPercentage = currentDaypercentage;
					}
					//Interpolating for missing days (i.e. days with 0 completed user), to have % of completion same as previous day
					else{
						completionDatePayload.push({'Days':parseInt(dates, 10), '% Of Users': previousDayPercentage });
					}
				}
				dispatch({type: UPDATE_LINE_CHART_ACTIVITY_TAB, 
					payload: completionDatePayload
				});
			}
			else{
				for(let dates = 0; dates <= 100; dates++){
					completionDatePayload.push({'Days':parseInt(dates, 10), '% Of Users': 0 })
				}
				dispatch({type: UPDATE_LINE_CHART_ACTIVITY_TAB, 
					payload: completionDatePayload
				});
			}
		}
	}, [CDLoading, CDError, CDData,activityTabState.enrolledLearners])

    if(error,TLError,CLError,ACPError,ATSError,CPLError,CDError) {
		console.log(error || TLError || CLError || ACPError || ATSError || CPLError || CDError)
        return <div>Error..</div>
	}
	
    return (
        <div className={Styles.mainContainer}>
            <div data-aos="fade-up" data-aos-duration="350" data-aos-delay="350" data-aos-once="true">
                <BannerWithBackground description="This section refers to the activity or engagement data and the usage statistics of all the learners in this organization across all the courses." />
            </div>

            <div className={Styles.row1Container} data-aos="fade-up" data-aos-duration="350" data-aos-delay="700" data-aos-once="true">
                <div className={Styles.twoRowsSpan}>
				<WhiteBox>
					{/* {loading &&
						<PieChartLoader {...activityTabState.dataForPieChart}/>
					}
					{!loading &&
					<>
						<div className={Styles.graphTitle}>
							<GraphTitle title={<span> COMPLETION <br/> (% OF LEARNERS)</span>} infoBody={`% of signedup learners who have completed atleast one course. 
																						Group: % of signedup learners in this organization who have completed atleast one course.
																						Benchmark: Average % of signedup learners in the universe who have completed atleast one course.`} />
						</div>
						<div>
							<PieChart {...activityTabState.dataForPieChart}/>
							<br/>
						</div>
					</>
					} */}
					{CLLoading &&
						<PieChartLoader {...activityTabState.dataForPieChart}/>
					}
					{!CLLoading &&
					<>
						<div className={Styles.graphTitle}>
							<GraphTitle title={<span> COMPLETION <br/> (% OF LEARNERS)</span>} infoBody="% of enrolled learners who have completed atleast one course.Group: % of enrolled learners in this organization who have completed atleast one course.Benchmark: Average % of enrolled learners in the universe who have completed atleast one course" />
						</div>
						<div>
							<PieChart {...activityTabState.dataForPieChart}/>
							<br/>
						</div>
					</>
					}
				</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						{CLLoading &&
							<TripleLineCardLoader width = {230*unit} height = {110*unit} thirdRectY = {65*unit}/>
						}
						{!CLLoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="NO. OF LEARNERS WHO COMPLETED" infoBody="Total number of learners who have completed atleast one course" />
							</div>
							<div className={Styles.text1}>
								{activityTabState.noOfCompletedLearners}
							</div>
						</>
						}
					</WhiteBox>
				</div>
				<div className= {Styles.otherStatsContainer} >
					<div className={ Styles.otherStatsHeader }>
						&emsp;OTHER STATISTICS
					</div>
					<div>
						<WhiteBox>
							{ TLLoading &&
								<DoubleLineCardLoader />
							}
							{ !TLLoading &&
							<>
								<div className={Styles.graphTitle}>
									<GraphTitle title="ENROLLED LEARNERS" infoBody="Total number of learners who have enrolled across all the courses" />
								</div>
								<div className={Styles.text1}>
								{activityTabState.enrolledLearners}
								</div>
							</>
							}
						</WhiteBox>
					</div>
					<div>
						<WhiteBox>
							{ ATSLoading &&
								<DoubleLineCardLoader />
							}
							{ !ATSLoading &&
							<>
								<div className={Styles.graphTitle}>
									<GraphTitle title="AVERAGE TIME SPENT" infoBody="Average time spent by all the learners on the platform in each course" />
								</div>
								<div className={Styles.text1}>
								{activityTabState.averageTimeSpent}<text style={{fontSize: '10px'}}>mins</text>
								</div>
							</>
							}
						</WhiteBox>
					</div>
				</div>
				<div>
					<WhiteBox>
						{ACPLoading &&
							<TripleLineCardLoader width = {230*unit} height = {110*unit} thirdRectY = {65*unit}/>
						}
						{!ACPLoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="AVERAGE COURSE PROGRESS" infoBody="Average course progress across all the learners in the organization" />
							</div>
							<div className={Styles.insideWhiteBox4Columns}>
								<div className={Styles.text1}>
									<text>{activityTabState.avgCourseProgress}</text>
									<text style={{fontSize: '10px'}}>%</text>
								</div>
								<div className={Styles.threeColumnsSpan}>
									<HorizontalProgressBar {...activityTabState.dataForHorizontalProgressBar}/>
								</div>
							</div>
						</>
						}
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						{SULoading &&
							<HorizontalBenchGroupLoader width = {440*unit} height = {115*unit}/>
						}
						{!SULoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="LEARNERS WHO SIGNED UP" infoBody="Total number of learners who have signed up for atleast one course.*Signed up learner: A learner who has accepted a course to which he was enrolled" />
							</div>
							<div className={Styles.insideWhiteBox4Columns}>
								<div className={Styles.text1}>
									{activityTabState.noOfLearnersSignedUp}
								</div>
								<div className={Styles.threeColumnsSpan} style={{borderLeft: '2px solid #F5EFFB'}}>
									<HorizontalBenchmarkGroupBar {...activityTabState.dataForHorizontalBenchmarkGroupBar}/>
								</div>
							</div>
						</>
						}
					</WhiteBox>
				</div>
            </div>

			<div className={Styles.row2Container} data-aos="fade-up" data-aos-duration="350" data-aos-delay="1050" data-aos-once="true">
				<div>
				<WhiteBox>
						<div className = {Styles.insideWhiteBox3Columns}>
							{CPLLoading &&
								<TripleLineCardLoader width={220*unit} height={90*unit} thirdRectY = {60*unit}/>
							}
							{!CPLLoading &&
								<div className={Styles.noInfoTitle}>
									Average No. Of Courses Accessed Per Learner
									<div className={Styles.text4}>
										{activityTabState.avgNoOfCoursesAccessed}
									</div>
								</div>
							}
							<div className={Styles.separatingBorder}></div>
							{CPLLoading &&
								<TripleLineCardLoader width={220*unit} height={90*unit} thirdRectY = {60*unit}/>
							}
							{!CPLLoading &&
								<div className={Styles.noInfoTitle}>
									Average No. Of Courses Completed Per Learner
									<div className={Styles.text4}>
										{activityTabState.avgNoOfCoursesCompleted}
									</div>
								</div>
							}
						</div>
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						{AULoading &&
							<HorizontalBenchGroupLoader width = {440*unit} height = {115*unit}/>
						}
						{!AULoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="LEARNERS WHO ACCESSED ATLEAST ONE COURSE" infoBody="Total number of learners who have accessed atleast one course.*Accessed learner: A learner who has accessed and completed atleast one module item in the course" />
							</div>
							<div className={Styles.insideWhiteBox4Columns}>
								<div className={Styles.text1}>
									{activityTabState.noOfLearnersWhoAccessed}
								</div>
								<div className={Styles.threeColumnsSpan} style={{borderLeft: '2px solid #F5EFFB'}}>
									<HorizontalBenchmarkGroupBar {...activityTabState.dataForHorizontalBenchmarkGroupBar2}/>
								</div>
							</div>
						</>
						}
					</WhiteBox>
				</div>
			</div>
        </div>
        )
}

export default ActivityTab ;