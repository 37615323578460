import React from "react"
import ContentLoader from "react-content-loader"
import WhiteBox from '../../../components/WhiteBox/WhiteBox'

const CompetencyLoader = () => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width = 400*unit;
    const height = 50*unit; 
    
    return(
        <WhiteBox>
            <ContentLoader 
                speed={2}
                width={width}
                height={height}
                viewBox={"0 0 "+width+" "+height}
                backgroundColor="#f3f3f3"
                foregroundColor="#dcdbdb"
            >
                <rect x={10*unit} y={15*unit} rx={5*unit} ry={5*unit} width={width - (40*unit)} height={10*unit} />
                <rect x={15*unit} y={40*unit} rx={5*unit} ry={5*unit} width={width - (250*unit)} height={7*unit} />
                <rect x={215*unit} y={40*unit} rx={5*unit} ry={5*unit} width={width - (250*unit)} height={7*unit} />
            </ContentLoader>
        </WhiteBox>
        
    )
}

export default CompetencyLoader;