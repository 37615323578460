import React from 'react'
import shape from '../../../assets/img/shape.png'
import thumbup from '../../../assets/img/thumbup.png'
import thumbdown from '../../../assets/img/thumbdown.png'
import Styles from './Likes.module.css'
import { useSelector } from "react-redux";
import Title from '../GraphTitle/Title'
// import { Container } from 'reactstrap'

function Likes(props){

    const response=props.responses || {likes:825,dislikes:25,}
    const {likes,dislikes}=response
    return(
        <div className={Styles.outerBox}>
            <div className={Styles.response1}>
                <div className={Styles.text}> TOTAL RESPONSE</div>
                <div className={Styles.spacing}>
                    
                    <img src={shape} className={Styles.icons}/>
                    <div className={Styles.numbers}>{likes + dislikes }</div>
                </div>
            </div>
            <div className={Styles.line}/>
            <div className={Styles.response2}>
                <div className={Styles.likes_dislikes_title}>
                    <Title title='LIKES/DISLIKES' infoBody={props.infoBody} />
                </div>
                <div className={Styles.thumbs}>
                    <div className={Styles.spacing}>
                        <img src={thumbup} className={Styles.icons}/>
                        <div className={Styles.numbers}>{likes}</div>
                    </div>
                    <div className={Styles.spacing}>
                        <img src={thumbdown} className={Styles.icons}/>
                        <div className={Styles.numbers}>{dislikes}</div>
                    </div>
                </div>  
            </div>

        </div>
    )
}

export default Likes;