import React, { useEffect, useState } from 'react'
import Styles from './PerformanceTabBYB.module.css'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import NoOfUsers from '../../commonComponents/textComponent/NoOfUsers/User'
import WhiteBox from '../WhiteBox/WhiteBox'
import BarGraph from '../../commonComponents/GraphComponents/BarGraph'
import ProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GroupHorizontalBar from '../../commonComponents/GraphComponents/GroupHorizontalBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import CompetencyList from '../../commonComponents/textComponent/CompetenciesList/CourseCompitency'
import BarBenchmarkGroup from '../../commonComponents/GraphComponents/BarBenchmarkGroup'
import TitleDropdown from '../../commonComponents/textComponent/TitleDropdown/TitleDropdown'
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import BannerLoader from '../../commonComponents/Loaders/PerformanceTabAll/BannerLoader'
import UsersLoader from '../../commonComponents/Loaders/PerformanceTabAll/UsersLoader'
import AverageBenchmarkLoader from '../../commonComponents/Loaders/PerformanceTabAll/AverageBenchmarkLoader'
import DistributionWhiteboxLoader from '../../commonComponents/Loaders/PerformanceTabAll/DistributionWhiteboxLoader'
import DistributionLoader from '../../commonComponents/Loaders/PerformanceTabAll/DistributionLoader'
import RowWiseLoader from '../../commonComponents/Loaders/PerformanceTabAll/RowWiseLoader'
import { useSelector } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';

function PerformanceTabBYB(props) {
    const [loading, setLoading] = useState(false)
    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const state = useSelector(state => state.performanceTab.byb)

    return (
        <div className={Styles.gridWrapper}>
            <div data-aos="fade-up" data-aos-once="true">
                {
                    loading && 
                    <BannerLoader />
                }
                {
                    !loading &&
                    <SimpleBanner title={state.headingTitle1} />
                }
            </div>
            <div className={Styles.gridWrapper1} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                <div>
                    {
                        loading && 
                        <UsersLoader />
                    }
                    {
                        !loading && 
                        <NoOfUsers />
                    }
                </div>
                <div className={Styles.averageWhitebox}>
                    <WhiteBox>
                        <div className={Styles.averageContainer}>
                            {
                                loading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !loading && 
                                <>
                                    <div className={Styles.groupAverageHeading0}>
                                        <GraphTitle title="COURSE AVERAGE" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.dataForHorizontalProgressBar1.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.dataForHorizontalProgressBar1} />
                                    </div>
                                </>
                            }
                            <div className={Styles.lineDifferentiator}></div>
                            {
                                loading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !loading && 
                                <>
                                    <div className={Styles.groupAverageHeading}>UNIVERSE AVERAGE</div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.dataForHorizontalProgressBar2.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.dataForHorizontalProgressBar2} />
                                    </div>
                                </>
                            }
                        </div>
                    </WhiteBox>
                </div>
                <div className={Styles.competencyList}>
                    {
                        loading && 
                        <DistributionWhiteboxLoader />
                    }
                    {
                        !loading && 
                        <CompetencyList course={state.competencyArray} />
                    } 
                </div>
            </div>
            <div data-aos="fade-up" data-aos-once="true" data-aos-delay="700">
                {
                    loading && 
                    <BannerLoader />
                }
                {
                    !loading && 
                    <BannerWithBackground title={state.headingTitle2} />   
                }
            </div>
            {
                loading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !loading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                    <div className={Styles.gridWrapper3}>
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle />
                                </div>
                                <div className={Styles.groupHorizontalComponent}>
                                    <GroupHorizontalBar {...state.dataForGroupHorizontalBar} />
                                </div>
                            </WhiteBox>
                        </div>
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title="DISTRIBUTION OF LEARNERS ACROSS COMPETENCIES" />
                                </div>
                                <div className={Styles.titleDropdown}>
                                    <TitleDropdown />
                                </div>
                                <div className={Styles.barGraphComponent1}> 
                                    <BarGraph {...state.dataForBarGraph1} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title={state.graphTitle2} />
                            </div>
                            <div>
                                <PieChart {...state.dataForPieChart} />
                            </div>
                            <div className={Styles.lineDifferentiator1}></div>
                            <div className={Styles.graphTitle1}>
                                <GraphTitle title={state.graphTitle3} />
                            </div>
                            <div>
                                <PieChart {...state.dataForPieChart} />
                            </div>
                        </WhiteBox>
                    </div>
                </div>
                </>
            }
            {
                loading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !loading &&
                <>
                    <div className={Styles.gridWrapper4} data-aos="fade-up" data-aos-once="true">
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title={state.graphTitle4} />
                            </div>
                            <div className={Styles.benchmarkGroup1}>
                                <BarBenchmarkGroup {...state.dataForBarBenchmarkGroup1} />
                            </div>
                        </WhiteBox>
                    </div>
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title={state.graphTitle5} />
                            </div>
                            <div className={Styles.benchmarkGroup1}>
                                <BarBenchmarkGroup {...state.dataForBarBenchmarkGroup2} />
                            </div>
                        </WhiteBox>
                    </div>
                </div>
                </>
            }
            {
                loading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !loading && 
                <>
                     <div className={Styles.gridWrapper4} data-aos="fade-up" data-aos-once="true">
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title={state.graphTitle6} />
                            </div>
                            <div className={Styles.benchmarkGroup1}>
                                <BarBenchmarkGroup {...state.dataForBarBenchmarkGroup3} />
                            </div>
                        </WhiteBox>
                    </div>
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title={state.graphTitle7} />
                            </div>
                            <div className={Styles.benchmarkGroup1}>
                                <BarBenchmarkGroup {...state.dataForBarBenchmarkGroup4} />
                            </div>
                        </WhiteBox>
                    </div>
                </div>
                </>
            }
        </div>
    )
}

export default PerformanceTabBYB