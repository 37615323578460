import React, { useEffect } from 'react';
import Styles from './CourseGroupPerformance.module.css'
import FilterPanel from '../filterPanel/filterPanel'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import NoOfUsers from '../../commonComponents/textComponent/NoOfUsers/User'
import WhiteBox from '../WhiteBox/WhiteBox'
import CompetencyBox from '../../commonComponents/textComponent/Competency/mainModule'
import BarGraph from '../../commonComponents/GraphComponents/BarGraph'
import ProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import { useSelector } from 'react-redux'
import { performanceTab } from '../../reducers/performanceTab/performanceTab';
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import AOS from 'aos';
import 'aos/dist/aos.css';

function CourseGroupPerformance(props) {
    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )
    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const state = useSelector(state => state.performanceTab.courseGroupPerformance)

    return (
                <div className={Styles.gridWrapper}>
                    <div className={Styles.heading} data-aos="fade-up" data-aos-once="true">
                        <SimpleBanner title={props.dropdownItem.toUpperCase()} courseGroup={true} />
                    </div>
                    <div className={Styles.gridWrapper1} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                        <div>
                            <NoOfUsers />
                        </div>
                        <div className={Styles.averageWhitebox}>
                            <WhiteBox>
                                <div className={Styles.averageContainer}>
                                    <div className={Styles.groupAverageHeading}>
                                        <GraphTitle title="GROUP AVERAGE" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.dataForHorizontalProgressBar1.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.dataForHorizontalProgressBar1} />
                                    </div>
                                    <div className={Styles.lineDifferentiator}></div>
                                    <div className={Styles.groupAverageHeading}>
                                        <GraphTitle title="BENCHMARK" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.dataForHorizontalProgressBar2.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.dataForHorizontalProgressBar2} />
                                    </div>
                                </div>
                            </WhiteBox>
                        </div>
                        <div className={Styles.barGraphContainer}>
                            <WhiteBox>
                                <div className={Styles.barGraphHeading}>
                                    <GraphTitle title="DISTRIBUTION OF LEARNERS ACROSS PROFICIENCIES" />
                                </div>
                                <div className={Styles.barGraphComponent}>
                                    <BarGraph {...state.dataForBarGraph1} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                    <div className={Styles.gridWrapper3} data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <div className={Styles.topPerformersContainer}>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title="TOP PERFORMERS" />
                                </div>
                                <div className={Styles.tableContainer}>
                                    <table className={Styles.topPerformersList}>
                                        {
                                            state.topPerformersArray.map(item => {
                                                return (
                                                        <React.Fragment>
                                                            <tr key={item.userId} className={Styles.eachPerformer}>
                                                                <td className={Styles.performerName}>{item.name}</td>
                                                                <td className={Styles.performerEmail}>{item.email}</td>
                                                                <td className={Styles.performerScoreContainer}>
                                                                    <div className={Styles.performerScore}>{item.score}</div>
                                                                    <div className={Styles.performerTotal}>/10</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td><div className={Styles.topPerformersEachItemLine}></div></td>
                                                                <td><div className={Styles.topPerformersEachItemLine}></div></td>
                                                                <td><div className={Styles.topPerformersEachItemLine}></div></td>
                                                            </tr>
                                                        </React.Fragment>
                                                )
                                            })
                                        }
                                    </table>
                                </div>
                            </WhiteBox>
                        </div>
                        <div className={Styles.gridWrapper2}>
                            <div className={Styles.headingText}>
                                {state.title1}
                            </div>
                            <div>
                                <CompetencyBox />
                            </div>
                            <div>
                                <CompetencyBox />
                            </div>
                            <div>
                                <CompetencyBox />
                            </div>
                            <div className={Styles.headingText}>
                                {state.title2}
                            </div>
                            <div>
                                <CompetencyBox />
                            </div>
                            <div>
                                <CompetencyBox />
                            </div>
                            <div>
                                <CompetencyBox />
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default CourseGroupPerformance