import React,{useState,useEffect} from "react";
import Styles from './FeedbackTabCoachingSim.module.css'
import SimpleBanner from "../../commonComponents/textComponent/Banner/SimpleBanner";
import Feedback from "../../commonComponents/textComponent/AverageFeedback/Feedback";
import Likes from "../../commonComponents/textComponent/Likes/Likes";
import Aggregate from "../../commonComponents/textComponent/Stats/Aggregate";
import CourseOrder from "../../commonComponents/textComponent/CourseOrder/CourseOrder";
import CourseFeedback from "../../commonComponents/textComponent/CourseFeedback/CourseFeedback";
import performanceTab1 from '../../assets/img/performanceTab1.png'
import performanceTab2 from '../../assets/img/performanceTab2.png'
import { useSelector, useDispatch } from "react-redux";
import WhiteBox from "../WhiteBox/WhiteBox";
import Title from "../../commonComponents/textComponent/GraphTitle/Title"
import { Component3 } from "../../commonComponents/textComponent/Competency/Rated"
import WordCloud from '../../commonComponents/textComponent/WordCloud/WordCloud'
import {BannerQuery,AverageFeedbackQuery,ResponsesQuery,WordCloudQuery} from './FeedbackCoachingSimQueries';
import { useQuery } from '@apollo/react-hooks'
import {handleBannerFS,handleAverageCourseFeedbackRatingFS, handleBenchmarkRatingFS, handleCourseFeedbackFS, handleResponsesFS, handleWordCloudFS} from '../../actions/experienceAction'
import {UPDATE_BANNER_FT_COACHINGSIM,UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_COACHINGSIM,UPDATE_BENCHMARK_FT_COACHINGSIM, UPDATE_COURSEFEEDBACK_FT_COACHINGSIM,UPDATE_RESPONSE_FT_COACHINGSIM,UPDATE_WORDCLOUD_FT_COACHINGSIM} from '../../constants/actions'
import AverageFeedbackLoader from '../../commonComponents/Loaders/FeedbackAll/AverageFeedback'
import ResponsesLoader from '../../commonComponents/Loaders/FeedbackAll/Responses'
import WordCloudLoader from '../../commonComponents/Loaders/FeedbackAll/WordCloud'


function FeedbackTabCoachingSim(props) {
    const dispatch = useDispatch()
    const {banner,averageCourseFeedback,benchmark,response,courseFeedback,wordCloud}=useSelector(state=>state.CoachingSimFeedbackTab)
    const {dealUsers,formatBatches,demographicsUsers,batchIds}=useSelector(state=>state.orgLevelReducer.landingNotRequired)
    const {deals,learningFormats,batches} = useSelector(state=>state.orgLevelReducer.filtersPanelCheckedList)
    const {courseDeals,courseBatches,courseFormats} = useSelector(state=>state.orgLevelReducer.courseSpecificfiltersPanelApi)
    const {orgId} = useSelector(state => state.orgLevelReducer.landingPage) 
    const {selectedCourse} = useSelector(state=>state.orgLevelReducer.header)
    let [dealsUserArray, setDealsUserArray ]= useState([])
    let [formatsBatchesArray, setFormatsBatchesArray] = useState([])
    let [batchIdsArray, setBatchIdsArray] = useState([])

    const {  loading:Bannerloading, error:Bannererror, data:Bannerdata} = useQuery(BannerQuery, { variables: { orgId: orgId , courseName : selectedCourse}});  
    const {  loading:Avgloading, error:Avgerror, data:Avgdata, refetch:Avgrefetch} = useQuery(AverageFeedbackQuery, { variables: { deals: [...dealsUserArray,...demographicsUsers],formats: [...formatsBatchesArray,...batchIdsArray] }});  
    const {  loading:Resloading, error:Reserror, data:Resdata,refetch:Resrefetch } = useQuery(ResponsesQuery, { variables: { deals: [...dealsUserArray,...demographicsUsers],formats: [...formatsBatchesArray,...batchIdsArray] }}); 
    const {  loading:Wordloading, error:Worderror, data:Worddata,refetch:Wordrefetch } = useQuery(WordCloudQuery, { variables: { deals: [...dealsUserArray,...demographicsUsers],formats: [...formatsBatchesArray,...batchIdsArray] }}); 

    console.log(formatBatches)
    useEffect(() =>{
        if(formatBatches && courseBatches){
            let tempdeals = []
            let tempformates = []
            let tempbatchids = []
            for (let i=0;i<deals.length;i++){    
                if(deals[i] === "All Deals" && deals.length == 1)  
                {
                    for(let j=1;j<courseDeals[1].params.length;j++){
                        tempdeals = [ ...tempdeals,...dealUsers[courseDeals[1].params[j]] ]
                    }
                }
                else{
                    if(courseDeals[1].params.includes(deals[i])){
                        tempdeals = [ ...tempdeals,...dealUsers[deals[i]] ] 
                    }
                }                
            }
            for (let i=0;i<learningFormats.length;i++){
                console.log(courseFormats[1].params)
                if(learningFormats[i] === "All Formats" && learningFormats.length == 1)  
                {
                    for(let j=1;j<courseFormats[1].params.length;j++){
                        tempformates = [ ...tempformates,...formatBatches[courseFormats[1].params[j]] ]
                    }
                }
                else{
                    if(courseFormats[1].params.includes(learningFormats[i])){
                        console.log(learningFormats[i])
                        tempformates = [ ...tempformates,...formatBatches[learningFormats[i]] ] 
                    }
                }                
            }
            for(let i=0;i<batches.length;i++){
                if(batches[i] === "All Batches" && batches.length == 1)  
                {
                    for(let j=1;j<courseBatches[1].params.length;j++){
                        
                        tempbatchids = [ ...tempbatchids,...batchIds[courseBatches[1].params[j]] ]
                    }
                }
                else{
                    if(courseBatches[1].params.includes(batches[i])){
                        tempbatchids = [ ...tempbatchids,...batchIds[batches[i]] ] 
                    }
                }       
            }
            setDealsUserArray(tempdeals)
            setFormatsBatchesArray(tempformates)
            setBatchIdsArray(tempbatchids)
            Avgrefetch()
            Resrefetch()
            Wordrefetch()
        }
    },[selectedCourse,courseDeals,courseBatches,courseFormats,deals,learningFormats,batches])
    
    useEffect(() => {
        if(!Bannerloading && !Bannererror && Bannerdata.banner.length){
            dispatch(handleBannerFS(Bannerdata, UPDATE_BANNER_FT_COACHINGSIM))
        }
    }, [Bannerloading, Bannererror, Bannerdata])

    useEffect(() => {
        if(!Avgloading && !Avgerror && Avgdata!=null){  
            dispatch(handleAverageCourseFeedbackRatingFS(Avgdata.averageFeedback, UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_COACHINGSIM))
            dispatch(handleBenchmarkRatingFS(Avgdata.benchMark, UPDATE_BENCHMARK_FT_COACHINGSIM))
            dispatch(handleCourseFeedbackFS(Avgdata,UPDATE_COURSEFEEDBACK_FT_COACHINGSIM)) 
        }
    }, [Avgloading, Avgerror, Avgdata])

    useEffect(() => {
        if(!Resloading && !Reserror && Resdata!=null){
            dispatch(handleResponsesFS(Resdata, UPDATE_RESPONSE_FT_COACHINGSIM))
        }
    }, [Resloading, Reserror, Resdata])

    useEffect(() => {
        if(!Wordloading && !Worderror && Worddata!=null){
            dispatch(handleWordCloudFS(Worddata, UPDATE_WORDCLOUD_FT_COACHINGSIM))
        }
    }, [Wordloading, Worderror, Worddata])
    return (
        <div className={Styles.gridWrapper}>
            <div className={Styles.heading} data-aos="fade-up" data-aos-once="true" data-aos-duration="500">
                <SimpleBanner title={banner.courseName} description={banner.description} />
            </div>
            
            <div className={Styles.gridBodyWrapper} data-aos="fade-up"  data-aos-once="true" data-aos-duration="600" data-aos-delay="350">
                <div className={Styles.verticalgrid1}>
                    {   
                        !Avgdata ?  <AverageFeedbackLoader/> :(

                            <div className={Styles.whiteBox}>
                                <Feedback title='AVERAGE COURSE FEEDBACK' color='#9F7DFF' rating={averageCourseFeedback}/>
                                <div className={Styles.average_feedback_divider}>
                                    <div className={Styles.line}/>
                                </div>
                                <Feedback title='BENCHMARK' color='#FF8B72' rating={benchmark} />
                                <div className={Styles.average_feedback_divider}>
                                    <div className={Styles.line}/>
                                </div>
                                <CourseFeedback feedback={courseFeedback}/>
                            </div>
                        )
                    }
                </div>
                <div className={Styles.verticalgrid2}>
                    {
                       !Resdata ?  <ResponsesLoader/> :(                            
                            <Likes responses={response}/>       
                        )
                    }
                </div>
                <div className={Styles.wordcloud_grid}> 
                    {
                        !Worddata ? <WordCloudLoader/> : (
                            <WordCloud wordData={wordCloud}/>
                        )
                    }                       
                    
                </div>                
            </div>
        </div>        
    )
}

export default FeedbackTabCoachingSim;  