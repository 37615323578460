import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

function Home(props) {

    const history = useHistory()

    const redirectTo = () => {
        history.push('/337')
    }

    return (
        <div>
            Home
            {redirectTo()}
        </div>
    )
}

export default Home