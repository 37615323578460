import React from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import Dashboard from '../components/Dashboard/Dashboard'
import AllGraphs from '../commonComponents/AllGraphs'
import HeaderFilterTab from '../components/HeaderFilterTab/HeaderFilterTab'
import Home from '../components/Home/Home'
import Landing from '../components/Landing/Landing'
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, HttpLink, InMemoryCache } from "apollo-boost";
import { setContext } from "apollo-link-context";

export default function Routes() {
    /*const httpLink = new HttpLink({
        uri: "http://localhost:8080/v1/graphql"
      });
    
      const client = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache()
      });*/

    return (
        <HashRouter>
                <Switch>
                    <Route exact path='/' component={Home} />
                    <Route exact path='/graphs' component={AllGraphs} />
                    <Route exact path='/:orgId' component={Dashboard} />
                    <Route exact path='/:orgId/:tab/:course/:tag/:id' component={HeaderFilterTab} />
                </Switch>
        </HashRouter>
    )
}
