import React from "react";
import Styles from './FeedbackTabNonSim.module.css'
import SimpleBanner from "../../commonComponents/textComponent/Banner/SimpleBanner";
import Feedback from "../../commonComponents/textComponent/AverageFeedback/Feedback";
import Likes from "../../commonComponents/textComponent/Likes/Likes";
import Aggregate from "../../commonComponents/textComponent/Stats/Aggregate";
import CourseOrder from "../../commonComponents/textComponent/CourseOrder/CourseOrder";
import CourseFeedback from "../../commonComponents/textComponent/CourseFeedback/CourseFeedback";
import performanceTab1 from '../../assets/img/performanceTab1.png'
import performanceTab2 from '../../assets/img/performanceTab2.png'
import { useSelector } from "react-redux";
import WhiteBox from "../WhiteBox/WhiteBox";
import Title from "../../commonComponents/textComponent/GraphTitle/Title"
import { Component3 } from "../../commonComponents/textComponent/Competency/Rated"
import FeedbackWordCloud from '../../assets/img/feedbackwordcloud.png'
import WordCloud from '../../commonComponents/textComponent/WordCloud/WordCloud'


function FeedbackTabNonSim(props) {
    const {courseRating,courseFeedback}=useSelector(state=>state.nonSimFeedbackTab)
    return (
        <div className={Styles.gridWrapper}>
            <div className={Styles.heading} data-aos="fade-up" data-aos-once="true" data-aos-duration="500">
                <SimpleBanner title={'Clearing The Digital BLUR'} />
            </div>
            
            <div className={Styles.gridBodyWrapper} data-aos="fade-up"  data-aos-once="true" data-aos-duration="600" data-aos-delay="350">
                <div className={Styles.verticalgrid1}>
                    <div className={Styles.whiteBox}>
                        <Feedback title='AVERAGE COURSE FEEDBACK' color='#9F7DFF'/>
                        <div className={Styles.average_feedback_divider}>
                            <div className={Styles.line}/>
                        </div>
                        <Feedback title='BENCHMARK' color='#FF8B72' />
                        <div className={Styles.average_feedback_divider}>
                            <div className={Styles.line}/>
                        </div>
                        <CourseFeedback feedback={courseFeedback}/>
                    </div>
                </div>
                <div className={Styles.verticalgrid2}>
                    <Likes/> 
                </div>
                <div className={Styles.wordcloud_grid}>                        
                    <WordCloud/>
                </div>                
            </div>
        </div>
    )
}
export default FeedbackTabNonSim;