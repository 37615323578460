import React from "react"
import ContentLoader from "react-content-loader"
import Styles from "./FeedbackAll.module.css"



const Responses = (props) => (
    <div className={Styles.responses}>
        <ContentLoader 
        speed={2}
        width={436}
        height={64}
        viewBox="0 0 436 64"
        backgroundColor="#f3f3f3"
        foregroundColor="#dcdbdb"
        {...props}
    >
        <rect x="30" y="14" rx="7" ry="7" width="118" height="10" /> 
        <rect x="30" y="43" rx="7" ry="7" width="51" height="16" /> 
        <rect x="214" y="11" rx="0" ry="0" width="2" height="60" /> 
        <rect x="234" y="14" rx="7" ry="7" width="118" height="10" /> 
        <rect x="234" y="43" rx="7" ry="7" width="51" height="16" /> 
        <rect x="304" y="43" rx="7" ry="7" width="51" height="16" />

        
    </ContentLoader>
    </div>
    )
    
    
    export default Responses
    
    
    