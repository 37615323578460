import {UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CG, UPDATE_BENCHMARK_FT_CG, UPDATE_COURSEFEEDBACK_FT_CG,UPDATE_RESPONSE_FT_CG} from '../../constants/actions'
const initialState = {
    avgCourseFeedback:4.5,
    benchMark: 4.5,
    courseFeedback:[
        {
            title:'Satisfaction',
            rating:4,
        },
        {
            title:'Engagement',
            rating:4.5,
        },
        {
            title:'Platform',
            rating:4,
        },
        {
            title:'Content Feedback',
            rating:4,
        }
    ],

    response:{
        likes:825,
        dislikes:25,
    }
}

export function courseGroupFeedbackTab(state=initialState, action){
    switch(action.type){
        case UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CG:
            return{
                ...state,
                avgCourseFeedback: action.payload
            }            
        case UPDATE_BENCHMARK_FT_CG:
            return{
                ...state,
                benchMark: action.payload
            }
        case UPDATE_COURSEFEEDBACK_FT_CG:
            return{
                ...state,
                courseFeedback: action.payload
            }
        case UPDATE_RESPONSE_FT_CG:
            return{
                ...state,
                response: action.payload
            }

        default:
            return state
    }
}
