import { 
    UPDATE_PROGRESS_BAR1_PF,UPDATE_PROGRESS_BAR2_PF, UPDATE_BAR_GRAPH_PF, UPDATE_TITLE1_PF, UPDATE_TITLE2_PF, UPDATE_COMPETENCY_TITLE_PF,
    UPDATE_PROGRESS_BAR1_PF_BYB, UPDATE_PROGRESS_BAR2_PF_BYB, UPDATE_HORIZONTAL_BAR_PF_BYB, UPDATE_BAR_GRAPH_PF_BYB, UPDATE_PIE_CHART_PF_BYB, UPDATE_BENCHMARK1_PF_BYB, UPDATE_BENCHMARK2_PF_BYB, UPDATE_BENCHMARK3_PF_BYB, UPDATE_BENCHMARK4_PF_BYB, UPDATE_COMPETENCY_PF_BYB,
    UPDATE_PROGRESS_BAR1_PF_CQ, UPDATE_CQ_BANNER_FIRST , UPDATE_CQ_GROUP_BENCHMARK , UPDATE_CQ_COMPETENCY_SCORES,UPDATE_CQ_DISTRIBUTIONS, UPDATE_CQ_DIFFUSION , UPDATE_CQ_STAKEHOLDERS ,UPDATE_PROGRESS_BAR2_PF_CQ, UPDATE_HORIZONTAL_BAR_PF_CQ, UPDATE_BENCHMARK1_PF_CQ, UPDATE_BAR_GRAPH_PF_CQ, UPDATE_BAR_LINE_PF_CQ, UPDATE_COMPETENCY_PF_CQ,
    UPDATE_IL_COMPETENCY_SCORES, UPDATE_IL_LEARNERS,UPDATE_IL_DISTRIBUTIONS, UPDATE_IL_BANNER_FIRST,UPDATE_IL_LEADERSHIP ,UPDATE_PROGRESS_BAR1_PF_IL, UPDATE_PROGRESS_BAR2_PF_IL, UPDATE_BAR_GRAPH1_PF_IL, UPDATE_BAR_GRAPH2_PF_IL, UPDATE_BAR_GRAPH3_PF_IL, UPDATE_HORIZONTAL_BAR_PF_IL, UPDATE_VERTICAL_BAR_PF_IL, UPDATE_PIE_CHART_PF_IL, UPDATE_BAR_ACCURACY_PF_IL, UPDATE_COMPETENCY_PF_IL,
    UPDATE_PROGRESS_BAR1_PF_TS, UPDATE_TS_LEARNERS,UPDATE_TS_COMPETENCY_SCORES, UPDATE_TS_BANNER_FIRST,UPDATE_TS_DISTRIBUTIONS, UPDATE_TS_ACCURACIES,UPDATE_PROGRESS_BAR2_PF_TS, UPDATE_HORIZONTAL_BAR_PF_TS, UPDATE_PIE_CHART1_PF_TS, UPDATE_BAR_GRAPH1_PF_TS, UPDATE_PIE_CHART_FILLED_PF_TS, UPDATE_BAR_BENCHMARK_PF_TS, UPDATE_PIE_CHART2_PF_TS, UPDATE_BAR_ACCURACY_PF_TS, UPDATE_COMPETENCY_PF_TS,
    UPDATE_PROGRESS_BAR1_PF_CG,UPDATE_PROGRESS_BAR2_PF_CG, UPDATE_BAR_GRAPH_PF_CG, UPDATE_TITLE1_PF_CG, UPDATE_TITLE2_PF_CG, UPDATE_COMPETENCY_TITLE_PF_CG,
    UPDATE_PROGRESS_BAR1_PF_NS, UPDATE_PROGRESS_BAR2_PF_NS, UPDATE_PROGRESS_BAR3_PF_NS, UPDATE_BAR_GRAPH_PF_NS, UPDATE_COURSE_DROPDOWN_PF_NS, UPDATE_PIE_CHART_PF_NS, UPDATE_TOTAL_QUESTIONS_PF_NS,
    UPDATE_AS_USERS,UPDATE_AS_SKILLS,UPDATE_AC_COMPETENCIES ,UPDATE_CS_COMPETENCY_SCORES, UPDTAE_CS_DISTRIBUTIONS, UPDATE_CS_GROW, UPDATE_AC_COMPETENCY_SCORES, UPDATE_AC_DISTRIBUTIONS, UPDATE_AC_TOP_PERFORMERS, UPDATE_AC_USERS, UPDATE_AS_COMPETENCY_SCORES, UPDATE_AS_DISTRIBUTIONS, UPDATE_AS_BANNER_FIRST, UPDATE_DOMAIN_COMPETENCY_ID
    } 
from '../../constants/actions'
import _ from 'lodash'
import { act } from 'react-dom/test-utils'
import { init } from 'aos'

let allCoursesHorizontalProgressBar1 = { width : 315, height : 10, 'Total': 10, 'score': 5.7 }
let allCoursesHorizontalProgressBar2 = { width : 315, height : 10, 'Total': 10, 'score': 4.6 }
let allCoursesBarGraph =  {
    width : 450,
    height : 150,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ],
    show: true
}

let courseGroupHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.7 }
let courseGroupHorizontalProgressBar2 =  { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let courseGroupBarGraph = {
    width : 450,
    height : 150,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
}

let nonSimHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.7 }
let nonSimHorizontalProgressBar2 = { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let nonSimHorizontalProgressBar3 = { width : 270, height : 10, 'Total': 100, 'score': 90 }
let nonSimBarGraph = {
    width : 450,
    height : 150,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
}
let nonSimPieChart = {height:210, width:225, 'data': [
        {
            'Avg No. of Questions Answered': 77,
            'Avg No. of Questions Unanswered': 23
        }
    ]
}

let asHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.7 }
let asHorizontalProgressBar2 = { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let asGroupHorizontal = { 'height':250, 
'width':472,
'data': [
    { Title: 'Customer Centricity', Group: 'Competent', Benchmark: 'Proficient' },
    { Title: 'Change Agility', Group: 'Competent', Benchmark: 'Role Model' },
    { Title: 'Collaboration', Group: 'Proficient', Benchmark: 'Competent' },
    { Title: 'Result Focus', Group: 'Proficient', Benchmark: 'Proficient' },
    ],
    show: true
}
let asBarGraph1 = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ],
    show: true
}
let asBarGraph1a = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ],
    show: true
}
let asBarGraph1b = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 50, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ],
    show: true
}
let asBarGraph1c = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ], 
    show: true
}
let asBarGraph2 = {
    width : 400,
    height : 150,
    data : [
                { exp: 'Low', '% Of Deviation': 25,  },
                { exp: 'Med', '% Of Deviation': 50,  },
                { exp: 'High', '% Of Deviation': 80,  },
          ]
}
let asBenchmarkGroup1 = {
    'height':215, 
    'width':425, 
    'data':[
        { Type: 'Relevance', 'Group': 80, 'Benchmark': 90},
        { Type: 'Efficiency',  'Group': 80, 'Benchmark': 80},
    ]
}
let asGroupVertical = {
    'height': 200,
    'width': 425,
    'data' : [
        { Type: 'Team Skill', Low: 50, Med: 62, High: 75 },
        { Type: 'Team Morale', Low: 80, Med: 78, High: 75 },
        { Type: 'Customer Focus', Low: 25, Med: 62, High: 75 }
    ]
}
let asBenchmarkGroup2 = {
    'height':215, 
    'width':425, 
    'data':[
        { Type: 'Velocity', 'Group': 80, 'Benchmark': 90},
    ]
}

let csHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.7 }
let csHorizontalProgressBar2 = { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let csGroupHorizontal = { 'height':250, 
'width':472,
'data': [
    { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
    { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
    { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
    { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
    { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
}
let csBarGraph2 = {
    width : 400,
    height : 175,
    data : [
        { exp: 'Group', '$': 1500000,  },
        { exp: 'Benchmark', '$': 2000000,  }
    ]
}
let csBarGraph1 = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
}
let csBarGraph1a = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 1, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
}
let csBarGraph1b = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 1, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
}
let csBarGraph1c = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 1, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
}
let csBarBenchmark = {
    'height':215, 
    'width':430, 
    'data':[
        { Type: 'Coaching Readiness',                 'Group': 60, 'Benchmark': 90},
        { Type: 'Coaching Performance',     'Group': 58, 'Benchmark': 72},
    ]
}
let csBarGraph3 = {
    width : 325,
    height : 200,
    data : [
                { exp: 'Goal', '% Of Deviation': 45},
                { exp: 'Reality', '% Of Deviation': 33},
                { exp: 'Options', '% Of Deviation': 60},
                { exp: 'Way', '% Of Deviation': 10}
          ]
}
let csPieChart = {'height':225, 'width':225,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}


let iLeadHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.7 }
let iLeadHorizontalProgressBar2 =  { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let iLeadBarGraph1 = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}
let iLeadBarGraph1a = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}
let iLeadBarGraph1b = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}
let iLeadBarGraph1c = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}
let iLeadBarGraph1d = {
    width : 450,
    height : 190,
    data : [
        { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}
let iLeadBarGraph2 = {
    width : 310,
    height : 200,
    data : [
        { exp: 'Desired Vs. Actual', '% Of Deviation': 5,  },
        { exp: 'Desired Vs. Intent', '% Of Deviation': 13,  },
        { exp: 'Intent Vs. Actual', '% Of Deviation': 40,  },
    ]
}
let iLeadBarGraph3 = {
    width : 310,
    height : 200,
    data : [
        { exp: 'Bottom Performers', '% of Time Spent': 5, years: '(1,2)' },
        { exp: 'Average Performers', '% of Time Spent': 13, years: '(3,4)' },
        { exp: 'Top Performers', '% of Time Spent': 40, years: '(5,6)' }
    ]
}
let iLeadGroupHorizontal = { 'height':250, 
'width':472,
'data': [
    { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
    { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
    { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
    { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
    { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
}
let iLeadGroupVertical = {
    'height': 160,
    'width': 370,
    'data' : [
        { Type: 'Conversions', Group: 50, Target: 62, Benchmark: 75 },
        { Type: 'Revenue', Group: 57, Target: 78, Benchmark: 75 },
    ]
}
let iLeadPieChart = {'height':225, 'width':225,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
let iLeadBarProportionAccuracy = {
    'height':200, 
    'width':310, 
    'data':[
        { Type: 'Directing',    'Proportion': 40, 'Accuracy': 37},
        { Type: 'Entrusting',   'Proportion': 24, 'Accuracy': 18},
        { Type: 'Guiding',      'Proportion': 56, 'Accuracy': 70},
        { Type: 'Partnering',   'Proportion': 58, 'Accuracy': 62},
    ]
}

let bybHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.7 }
let bybHorizontalProgressBar2 = { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let bybGroupHorizontal = { 'height':225, 
    'width':528,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
}
let bybBarGraph1 = {
    width : 500,
    height : 180,
    data : [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
}
let bybBenchmarkGroup1 = {
    'height':190, 
    'width':470, 
    'data':[
        { Type: 'Net Profit ',          'Group': 60, 'Benchmark': 50},
        { Type: 'Employee Utilization', 'Group': 80, 'Benchmark': 90},
        { Type: 'Market Share',         'Group': 80, 'Benchmark': 80},
        { Type: 'Economies Of Sale',    'Group': 30, 'Benchmark': 40},
    ]
}
let bybBenchmarkGroup2 = {
    'height':190, 
    'width':470, 
    'data':[
        { Type: 'Training Costs',                 'Group': 60, 'Benchmark': 90},
        { Type: 'Projects',     'Group': 58, 'Benchmark': 72},
    ]
}
let bybBenchmarkGroup3 = {
    'height':190, 
    'width':470, 
    'data':[
        { Type: 'STP',                 'Group': 60, 'Benchmark': 90},
        { Type: 'Opportunity Loss',     'Group': 58, 'Benchmark': 72},
    ]
}
let bybBenchmarkGroup4 = {
    'height':190, 
    'width':470, 
    'data':[
        { Type: 'Inventory',                 'Group': 60, 'Benchmark': 90}
    ]
}
let bybPieChart = {'height':225, 'width':225,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}

let tsHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.7 }
let tsHorizontalProgressBar2 = { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let tsGroupHorizontal = { 'height':250, 
'width':472,
'data': [
    { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
    { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
    { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
    { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
    { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
],
}
let tsPieChart1 = {'height':250, 'width':250,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
let tsBarGraph1 = {
    width : 455,
    height : 200,
    data : [
        { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}

let tsBarGraph1a = {
    width : 455,
    height : 200,
    data : [
        { exp: 'Novice', '% Of Users': 2, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}

let tsBarGraph1b = {
    width : 455,
    height : 200,
    data : [
        { exp: 'Novice', '% Of Users': 1, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}

let tsBarGraph1c = {
    width : 455,
    height : 200,
    data : [
        { exp: 'Novice', '% Of Users': 0, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ]
}

let tsPieChartFilled = {'height':225, 'width':225, 'data': [
    {
        'Achieved': 65, 'Unachieved': 35
    }
] 
}
let tsBenchmarkGroup1 = {
    'height':187, 
    'width':350, 
    'data':[
        { Type: 'STP ',                 'Group': 60, 'Benchmark': 90},
        { Type: 'Opportunity Less',     'Group': 58, 'Benchmark': 72},
    ]
}
let tsPieChart2 = {'height':198, 'width':180,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
let tsBarFrequencyAccuracy = {
    'height':180, 
    'width':310, 
    'data':[
        { Type: 'R', 'Frequency': 60, 'Accuracy': 80},
        { Type: 'E', 'Frequency': 60, 'Accuracy': 80},
        { Type: 'S', 'Frequency': 60, 'Accuracy': 80},
        { Type: 'P', 'Frequency': 60, 'Accuracy': 80},
    ]
}

let cqHorizontalProgressBar1 = { width : 270, height : 10, 'Total': 10, 'score': 5.1 }
let cqHorizontalProgressBar2 = { width : 270, height : 10, 'Total': 10, 'score': 4.6 }
let cqGroupHorizontal = { 'height':270, 
'width':532,
'data': [
    { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient'},
    { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
    { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
    { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
    { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
],
show: true
}
let cqBenchmarkGroup1 = {
    'height':225, 
    'width':370, 
    'data':[
        { Type: 'Adoptions',          'Group': 60, 'Benchmark': 50},
        { Type: 'Weighted Avg. Adoption', 'Group': 80, 'Benchmark': 90}
    ]
}
let cqHorizontalBenchmark = { 'height':51, 
    'width': 240,
    'data': [
        { Title: 'Group', '% Of Users': 74 },
        { Title: 'Benchmark', '% Of Users': 90 },
    ],
    }
let cqPieChart = {'height':200, 'width':200,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
let cqBarGraph1 = {
    width : 500,
    height : 170,
    data : [
        { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
    ],
    show: true
}
let cqBarLineCHart = {
    'height':170, 
    'width':450, 
    'data':[
        { Type: 'Innovators',       'Stakeholders': 18, 'TimeSpent': 22},
        { Type: 'Early Adopters',   'Stakeholders': 42, 'TimeSpent': 50},
        { Type: 'Early Majority',   'Stakeholders': 80, 'TimeSpent': 80},
        { Type: 'Late Majority',    'Stakeholders': 30, 'TimeSpent': 58},
        { Type: 'Laggards',         'Stakeholders': 30, 'TimeSpent': 36},
    ]
}

if(window.screen.width >= 1920) {
    allCoursesHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    allCoursesHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    allCoursesBarGraph = {
        width : 650,
        height : 225,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ],
        show: true
    }

    courseGroupHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    courseGroupHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    courseGroupBarGraph = {
        width : 650,
        height : 225,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ]
    }

    nonSimHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    nonSimHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    nonSimBarGraph = {
        width : 650,
        height : 225,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ]
    }
    nonSimPieChart = {height:265, width:265, 'data': [
            {
                'Avg No. of Questions Answered': 77,
                'Avg No. of Questions Unanswered': 23
            }
        ]
    }
    nonSimHorizontalProgressBar3 = { width : 350, height : 11.25, 'Total': 100, 'score': 90 }

    bybHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    bybHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    bybGroupHorizontal = { 'height':330, 
    'width':735,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
    }
    bybPieChart = {'height':337.5, 'width':337.5,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    bybBarGraph1 = {
        width : 750,
        height : 277.5,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ]
    }
    bybBenchmarkGroup1 = {
        'height':277.5, 
        'width':660, 
        'data':[
            { Type: 'Net Profit ',          'Group': 60, 'Benchmark': 50},
            { Type: 'Employee Utilization', 'Group': 80, 'Benchmark': 90},
            { Type: 'Market Share',         'Group': 80, 'Benchmark': 80},
            { Type: 'Economies Of Sale',    'Group': 30, 'Benchmark': 40},
        ]
    }
    bybBenchmarkGroup2 = {
        'height':277.5, 
        'width':660, 
        'data':[
            { Type: 'Training Costs',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Projects',     'Group': 58, 'Benchmark': 72},
        ]
    }
    bybBenchmarkGroup3 = {
        'height':277.5, 
        'width':660, 
        'data':[
            { Type: 'STP',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Opportunity Loss',     'Group': 58, 'Benchmark': 72},
        ]
    }
    bybBenchmarkGroup4 = {
        'height':277.5, 
        'width':660, 
        'data':[
            { Type: 'Inventory',                 'Group': 60, 'Benchmark': 90}
        ]
    }

    asHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    asHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    asGroupHorizontal = { 'height':350, 
    'width':665,
    'data': [
        { Title: 'Customer Centricity', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Change Agility', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Collaboration', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Result Focus', Group: 'Proficient', Benchmark: 'Proficient' },
        ],
        show: true
    }
    asBarGraph1 = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    asBarGraph1a = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    asBarGraph1b = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    asBarGraph1c = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    asBarGraph2 = {
        width : 600,
        height : 220,
        data : [
                    { exp: 'Low', '% Of Deviation': 25,  },
                    { exp: 'Med', '% Of Deviation': 50,  },
                    { exp: 'High', '% Of Deviation': 80,  },
              ]
    }
    asBenchmarkGroup1 = {
        'height':300, 
        'width':575, 
        'data':[
            { Type: 'Relevance', 'Group': 80, 'Benchmark': 90},
            { Type: 'Efficiency',  'Group': 80, 'Benchmark': 80},
        ]
    }
    asBenchmarkGroup2 = {
        'height':300, 
        'width':575, 
        'data':[
            { Type: 'Velocity', 'Group': 80, 'Benchmark': 90},
        ]
    }
    asGroupVertical = {
        'height': 250,
        'width': 625,
        'data' : [
            { Type: 'Team Skill', Low: 50, Med: 62, High: 75 },
            { Type: 'Team Morale', Low: 80, Med: 78, High: 75 },
            { Type: 'Customer Focus', Low: 25, Med: 62, High: 75 }
        ]
    }

    csHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    csHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    csGroupHorizontal = { 'height':350, 
    'width':665,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
        ],
    }
    csBarGraph2 = {
        width : 600,
        height : 250,
        data : [
            { exp: 'Group', '$': 45,  },
            { exp: 'Benchmark', '$': 33,  }
        ]
    }
    csBarGraph1 = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarGraph1a = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarGraph1b = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarGraph1c = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarBenchmark = {
        'height':300, 
        'width':550, 
        'data':[
            { Type: 'Coaching Readiness',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Coaching Performance',     'Group': 58, 'Benchmark': 72},
        ]
    }
    csBarGraph3 = {
        width : 400,
        height : 260,
        data : [
                    { exp: 'Goal', '% Of Deviation': 45},
                    { exp: 'Reality', '% Of Deviation': 33},
                    { exp: 'Options', '% Of Deviation': 60},
                    { exp: 'Way', '% Of Deviation': 10}
              ]
    }
    csPieChart = {'height':275, 'width':275,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}

    iLeadHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    iLeadHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    iLeadGroupHorizontal = { 'height':350, 
    'width':665,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
        ],
    }
    iLeadGroupVertical = {
        'height': 227.5,
        'width': 555,
        'data' : [
            { Type: 'Conversions', Group: 50, Target: 62, Benchmark: 75 },
            { Type: 'Revenue', Group: 57, Target: 78, Benchmark: 75 },
        ]
    }
    iLeadBarGraph1 = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1a = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1b = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1c = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1d = {
        width : 675,
        height : 270,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph2 = {
        width : 435,
        height : 250,
        data : [
            { exp: 'Desired Vs. Actual', '% Of Deviation': 5,  },
            { exp: 'Desired Vs. Intent', '% Of Deviation': 13,  },
            { exp: 'Intent Vs. Actual', '% Of Deviation': 40,  },
        ]
    }
    iLeadBarGraph3 = {
        width : 435,
        height : 250,
        data : [
            { exp: 'Bottom Performers', '% of Time Spent': 5, years: '(1,2)' },
            { exp: 'Average Performers', '% of Time Spent': 13, years: '(3,4)' },
            { exp: 'Top Performers', '% of Time Spent': 40, years: '(5,6)' }
        ]
    }
    iLeadPieChart = {'height':325, 'width':325,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    iLeadBarProportionAccuracy = {
        'height':250, 
        'width':455, 
        'data':[
            { Type: 'Directing',    'Proportion': 40, 'Accuracy': 37},
            { Type: 'Entrusting',   'Proportion': 24, 'Accuracy': 18},
            { Type: 'Guiding',      'Proportion': 56, 'Accuracy': 70},
            { Type: 'Partnering',   'Proportion': 58, 'Accuracy': 62},
        ]
    }

    tsHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    tsHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    tsGroupHorizontal = { 'height':337.5, 
    'width':665,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
    }
    tsPieChart1 = {'height':350, 'width':350,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    tsBarGraph1 = {
        width : 680,
        height : 275,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsBarGraph1a = {
        width : 680,
        height : 275,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsBarGraph1b = {
        width : 680,
        height : 275,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsBarGraph1c = {
        width : 680,
        height : 275,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsPieChartFilled = {'height':320, 'width':320, 'data': [
        {
            'Achieved': 65, 'Unachieved': 35
        }
    ] 
    }
    tsBenchmarkGroup1 = {
        'height':265, 
        'width':475, 
        'data':[
            { Type: 'STP ',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Opportunity Less',     'Group': 58, 'Benchmark': 72},
        ]
    }
    tsPieChart2 = {'height':282, 'width':282,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    tsBarFrequencyAccuracy = {
        'height':265, 
        'width':460, 
        'data':[
            { Type: 'R', 'Frequency': 60, 'Accuracy': 80},
            { Type: 'E', 'Frequency': 60, 'Accuracy': 80},
            { Type: 'S', 'Frequency': 60, 'Accuracy': 80},
            { Type: 'P', 'Frequency': 60, 'Accuracy': 80},
        ]
    }
    cqHorizontalProgressBar1 = { width : 370, height : 11.25, 'Total': 10, 'score': 5.7 }
    cqHorizontalProgressBar2 = { width : 370, height : 11.25, 'Total': 10, 'score': 4.6 }
    cqGroupHorizontal = { 'height':365, 
    'width':750,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
    show: true
    }
    cqBenchmarkGroup1 = {
        'height':337.5, 
        'width':525, 
        'data':[
            { Type: 'Adoptions',          'Group': 60, 'Benchmark': 50},
            { Type: 'Weighted Avg. Adoption', 'Group': 80, 'Benchmark': 90}
        ]
    }
    cqHorizontalBenchmark = { 'height':75.5, 
    'width': 360,
    'data': [
        { Title: 'Group', '% Of Users': 74 },
        { Title: 'Benchmark', '% Of Users': 90 },
    ],
    }
    cqPieChart = {'height':250, 'width':250,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    cqBarGraph1 = {
        width : 750,
        height : 235,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    cqBarLineCHart = {
        'height':260, 
        'width':650, 
        'data':[
            { Type: 'Innovators',       'Stakeholders': 18, 'TimeSpent': 22},
            { Type: 'Early Adopters',   'Stakeholders': 42, 'TimeSpent': 50},
            { Type: 'Early Majority',   'Stakeholders': 80, 'TimeSpent': 80},
            { Type: 'Late Majority',    'Stakeholders': 30, 'TimeSpent': 58},
            { Type: 'Laggards',         'Stakeholders': 30, 'TimeSpent': 36},
        ]
    }
}

if(window.screen.width >= 2560) {
    allCoursesHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    allCoursesHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    allCoursesBarGraph = {
        width : 850,
        height : 300,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ],
        show: true
    }

    courseGroupHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    courseGroupHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    courseGroupBarGraph = {
        width : 850,
        height : 300,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ]
    }

    nonSimHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    nonSimHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    nonSimBarGraph = {
        width : 850,
        height : 300,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ]
    }
    nonSimPieChart = {height:300, width:300, 'data': [
            {
                'Avg No. of Questions Answered': 77,
                'Avg No. of Questions Unanswered': 23
            }
        ]
    }
    nonSimHorizontalProgressBar3 = { width : 500, height : 12.5, 'Total': 100, 'score': 90 }

    asHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    asHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    asGroupHorizontal = { 'height':425, 
    'width':915,
    'data': [
        { Title: 'Customer Centricity', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Change Agility', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Collaboration', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Result Focus', Group: 'Proficient', Benchmark: 'Proficient' },
        ],
        show: true
    }
    asBarGraph1 = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    asBarGraph1a = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        shoe: true
    }
    asBarGraph1b = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    asBarGraph1c = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    asBarGraph2 = {
        width : 800,
        height : 275,
        data : [
                    { exp: 'Low', '% Of Deviation': 25,  },
                    { exp: 'Med', '% Of Deviation': 50,  },
                    { exp: 'High', '% Of Deviation': 80,  },
              ]
    }
    asBenchmarkGroup1 = {
        'height':400, 
        'width':750, 
        'data':[
            { Type: 'Relevance', 'Group': 80, 'Benchmark': 90},
            { Type: 'Efficiency',  'Group': 80, 'Benchmark': 80},
        ]
    }
    asBenchmarkGroup2 = {
        'height':400, 
        'width':750, 
        'data':[
            { Type: 'Velocity', 'Group': 80, 'Benchmark': 90},
        ]
    }
    asGroupVertical = {
        'height': 375,
        'width': 850,
        'data' : [
            { Type: 'Team Skill', Low: 50, Med: 62, High: 75 },
            { Type: 'Team Morale', Low: 80, Med: 78, High: 75 },
            { Type: 'Customer Focus', Low: 25, Med: 62, High: 75 }
        ]
    }

    csHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    csHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    csGroupHorizontal = { 'height':425, 
    'width':890,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
        ],
    }
    csBarGraph2 = {
        width : 775,
        height : 315,
        data : [
            { exp: 'Group', '$': 45,  },
            { exp: 'Benchmark', '$': 33,  }
        ]
    }
    csBarGraph1 = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarGraph1a = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarGraph1b = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarGraph1c = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    csBarBenchmark = {
        'height':390, 
        'width':750, 
        'data':[
            { Type: 'Coaching Readiness',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Coaching Performance',     'Group': 58, 'Benchmark': 72},
        ]
    }
    csBarGraph3 = {
        width : 550,
        height : 325,
        data : [
                    { exp: 'Goal', '% Of Deviation': 45 },
                    { exp: 'Reality', '% Of Deviation': 33 },
                    { exp: 'Options', '% Of Deviation': 60 },
                    { exp: 'Way', '% Of Deviation': 10 }
              ]
    }
    csPieChart = {'height':350, 'width':350,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}

    iLeadHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    iLeadHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    iLeadGroupHorizontal = { 'height':450, 
    'width':890,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
        ],
    }
    iLeadGroupVertical = {
        'height': 295,
        'width': 740,
        'data' : [
            { Type: 'Conversions', Group: 50, Target: 62, Benchmark: 75 },
            { Type: 'Revenue', Group: 57, Target: 78, Benchmark: 75 },
        ]
    }
    iLeadBarGraph1 = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1a = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1b = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1c = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadBarGraph1d = {
        width : 900,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    iLeadPieChart = {'height':425, 'width':425,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    iLeadBarProportionAccuracy = {
        'height':300, 
        'width':600, 
        'data':[
            { Type: 'Directing',    'Proportion': 40, 'Accuracy': 37},
            { Type: 'Entrusting',   'Proportion': 24, 'Accuracy': 18},
            { Type: 'Guiding',      'Proportion': 56, 'Accuracy': 70},
            { Type: 'Partnering',   'Proportion': 58, 'Accuracy': 62},
        ]
    }
    iLeadBarGraph2 = {
        width : 600,
        height : 270,
        data : [
            { exp: 'Desired Vs. Actual', '% Of Deviation': 5,  },
            { exp: 'Desired Vs. Intent', '% Of Deviation': 13,  },
            { exp: 'Intent Vs. Actual', '% Of Deviation': 40,  },
        ]
    }
    iLeadBarGraph3 = {
        width : 600,
        height : 300,
        data : [
            { exp: 'Bottom Performers', '% of Time Spent': 5, years: '(1,2)' },
            { exp: 'Average Performers', '% of Time Spent': 13, years: '(3,4)' },
            { exp: 'Top Performers', '% of Time Spent': 40, years: '(5,6)' }
        ]
    }

    bybHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    bybHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    bybPieChart = {'height':450, 'width':450,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    bybGroupHorizontal = { 'height':435, 
    'width':975,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
    }
    bybBarGraph1 = {
        width : 975,
        height : 375,
        data : [
            { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
        ]
    }
    bybBenchmarkGroup1 = {
        'height':365, 
        'width':850, 
        'data':[
            { Type: 'Net Profit ',          'Group': 60, 'Benchmark': 50},
            { Type: 'Employee Utilization', 'Group': 80, 'Benchmark': 90},
            { Type: 'Market Share',         'Group': 80, 'Benchmark': 80},
            { Type: 'Economies Of Sale',    'Group': 30, 'Benchmark': 40},
        ]
    }
    bybBenchmarkGroup2 = {
        'height':365, 
        'width':850, 
        'data':[
            { Type: 'Training Costs',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Projects',     'Group': 58, 'Benchmark': 72},
        ]
    }
    bybBenchmarkGroup3 = {
        'height':365, 
        'width':850, 
        'data':[
            { Type: 'STP',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Opportunity Loss',     'Group': 58, 'Benchmark': 72},
        ]
    }
    bybBenchmarkGroup4 = {
        'height':365, 
        'width':865, 
        'data':[
            { Type: 'Inventory',                 'Group': 60, 'Benchmark': 90}
        ]
    }

    tsHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    tsHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    tsGroupHorizontal = { 'height':425, 
    'width':900,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
    }
    tsPieChart1 = {'height':445, 'width':445,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    tsBarGraph1 = {
        width : 875,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsBarGraph1a = {
        width : 875,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsBarGraph1b = {
        width : 875,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsBarGraph1c = {
        width : 875,
        height : 350,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ]
    }
    tsPieChartFilled = {'height':400, 'width':400, 'data': [
        {
            'Achieved': 65, 'Unachieved': 35
        }
    ] 
    }
    tsBenchmarkGroup1 = {
        'height':350, 
        'width':625, 
        'data':[
            { Type: 'STP ',                 'Group': 60, 'Benchmark': 90},
            { Type: 'Opportunity Less',     'Group': 58, 'Benchmark': 72},
        ]
    }
    tsPieChart2 = {'height':375, 'width':375,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    tsBarFrequencyAccuracy = {
        'height':350, 
        'width':625, 
        'data':[
            { Type: 'R', 'Frequency': 60, 'Accuracy': 80},
            { Type: 'E', 'Frequency': 60, 'Accuracy': 80},
            { Type: 'S', 'Frequency': 60, 'Accuracy': 80},
            { Type: 'P', 'Frequency': 60, 'Accuracy': 80},
        ]
    }

    cqHorizontalProgressBar1 = { width : 425, height : 12.5, 'Total': 10, 'score': 5.7 }
    cqHorizontalProgressBar2 = { width : 425, height : 12.5, 'Total': 10, 'score': 4.6 }
    cqGroupHorizontal = { 'height':500, 
    'width':975,
    'data': [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
    ],
    show: true
    }
    cqBenchmarkGroup1 = {
        'height':450, 
        'width':675, 
        'data':[
            { Type: 'Adoptions',          'Group': 60, 'Benchmark': 50},
            { Type: 'Weighted Avg. Adoption', 'Group': 80, 'Benchmark': 90}
        ]
    }
    cqHorizontalBenchmark = { 'height':102, 
    'width': 480,
    'data': [
        { Title: 'Group', '% Of Users': 74 },
        { Title: 'Benchmark', '% Of Users': 90 },
    ],
    }
    cqPieChart = {'height':350, 'width':350,'TotalInner': 100, 'scoreInner': 70, 'TotalOuter': 100, 'scoreOuter': 80}
    cqBarGraph1 = {
        width : 975,
        height : 300,
        data : [
            { exp: 'Novice', '% Of Users': 5, years: '(1,2)' },
            { exp: 'Emerging', '% Of Users': 13, years: '(3,4)' },
            { exp: 'Competent', '% Of Users': 40, years: '(5,6)' },
            { exp: 'Proficient', '% Of Users': 35, years: '(7,8)' },
            { exp: 'Role Model', '% Of Users': 8, years: '(9,10)' },
        ],
        show: true
    }
    cqBarLineCHart = {
        'height':350, 
        'width':850, 
        'data':[
            { Type: 'Innovators',       'Stakeholders': 18, 'TimeSpent': 22},
            { Type: 'Early Adopters',   'Stakeholders': 42, 'TimeSpent': 50},
            { Type: 'Early Majority',   'Stakeholders': 80, 'TimeSpent': 80},
            { Type: 'Late Majority',    'Stakeholders': 30, 'TimeSpent': 58},
            { Type: 'Laggards',         'Stakeholders': 30, 'TimeSpent': 36},
        ]
    }
} 

const initialState = {
    usersAC: {
        learners: 10
    },
    competencyScoresAC : {
        dataForHorizontalProgressBar1 : allCoursesHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : allCoursesHorizontalProgressBar2,
        testArray: []
    },
    distributionsAC: {
        dataForBarGraph1 : allCoursesBarGraph
    },
    competenciesAC: {
        totalCompetenciesArray : [{'topCompetenciesArray': ['Contextual Leadership', 'Influencing Skills']},{'lowCompetenciesArray': ['Contextual Leadership', 'Influencing Skills', 'Communication']}],
        topCompetenciesArray : ['Contextual Leadership', 'Influencing Skills'],
        lowCompetenciesArray : ['Contextual Leadership', 'Influencing Skills', 'Communication']
    },
    topPerformersAC: {
        topPerformersArray : []
    },
    jidAC: {
        jid: 0
    },
    allCourses: {
        dataForHorizontalProgressBar1 : allCoursesHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : allCoursesHorizontalProgressBar2,
        dataForBarGraph1 : allCoursesBarGraph,
        title1: 'TOP COMPETENCIES',
        title2: 'COMPETENCIES THAT NEED IMPROVEMENT',
        competencyBoxTitles: {
            title1: 'Ilead',
            title2: 'BYB',
            title3: 'CQ',
            title4: 'Trust Sim'
        },
        topPerformersArray : [
            {
                'userId': 1,
                'name': 'A S',
                'email': 'doritha@xyz.com',
                'score': 7.1
            },
            {
                'userId': 2,
                'name': 'Doretha Peralta',
                'email': 'ajendra.singh@knolskape.com',
                'score': '7.0'
            },
            {
                'userId': 3,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 4,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 5,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 6,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 7,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 8,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 9,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 10,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 11,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 12,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 13,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 14,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 15,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 16,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 17,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 18,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 19,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 20,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 21,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            }
        ]
    },
    byb: {
        competencyArray: ["Ability to Motivate", "Ability to upskill", "Enabling Performance", "Drive For Results"],
        dataForHorizontalProgressBar1 : bybHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : bybHorizontalProgressBar2,
        dataForGroupHorizontalBar : bybGroupHorizontal,
        dataForBarGraph1 : bybBarGraph1,
        dataForPieChart : bybPieChart,
        dataForBarBenchmarkGroup1 : bybBenchmarkGroup1,
        dataForBarBenchmarkGroup2 : bybBenchmarkGroup2,
        dataForBarBenchmarkGroup3 : bybBenchmarkGroup3,
        dataForBarBenchmarkGroup4 : bybBenchmarkGroup4,
        headingTitle1: 'DEVELOPING BUSINESS ACUMEN',
        headingTitle2: 'BYB',
        graphTitle1: 'DISTRIBUTION OF USERS ACROSS COMPETENCIES',
        graphTitle2: 'NET PROFIT',
        graphTitle3: 'MARKET SHARE',
        graphTitle4: 'EXECUTION EXCELLENCE',
        graphTitle5: 'STRATEGIC THINKING',
        graphTitle6: 'MARKET ORIENTATION',
        graphTitle7: 'INVENTORY MANAGEMENT'
    },
    bannerFirstCQ: {
        description: '',
        name: '',
        domainCategory : []
    },
    groupBenchmarkCQ: {
        totalLearners: 10
    },
    competencyScoresCQ: {
        dataForHorizontalProgressBar1: cqHorizontalProgressBar1,
        dataForHorizontalProgressBar2: cqHorizontalProgressBar2,
        dataForGroupHorizontalBar: cqGroupHorizontal,
        dataForAdoptions: cqBenchmarkGroup1,
        dataForAdoptors: cqHorizontalBenchmark,
        dataForPieChart: cqPieChart
    },
    distributionsCQ: {
        dataForBarGraph1: cqBarGraph1,
        actionText1: '',
        actionText2: 'Ask About Networks',
        metricGroup1: 68,
        metricBenchmark1: 75,
        metricGroup2: 78,
        metricBenchmark2: 83 
    },
    diffusionCQ : {
        dataForBarWithLineChart : cqBarLineCHart
    },
    stakeholdersCQ: {
        timingofstakeholderinteractionsProps : {
            headerTitle: 'timing of stakeholder interactions',
            xAxis : ['innovators','early adopters','early majority','late majority','laggards'],
            yAxis : ['too early','early','late','too late'],
            meetPoints : [{'innovators': ['too late','early']}, {'early adopters' : ['too early','early']}, {'early majority': ['too early','late']}, {'late majority': ['early','late']}, {'laggards' :['too early','late']}]    
        },
        frequencyofstakeholderinteractionsProps : {
            headerTitle: 'frequency of stakeholder interactions',
            xAxis : ['innovators','early adopters','early majority','late majority','laggards'],
            yAxis : ['optimal','high','low','irregular'],
            meetPoints : [{'innovators': ['irregular','optimal']}, {'early adopters' : ['high','high']}, {'early majority': ['high','high']}, {'late majority': ['high','optimal']}, {'laggards' :['high','low']}]    
        }
    },
    cq: {
        bannerDescription: "Until...",
        competencyArray: ["Influencing Skills"],
        dataForHorizontalProgressBar1 : cqHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : cqHorizontalProgressBar2,
        dataForGroupHorizontalBar : cqGroupHorizontal,
        dataForBarBenchmarkGroup1 : cqBenchmarkGroup1,
        dataForBarGraph1 : cqBarGraph1,
        dataForBarWithLineChart : cqBarLineCHart,
        headingTitle1 : 'ChangeQuest',
        graphTitle1 : 'ADOPTION AND ADOPTIONS LEVEL',
        graphTitle2 : 'DISTRIBUTION OF USERS ACROSS COMPETENCIES',
        graphTitle3 : 'INNOVATION DIFFUSION CURVE',
        actionTitle1 : 'LEAST PREFERRED ACTION',
        actionText1 : 'Ask About Networks',
        metricTitle1 : 'IMPACT OF ACTIONS',
        metricGroupPercent: '78%',
        metricBenchmarkPercent: '83%'
    },
    bannerFirstIL: {
        description: 'hola.....'
    },
    totalLearnersIL : {
        users: 10
    },
    competencyScoresIL : {
        dataForHorizontalProgressBar1: iLeadHorizontalProgressBar1,
        dataForHorizontalProgressBar2: iLeadHorizontalProgressBar2,
        dataForGroupHorizontalBar: iLeadGroupHorizontal,
        dataForGroupVerticalBar : iLeadGroupVertical,
        targetAchieved : 10
    },
    distributionsIL : {
        dataForBarGraphUpskill: iLeadBarGraph1a,
        dataForBarGraphMotivate: iLeadBarGraph1,
        dataForBarGraphPerformance: iLeadBarGraph1b,
        dataForBarGraphResults: iLeadBarGraph1c,
        dataForBarGraphLeadership: iLeadBarGraph1d,
        dataForPieChart: iLeadPieChart
    },
    leadershipIL: {
        dataForBarGraph2: iLeadBarGraph2,
        dataForBarGraph3: iLeadBarGraph3
    },
    iLead: {
        competencyArray: ["Ability to Motivate", "Ability to Upskill", "Enabling Performance", "Drive For Results", "Contextual Leadership"],
        dataForHorizontalProgressBar1 : iLeadHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : iLeadHorizontalProgressBar2,
        dataForBarGraph1 : iLeadBarGraph1,
        dataForBarGraph2 : iLeadBarGraph2,
        dataForBarGraph3 : iLeadBarGraph3,
        dataForGroupHorizontalBar : iLeadGroupHorizontal,
        dataForGroupVerticalBar : iLeadGroupVertical,
        dataForPieChart : iLeadPieChart,
        dataForBarProportionAccuracy : iLeadBarProportionAccuracy,
        headingTitle1: 'LEADING TEAMS EFFECTIVELY',
        headingTitle2: 'Ilead',
        graphTitle1: 'TARGETS (REVENUE AND CONVERSION)',
        graphTitle2: 'DISTRIBUTION OF USERS ACROSS COMPETENCIES',
        graphTitle3: 'LEADERSHIP STYLE ADAPTABILITY',
        graphTitle4: 'LEADERSHIP STYLES DISTRIBUTION',
        graphTitle5: 'LEADERSHIP STYLES - CONSISTENCY',
        graphTitle6: 'TIME SPENT'
    },
    bannerFirstTS: {
        description: 'hola'
    },
    usersTS: {
        totalLearners: 10
    },
    competencyScoresTS:{
        dataForHorizontalProgressBar1: tsHorizontalProgressBar1,
        dataForHorizontalProgressBar2: tsHorizontalProgressBar2,
        dataForGroupHorizontalBar: tsGroupHorizontal,
        dataForPieChart1: tsPieChart1
    },
    distributionsTS: {
        dataForBarGraphTrust: tsBarGraph1,
        dataForBarGraphNetworks: tsBarGraph1a,
        dataForBarGraphRelationships: tsBarGraph1b,
        dataForBarGraphStakeholders: tsBarGraph1c,
        dataForPieChartFilled: tsPieChartFilled
    },
    accuraciesTS: {
        dataForBarBenchmarkGroup1: tsBenchmarkGroup1,
        dataForPieChart2: tsPieChart2,
        dataForBarFrequencyAccuracy: tsBarFrequencyAccuracy
    },
    trustSim: {
        competencyArray: ["Building Trust", "Leveraging Networks", "Maintaining Sustainable Relationships", "Managing Stakeholders"],
        dataForHorizontalProgressBar1 : tsHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : tsHorizontalProgressBar2,
        dataForGroupHorizontalBar : tsGroupHorizontal,
        dataForPieChart1 : tsPieChart1,
        dataForBarGraph1 : tsBarGraph1,
        dataForPieChartFilled : tsPieChartFilled,
        dataForBarBenchmarkGroup1 : tsBenchmarkGroup1,
        dataForPieChart2 : tsPieChart2,
        dataForBarFrequencyAccuracy : tsBarFrequencyAccuracy,
        headingTitle1: 'BUILDING TRUST',
        headingTitle2: 'Trust Sim',
        graphTitle1: 'TRUST SCORE',
        graphTitle2: 'DISTRIBUTION OF USERS ACROSS COMPETENCIES',
        graphTitle3: 'TARGET ACHIEVED VS UNACHIEVED',
        graphTitle4: 'ACCURACIES',
        graphTitle5: 'NETWORK UNCOVERED',
        graphTitle6: 'RELATIONSHIP STYLES'
    },
    bannerFirstAS: {
        description: '',
        name: '',
        domainCategory:  []
    },
    usersAS: {
        totalLearners: 10
    },
    competencyScoresAS: {
        dataForHorizontalProgressBar1: asHorizontalProgressBar1,
        dataForHorizontalProgressBar2: asHorizontalProgressBar2,
        dataForGroupHorizontalBar: asGroupHorizontal,
        targetAchieved: 15,
        dataForPCF: asBarGraph2

    },
    distributionsAS: {
        dataForBarGraphCentricity : asBarGraph1,
        dataForBarGraphAgility: asBarGraph1a,
        dataForBarGraphCollaboration: asBarGraph1b,
        dataForBarGraphFocus: asBarGraph1c,
        dataForBarBenchmarkGroup1: asBenchmarkGroup1
    },
    skillAS: {
        dataForGroupVertical : asGroupVertical,
        dataForBarBenchmarkGroup2 : asBenchmarkGroup2
    },
    competenciesAS: {
        competencyArray: ["Customer Centricity", "Change Agility", "Collaboration", "Result Focus"]
    },
    bannerFirstCS: {
        description: '<p>Journey with all items optional</p>'
    },
    usersCS: {
        totalLearners: 10
    },
    competencyScoresCS: {
        dataForHorizontalProgressBar1: csHorizontalProgressBar1,
        dataForHorizontalProgressBar2: csHorizontalProgressBar2,
        dataForGroupHorizontalBar: csGroupHorizontal,
        targetAchieved: 20,
        dataForBarGraphTarget: csBarGraph2
    },
    competenciesCS: {
        competencyArray: ["Building Trust", "Powerful Questioning", "Accountability", "Communications"]
    },
    distributionsCS: {
        dataForBarGraphTrust: csBarGraph1,
        dataForBarGraphQuestioning: csBarGraph1a,
        dataForBarGraphAccountability: csBarGraph1b,
        dataForBarGraphCommunications: csBarGraph1c,
        dataForBarBenchmarkGroup: csBarBenchmark
    },
    growCS: {
        dataForBarGraphGrow: csBarGraph3,
        dataForPieChart: csPieChart
    },
    courseGroupPerformance: {
        dataForHorizontalProgressBar1 : courseGroupHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : courseGroupHorizontalProgressBar2,
        dataForBarGraph1 : courseGroupBarGraph,
        title1: 'TOP COMPETENCIES',
        title2: 'COMPETENCIES THAT NEED IMPROVEMENT',
        competencyBoxTitles: {
            title1: 'Ilead',
            title2: 'BYB',
            title3: 'CQ',
            title4: 'Trust Sim'
        },
        topPerformersArray : [
            {
                'userId': 1,
                'name': 'A S',
                'email': 'doritha@xyz.com',
                'score': 7.1
            },
            {
                'userId': 2,
                'name': 'Doretha Peralta',
                'email': 'ajendra.singh@knolskape.com',
                'score': '7.0'
            },
            {
                'userId': 3,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 4,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 5,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 6,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 7,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 8,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 9,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 10,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 11,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 12,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 13,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 14,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 15,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 16,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 17,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 18,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 19,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 20,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            },
            {
                'userId': 21,
                'name': 'Doretha Peralta',
                'email': 'doritha.peralta@xyz.com',
                'score': '7.0'
            }
        ]
    },
    nonSim: {
        dataForHorizontalProgressBar1 : nonSimHorizontalProgressBar1,
        dataForHorizontalProgressBar2 : nonSimHorizontalProgressBar2,
        dataForHorizontalProgressBar3 : nonSimHorizontalProgressBar3,
        dataForBarGraph1 : nonSimBarGraph,
        arr : [
            'All Courses',
            'Ability To Upskill',
            'Ability To Motivate',
            'Contextual Leadership',
            'Enabling Performance',
            'Ability To Upskill',
            'Ability To Motivate',
            'Contextual Leadership',
        ],
        dataForPieChartFilled : nonSimPieChart,
        totalQuestions : 20
    }
}

const checkTags = (dataArray) => {
    let isPresent = false
    if(dataArray)
    {
        for(let i=0 ; i<dataArray.length ; i++)
        {
            if(dataArray[i].name.toLowerCase()=='passport')
            {
                isPresent = true
                break
            }
        }
    }
    return isPresent
}

const bannerFirstDescription = (dataArray) => {
    //console.log(dataArray)
    if(dataArray.length==0)
    {
        return ""
    }
    return dataArray[0].filterString[0].langvalue
}

const bannerFirstName = (dataArray) => {
    if(dataArray.length==0)
    {
        return ""
    }
    return dataArray[0].filterStringName[0].langvalue
}

const bannerDomainArray = (dataArray) => {
    let arr = []
    if(dataArray.length==0)
    {
        return arr
    }
    if(dataArray[0].journey_domain && dataArray[0].journey_categories)
    {
        arr.push(dataArray[0].journey_domain)
        arr.push(dataArray[0].journey_categories)
    }
    return arr
}

const calculateGroupAvgProgressBarCQ = (dataArray) => {
    if(dataArray.length==0)
    {
        initialState.competencyScoresCQ.dataForHorizontalProgressBar1.score = 0
        return initialState.competencyScoresCQ.dataForHorizontalProgressBar1 
    }
    let total=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            total = total + dataArray[i].overall_competency_score
        }
    }

    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            instances  = instances + 1
        }
    }

    if(instances==0)
    {
        instances = 1
    }

    total = total/instances

    initialState.competencyScoresCQ.dataForHorizontalProgressBar1.score = Math.round((total*10))/10 
    return initialState.competencyScoresCQ.dataForHorizontalProgressBar1 
}

const calculateBenchmarkAvgProgressBarCQ = (dataArray) => {
    if(dataArray.length==0)
    {
        initialState.competencyScoresCQ.dataForHorizontalProgressBar2.score = 0
        return initialState.competencyScoresCQ.dataForHorizontalProgressBar2
    }
    let total=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            total = total + dataArray[i].overall_competency_score
        }
    }

    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            instances  = instances + 1
        }
    }

    if(instances==0)
    {
        instances = 1
    }

    total = total/instances

    initialState.competencyScoresCQ.dataForHorizontalProgressBar2.score = Math.round((total*10))/10 
    return initialState.competencyScoresCQ.dataForHorizontalProgressBar2
}

const calculateNameGroupHorizontal = (val) => {
    if(val>=0 && val<=2)
    {
        return 'Novice'
    }
    else if(val>2 && val<=4) {
        return 'Emerging'
    }
    else if(val>4 && val<=6) {
        return 'Competent'
    }
    else if(val>6 && val<=8) {
        return 'Proficient'
    }
    else {
        return 'Role Model'
    }
}

const calculateGroupHorizontalCQ = (dataArray1, dataArray2) => {
    let data = [
        { Title: 'Influencing Skills', Group: 'Competent', Benchmark: 'Proficient' }
        ]
    if(dataArray1.length==0 && dataArray2.length==0)
    {
        data[0].Group = ' '
        data[0].Benchmark = ' '
        initialState.competencyScoresCQ.dataForGroupHorizontalBar.data = data
        return initialState.competencyScoresCQ.dataForGroupHorizontalBar
    }
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        let entries = Object.entries(dataArray1[i].competencies)
        //console.log(entries)
        dataArray1[i].competencies = entries
    }
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        let entries = Object.entries(dataArray2[i].competencies)
        //console.log(entries)
        dataArray2[i].competencies = entries
    }
    let influencing= {group: 0, benchmark: 0}

    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray1[i].competencies.length ; j++)
            {
                if(dataArray1[i].competencies[j][0]=='influencing')
                {
                    influencing.group =  influencing.group + dataArray1[i].competencies[j][1].value
                }
            }
        }
    }
    let totalGroup = 0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            totalGroup = totalGroup + 1
        }
    }
    let checkGroupCount = totalGroup
    if(totalGroup==0)
    {
        totalGroup=1
    }
    influencing.group = influencing.group/totalGroup

    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray2[i].competencies.length ; j++)
            {
                if(dataArray2[i].competencies[j][0] == 'influencing')
                {
                    influencing.benchmark =  influencing.benchmark + dataArray2[i].competencies[j][1].value
                }
            }
        }
    }
    let totalBenchmark = 0
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
        {
            totalBenchmark = totalBenchmark + 1
        }
    }
    if(totalBenchmark==0)
    {
        totalBenchmark=1
    }
    influencing.benchmark = influencing.benchmark/totalBenchmark
    
    
    data[0].Group = calculateNameGroupHorizontal(Math.floor(influencing.group))
    data[0].Benchmark = calculateNameGroupHorizontal(Math.floor(influencing.benchmark))

    if(dataArray1.length==0 || checkGroupCount==0)
    {
        data[0].Group = ' '
    }

    initialState.competencyScoresCQ.dataForGroupHorizontalBar.data = data
    return initialState.competencyScoresCQ.dataForGroupHorizontalBar
}

const calculateBarBenchmarkCQ = (dataArray10, dataArray20) => {
    console.log('ADOPTIONS')
    let data= [
        { Type: 'Adoptions', 'Group': 20, 'Benchmark': 50},
        { Type: 'Weighted Avg. Adoption', 'Group': 80, 'Benchmark': 90}
    ]

    /*let adoption = { group: 0, benchmark: 0 }
    let wadoption = {group: 0, benchmark: 0}

    for(let i=0 ; i<dataArray1.length ; i++)
    {
        adoption.group = adoption.group + dataArray1[i].scores.metrictotaladopters
        wadoption.group = wadoption.group + dataArray1[i].scores.metricweightedavgadoption
    }
    adoption.group = adoption.group/dataArray1.length
    wadoption.group = wadoption.group/dataArray1.length
    console.log(wadoption.group)

    for(let i=0 ; i<dataArray2.length ; i++)
    {
        adoption.benchmark = adoption.benchmark + dataArray2[i].scores.metrictotaladopters
        wadoption.benchmark = wadoption.benchmark + dataArray2[i].scores.metricweightedavgadoption
    }
    adoption.benchmark = adoption.benchmark/dataArray2.length
    wadoption.benchmark = wadoption.benchmark/dataArray2.length

    data[0].Group = Math.round((adoption.group*10))/10 
    data[1].Group = Math.round((wadoption.group*10))/10 
    data[0].Benchmark = Math.round((adoption.benchmark*10))/10 
    data[1].Benchmark = Math.round((wadoption.benchmark*10))/10 */
    initialState.competencyScoresCQ.dataForBarBenchmarkGroup1.data = data
    return initialState.competencyScoresCQ.dataForBarBenchmarkGroup1
}

const calculateDistributionsCQ = (dataArray1) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)',  'countOfUsers': 0 },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)', 'countOfUsers': 0 },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)', 'countOfUsers': 0 },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)', 'countOfUsers': 0 },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)', 'countOfUsers': 0 },
    ]
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        let entries = Object.entries(dataArray1[i].competencies)
        //console.log(entries)
        dataArray1[i].competencies = entries
    }
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray1[i].competencies.length ; j++)
            {
                if(dataArray1[i].competencies[j][0]=='influencing')
                {
                    if((dataArray1[i].competencies[j][1].value)>=0 && (dataArray1[i].competencies[j][1].value)<=2)
                    {
                        val1++
                    }
                    else if((dataArray1[i].competencies[j][1].value)>2 && (dataArray1[i].competencies[j][1].value)<=4)
                    {
                        val2++
                    }
                    else if(dataArray1[i].competencies[j][1].value>4 && dataArray1[i].competencies[j][1].value<=6)
                    {
                        val3++
                    }
                    else if(dataArray1[i].competencies[j][1].value>6 && dataArray1[i].competencies[j][1].value<=8)
                    {
                        val4++
                    }
                    else {
                        val5++
                    }
                }
            }
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(total===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = Math.round(((val1/total)*100)*10)/10 
    data[0]['countOfUsers'] = val1
    data[1]["% Of Learners"] = Math.round(((val2/total)*100)*10)/10 
    data[1]['countOfUsers'] = val2
    data[2]["% Of Learners"] = Math.round(((val3/total)*100)*10)/10 
    data[2]['countOfUsers'] = val3
    data[3]["% Of Learners"] = Math.round(((val4/total)*100)*10)/10 
    data[3]['countOfUsers'] = val4
    data[4]["% Of Learners"] = Math.round(((val5/total)*100)*10)/10 
    data[4]['countOfUsers'] = val5
    initialState.distributionsCQ.dataForBarGraph1.data = data
    return initialState.distributionsCQ.dataForBarGraph1
}

const calculateMostFrequent = (dataArray) => {
    let array = []
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray[i].scores.frequentlyusedaction!=null)
            {
                let j=array.findIndex(item => item.action == dataArray[i].scores.frequentlyusedaction)
                if(j!=-1)
                {
                    array[j].count = array[j].count + 1
                }
                else {
                    array.push({'action': dataArray[i].scores.frequentlyusedaction, 'count':1})
                }
            }
        }
    }

    if(array.length == 0)
        return "";
    array.sort((a,b) => {
        return b.count - a.count
    })
    return array[0].action
}

const calculateLeastFrequent = (dataArray) => {
    let array = []
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray[i].scores.leastUsedAction!=null)
            {
                let j=array.findIndex(item => item.action == dataArray[i].scores.leastUsedAction)
                if(j!=-1)
                {
                    array[j].count = array[j].count + 1
                }
                else {
                    array.push({'action': dataArray[i].scores.leastUsedAction, 'count':1})
                }
            }
        }
    }

    if(array.length == 0)
        return "";
    array.sort((a,b) => {
        return a.count - b.count
    })
    return array[0].action
}

const calculateInfussionCurveCQ = (dataArray) => {
    let data = [
        { Type: 'Innovators',       'Stakeholders': 18, 'TimeSpent': 22},
        { Type: 'Early Adopters',   'Stakeholders': 42, 'TimeSpent': 50},
        { Type: 'Early Majority',   'Stakeholders': 80, 'TimeSpent': 80},
        { Type: 'Late Majority',    'Stakeholders': 30, 'TimeSpent': 58},
        { Type: 'Laggards',         'Stakeholders': 30, 'TimeSpent': 36},
    ]

    let innovator = {time: 0, percent: 0}
    let earlyAdopter = {time: 0, percent: 0}
    let earlyMajority = {time: 0, percent: 0}
    let lateMajority = {time: 0, percent: 0}
    let laggards = {time: 0, percent: 0}

    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            innovator.time = innovator.time + dataArray[i].scores.innovatorstimespentoverall
            innovator.percent = innovator.percent + dataArray[i].scores.innovatorsactorspercentage
            earlyAdopter.time = earlyAdopter.time + dataArray[i].scores.earlyadopterstimespentoverall
            earlyAdopter.percent = earlyAdopter.percent + dataArray[i].scores.earlyadoptersactorspercentage
            earlyMajority.time = earlyMajority.time + dataArray[i].scores.earlymajoritytimespentoverall
            earlyMajority.percent = earlyMajority.percent + dataArray[i].scores.earlymajorityactorspercentage
            lateMajority.time = lateMajority.time + dataArray[i].scores.latemajoritytimespentoverall
            lateMajority.percent = lateMajority.percent + dataArray[i].scores.latemajorityactorspercentage
            laggards.time = laggards.time + dataArray[i].scores.laggardstimespentoverall
            laggards.percent = laggards.percent + dataArray[i].scores.laggardsactorspercentage
        }
    }

    let total = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + 1
    }
    if(total==0)
    {
        total=1
    }

    data[0].Stakeholders = Math.round(((innovator.percent/total)*10))/10 
    data[0].TimeSpent = Math.round(((innovator.time/total)*10))/10 
    data[1].Stakeholders = Math.round(((earlyAdopter.percent/total)*10))/10 
    data[1].TimeSpent = Math.round(((earlyAdopter.time/total)*10))/10
    data[2].Stakeholders = Math.round(((earlyMajority.percent/total)*10))/10 
    data[2].TimeSpent = Math.round(((earlyMajority.time/total)*10))/10 
    data[3].Stakeholders = Math.round(((lateMajority.percent/total)*10))/10 
    data[3].TimeSpent = Math.round(((lateMajority.time/total)*10))/10 
    data[4].Stakeholders = Math.round(((laggards.percent/total)*10))/10 
    data[4].TimeSpent = Math.round(((laggards.time/total)*10))/10 

    initialState.diffusionCQ.dataForBarWithLineChart.data = data
    return initialState.diffusionCQ.dataForBarWithLineChart
}

const calulateAvgNumber = (ss) => {
    if(ss==="Too Early" || ss==="optimal")
    {
        return 4
    }
    else if(ss==="Early" || ss==="high")
    {
        return 3
    }
    else if(ss==="Late" || ss==="low")
    {
        return 2
    }
    else {
        return 1
    }
}

const calculateStringForStakeholders = (val) => {
    if(val===4)
    {
        return "too early"
    }
    else if(val===3)
    {
        return "early"
    }
    else if(val===2)
    {
        return "late"
    }
    else {
        return "too late"
    }
}

const calculateStringFrequencyStakeholders = (val) => {
    if(val===4) {
        return "optimal"
    }
    else if(val===3) {
        return "high"
    }
    else if(val===2) {
        return "low"
    }
    else {
        return "irregular"
    }
}

const calculateTimingStakeholders = (dataArray) => {
    let val1=0, val2=0, val3=0, val4=0, val5=0
    let meetPoints = [{'innovators': ['too late','early']}, {'early adopters' : ['too early','early']}, {'early majority': ['too early','late']}, {'late majority': ['early','late']}, {'laggards' :['too early','late']}]
    if(dataArray.length==0)
    {
        meetPoints = [{'innovators': ['','early']}, {'early adopters' : ['','early']}, {'early majority': ['','late']}, {'late majority': ['','late']}, {'laggards' :['','late']}]
        initialState.stakeholdersCQ.timingofstakeholderinteractionsProps.meetPoints = meetPoints
        return initialState.stakeholdersCQ.timingofstakeholderinteractionsProps
    }
    for(let i=0 ; i<dataArray.length ; i++) 
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            val1 = val1 + calulateAvgNumber(dataArray[i].scores.innovatorstiming) 
            val2 = val2 + calulateAvgNumber(dataArray[i].scores.earlyadopterstiming)
            val3 = val3 + calulateAvgNumber(dataArray[i].scores.earlymajoritytiming)
            val4 = val4 + calulateAvgNumber(dataArray[i].scores.latemajoritytiming)
            val5 = val5 + calulateAvgNumber(dataArray[i].scores.laggardstiming)
        }
    }
    let total = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + 1
    }
    if(total==0)
    {
        meetPoints = [{'innovators': ['','early']}, {'early adopters' : ['','early']}, {'early majority': ['','late']}, {'late majority': ['','late']}, {'laggards' :['','late']}]
        initialState.stakeholdersCQ.timingofstakeholderinteractionsProps.meetPoints = meetPoints
        return initialState.stakeholdersCQ.timingofstakeholderinteractionsProps
    }
    let s1 = calculateStringForStakeholders(Math.round(val1/total))
    let s2 = calculateStringForStakeholders(Math.round(val2/total))
    let s3 = calculateStringForStakeholders(Math.round(val3/total))
    let s4 = calculateStringForStakeholders(Math.round(val4/total))
    let s5 = calculateStringForStakeholders(Math.round(val5/total))
    meetPoints[0].innovators[0] = s1
    meetPoints[1]["early adopters"][0] = s2
    meetPoints[2]["early majority"][0] = s3
    meetPoints[3]["late majority"][0] = s4
    meetPoints[4].laggards[0] = s5
    initialState.stakeholdersCQ.timingofstakeholderinteractionsProps.meetPoints = meetPoints
    return initialState.stakeholdersCQ.timingofstakeholderinteractionsProps
}

const calculateFrequencyStakeholders = (dataArray) => {
    let val1=0, val2=0, val3=0, val4=0, val5=0
    let meetPoints = [{'innovators': ['irregular','optimal']}, {'early adopters' : ['high','high']}, {'early majority': ['high','high']}, {'late majority': ['high','optimal']}, {'laggards' :['high','low']}]
    
    if(dataArray.length==0)
    {
        meetPoints = [{'innovators': ['','optimal']}, {'early adopters' : ['','high']}, {'early majority': ['','high']}, {'late majority': ['','optimal']}, {'laggards' :['','low']}]
        initialState.stakeholdersCQ.frequencyofstakeholderinteractionsProps.meetPoints = meetPoints
        return initialState.stakeholdersCQ.frequencyofstakeholderinteractionsProps
    }

    for(let i=0 ; i<dataArray.length ; i++) 
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            val1 = val1 + calulateAvgNumber(dataArray[i].scores.innovatorsfrequency) 
            val2 = val2 + calulateAvgNumber(dataArray[i].scores.earlyadoptersfrequency)
            val3 = val3 + calulateAvgNumber(dataArray[i].scores.earlymajorityfrequency)
            val4 = val4 + calulateAvgNumber(dataArray[i].scores.latemajorityfrequency)
            val5 = val5 + calulateAvgNumber(dataArray[i].scores.laggardsfrequency)
        }
    }
    let total = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if( dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + 1
    }
    if(total==0)
    {
        meetPoints = [{'innovators': ['','optimal']}, {'early adopters' : ['','high']}, {'early majority': ['','high']}, {'late majority': ['','optimal']}, {'laggards' :['','low']}]
        initialState.stakeholdersCQ.frequencyofstakeholderinteractionsProps.meetPoints = meetPoints
        return initialState.stakeholdersCQ.frequencyofstakeholderinteractionsProps
    }
    let s1 = calculateStringFrequencyStakeholders(Math.round(val1/total))
    let s2 = calculateStringFrequencyStakeholders(Math.round(val2/total))
    let s3 = calculateStringFrequencyStakeholders(Math.round(val3/total))
    let s4 = calculateStringFrequencyStakeholders(Math.round(val4/total))
    let s5 = calculateStringFrequencyStakeholders(Math.round(val5/total))
    meetPoints[0].innovators[0] = s1
    meetPoints[1]["early adopters"][0] = s2
    meetPoints[2]["early majority"][0] = s3
    meetPoints[3]["late majority"][0] = s4
    meetPoints[4].laggards[0] = s5
    initialState.stakeholdersCQ.frequencyofstakeholderinteractionsProps.meetPoints = meetPoints
    return initialState.stakeholdersCQ.frequencyofstakeholderinteractionsProps
}

const calculateGroupAvgProgressBarIL = (obj) => {
    let total = obj.aggregate.avg.abilitytoupskillcompetencyscore + obj.aggregate.avg.abilitytomotivatecompetencyscore + obj.aggregate.avg.contextualleadershipcompetencyscore + obj.aggregate.avg.enablingperformancecompetencyscore + obj.aggregate.avg.driveforresultcompetencyscore
    total = (total/5)
    initialState.competencyScoresIL.dataForHorizontalProgressBar1.score = Math.round((total*10))/10 
    return initialState.competencyScoresIL.dataForHorizontalProgressBar1
}

const calculateBenchmarkAvgProgressBarIL = (obj) => {
    let total = obj.aggregate.avg.abilitytoupskillcompetencyscore + obj.aggregate.avg.abilitytomotivatecompetencyscore + obj.aggregate.avg.contextualleadershipcompetencyscore + obj.aggregate.avg.enablingperformancecompetencyscore + obj.aggregate.avg.driveforresultcompetencyscore
    total = (total/5)
    initialState.competencyScoresIL.dataForHorizontalProgressBar2.score = Math.round((total*10))/10 
    return initialState.competencyScoresIL.dataForHorizontalProgressBar2
}

const calculateGroupHorizontalIL = (obj1, obj2) => {
    let data = [
        { Title: 'Ability To Upskill', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Ability To Motivate', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Contextual Leadership', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Enabling Performance', Group: 'Proficient', Benchmark: 'Proficient' },
        { Title: 'Drive for Results', Group: 'Competent', Benchmark: 'Proficient' },
        ]
    data[0].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.abilitytoupskillcompetencyscore)
    data[0].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.abilitytoupskillcompetencyscore)
    data[1].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.abilitytomotivatecompetencyscore)
    data[1].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.abilitytomotivatecompetencyscore)
    data[2].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.contextualleadershipcompetencyscore)
    data[2].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.contextualleadershipcompetencyscore)
    data[3].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.enablingperformancecompetencyscore)
    data[3].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.enablingperformancecompetencyscore)
    data[4].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.driveforresultcompetencyscore)
    data[4].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.driveforresultcompetencyscore)
    
    initialState.competencyScoresIL.dataForGroupHorizontalBar.data = data
    return initialState.competencyScoresIL.dataForGroupHorizontalBar
}

const calculateDistributionsUpskillIL = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].abilitytoupskillcompetencyscore>=0 && dataArray[i].abilitytoupskillcompetencyscore<=2)
        {
            val1++
        }
        else if(dataArray[i].abilitytoupskillcompetencyscore>2 && dataArray[i].abilitytoupskillcompetencyscore<=4)
        {
            val2++
        }
        else if(dataArray[i].abilitytoupskillcompetencyscore>4 && dataArray[i].abilitytoupskillcompetencyscore<=6)
        {
            val3++
        }
        else if(dataArray[i].abilitytoupskillcompetencyscore>6 && dataArray[i].abilitytoupskillcompetencyscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsIL.dataForBarGraphUpskill.data = data
    return initialState.distributionsIL.dataForBarGraphUpskill
}

const calculateDistributionsMotivateIL = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].abilitytomotivatecompetencyscore>=0 && dataArray[i].abilitytomotivatecompetencyscore<=2)
        {
            val1++
        }
        else if(dataArray[i].abilitytomotivatecompetencyscore>2 && dataArray[i].abilitytomotivatecompetencyscore<=4)
        {
            val2++
        }
        else if(dataArray[i].abilitytomotivatecompetencyscore>4 && dataArray[i].abilitytomotivatecompetencyscore<=6)
        {
            val3++
        }
        else if(dataArray[i].abilitytomotivatecompetencyscore>6 && dataArray[i].abilitytomotivatecompetencyscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsIL.dataForBarGraphMotivate.data = data
    return initialState.distributionsIL.dataForBarGraphMotivate
}

const calculateDistributionsLeadershipIL = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].contextualleadershipcompetencyscore>=0 && dataArray[i].contextualleadershipcompetencyscore<=2)
        {
            val1++
        }
        else if(dataArray[i].contextualleadershipcompetencyscore>2 && dataArray[i].contextualleadershipcompetencyscore<=4)
        {
            val2++
        }
        else if(dataArray[i].contextualleadershipcompetencyscore>4 && dataArray[i].contextualleadershipcompetencyscore<=6)
        {
            val3++
        }
        else if(dataArray[i].contextualleadershipcompetencyscore>6 && dataArray[i].contextualleadershipcompetencyscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsIL.dataForBarGraphLeadership.data = data
    return initialState.distributionsIL.dataForBarGraphLeadership
}

const calculateDistributionsPerformanceIL = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].enablingperformancecompetencyscore>=0 && dataArray[i].enablingperformancecompetencyscore<=2)
        {
            val1++
        }
        else if(dataArray[i].enablingperformancecompetencyscore>2 && dataArray[i].enablingperformancecompetencyscore<=4)
        {
            val2++
        }
        else if(dataArray[i].enablingperformancecompetencyscore>4 && dataArray[i].enablingperformancecompetencyscore<=6)
        {
            val3++
        }
        else if(dataArray[i].enablingperformancecompetencyscore>6 && dataArray[i].enablingperformancecompetencyscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsIL.dataForBarGraphPerformance.data = data
    return initialState.distributionsIL.dataForBarGraphPerformance
}

const calculateDistributionsResultsIL = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].driveforresultcompetencyscore>=0 && dataArray[i].driveforresultcompetencyscore<=2)
        {
            val1++
        }
        else if(dataArray[i].driveforresultcompetencyscore>2 && dataArray[i].driveforresultcompetencyscore<=4)
        {
            val2++
        }
        else if(dataArray[i].driveforresultcompetencyscore>4 && dataArray[i].driveforresultcompetencyscore<=6)
        {
            val3++
        }
        else if(dataArray[i].driveforresultcompetencyscore>6 && dataArray[i].driveforresultcompetencyscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsIL.dataForBarGraphResults.data = data
    return initialState.distributionsIL.dataForBarGraphResults
}

const calculatePieChartIL = (obj1, obj2) => {
    initialState.distributionsIL.dataForPieChart.scoreInner = Math.round((obj1.aggregate.avg.overallleadershipadaptability*10))/10 
    initialState.distributionsIL.dataForPieChart.scoreOuter = Math.round((obj2.aggregate.avg.overallleadershipadaptability*10))/10
    return initialState.distributionsIL.dataForPieChart
}

const calculateAchievedTarget = (dataArray) => {
    if(dataArray.length===0)
    {
        return 0
    }
    let count=0
    for(let i=0 ; i<dataArray.length ; i++) 
    {
        if(dataArray[i].actualrevenue > dataArray[i].targetrevenue)
        {
            count++
        }
    }
    let percent = (count/dataArray.length)*100
    return Math.round((percent*10))/10
}

const calculateLeadershipConsistencyIL = (obj) => {
    let data = [
        { exp: 'Desired Vs. Actual', '% Of Deviation': 5,  },
        { exp: 'Desired Vs. Intent', '% Of Deviation': 13,  },
        { exp: 'Intent Vs. Actual', '% Of Deviation': 40,  },
    ]
    data[0]["% Of Deviation"] = Math.round((obj.aggregate.avg.desiredvsactualdeviation*10))/10 
    data[1]["% Of Deviation"] = Math.round((obj.aggregate.avg.desiredvsintentdeviation*10))/10 
    data[2]["% Of Deviation"] = Math.round((obj.aggregate.avg.intentvsactualdeviation*10))/10 
    
    initialState.leadershipIL.dataForBarGraph2.data = data
    return initialState.leadershipIL.dataForBarGraph2
}

const calculateTimeSpentIL = (obj) => {
    let data = [
        { exp: 'Bottom Performers', '% of Time Spent': 5, years: '(1,2)' },
        { exp: 'Average Performers', '% of Time Spent': 13, years: '(3,4)' },
        { exp: 'Top Performers', '% of Time Spent': 40, years: '(5,6)' }
    ]
    data[0]["% of Time Spent"] = Math.round((obj.aggregate.avg.bottomperformertimespentpercentage*10))/10 
    data[1]["% of Time Spent"] = Math.round((obj.aggregate.avg.averageperformertimespentpercentage*10))/10 
    data[2]["% of Time Spent"] = Math.round((obj.aggregate.avg.topperformertimespentpercentage*10))/10 

    initialState.leadershipIL.dataForBarGraph3.data = data
    return initialState.leadershipIL.dataForBarGraph3
}

const calculateGroupAvgProgressBarTS = (obj) => {
    let total = obj.aggregate.avg.buildingtrustscore + obj.aggregate.avg.leveragingnetworksscore + obj.aggregate.avg.maintainingsustainablerelationshipsscore + obj.aggregate.avg.managingstakeholdersscore
    total = (total/4)
    initialState.competencyScoresTS.dataForHorizontalProgressBar1.score = Math.round((total*10))/10 
    return initialState.competencyScoresTS.dataForHorizontalProgressBar1
}

const calculateBenchmarkAvgProgressBarTS = (obj) => {
    let total = obj.aggregate.avg.buildingtrustscore + obj.aggregate.avg.leveragingnetworksscore + obj.aggregate.avg.maintainingsustainablerelationshipsscore + obj.aggregate.avg.managingstakeholdersscore
    total = (total/4)
    initialState.competencyScoresTS.dataForHorizontalProgressBar2.score = Math.round((total*10))/10 
    return initialState.competencyScoresTS.dataForHorizontalProgressBar2
}

const calculateGroupHorizontalTS = (obj1, obj2) => {
    let data = [
        { Title: 'Building Trust', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Leveraging Networks', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Sustainable Relationships', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Managing Stakeholders', Group: 'Proficient', Benchmark: 'Proficient' }
        ]
    data[0].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.buildingtrustscore)
    data[0].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.buildingtrustscore)
    data[1].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.leveragingnetworksscore)
    data[1].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.leveragingnetworksscore)
    data[2].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.maintainingsustainablerelationshipsscore)
    data[2].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.maintainingsustainablerelationshipsscore)
    data[3].Group = calculateNameGroupHorizontal(obj1.aggregate.avg.managingstakeholdersscore)
    data[3].Benchmark = calculateNameGroupHorizontal(obj2.aggregate.avg.managingstakeholdersscore)
    
    initialState.competencyScoresTS.dataForGroupHorizontalBar.data = data
    return initialState.competencyScoresTS.dataForGroupHorizontalBar
}

const calculateTrustScoreTS = (obj1, obj2) => {
    initialState.competencyScoresTS.dataForPieChart1.scoreInner = Math.round((obj1.aggregate.avg.trustscore*10))/10 
    initialState.competencyScoresTS.dataForPieChart1.scoreOuter = Math.round((obj2.aggregate.avg.trustscore*10))/10 
    return initialState.competencyScoresTS.dataForPieChart1
}

const calculateDistributionsTrustTS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].buildingtrustscore>=0 && dataArray[i].buildingtrustscore<=2)
        {
            val1++
        }
        else if(dataArray[i].buildingtrustscore>2 && dataArray[i].buildingtrustscore<=4)
        {
            val2++
        }
        else if(dataArray[i].buildingtrustscore>4 && dataArray[i].buildingtrustscore<=6)
        {
            val3++
        }
        else if(dataArray[i].buildingtrustscore>6 && dataArray[i].buildingtrustscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsTS.dataForBarGraphTrust.data = data
    return initialState.distributionsTS.dataForBarGraphTrust
}

const calculateDistributionsNetworksTS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].leveragingnetworksscore>=0 && dataArray[i].leveragingnetworksscore<=2)
        {
            val1++
        }
        else if(dataArray[i].leveragingnetworksscore>2 && dataArray[i].leveragingnetworksscore<=4)
        {
            val2++
        }
        else if(dataArray[i].leveragingnetworksscore>4 && dataArray[i].leveragingnetworksscore<=6)
        {
            val3++
        }
        else if(dataArray[i].leveragingnetworksscore>6 && dataArray[i].leveragingnetworksscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsTS.dataForBarGraphNetworks.data = data
    return initialState.distributionsTS.dataForBarGraphNetworks
}

const calculateDistributionsRelationshipsTS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].maintainingsustainablerelationshipsscore>=0 && dataArray[i].maintainingsustainablerelationshipsscore<=2)
        {
            val1++
        }
        else if(dataArray[i].maintainingsustainablerelationshipsscore>2 && dataArray[i].maintainingsustainablerelationshipsscore<=4)
        {
            val2++
        }
        else if(dataArray[i].maintainingsustainablerelationshipsscore>4 && dataArray[i].maintainingsustainablerelationshipsscore<=6)
        {
            val3++
        }
        else if(dataArray[i].maintainingsustainablerelationshipsscore>6 && dataArray[i].maintainingsustainablerelationshipsscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsTS.dataForBarGraphRelationships.data = data
    return initialState.distributionsTS.dataForBarGraphRelationships
}

const calculateDistributionsStakeholdersTS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].managingstakeholdersscore>=0 && dataArray[i].managingstakeholdersscore<=2)
        {
            val1++
        }
        else if(dataArray[i].managingstakeholdersscore>2 && dataArray[i].managingstakeholdersscore<=4)
        {
            val2++
        }
        else if(dataArray[i].managingstakeholdersscore>4 && dataArray[i].managingstakeholdersscore<=6)
        {
            val3++
        }
        else if(dataArray[i].managingstakeholdersscore>6 && dataArray[i].managingstakeholdersscore<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsTS.dataForBarGraphStakeholders.data = data
    return initialState.distributionsTS.dataForBarGraphStakeholders
}

const calculateTargetStatusTS = (dataArray) => {
    let data = [
        {
            'Achieved': 65, 'Unachieved': 35
        }
    ] 
    let val1=0, val2=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].dealsstatus==='won')
        {
            val1++
        }
    }
    let total
    if(dataArray.length==0)
    {
        total=1
    }
    else {
        total = dataArray.length
    }
    val1 = (val1/total)*100
    val2 = 100 - val1

    data[0].Achieved = Math.round((val1*10))/10
    data[0].Unachieved = Math.round((val2*10))/10

    initialState.distributionsTS.dataForPieChartFilled.data = data
    return initialState.distributionsTS.dataForPieChartFilled
}

const calculateAccuraciesTS = (obj1, obj2) => {
    let data = [
        { Type: 'REPS ',                 'Group': 60, 'Benchmark': 90},
        { Type: 'CRIS',     'Group': 58, 'Benchmark': 72},
    ]
    data[0].Group = Math.round((obj1.aggregate.avg.repsscore*10))/10 
    data[0].Benchmark = Math.round((obj2.aggregate.avg.repsscore*10))/10 
    data[1].Group = Math.round((obj1.aggregate.avg.crisscore*10))/10 
    data[1].Benchmark = Math.round((obj2.aggregate.avg.crisscore*10))/10 

    initialState.accuraciesTS.dataForBarBenchmarkGroup1.data = data
    return initialState.accuraciesTS.dataForBarBenchmarkGroup1
}

const calculateNetworkUncoveredTS = (obj1, obj2) => {
    initialState.accuraciesTS.dataForPieChart2.scoreInner = Math.round((obj1.aggregate.avg.networksuncovered*10))/10
    initialState.accuraciesTS.dataForPieChart2.scoreOuter = Math.round((obj2.aggregate.avg.networksuncovered*10))/10
    return initialState.accuraciesTS.dataForPieChart2
}

const calculateRelationshipsTS = (obj) => {
    let data = [
        { Type: 'R', 'Frequency': 60, 'Accuracy': 80},
        { Type: 'E', 'Frequency': 60, 'Accuracy': 80},
        { Type: 'S', 'Frequency': 60, 'Accuracy': 80},
        { Type: 'P', 'Frequency': 60, 'Accuracy': 80},
    ]

    data[0].Frequency = Math.round((obj.aggregate.avg.relaterfrequency*10))/10
    data[0].Accuracy = Math.round((obj.aggregate.avg.relateraccuracy*10))/10 
    data[1].Frequency = Math.round(( obj.aggregate.avg.executorfrequency*10))/10
    data[1].Accuracy = Math.round((obj.aggregate.avg.executoraccuracy*10))/10 
    data[2].Frequency = Math.round((obj.aggregate.avg.scholarfrequency*10))/10 
    data[2].Accuracy = Math.round((obj.aggregate.avg.scholaraccuracy*10))/10 
    data[3].Frequency = Math.round((obj.aggregate.avg.promoterfrequency*10))/10 
    data[3].Accuracy = Math.round((obj.aggregate.avg.promoteraccuracy*10))/10 

    initialState.accuraciesTS.dataForBarFrequencyAccuracy.data = data
    return initialState.accuraciesTS.dataForBarFrequencyAccuracy
}

const calculateGroupAvgProgressBarCS = (obj) => {
    let total = (obj.aggregate.avg.competencytrustbuildingscore/10) + (obj.aggregate.avg.competencypowerfulquestioningscore/10) + (obj.aggregate.avg.competencyaccountabilityscore/10) + (obj.aggregate.avg.competencycommunicationscore/10)
    total = (total/4)
    initialState.competencyScoresCS.dataForHorizontalProgressBar1.score = Math.round((total*10))/10 
    return initialState.competencyScoresCS.dataForHorizontalProgressBar1
}

const calculateBenchmarkAvgProgressBarCS = (obj) => {
    let total = (obj.aggregate.avg.competencytrustbuildingscore/10) + (obj.aggregate.avg.competencypowerfulquestioningscore/10) + (obj.aggregate.avg.competencyaccountabilityscore/10) + (obj.aggregate.avg.competencycommunicationscore/10)
    total = (total/4)
    initialState.competencyScoresCS.dataForHorizontalProgressBar2.score = Math.round((total*10))/10 
    return initialState.competencyScoresCS.dataForHorizontalProgressBar2
}

const calculateGroupHorizontalCS = (obj1, obj2) => {
    let data = [
        { Title: 'Building Trust', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Powerful Questioning', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Accountability', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Communications', Group: 'Proficient', Benchmark: 'Proficient' }
        ]
    data[0].Group = calculateNameGroupHorizontal((obj1.aggregate.avg.competencytrustbuildingscore/10))
    data[0].Benchmark = calculateNameGroupHorizontal((obj2.aggregate.avg.competencytrustbuildingscore/10))
    data[1].Group = calculateNameGroupHorizontal((obj1.aggregate.avg.competencypowerfulquestioningscore/10))
    data[1].Benchmark = calculateNameGroupHorizontal((obj2.aggregate.avg.competencypowerfulquestioningscore/10))
    data[2].Group = calculateNameGroupHorizontal((obj1.aggregate.avg.competencyaccountabilityscore/10))
    data[2].Benchmark = calculateNameGroupHorizontal((obj2.aggregate.avg.competencyaccountabilityscore/10))
    data[3].Group = calculateNameGroupHorizontal((obj1.aggregate.avg.competencycommunicationscore/10))
    data[3].Benchmark = calculateNameGroupHorizontal((obj2.aggregate.avg.competencycommunicationscore/10))
    
    initialState.competencyScoresCS.dataForGroupHorizontalBar.data = data
    return initialState.competencyScoresCS.dataForGroupHorizontalBar
}

const calculateAchievedTargetCS = (dataArray) => {
    if(dataArray.length===0)
    {
        return 0
    }
    let count=0
    for(let i=0 ; i<dataArray.length ; i++) 
    {
        if(dataArray[i].metricuserrevenue >= dataArray[i].metrictargetrevenue)
        {
            count++
        }
    }
    let percent = (count/dataArray.length)*100
    return Math.round((percent*10))/10
}

const calculateDistributionsTrustCS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if((dataArray[i].competencytrustbuildingscore/10)>=0 && (dataArray[i].competencytrustbuildingscore/10)<=2)
        {
            val1++
        }
        else if((dataArray[i].competencytrustbuildingscore/10)>2 && (dataArray[i].competencytrustbuildingscore/10)<=4)
        {
            val2++
        }
        else if((dataArray[i].competencytrustbuildingscore/10)>4 && (dataArray[i].competencytrustbuildingscore/10)<=6)
        {
            val3++
        }
        else if((dataArray[i].competencytrustbuildingscore/10)>6 && (dataArray[i].competencytrustbuildingscore/10)<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsCS.dataForBarGraphTrust.data = data
    return initialState.distributionsCS.dataForBarGraphTrust
}

const calculateDistributionsQuestioningCS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if((dataArray[i].competencypowerfulquestioningscore/10)>=0 && (dataArray[i].competencypowerfulquestioningscore/10)<=2)
        {
            val1++
        }
        else if((dataArray[i].competencypowerfulquestioningscore/10)>2 && (dataArray[i].competencypowerfulquestioningscore/10)<=4)
        {
            val2++
        }
        else if((dataArray[i].competencypowerfulquestioningscore/10)>4 && (dataArray[i].competencypowerfulquestioningscore/10)<=6)
        {
            val3++
        }
        else if((dataArray[i].competencypowerfulquestioningscore/10)>6 && (dataArray[i].competencypowerfulquestioningscore/10)<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsCS.dataForBarGraphQuestioning.data = data
    return initialState.distributionsCS.dataForBarGraphQuestioning
}

const calculateDistributionsAccountabilityCS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if((dataArray[i].competencyaccountabilityscore/10)>=0 && (dataArray[i].competencyaccountabilityscore/10)<=2)
        {
            val1++
        }
        else if((dataArray[i].competencyaccountabilityscore/10)>2 && (dataArray[i].competencyaccountabilityscore/10)<=4)
        {
            val2++
        }
        else if((dataArray[i].competencyaccountabilityscore/10)>4 && (dataArray[i].competencyaccountabilityscore/10)<=6)
        {
            val3++
        }
        else if((dataArray[i].competencyaccountabilityscore/10)>6 && (dataArray[i].competencyaccountabilityscore/10)<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsCS.dataForBarGraphAccountability.data = data
    return initialState.distributionsCS.dataForBarGraphAccountability
}

const calculateDistributionsCommunicationCS = (dataArray) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)' },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)' },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)' },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)' },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)' },
    ]
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if((dataArray[i].competencycommunicationscore/10)>=0 && (dataArray[i].competencycommunicationscore/10)<=2)
        {
            val1++
        }
        else if((dataArray[i].competencycommunicationscore/10)>2 && (dataArray[i].competencycommunicationscore/10)<=4)
        {
            val2++
        }
        else if((dataArray[i].competencycommunicationscore/10)>4 && (dataArray[i].competencycommunicationscore/10)<=6)
        {
            val3++
        }
        else if((dataArray[i].competencycommunicationscore/10)>6 && (dataArray[i].competencycommunicationscore/10)<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(dataArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = (val1/total)*100
    data[1]["% Of Learners"] = (val2/total)*100
    data[2]["% Of Learners"] = (val3/total)*100
    data[3]["% Of Learners"] = (val4/total)*100
    data[4]["% Of Learners"] = (val5/total)*100
    initialState.distributionsCS.dataForBarGraphCommunications.data = data
    return initialState.distributionsCS.dataForBarGraphCommunications
}

const calculatePerformanceIndicatorCS = (obj1, obj2) => {
    let data = [
        { Type: 'Coaching Readiness',                 'Group': 60, 'Benchmark': 90},
        { Type: 'Coaching Performance',     'Group': 58, 'Benchmark': 72},
    ]
    data[0].Group = Math.round((obj1.aggregate.avg.metricaveragecoacheereadiness*10))/10 
    data[0].Benchmark = Math.round((obj2.aggregate.avg.metricaveragecoacheereadiness*10))/10 
    data[1].Group = Math.round((obj1.aggregate.avg.metricnormalisedperformance*10))/10 
    data[1].Benchmark = Math.round((obj2.aggregate.avg.metricnormalisedperformance*10))/10 

    initialState.distributionsCS.dataForBarBenchmarkGroup.data = data
    return initialState.distributionsCS.dataForBarBenchmarkGroup
}

const calculateBarGraphGrow = (obj) => {
    let data = [
        { exp: 'Goal', '% Of Deviation': 45},
        { exp: 'Reality', '% Of Deviation': 33},
        { exp: 'Options', '% Of Deviation': 60},
        { exp: 'Way', '% Of Deviation': 10}
  ]
  data[0]["% Of Deviation"] = Math.round((obj.aggregate.avg.accuracyscoregoalphase*10))/10 
  data[1]["% Of Deviation"] = Math.round((obj.aggregate.avg.accuracyscorerealityphase*10))/10 
  data[2]["% Of Deviation"] = Math.round((obj.aggregate.avg.accuracyscoreoverall*10))/10 
  data[3]["% Of Deviation"] = Math.round((obj.aggregate.avg.accuracyscorewayforwardphase*10))/10 

  initialState.growCS.dataForBarGraphGrow.data = data
  return initialState.growCS.dataForBarGraphGrow
}

const calculatePieChartCS = (obj1, obj2) => {
    initialState.growCS.dataForPieChart.scoreInner = Math.round((obj1.aggregate.avg.accuracyscoreoverall*10))/10
    initialState.growCS.dataForPieChart.scoreOuter =  Math.round((obj2.aggregate.avg.accuracyscoreoverall*10))/10

    return initialState.growCS.dataForPieChart
}

const calculateTargetRevenueCS = (obj1, obj2) => {
    let data = [
        { exp: 'Group', '$': 1500000,  },
        { exp: 'Benchmark', '$': 2000000,  }
    ]
    data[0]["$"] = Math.round((obj1.aggregate.avg.metricuserrevenue*10))/10 
    data[1]['$'] = Math.round((obj2.aggregate.avg.metricuserrevenue*10))/10 

    initialState.competencyScoresCS.dataForBarGraphTarget.data = data
    return initialState.competencyScoresCS.dataForBarGraphTarget
}

const calculateGroupAvgProgressBarAC = (array) => {
    if(array.length==0)
    {
        initialState.competencyScoresAC.dataForHorizontalProgressBar1.score = 0 
        return initialState.competencyScoresAC.dataForHorizontalProgressBar1
    }
    let resArray = []
        for(let i=0 ; i<array.length ; i++)
        {
            if(array[i].simPerformanceFilters && checkTags(array[i].simPerformanceFilters.journey_tags))
            {
                let j=resArray.findIndex(item => item.userid == array[i].user_id)
                if(j!=-1)
                {
                    resArray[j].score = resArray[j].score + array[i].overall_competency_score
                    resArray[j].count = resArray[j].count + 1
                }
                else {
                    resArray.push({'userid':  array[i].user_id, 'score': array[i].overall_competency_score, 'count':1})
                }
            }
        }
    let sumArray = []
    for(let i=0 ; i<resArray.length ; i++)
    {
        sumArray.push((resArray[i].score/resArray[i].count))
    }
    let total=0
    for(let i=0 ; i<sumArray.length ; i++)
    {
        total = total + sumArray[i]
    }
    total = total/sumArray.length
    initialState.competencyScoresAC.dataForHorizontalProgressBar1.score = Math.round((total*10))/10 
    return initialState.competencyScoresAC.dataForHorizontalProgressBar1
}

const calculateBenchmarkAvgProgressBarAC = (array) => {
    if(array.length==0)
    {
        initialState.competencyScoresAC.dataForHorizontalProgressBar2.score = 0 
        return initialState.competencyScoresAC.dataForHorizontalProgressBar2
    }
    let resArray = []
        for(let i=0 ; i<array.length ; i++)
        {
            if(array[i].simPerformanceFilters && checkTags(array[i].simPerformanceFilters.journey_tags)) {
                let j=resArray.findIndex(item => item.userid == array[i].user_id)
                if(j!=-1)
                {
                    resArray[j].score = resArray[j].score + array[i].overall_competency_score
                    resArray[j].count = resArray[j].count + 1
                }
                else {
                    resArray.push({'userid':  array[i].user_id, 'score': array[i].overall_competency_score, 'count':1})
                }
            }
        }
    let sumArray = []
    for(let i=0 ; i<resArray.length ; i++)
    {
        sumArray.push((resArray[i].score/resArray[i].count))
    }
    let total=0
    for(let i=0 ; i<sumArray.length ; i++)
    {
        total = total + sumArray[i]
    }
    total = total/sumArray.length
    initialState.competencyScoresAC.dataForHorizontalProgressBar2.score = Math.round((total*10))/10 
    return initialState.competencyScoresAC.dataForHorizontalProgressBar2
}

const calculateDistributionsAC = (array) => {
    let resArray = []
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)',  'countOfUsers': 0 },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)', 'countOfUsers': 0 },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)', 'countOfUsers': 0 },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)', 'countOfUsers': 0 },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)', 'countOfUsers': 0 },
    ]
        for(let i=0 ; i<array.length ; i++)
        {
            if(array[i].simPerformanceFilters && checkTags(array[i].simPerformanceFilters.journey_tags))
            {
                let j=resArray.findIndex(item => item.userid == array[i].user_id)
                if(j!=-1)
                {
                    resArray[j].score = resArray[j].score + array[i].overall_competency_score
                    resArray[j].count = resArray[j].count + 1
                }
                else {
                    resArray.push({'userid':  array[i].user_id, 'score': array[i].overall_competency_score, 'count':1})
                }
            }
        }
    for(let i=0 ; i<resArray.length ; i++)
    {
        resArray[i].score = resArray[i].score/resArray[i].count
    }   
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<resArray.length ; i++)
    {
        if(resArray[i].score>=0 && resArray[i].score<=2)
        {
            val1++
        }
        else if(resArray[i].score>2 && resArray[i].score<=4)
        {
            val2++
        }
        else if(resArray[i].score>4 && resArray[i].score<=6)
        {
            val3++
        }
        else if(resArray[i].score>6 && resArray[i].score<=8)
        {
            val4++
        }
        else {
            val5++
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(resArray.length===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = Math.round(((val1/total)*100)*10)/10 
    data[0]['countOfUsers'] = val1
    data[1]["% Of Learners"] = Math.round(((val2/total)*100)*10)/10 
    data[1]['countOfUsers'] = val2
    data[2]["% Of Learners"] = Math.round(((val3/total)*100)*10)/10 
    data[2]['countOfUsers'] = val3
    data[3]["% Of Learners"] = Math.round(((val4/total)*100)*10)/10 
    data[3]['countOfUsers'] = val4
    data[4]["% Of Learners"] = Math.round(((val5/total)*100)*10)/10 
    data[4]['countOfUsers'] = val5
    initialState.distributionsAC.dataForBarGraph1.data = data
    return initialState.distributionsAC.dataForBarGraph1
}

const removeEscape = (str) => {
    
    let parts = str.split('.')
    console.log(parts)
    let newStr = ""
    for(let i=0 ; i<parts.length-1 ; i++)
    {
        for(let j=0 ; j<parts[i].length-1 ; j++)
        {
            newStr = newStr + parts[i][j]
        }
        newStr = newStr + '.'
    }
    newStr = newStr + parts[parts.length-1]

    return newStr
}

const calculateTopPerformersAC = (array) => {
    let resArray = []
    for(let i=0 ; i<array.length ; i++)
    {
        if(array[i].simPerformanceFilters && checkTags(array[i].simPerformanceFilters.journey_tags)){
            let j=resArray.findIndex(item => item.userId == array[i].user_id)
            if(j!=-1)
            {
                resArray[j].score = resArray[j].score + array[i].overall_competency_score
                resArray[j].count = resArray[j].count + 1
            }
            else {
                resArray.push({'userId':  array[i].user_id, 'score': array[i].overall_competency_score, 'count':1, 'email': removeEscape(array[i].user_email), 'name': `${array[i].simPerformanceFilterUid.user_firstname} ${array[i].simPerformanceFilterUid.user_lastname}`})
            }
        }
    }
    for(let i=0 ; i<resArray.length ; i++)
    {
        resArray[i].score = Math.round((resArray[i].score/resArray[i].count*10))/10
    }
    resArray.sort((a, b) => {
        return b.score - a.score;
    });
    let totalUsers = resArray.length
    if(totalUsers>30)
    {
        totalUsers=30
    }
    let finalArray = []
    for(let i=0 ; i<totalUsers ; i++)
    {   
        finalArray[i] = resArray[i]
    }
    return finalArray
}

const calculateTotalCompetenciesAC = (array) => {
    let resArray = []
    for(let i=0 ; i<array.length ; i++)
    {
        if(array[i].simPerformanceFilters && checkTags(array[i].simPerformanceFilters.journey_tags)) {
            let j=resArray.findIndex(item => item.userid == array[i].user_id)
            if(j!=-1)
            {
                resArray[j].competencies.push(array[i].competencies)
            }
            else {
                resArray.push({'userid':  array[i].user_id, 'competencies': [array[i].competencies]})
            }
        }
    }
    
    let finalArray = []
    for(let i=0 ; i<resArray.length ; i++)
    {
        let compArray = []
        let keysArray = []
        for(let j=0 ; j<resArray[i].competencies.length ; j++)
        {
            //console.log(Object.keys(resArray[i].competencies[j]))
            keysArray = Object.keys(resArray[i].competencies[j])
            for(let l=0 ; l<keysArray.length ; l++)
            {
                let k = compArray.findIndex(item => item.compName == keysArray[l])
                if(k!=-1)
                {
                    compArray[k].score = compArray[k].score + resArray[i].competencies[j][keysArray[l]].value
                    compArray[k].count = compArray[k].count + 1
                }
                else {
                    compArray.push({'compName': keysArray[l], 'score':resArray[i].competencies[j][keysArray[l]].value , 'count': 1})
                }
            }
            /*let k = compArray.findIndex(item => item.compName == (Object.keys(resArray[i].competencies[j])[0]))
            if(k!=-1)
            {
                compArray[k].score = compArray[k].score + resArray[i].competencies[j][Object.keys(resArray[i].competencies[j])[0]].value
                compArray[k].count = compArray[k].count + 1
            }
            else {
                compArray.push({'compName': (Object.keys(resArray[i].competencies[j])[0]), 'score':resArray[i].competencies[j][Object.keys(resArray[i].competencies[j])[0]].value , 'count': 1})
            }*/
        }
        resArray[i].competencies = compArray
        
    }
    
    for(let i=0 ; i<resArray.length ; i++)
    {
        let compArray = []
        for(let j=0 ; j<resArray[i].competencies.length; j++)
        {
            resArray[i].competencies[j].score = Math.round((resArray[i].competencies[j].score/resArray[i].competencies[j].count*10)/10)
            resArray[i].competencies[j].count = 1
        }
    }
    for(let i=0 ; i<resArray.length ; i++)
    {
        let compArray = []
        for(let j=0 ; j<resArray[i].competencies.length; j++)
        {
            let k = finalArray.findIndex(item => item.compName == resArray[i].competencies[j].compName)
            if(k!=-1)
            {
                finalArray[k].score = finalArray[k].score + resArray[i].competencies[j].score
                finalArray[k].count = finalArray[k].count +  1
            }
            else {
                finalArray.push({'compName': resArray[i].competencies[j].compName, 'score': resArray[i].competencies[j].score, 'count':1})
            }
        }
    }
    for(let i=0 ; i<finalArray.length ; i++)
    {
        finalArray[i].score = Math.round((finalArray[i].score/finalArray[i].count*10))/10
        finalArray[i].count = 1 
    }
    console.log(finalArray)
    let topArray = []
    let lowArray = []
    for(let i=0 ; i<finalArray.length ; i++)
    {
        if(finalArray[i].score<5)
        {
            lowArray.push(finalArray[i])
        }
        else {
            topArray.push(finalArray[i])
        }
    }

    topArray.sort((a,b) => b.score-a.score)
    lowArray.sort((a, b) => {
        return a.score - b.score;
    });
    
    let topSize = topArray.length
    if(topSize>3)
    {
        topSize = 3
    }
    let topArrayFinal = []
    for(let i=0 ; i<topSize ; i++)
    {
        topArrayFinal.push(topArray[i])
    }
    let lowSize = lowArray.length
    if(lowSize>3)
    {
        lowSize = 3
    }
    let lowArrayFinal = []
    for(let i=0 ; i<lowSize ; i++)
    {
        lowArrayFinal.push(lowArray[i])
    }

    initialState.competenciesAC.totalCompetenciesArray[0].topCompetenciesArray = topArrayFinal
    initialState.competenciesAC.totalCompetenciesArray[1].lowCompetenciesArray = lowArrayFinal
    return initialState.competenciesAC.totalCompetenciesArray
}

const calculateGroupAvgProgressBarAS = (dataArray) => {
    let total=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            total = total + dataArray[i].overall_competency_score
        }
    }

    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            instances  = instances + 1
        }
    }

    if(instances==0)
    {
        instances = 1
    }

    total = total/instances    

    initialState.competencyScoresAS.dataForHorizontalProgressBar1.score = Math.round((total*10))/10 
    return initialState.competencyScoresAS.dataForHorizontalProgressBar1
}

const calculateBenchmarkAvgProgressBarAS = (dataArray) => {
    let total=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            total = total + dataArray[i].overall_competency_score
        }
    }

    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            instances  = instances + 1
        }
    }

    if(instances==0)
    {
        instances = 1
    }

    total = total/instances    

    initialState.competencyScoresAS.dataForHorizontalProgressBar2.score = Math.round((total*10))/10 
    return initialState.competencyScoresAS.dataForHorizontalProgressBar2
}

const calculateGroupHorizontalAS = (dataArray1, dataArray2) => {
    let data = [
        { Title: 'Customer Centricity', Group: 'Competent', Benchmark: 'Proficient' },
        { Title: 'Change Agility', Group: 'Competent', Benchmark: 'Role Model' },
        { Title: 'Collaboration', Group: 'Proficient', Benchmark: 'Competent' },
        { Title: 'Result Focus', Group: 'Proficient', Benchmark: 'Proficient' },
        ]

    if(dataArray1.length==0 && dataArray2.length==0)
    {
        data[0].Group = ' '
        data[0].Benchmark = ' '
        data[1].Group = ' '
        data[1].Benchmark = ' '
        data[2].Group = ' '
        data[2].Benchmark = ' '
        data[3].Group = ' '
        data[3].Benchmark = ' '

        initialState.competencyScoresAS.dataForGroupHorizontalBar.data = data
        return initialState.competencyScoresAS.dataForGroupHorizontalBar
    }

    for(let i=0 ; i<dataArray1.length ; i++)
    {
        let entries = Object.entries(dataArray1[i].competencies)
        //console.log(entries)
        dataArray1[i].competencies = entries
    }
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        let entries = Object.entries(dataArray2[i].competencies)
        //console.log(entries)
        dataArray2[i].competencies = entries
    }
    let customerCentricity= {group: 0, benchmark: 0}
    let changeAgility = { group: 0, benchmark: 0 }
    let collaboration = { group: 0, benchmark: 0 }
    let resultFocues = { group: 0, benchmark: 0 }
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray1[i].competencies.length ; j++)
            {
                if(dataArray1[i].competencies[j][0]=='customer_centricity')
                {
                    customerCentricity.group =  customerCentricity.group + dataArray1[i].competencies[j][1].value
                }
                else if(dataArray1[i].competencies[j][0] == 'change_agility')
                {
                    changeAgility.group =  changeAgility.group + dataArray1[i].competencies[j][1].value
                }
                else if(dataArray1[i].competencies[j][0] == 'collaboration')
                {
                    collaboration.group =  collaboration.group + dataArray1[i].competencies[j][1].value
                }
                else {
                    resultFocues.group = resultFocues.group + dataArray1[i].competencies[j][1].value
                }
            }
        }
    }
    let totalGroup = 0
    for(let i=0 ; i<dataArray1.length; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
            totalGroup = totalGroup + 1
    }
    let checkGroupCount = totalGroup
    if(totalGroup==0)
    {
        totalGroup=1
    }
    customerCentricity.group = customerCentricity.group/totalGroup
    changeAgility.group = changeAgility.group/totalGroup
    collaboration.group = collaboration.group/totalGroup
    resultFocues.group = resultFocues.group/totalGroup
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray2[i].competencies.length ; j++)
        {
            if(dataArray2[i].competencies[j][0] == 'customer_centricity')
            {
                customerCentricity.benchmark =  customerCentricity.benchmark + dataArray2[i].competencies[j][1].value
            }
            else if(dataArray2[i].competencies[j][0] == 'change_agility')
            {
                changeAgility.benchmark =  changeAgility.benchmark + dataArray2[i].competencies[j][1].value
            }
            else if(dataArray2[i].competencies[j][0] == 'collaboration')
            {
                collaboration.benchmark =  collaboration.benchmark + dataArray2[i].competencies[j][1].value
            }
            else {
                resultFocues.benchmark = resultFocues.benchmark + dataArray2[i].competencies[j][1].value
            }
        }
        }
    }

    let totalBenchmark = 0
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
            totalBenchmark = totalBenchmark + 1
    }
    if(totalBenchmark==0)
    {
        totalBenchmark = 1
    }

    customerCentricity.benchmark = customerCentricity.benchmark/totalBenchmark
    changeAgility.benchmark = changeAgility.benchmark/totalBenchmark
    collaboration.benchmark = collaboration.benchmark/totalBenchmark
    resultFocues.benchmark = resultFocues.benchmark/totalBenchmark
    
    data[0].Group = calculateNameGroupHorizontal(Math.floor(customerCentricity.group))
    data[0].Benchmark = calculateNameGroupHorizontal(Math.floor(customerCentricity.benchmark))
    data[1].Group = calculateNameGroupHorizontal(Math.floor(changeAgility.group))
    data[1].Benchmark = calculateNameGroupHorizontal(Math.floor(changeAgility.benchmark))
    data[2].Group = calculateNameGroupHorizontal(Math.floor(collaboration.group))
    data[2].Benchmark = calculateNameGroupHorizontal(Math.floor(collaboration.benchmark))
    data[3].Group = calculateNameGroupHorizontal(Math.floor(resultFocues.group))
    data[3].Benchmark = calculateNameGroupHorizontal(Math.floor(resultFocues.benchmark))

    if(dataArray1.length==0 || checkGroupCount==0)
    {
        data[0].Group = ' '
        data[1].Group = ' '
        data[2].Group = ' '
        data[3].Group = ' '
    }

    initialState.competencyScoresAS.dataForGroupHorizontalBar.data = data
    return initialState.competencyScoresAS.dataForGroupHorizontalBar
}

const calculateTargetAchievedAS = (dataArray) => {
    if(dataArray.length==0)
    {
        return 0
    }
    let total=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray[i].scores.objective_product_fit_score==100)
            {
                total = total + 1
            }
        }
    }
    let count = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            count = count + 1
        }
    }
    if(count==0)
    {
        count = 1
    }
    total = Math.round(((total/count)*100)*10)/10
    return total
}

const calculatePCFGraphAS = (dataArray) => {
    let data = [
        { exp: 'Low', '% Of Learners': 25,  },
        { exp: 'Med', '% Of Learners': 50,  },
        { exp: 'High', '% Of Learners': 80,  },
  ]
  let low=0
  let med=0
  let high=0
  if(dataArray.length==0)
  {
      data[0]['% Of Learners'] = low
      data[1]['% Of Learners'] = med
      data[2]['% Of Learners'] = high
      initialState.competencyScoresAS.dataForPCF.data = data
     return initialState.competencyScoresAS.dataForPCF
    }
  for(let i=0 ; i<dataArray.length ; i++)
  {
      if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
      {
        if(dataArray[i].scores.objective_product_fit_label=='High')
        {
            high = high + 1
        }
        else if(dataArray[i].scores.objective_product_fit_label=='Low')
        {
            low = low + 1
        }
        else {
            med = med + 1
        }
      }
  }
  let count = 0
  for(let i=0 ; i<dataArray.length ; i++)
  {
    if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        count = count + 1
  }
  high = Math.round(((high/count)*100)*10)/10 
  med = Math.round(((med/count)*100)*10)/10 
  low = Math.round(((low/count)*100)*10)/10 
  data[0]['% Of Learners'] = low
  data[1]['% Of Learners'] = med
  data[2]['% Of Learners'] = high
  initialState.competencyScoresAS.dataForPCF.data = data
  return initialState.competencyScoresAS.dataForPCF
}

const calculateCustomerCentricityAS = (dataArray1) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)',  'countOfUsers': 0 },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)', 'countOfUsers': 0 },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)', 'countOfUsers': 0 },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)', 'countOfUsers': 0 },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)', 'countOfUsers': 0 },
    ]
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        let entries = Object.entries(dataArray1[i].competencies)
        //console.log(entries)
        dataArray1[i].competencies = entries
    }
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray1[i].competencies.length ; j++)
            {
                if(dataArray1[i].competencies[j][0]=='customer_centricity')
                {
                    if((dataArray1[i].competencies[j][1].value)>=0 && (dataArray1[i].competencies[j][1].value)<=2)
                    {
                        val1++
                    }
                    else if((dataArray1[i].competencies[j][1].value)>2 && (dataArray1[i].competencies[j][1].value)<=4)
                    {
                        val2++
                    }
                    else if(dataArray1[i].competencies[j][1].value>4 && dataArray1[i].competencies[j][1].value<=6)
                    {
                        val3++
                    }
                    else if(dataArray1[i].competencies[j][1].value>6 && dataArray1[i].competencies[j][1].value<=8)
                    {
                        val4++
                    }
                    else {
                        val5++
                    }
                }
            }
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(total===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = Math.round(((val1/total)*100)*10)/10 
    data[0]['countOfUsers'] = val1
    data[1]["% Of Learners"] = Math.round(((val2/total)*100)*10)/10
    data[1]['countOfUsers'] = val2 
    data[2]["% Of Learners"] = Math.round(((val3/total)*100)*10)/10 
    data[2]['countOfUsers'] = val3
    data[3]["% Of Learners"] = Math.round(((val4/total)*100)*10)/10 
    data[3]['countOfUsers'] = val4
    data[4]["% Of Learners"] = Math.round(((val5/total)*100)*10)/10 
    data[4]['countOfUsers'] = val5
    initialState.distributionsAS.dataForBarGraphCentricity.data = data
    return initialState.distributionsAS.dataForBarGraphCentricity
}

const calculateChangeAgilityAS = (dataArray1) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)',  'countOfUsers': 0 },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)', 'countOfUsers': 0 },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)', 'countOfUsers': 0 },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)', 'countOfUsers': 0 },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)', 'countOfUsers': 0 },
    ]
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        let entries = Object.entries(dataArray1[i].competencies)
        //console.log(entries)
        dataArray1[i].competencies = entries
    }
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray1[i].competencies.length ; j++)
            {
                if(dataArray1[i].competencies[j][1][0]=='change_agility')
                {
                    if((dataArray1[i].competencies[j][1][1].value)>=0 && (dataArray1[i].competencies[j][1][1].value)<=2)
                    {
                        val1++
                    }
                    else if((dataArray1[i].competencies[j][1][1].value)>2 && (dataArray1[i].competencies[j][1][1].value)<=4)
                    {
                        val2++
                    }
                    else if(dataArray1[i].competencies[j][1][1].value>4 && dataArray1[i].competencies[j][1][1].value<=6)
                    {
                        val3++
                    }
                    else if(dataArray1[i].competencies[j][1][1].value>6 && dataArray1[i].competencies[j][1][1].value<=8)
                    {
                        val4++
                    }
                    else {
                        val5++
                    }
                }
            }
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(total===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = Math.round(((val1/total)*100)*10)/10 
    data[0]['countOfUsers'] = val1
    data[1]["% Of Learners"] = Math.round(((val2/total)*100)*10)/10
    data[1]['countOfUsers'] = val2 
    data[2]["% Of Learners"] = Math.round(((val3/total)*100)*10)/10 
    data[2]['countOfUsers'] = val3
    data[3]["% Of Learners"] = Math.round(((val4/total)*100)*10)/10 
    data[3]['countOfUsers'] = val4
    data[4]["% Of Learners"] = Math.round(((val5/total)*100)*10)/10 
    data[4]['countOfUsers'] = val5
    initialState.distributionsAS.dataForBarGraphAgility.data = data
    //console.log(initialState.distributionsAS.dataForBarGraphAgility)
    return initialState.distributionsAS.dataForBarGraphAgility
}

const calculateCollaborationAS = (dataArray1) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)',  'countOfUsers': 0 },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)', 'countOfUsers': 0 },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)', 'countOfUsers': 0 },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)', 'countOfUsers': 0 },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)', 'countOfUsers': 0 },
    ]
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        let entries = Object.entries(dataArray1[i].competencies)
        //console.log(entries)
        dataArray1[i].competencies = entries
    }
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray1[i].competencies.length ; j++)
            {
                if(dataArray1[i].competencies[j][1][1][0]=='collaboration')
                {
                    if((dataArray1[i].competencies[j][1][1][1].value)>=0 && (dataArray1[i].competencies[j][1][1][1].value)<=2)
                    {
                        val1++
                    }
                    else if((dataArray1[i].competencies[j][1][1][1].value)>2 && (dataArray1[i].competencies[j][1][1][1].value)<=4)
                    {
                        val2++
                    }
                    else if(dataArray1[i].competencies[j][1][1][1].value>4 && dataArray1[i].competencies[j][1][1][1].value<=6)
                    {
                        val3++
                    }
                    else if(dataArray1[i].competencies[j][1][1][1].value>6 && dataArray1[i].competencies[j][1][1][1].value<=8)
                    {
                        val4++
                    }
                    else {
                        val5++
                    }
                }
            }
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(total===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = Math.round(((val1/total)*100)*10)/10 
    data[0]['countOfUsers'] = val1
    data[1]["% Of Learners"] = Math.round(((val2/total)*100)*10)/10
    data[1]['countOfUsers'] = val2 
    data[2]["% Of Learners"] = Math.round(((val3/total)*100)*10)/10 
    data[2]['countOfUsers'] = val3
    data[3]["% Of Learners"] = Math.round(((val4/total)*100)*10)/10 
    data[3]['countOfUsers'] = val4
    data[4]["% Of Learners"] = Math.round(((val5/total)*100)*10)/10 
    data[4]['countOfUsers'] = val5
    initialState.distributionsAS.dataForBarGraphCollaboration.data = data
    return initialState.distributionsAS.dataForBarGraphCollaboration
}

const calculateResultFocusAS = (dataArray1) => {
    let data = [
        { exp: 'Novice', '% Of Learners': 5, years: '(1,2)',  'countOfUsers': 0 },
        { exp: 'Emerging', '% Of Learners': 13, years: '(3,4)', 'countOfUsers': 0 },
        { exp: 'Competent', '% Of Learners': 40, years: '(5,6)', 'countOfUsers': 0 },
        { exp: 'Proficient', '% Of Learners': 35, years: '(7,8)', 'countOfUsers': 0 },
        { exp: 'Role Model', '% Of Learners': 8, years: '(9,10)', 'countOfUsers': 0 },
    ]
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        let entries = Object.entries(dataArray1[i].competencies)
        //console.log(entries)
        dataArray1[i].competencies = entries
    }
    let val1=0
    let val2=0
    let val3=0
    let val4=0
    let val5=0
    
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            for(let j=0 ; j<dataArray1[i].competencies.length ; j++)
            {
                if(dataArray1[i].competencies[j][1][1][1][0]=='result_focus')
                {
                    if((dataArray1[i].competencies[j][1][1][1][1].value)>=0 && (dataArray1[i].competencies[j][1][1][1][1].value)<=2)
                    {
                        val1++
                    }
                    else if((dataArray1[i].competencies[j][1][1][1][1].value)>2 && (dataArray1[i].competencies[j][1][1][1][1].value)<=4)
                    {
                        val2++
                    }
                    else if(dataArray1[i].competencies[j][1][1][1][1].value>4 && dataArray1[i].competencies[j][1][1][1][1].value<=6)
                    {
                        val3++
                    }
                    else if(dataArray1[i].competencies[j][1][1][1][1].value>6 && dataArray1[i].competencies[j][1][1][1][1].value<=8)
                    {
                        val4++
                    }
                    else {
                        val5++
                    }
                }
            }
        }
    }
    let total = val1 + val2 + val3 + val4 + val5
    if(total===0)
    {
        total=1
    }
    data[0]["% Of Learners"] = Math.round(((val1/total)*100)*10)/10 
    data[0]['countOfUsers'] = val1
    data[1]["% Of Learners"] = Math.round(((val2/total)*100)*10)/10
    data[1]['countOfUsers'] = val2 
    data[2]["% Of Learners"] = Math.round(((val3/total)*100)*10)/10 
    data[2]['countOfUsers'] = val3
    data[3]["% Of Learners"] = Math.round(((val4/total)*100)*10)/10 
    data[3]['countOfUsers'] = val4
    data[4]["% Of Learners"] = Math.round(((val5/total)*100)*10)/10 
    data[4]['countOfUsers'] = val5
    initialState.distributionsAS.dataForBarGraphFocus.data = data
    return initialState.distributionsAS.dataForBarGraphFocus
}

const calculateEfficiencyAS = (dataArray, dataArray1) => {
    let data = [
        { Type: 'Relevance', 'Group': 80, 'Benchmark': 90},
        { Type: 'Efficiency',  'Group': 80, 'Benchmark': 80},
    ]

    let relevance = {group: 0, benchmark: 0}
    let efficiency = {group: 0, benchmark: 0}
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            relevance.group = relevance.group + dataArray[i].scores.metric_accuracy
            efficiency.group = efficiency.group + dataArray[i].scores.metric_efficiency 
        }
    }
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            relevance.benchmark = relevance.benchmark + dataArray1[i].scores.metric_accuracy
            efficiency.benchmark = efficiency.benchmark + dataArray1[i].scores.metric_efficiency
        }
    }   
    let totalGroup = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            totalGroup = totalGroup + 1
    }
    if(totalGroup==0)
    {
        totalGroup = 1
    }
    relevance.group = relevance.group/totalGroup
    efficiency.group = efficiency.group/totalGroup
    let totalBenchmark = 0
    for(let i=0 ; i<dataArray1.length; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            totalBenchmark = totalBenchmark + 1
        }
    }
    if(totalBenchmark==0)
    {
        totalBenchmark = 1
    }
    relevance.benchmark = relevance.benchmark/totalBenchmark
    efficiency.benchmark = efficiency.benchmark/totalBenchmark
    data[0].Group = Math.round(relevance.group*10)/10 
    data[0].Benchmark = Math.round(relevance.benchmark*10)/10 
    data[1].Group = Math.round(efficiency.group*10)/10 
    data[1].Benchmark = Math.round(efficiency.benchmark*10)/10 
    initialState.distributionsAS.dataForBarBenchmarkGroup1.data = data
    return initialState.distributionsAS.dataForBarBenchmarkGroup1
}

const calculateSkillsAS = (dataArray) => {
    let data = [
        { Type: 'Team Skill', Low: 50, Med: 62, High: 75 },
        { Type: 'Team Morale', Low: 80, Med: 78, High: 75 },
        { Type: 'Customer Focus', Low: 25, Med: 62, High: 75 }
    ]
    let skill = {low: 0, med: 0, high:0}
    let morale = {low: 0, med: 0, high:0}
    let customer = {low: 0, med: 0, high:0}

    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray[i].scores.metric_skill_label=='High')
            {
                skill.high++
            }
            else if(dataArray[i].scores.metric_skill_label=='Low')
            {
                skill.low++
            }
            else {
                skill.med++
            }
        }
    }
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray[i].scores.metric_morale_label=='High')
            {
                morale.high++
            }
            else if(dataArray[i].scores.metric_morale_label=='Low')
            {
                morale.low++
            }
            else {
                morale.med++
            }
        }
    }
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray[i].scores.metric_customer_focus_label=='High')
            {
                customer.high++
            }
            else if(dataArray[i].scores.metric_customer_focus_label=='Low')
            {
                customer.low++
            }
            else {
                customer.med++
            }
        }
    }
    let total = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + 1
    }
    if(total==0)
    {
        total=1
    }
    skill.low = (skill.low/total)*100
    skill.med = (skill.med/total)*100
    skill.high = (skill.high/total)*100
    morale.low = (morale.low/total)*100
    morale.med = (morale.med/total)*100
    morale.high = (morale.high/total)*100
    customer.low = (customer.low/total)*100
    customer.med = (customer.med/total)*100
    customer.high = (customer.high/total)*100
    data[0].Low = Math.round(skill.low*10)/10
    data[0].Med = Math.round(skill.med*10)/10 
    data[0].High = Math.round(skill.high*10)/10 
    data[1].Low = Math.round(morale.low*10)/10 
    data[1].Med = Math.round(morale.med*10)/10 
    data[1].High = Math.round(morale.high*10)/10 
    data[2].Low = Math.round(customer.low*10)/10 
    data[2].Med = Math.round(customer.med*10)/10 
    data[2].High = Math.round(customer.high*10)/10 

    initialState.skillAS.dataForGroupVertical.data = data
    return initialState.skillAS.dataForGroupVertical
}

const calculateVelocityAS = (dataArray, dataArray1) => {
    let data = [
        { Type: 'Velocity', 'Group': 80, 'Benchmark': 90},
    ]
    let velocity = {group: 0, benchmark: 0}
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            velocity.group = velocity.group + dataArray[i].scores.metric_velocity
    }
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
            velocity.benchmark = velocity.benchmark + dataArray1[i].scores.metric_velocity
    }
    let totalGroup = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            totalGroup = totalGroup + 1
    }
    if(totalGroup==0)
    {
        totalGroup = 1
    }
    velocity.group = velocity.group/totalGroup

    let totalBenchmark = 0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
            totalBenchmark = totalBenchmark + 1
    }
    if(totalBenchmark==0)
    {
        totalBenchmark = 1
    }
    velocity.benchmark = velocity.benchmark/totalBenchmark

    data[0].Group = Math.round(velocity.group*10)/10 
    data[0].Benchmark = Math.round(velocity.benchmark*10)/10 

    initialState.skillAS.dataForBarBenchmarkGroup2.data = data
    return initialState.skillAS.dataForBarBenchmarkGroup2
}

const calculateAdoptionCQ = (dataArray1, dataArray2) => {
    let wadoption = {group: 0, benchmark: 0}

    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            wadoption.group = wadoption.group + Number(dataArray1[i].scores.metricweightedavgadoption) 
        }
    }
    let total1 = 0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
            total1 = total1 + 1
    }
    if(total1==0)
    {
        total1=1
    }
    wadoption.group = wadoption.group/total1

    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
        {
            wadoption.benchmark = wadoption.benchmark + Number(dataArray2[i].scores.metricweightedavgadoption)
        } 
    }
    let total2 = 0
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
        {
            total2 = total2 + 1
        }
    }
    if(total2==0)
    {
        total2=1
    }
    wadoption.benchmark = wadoption.benchmark/total2

    initialState.competencyScoresCQ.dataForPieChart.scoreInner = Math.round((wadoption.group*10))/10 
    initialState.competencyScoresCQ.dataForPieChart.scoreOuter = Math.round((wadoption.benchmark*10))/10 
    return initialState.competencyScoresCQ.dataForPieChart
}

const calculateAdoptorsCQ = (dataArray1, dataArray2) => {
    let data = [
        { Title: 'Group', '% Of Users': 74 },
        { Title: 'Benchmark', '% Of Users': 90 },
    ]
    let adoption = { group: 0, benchmark: 0 }
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray1[i].scores.metrictotaladopters)
                adoption.group = adoption.group + Number(dataArray1[i].scores.metrictotaladopters)
        }
    }
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
        {
            if(dataArray2[i].scores.metrictotaladopters)
                adoption.benchmark = adoption.benchmark + Number(dataArray2[i].scores.metrictotaladopters) 
        }
    }
    let total1 = 0
    for(let i=0 ; i<dataArray1.length ; i++)
    {
        if(dataArray1[i].simPerformanceFilters && checkTags(dataArray1[i].simPerformanceFilters.journey_tags))
        {
            total1 = total1 + 1
        }   
    }
    if(total1==0)
    {
        total1=1
    }
    let total2 = 0
    for(let i=0 ; i<dataArray2.length ; i++)
    {
        if(dataArray2[i].simPerformanceFilters && checkTags(dataArray2[i].simPerformanceFilters.journey_tags))
        {
            total2 = total2 + 1
        }
    }
    if(total2==0)
    {
        total2=1
    }
    data[0]['% Of Users'] = Math.round((adoption.group/total1)*10)/10 
    data[1]['% Of Users'] = Math.round((adoption.benchmark/total2)*10)/10 

    initialState.competencyScoresCQ.dataForAdoptors.data = data
    return initialState.competencyScoresCQ.dataForAdoptors
}

const calculateMetricGroupCQ = (dataArray) => {
    if(dataArray.length==0)
    {
        return 0
    }
    let total=0 
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + dataArray[i].scores.metricnetworksuncoveredpercentage
    }
    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            instances = instances + 1
    }
    if(instances==0)
    {
        instances=1
    }
    total = Math.round((total/instances*10))/10 
    return total
}

const calculateMetricBenchmarkCQ = (dataArray) => {
    if(dataArray.length==0)
    {
        return 0
    }
    let total=0 
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + dataArray[i].scores.metricnetworksuncoveredpercentage
    }
    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            instances = instances + 1
    }
    if(instances==0)
    {
        instances=1
    }
    total = Math.round((total/instances*10))/10 
    return total
}

const calculateActionsGroupCQ = (dataArray) => {
    if(dataArray.length==0)
    {
        return 0
    }
    let total=0 
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + dataArray[i].scores.metricpositiveactionimpact
    }
    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            instances = instances + 1
    }
    if(instances==0)
    {
        instances=1
    }
    total = Math.round((total/instances*10))/10 
    return total
}

const calculateActionsBenchmarkCQ = (dataArray) => {
    if(dataArray.length==0)
    {
        return 0
    }
    let total=0 
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            total = total + dataArray[i].scores.metricpositiveactionimpact
    }
    let instances = 0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
            instances = instances + 1
    }
    if(instances==0)
    {
        instances=1
    }
    total = Math.round((total/instances*10))/10 
    return total
}

const completedLearnersCQ = (dataArray) => {
    if(dataArray.length==0)
        return 0
    let total=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].simPerformanceFilters && checkTags(dataArray[i].simPerformanceFilters.journey_tags))
        {
            total = total + 1
        }
    }
    return total
}

export function performanceTab(state=initialState, action) {
    switch(action.type) {
        case UPDATE_PROGRESS_BAR1_PF:
            return {
                ...state.allCourses,
                dataForHorizontalProgressBar1: action.payload
            }
        case UPDATE_PROGRESS_BAR2_PF:
            return {
                ...state.allCourses,
                dataForHorizontalProgressBar2: action.payload
            }
        case UPDATE_BAR_GRAPH_PF:
            return {
                ...state.allCourses,
                dataForBarGraph1: action.payload
            }
        case UPDATE_TITLE1_PF:
            return {
                ...state.allCourses,
                title1: action.payload
            }
        case UPDATE_TITLE2_PF: 
            return {
                ...state.allCourses,
                title2: action.payload
            }
        case UPDATE_COMPETENCY_TITLE_PF:
            return {
                ...state.allCourses,
                competencyBoxTitles: action.payload
            }    
        case UPDATE_COMPETENCY_PF_BYB:
            return {
                ...state.byb,
                competencyArray: action.payload
            }
        case UPDATE_PROGRESS_BAR1_PF_BYB:
            return {
                ...state.byb,
                dataForHorizontalProgressBar1: action.payload
            }
        case UPDATE_PROGRESS_BAR2_PF_BYB:
                return {
                    ...state.byb,
                    dataForHorizontalProgressBar2: action.payload
                }
        case UPDATE_HORIZONTAL_BAR_PF_BYB:
            return {
                ...state.byb,
                dataForGroupHorizontalBar: action.payload
            }
        case UPDATE_BAR_GRAPH_PF_BYB:
            return {
                ...state.byb,
                dataForBarGraph1: action.payload
            }
        case UPDATE_PIE_CHART_PF_BYB:
            return {
                ...state.byb,
                dataForPieChart: action.payload
            }
        case UPDATE_BENCHMARK1_PF_BYB:
            return {
                ...state.byb,
                dataForBarBenchmarkGroup1: action.payload
            }
        case UPDATE_BENCHMARK2_PF_BYB:
            return {
                ...state.byb,
                dataForBarBenchmarkGroup2: action.payload
            }
        case UPDATE_BENCHMARK3_PF_BYB:
            return {
                ...state.byb,
                dataForBarBenchmarkGroup3: action.payload
            }
        case UPDATE_BENCHMARK4_PF_BYB: 
            return {
                ...state.byb,
                dataForBarBenchmarkGroup4: action.payload
            }   
        case UPDATE_DOMAIN_COMPETENCY_ID:
            return {
                ...state,
                jidAC: {
                    jid: action.payload
                }
            }
        case UPDATE_AC_COMPETENCY_SCORES:
            return {
                ...state,
                competencyScoresAC: {
                    dataForHorizontalProgressBar1: calculateGroupAvgProgressBarAC(action.payload.groupAvg),
                    dataForHorizontalProgressBar2: calculateBenchmarkAvgProgressBarAC(action.payload.benchmarkAvg),
                    testArray: initialState.competencyScoresAC.testArray
                }
            } 
        case UPDATE_AC_USERS: 
            return {
                ...state,
                usersAC: {
                    learners: action.payload.usersCount.aggregate.count
                }
            }
        case UPDATE_AC_DISTRIBUTIONS:
            return {
                ...state,
                distributionsAC: {
                    dataForBarGraph1: calculateDistributionsAC(action.payload.distributionsArray)
                }
            }
        case UPDATE_AC_TOP_PERFORMERS: 
            return {
                ...state,
                topPerformersAC: {
                    topPerformersArray: calculateTopPerformersAC(action.payload.topPerformersArray)
                }
            }
        case UPDATE_AC_COMPETENCIES: 
            return {
                ...state, 
                competenciesAC: {
                    totalCompetenciesArray: calculateTotalCompetenciesAC(action.payload.competencies),
                    topCompetenciesArray: initialState.competenciesAC.topCompetenciesArray,
                    lowCompetenciesArray: initialState.competenciesAC.lowCompetenciesArray
                }
            }
        case UPDATE_CQ_BANNER_FIRST:
            return {
                ...state,
                bannerFirstCQ: {
                    description: bannerFirstDescription(action.payload.bannerFirstDetails),
                    name: bannerFirstName(action.payload.bannerFirstDetails),
                    domainCategory: bannerDomainArray(action.payload.bannerFirstDetails)
                }
            }    
        case UPDATE_CQ_GROUP_BENCHMARK:
            return {
                ...state,
                groupBenchmarkCQ: {
                    totalLearners:  action.payload.learnersCompleted.aggregate.count //completedLearnersCQ(action.payload.users) 
                }
            }
        case UPDATE_CQ_COMPETENCY_SCORES:
            return {
                ...state,
                competencyScoresCQ: {
                    dataForHorizontalProgressBar1: calculateGroupAvgProgressBarCQ(action.payload.groupAvg),
                    dataForHorizontalProgressBar2: calculateBenchmarkAvgProgressBarCQ(action.payload.benchmarkAvg),
                    dataForAdoptions: initialState.competencyScoresCQ.dataForAdoptions,
                    dataForGroupHorizontalBar: calculateGroupHorizontalCQ(action.payload.groupGraph, action.payload.benchmarkGraph),
                    dataForAdoptors: calculateAdoptorsCQ(action.payload.adoptionGroup, action.payload.adoptionBenchmark),
                    dataForPieChart: calculateAdoptionCQ(action.payload.adoptionGroup, action.payload.adoptionBenchmark)
                }
            }
        case UPDATE_CQ_DISTRIBUTIONS:
            return {
                ...state,
                distributionsCQ: {
                    dataForBarGraph1: calculateDistributionsCQ(action.payload.competencyOverallArray),
                    actionText1: calculateMostFrequent(action.payload.mostFrequentArray),
                    actionText2: calculateLeastFrequent(action.payload.mostFrequentArray),
                    metricGroup1: calculateMetricGroupCQ(action.payload.mostFrequentArray),
                    metricBenchmark1: calculateMetricBenchmarkCQ(action.payload.scoreBenchmark),
                    metricGroup2: calculateActionsGroupCQ(action.payload.mostFrequentArray),
                    metricBenchmark2: calculateActionsBenchmarkCQ(action.payload.scoreBenchmark)
                }
            }
        case UPDATE_CQ_DIFFUSION:
            return {
                ...state,
                diffusionCQ: {
                    dataForBarWithLineChart: calculateInfussionCurveCQ(action.payload.diffusionCurve)
                }
            }
        case UPDATE_CQ_STAKEHOLDERS:
            return {
                ...state,
                stakeholdersCQ: {
                    timingofstakeholderinteractionsProps: calculateTimingStakeholders(action.payload.timingArray),
                    frequencyofstakeholderinteractionsProps: calculateFrequencyStakeholders(action.payload.timingArray)
                }
            }
        case UPDATE_COMPETENCY_PF_CQ:
            return {
                ...state.cq,
                competencyArray: action.payload
            }
        case UPDATE_PROGRESS_BAR1_PF_CQ:
            return {
                ...state.cq,
                dataForHorizontalProgressBar1: action.payload
            }
        case UPDATE_PROGRESS_BAR2_PF_CQ:
            return {
                ...state.cq,
                dataForHorizontalProgressBar2: action.payload
            }
        case UPDATE_HORIZONTAL_BAR_PF_CQ:
            return {
                ...state.cq,
                dataForGroupHorizontalBar: action.payload
            }
        case UPDATE_BENCHMARK1_PF_CQ:
            return {
                ...state.cq,
                dataForBarBenchmarkGroup1: action.payload
            }
        case UPDATE_BAR_GRAPH_PF_CQ:
            return {
                ...state.cq,
                dataForBarGraph1: action.payload
            }
        case UPDATE_BAR_LINE_PF_CQ:
            return {
                ...state.cq,
                dataForBarWithLineChart: action.payload
            }
        case UPDATE_AS_BANNER_FIRST:
            return {
                ...state,
                bannerFirstAS: {
                    description: bannerFirstDescription(action.payload.bannerFirstDetails),
                    name: bannerFirstName(action.payload.bannerFirstDetails),
                    domainCategory: bannerDomainArray(action.payload.bannerFirstDetails)
                }
            }
        case UPDATE_AS_USERS:
            return {
                ...state,
                usersAS: {
                    totalLearners: action.payload.learnersSignedUp.aggregate.count //completedLearnersCQ(action.payload.users) 
                }
            }
        case UPDATE_AS_COMPETENCY_SCORES:
            return {
                ...state,
                competencyScoresAS: {
                    dataForHorizontalProgressBar1: calculateGroupAvgProgressBarAS(action.payload.groupAvg),
                    dataForHorizontalProgressBar2: calculateBenchmarkAvgProgressBarAS(action.payload.benchmarkAvg),
                    dataForGroupHorizontalBar: calculateGroupHorizontalAS(action.payload.groupGraph, action.payload.benchmarkGraph),
                    targetAchieved: calculateTargetAchievedAS(action.payload.target),
                    dataForPCF: calculatePCFGraphAS(action.payload.target)
                }
            }
        case UPDATE_AS_DISTRIBUTIONS:
            return {
                ...state,
                distributionsAS : {
                    dataForBarGraphCentricity: calculateCustomerCentricityAS(action.payload.distributions),
                    dataForBarGraphAgility: calculateChangeAgilityAS(action.payload.distributions),
                    dataForBarGraphCollaboration: calculateCollaborationAS(action.payload.distributions),
                    dataForBarGraphFocus: calculateResultFocusAS(action.payload.distributions),
                    dataForBarBenchmarkGroup1: calculateEfficiencyAS(action.payload.efficiencyGroup, action.payload.efficiencyBenchmark)
                }
            }
        case UPDATE_AS_SKILLS:
            return {
                ...state,
                skillAS: {
                    dataForGroupVertical: calculateSkillsAS(action.payload.skill),
                    dataForBarBenchmarkGroup2: calculateVelocityAS(action.payload.skill, action.payload.skillBenchmark)
                }
            }
        case UPDATE_CS_COMPETENCY_SCORES: 
            return {
                ...state,
                competencyScoresCS: {
                    dataForHorizontalProgressBar1: calculateGroupAvgProgressBarCS(action.payload.groupAvg),
                    dataForHorizontalProgressBar2:  calculateBenchmarkAvgProgressBarCS(action.payload.benchmarkAvg),
                    dataForGroupHorizontalBar: calculateGroupHorizontalCS(action.payload.groupAvg, action.payload.benchmarkAvg),
                    dataForBarGraphTarget: calculateTargetRevenueCS(action.payload.groupAvg, action.payload.benchmarkAvg),
                    targetAchieved: calculateAchievedTargetCS(action.payload.targetArray)
                }
            }
        case UPDTAE_CS_DISTRIBUTIONS:
            return {
                ...state,
                distributionsCS: {
                    dataForBarGraphTrust: calculateDistributionsTrustCS(action.payload.distributionsArray),
                    dataForBarGraphQuestioning: calculateDistributionsQuestioningCS(action.payload.distributionsArray),
                    dataForBarGraphAccountability: calculateDistributionsAccountabilityCS(action.payload.distributionsArray),
                    dataForBarGraphCommunications: calculateDistributionsCommunicationCS(action.payload.distributionsArray),
                    dataForBarBenchmarkGroup: calculatePerformanceIndicatorCS(action.payload.performanceGroup, action.payload.performanceBenchmark)
                }
            }
        case UPDATE_CS_GROW: 
            return {
                ...state,
                growCS: {
                    dataForBarGraphGrow: calculateBarGraphGrow(action.payload.growGroup),
                    dataForPieChart: calculatePieChartCS(action.payload.growGroup, action.payload.growBenchmark)
                }
            }
        case UPDATE_IL_BANNER_FIRST:
            return {
                ...state,
                bannerFirstIL: {
                    description: action.payload.bannerFirstDescription[0].JourneyInstanceDescriptionString.langvalue
                }
            }
        case UPDATE_IL_LEARNERS:
            return {
                ...state,
                totalLearnersIL: {
                    users: action.payload.learnersGroupCompleted.aggregate.count + action.payload.learnersGroupNotCompleted.aggregate.count
                }
            }
        case UPDATE_IL_COMPETENCY_SCORES: {
            return {
                ...state,
                competencyScoresIL: {
                    dataForHorizontalProgressBar1: calculateGroupAvgProgressBarIL(action.payload.groupAvg),
                    dataForHorizontalProgressBar2: calculateBenchmarkAvgProgressBarIL(action.payload.benchmarkAvg),
                    dataForGroupHorizontalBar: calculateGroupHorizontalIL(action.payload.groupAvg, action.payload.benchmarkAvg),
                    dataForGroupVerticalBar: initialState.competencyScoresIL.dataForGroupVerticalBar,
                    targetAchieved: calculateAchievedTarget(action.payload.achievedTargetArray) 
                }
            }
        }
        case UPDATE_IL_DISTRIBUTIONS: {
            return {
                ...state, 
                distributionsIL: {
                    dataForBarGraphUpskill: calculateDistributionsUpskillIL(action.payload.distributionsArray),
                    dataForBarGraphMotivate: calculateDistributionsMotivateIL(action.payload.distributionsArray),
                    dataForBarGraphLeadership: calculateDistributionsLeadershipIL(action.payload.distributionsArray),
                    dataForBarGraphPerformance: calculateDistributionsPerformanceIL(action.payload.distributionsArray),
                    dataForBarGraphResults: calculateDistributionsResultsIL(action.payload.distributionsArray),
                    dataForPieChart: calculatePieChartIL(action.payload.leadershipGroup, action.payload.leadershipBenchmark)
                }
            }
        }
        case UPDATE_IL_LEADERSHIP: {
            return {
                ...state, 
                leadershipIL: {
                    dataForBarGraph2: calculateLeadershipConsistencyIL(action.payload.consistency),
                    dataForBarGraph3: calculateTimeSpentIL(action.payload.timeSpent)
                }
            }
        }
        case UPDATE_COMPETENCY_PF_IL:
            return {
                ...state.iLead,
                competencyArray: action.payload
            }
        case UPDATE_PROGRESS_BAR1_PF_IL:
            return {
                ...state.iLead,
                dataForHorizontalProgressBar1: action.payload
            }
        case UPDATE_PROGRESS_BAR2_PF_IL:
            return {
                ...state.iLead,
                dataForHorizontalProgressBar2: action.payload
            }
        case UPDATE_BAR_GRAPH1_PF_IL:
            return {
                ...state.iLead,
                dataForBarGraph1: action.payload
            }
        case UPDATE_BAR_GRAPH2_PF_IL:
            return {
                ...state.iLead,
                dataForBarGraph2: action.payload
            }
        case UPDATE_BAR_GRAPH3_PF_IL:
            return {
                ...state.iLead,
                dataForBarGraph3: action.payload
            }
        case UPDATE_HORIZONTAL_BAR_PF_IL:
            return {
                ...state.iLead,
                dataForGroupHorizontalBar: action.payload
            }
        case UPDATE_VERTICAL_BAR_PF_IL: 
            return {
                ...state.iLead,
                dataForGroupVerticalBar: action.payload
            }
        case UPDATE_PIE_CHART_PF_IL:
            return {
                ...state.iLead,
                dataForPieChart: action.payload
            }
        case UPDATE_BAR_ACCURACY_PF_IL:
            return {
                ...state.iLead,
                dataForBarProportionAccuracy: action.payload
            }
        case UPDATE_TS_BANNER_FIRST:
            return {
                ...state,
                bannerFirstTS: {
                    description: action.payload.bannerFirstDescription[0].JourneyInstanceDescriptionString.langvalue
                }
            }
        case UPDATE_TS_LEARNERS: 
            return {
                ...state,
                usersTS: {
                    totalLearners: action.payload.learnersGroupCompleted.aggregate.count + action.payload.learnersGroupNotCompleted.aggregate.count
                }
            }    
        case UPDATE_TS_COMPETENCY_SCORES:
            return {
                ...state,
                competencyScoresTS: {
                    dataForHorizontalProgressBar1: calculateGroupAvgProgressBarTS(action.payload.groupAvg),
                    dataForHorizontalProgressBar2: calculateBenchmarkAvgProgressBarTS(action.payload.benchmarkAvg),
                    dataForGroupHorizontalBar: calculateGroupHorizontalTS(action.payload.groupAvg, action.payload.benchmarkAvg),
                    dataForPieChart1: calculateTrustScoreTS(action.payload.groupAvg, action.payload.benchmarkAvg)
                }
            }
        case UPDATE_TS_DISTRIBUTIONS:
            return {
                ...state,
                distributionsTS: {
                    dataForBarGraphTrust: calculateDistributionsTrustTS(action.payload.distributionsArray),
                    dataForBarGraphNetworks: calculateDistributionsNetworksTS(action.payload.distributionsArray),
                    dataForBarGraphRelationships: calculateDistributionsRelationshipsTS(action.payload.distributionsArray),
                    dataForBarGraphStakeholders: calculateDistributionsStakeholdersTS(action.payload.distributionsArray),
                    dataForPieChartFilled: calculateTargetStatusTS(action.payload.distributionsArray)
                }
            }
        case UPDATE_TS_ACCURACIES:
            return {
                ...state,
                accuraciesTS: {
                    dataForBarBenchmarkGroup1: calculateAccuraciesTS(action.payload.accuraciesGroup, action.payload.accuraciesBenchmark),
                    dataForPieChart2: calculateNetworkUncoveredTS(action.payload.accuraciesGroup, action.payload.accuraciesBenchmark),
                    dataForBarFrequencyAccuracy: calculateRelationshipsTS(action.payload.relationships)
                }
            }
        case UPDATE_COMPETENCY_PF_TS:
            return {
                ...state.trustSim,
                competencyArray: action.payload
            }
        case UPDATE_PROGRESS_BAR1_PF_TS:
            return {
                ...state.trustSim,
                dataForHorizontalProgressBar1: action.payload
            }
        case UPDATE_PROGRESS_BAR2_PF_TS:
            return {
                ...state.trustSim,
                dataForHorizontalProgressBar2: action.payload
            }
        case UPDATE_HORIZONTAL_BAR_PF_TS:
            return {
                ...state.trustSim,
                dataForGroupHorizontalBar: action.payload
            }
        case UPDATE_PIE_CHART1_PF_TS:
            return {
                ...state.trustSim,
                dataForPieChart1: action.payload
            }
        case UPDATE_PIE_CHART2_PF_TS:
            return {
                ...state.trustSim,
                dataForPieChart2: action.payload
            }
        case UPDATE_BAR_GRAPH1_PF_TS:
            return {
                ...state.trustSim,
                dataForBarGraph1: action.payload
            }
        case UPDATE_PIE_CHART_FILLED_PF_TS:
            return {
                ...state.trustSim,
                dataForPieChartFilled: action.payload
            }
        case UPDATE_BAR_BENCHMARK_PF_TS:
            return {
                ...state.trustSim,
                dataForBarBenchmarkGroup1: action.payload
            }
        case UPDATE_BAR_ACCURACY_PF_TS:
            return {
                ...state.trustSim,
                dataForBarFrequencyAccuracy: action.payload
            }
        case UPDATE_PROGRESS_BAR1_PF_CG:
            return {
                ...state.courseGroupPerformance,
                dataForHorizontalProgressBar1: action.payload
            }
        case UPDATE_PROGRESS_BAR2_PF_CG:
            return {
                ...state.courseGroupPerformance,
                dataForHorizontalProgressBar2: action.payload
            }
        case UPDATE_BAR_GRAPH_PF_CG:
            return {
                ...state.courseGroupPerformance,
                dataForBarGraph1: action.payload
            }
        case UPDATE_TITLE1_PF_CG:
            return {
                ...state.courseGroupPerformance,
                title1: action.payload
            }
        case UPDATE_TITLE2_PF_CG: 
            return {
                ...state.courseGroupPerformance,
                title2: action.payload
            }
        case UPDATE_COMPETENCY_TITLE_PF_CG:
            return {
                ...state.courseGroupPerformance,
                competencyBoxTitles: action.payload
            }
        case UPDATE_PROGRESS_BAR1_PF_NS:
            return {
                ...state.nonSim,
                dataForHorizontalProgressBar1: action.payload
            }
        case UPDATE_PROGRESS_BAR2_PF_NS:
            return {
                ...state.nonSim,
                dataForHorizontalProgressBar2: action.payload
            }
        case UPDATE_PROGRESS_BAR3_PF_NS:
            return {
                ...state.nonSim,
                dataForHorizontalProgressBar3: action.payload
            }
        case UPDATE_BAR_GRAPH_PF_NS:
            return {
                ...state.nonSim,
                dataForBarGraph1: action.payload
            }
        case UPDATE_COURSE_DROPDOWN_PF_NS:
            return {
                ...state.nonSim,
                arr : action.payload
            }
        case UPDATE_PIE_CHART_PF_NS:
            return {
                ...state.nonSim,
                dataForPieChartFilled: action.payload
            }
        case UPDATE_TOTAL_QUESTIONS_PF_NS:
            return {
                ...state.nonSim,
                totalQuestions: action.payload
            }    
        default:
            return state
    }
}