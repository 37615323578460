export const dataForHorizontalProgressBar1 = {'height': 10, 'width':180, 'Total': 100, 'score': 90 };

export const dataForLineChart2 = {
    'data':[],                    
}

for(let i = 1; i <= 100; i++ ){
    dataForLineChart2.data.push(
        {'Days':i, '% Of Users': Math.floor(50+Math.random()*30)},
    );
}

export const dataForPieChart = {'height': 180, 'width':180, 'TotalInner': 100, 'scoreInner': 65, 'TotalOuter': 100, 'scoreOuter': 70};

export const dataForHorizontalBenchmarkGroupBar = {
                                                'height': 58, 'width':300,
                                                'data': [
                                                    { Title: 'Group', '% Of Users': 55 },
                                                    { Title: 'Benchmark', '% Of Users': 85 },
                                                ],
}

if(window.screen.width > 1910 && window.screen.width < 2550){
    //for FHD Screens
    dataForPieChart['height']= 265; dataForPieChart['width']= 265;
    dataForHorizontalBenchmarkGroupBar['height'] = 93; dataForHorizontalBenchmarkGroupBar['width'] = 412;
    dataForHorizontalProgressBar1['height'] = 15; dataForHorizontalProgressBar1['width'] = 218; 
    dataForLineChart2['height'] = 235 ; dataForLineChart2['width'] = 1388; 
}else if(window.screen.width >= 2551){
    //for QHD Screens
    dataForPieChart['height']= 340; dataForPieChart['width']= 340;
    dataForHorizontalBenchmarkGroupBar['height'] = 110; dataForHorizontalBenchmarkGroupBar['width'] = 550;
    dataForHorizontalProgressBar1['height'] = 20; dataForHorizontalProgressBar1['width'] = 300; 
    dataForLineChart2['height'] = 335 ; dataForLineChart2['width'] = 1850; 
}else{
    //for HD Screens
    dataForPieChart['height']= 175; dataForPieChart['width']= 175;
    dataForHorizontalBenchmarkGroupBar['height'] = 62; dataForHorizontalBenchmarkGroupBar['width'] = 275;
    dataForHorizontalProgressBar1['height'] = 10; dataForHorizontalProgressBar1['width'] = 145; 
    dataForLineChart2['height'] = 157 ; dataForLineChart2['width'] = 925; 
}