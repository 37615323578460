import React, { useState } from 'react'
import Styles from './CourseFeedback.module.css'
import Stars from "../Stars/Stars";
import Title from '../GraphTitle/Title';

function CourseFeedback(props){
    return(
        <div className={Styles.container}>
            <Title title='COURSE FEEDBACK' infoBody={props.infoBody} />
            {
                props.feedback &&
                props.feedback.map((value)=>{
                    return(
                        <div className={Styles.subContainer}>
                            <div className={Styles.subHeading}>
                                {value.title}
                            </div>
                            <div className={Styles.starRatingContainer}>
                                <div className={Styles.ratingContainer}>
                                    <div className={Styles.ratingValue}>{value.rating || 0}</div>
                                    <div className={Styles.totalRating}>/5</div>
                                </div>
                                {
                                    window.screen.width > 2560 ? (<Stars rating={value.rating || 0} color='#B888CB' size='400px'/>)
                                    : (<Stars rating={value.rating || 0} color='#B888CB' size='20px'/>)
                                } 
                                
                            </div>
                        </div>
                    )
                })
            }
        </div>
    ) 
}

export default CourseFeedback;


