import React from "react";
import Styles from "./Title.module.css";
import InfoComp from './InfoIcon'
function Title(props){
    return(
        <div className={Styles.container}>
            <div className={Styles.text}>
                {props.title || 'COMPETENCY SCORES'}
            </div>
            <div>           
                <InfoComp infoBody={props.infoBody}/>
            </div>
        </div>
    )
}

export default Title;