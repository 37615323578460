import React from "react";
import Styles from "./Milestone.module.css";
import designUp from "../../../assets/img/milestone1.png";
import designDown from "../../../assets/img/milestone2.png";
import CircularProgressBar from "../../GraphComponents/CircularProgressBar";
import CircularTextRing from '../../GraphComponents/CircularTextRing'
import PropTypes from 'prop-types';

function Milestone(props){

    let height = '44'
    let width = '44'
    
    if(window.screen.width >= 2560) {
        height = '78'
        width = '78'
    }

    return(
        <div className={Styles.container}>
            <div className={Styles.heading}>MILESTONE</div>
            <div className={Styles.innerBox}>
                {
                    props.data &&
                    props.data.map((val,index)=>{
                        return(
                            <div className={Styles.elementContainer}>
                                {
                                    index!=0 && 
                                    <span className={Styles.line}/>
                                }
                                <div>
                                    <div className={Styles.title}>
                                        {val.title}
                                    </div>
                                    {
                                        val.percent &&
                                        <div className={Styles.statsBox}>
                                            <CircularProgressBar height={height} width={width} score={props.data[0].percent} Total='100'/>
                                            <div className={Styles.courseName}>
                                                {props.data[0].course}
                                            </div>
                                        </div>
                                    }
                                    {
                                        val.count &&
                                        <div className={Styles.statsBox}>
                                            <CircularTextRing height={height} width={width} count={val.count}/>                      
                                            <div className={Styles.courseName}>
                                                {props.data[2].course} 
                                            </div>
                                        </div>
                                    }
                                    {
                                        val.min &&
                                        <div className={Styles.timeBox}>
                                            <div className={Styles.time}>
                                                {props.data[1].hour} <span className={Styles.text}>hours</span> {props.data[1].min} <span className={Styles.text}>min</span>
                                            </div>
                                        </div>
                                    }
                                </div>
                                
                            </div>
                            
                        )
                    })
                }
            </div>
            <div className={Styles.image1}>
                <img src={designDown} className={Styles.image1}/>
                <img src={designUp} className={Styles.image2}/>  
            </div>
        </div>

    )
}


Milestone.prototype={
    data:PropTypes.shape([
        {
            title:PropTypes.string.isRequired,
            course:PropTypes.string,
            percent:PropTypes.number,
            count:PropTypes.number,
            hour:PropTypes.number,
            min:PropTypes.number,
        }
    ])
}

export default Milestone