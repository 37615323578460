import React, { useEffect } from 'react';
import Styles from './NonSimPerformanceTab.module.css'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import NoOfUsers from '../../commonComponents/textComponent/NoOfUsers/User'
import WhiteBox from '../WhiteBox/WhiteBox'
import BarGraph from '../../commonComponents/GraphComponents/BarGraph'
import ProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import { useSelector } from 'react-redux'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import PieChartFilled from '../../commonComponents/GraphComponents/PieChartFilled'
import HorizontalProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar';
import TitleDropdown from '../../commonComponents/textComponent/TitleDropdown/TitleDropdown'
import AOS from 'aos';
import 'aos/dist/aos.css';

function NonSimPerformanceTab(props) {
    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const state = useSelector(state => state.performanceTab.nonSim)

    return (
                <div className={Styles.gridWrapper}>
                    <div className={Styles.heading} data-aos="fade-up" data-aos-once="true">
                        <SimpleBanner title={props.dropdownItem} />
                    </div>
                    <div className={Styles.gridWrapper1} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                        <div>
                            <NoOfUsers />
                        </div>
                        <div className={Styles.averageWhitebox}>
                            <WhiteBox>
                                <div className={Styles.averageContainer}>
                                    <div className={Styles.groupAverageHeading}>
                                        <GraphTitle title="GROUP AVERAGE" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.dataForHorizontalProgressBar1.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.dataForHorizontalProgressBar1} />
                                    </div>
                                    <div className={Styles.lineDifferentiator}></div>
                                    <div className={Styles.groupAverageHeading}>
                                        <GraphTitle title="BENCHMARK" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.dataForHorizontalProgressBar2.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.dataForHorizontalProgressBar2} />
                                    </div>
                                </div>
                            </WhiteBox>
                        </div>
                        <div>
                            <WhiteBox>
                                <div className={Styles.barGraphHeading}>
                                    <GraphTitle title="DISTRIBUTION OF LEARNERS ACROSS PROFICIENCIES" />
                                </div>
                                <div className={Styles.barGraphComponent}>
                                    <BarGraph {...state.dataForBarGraph1} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                    <div data-aos="fade-up" data-aos-once="true" data-aos-delay="700">
                        <BannerWithBackground />
                    </div>
                    <div className={Styles.courseDropdown} data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <TitleDropdown arr={state.arr} />
                    </div>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true">
                        <div className={Styles.pieChartComponentContainer}>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title="QUESTIONS - ANSWERED VS UNANSWERED" />
                                </div>
                                <div>
                                    <PieChartFilled {...state.dataForPieChartFilled} />
                                </div>
                            </WhiteBox>
                        </div>
                        <div className={Styles.gridWrapper3}>
                            <div className={Styles.totalQuestionsContainer}>
                                <WhiteBox>
                                    <div className={Styles.graphTitle}>
                                        <GraphTitle title="TOTAL NO. OF QUESTIONS" />
                                    </div>
                                    <div className={Styles.totalQuestions}>{state.totalQuestions}</div>
                                </WhiteBox>
                            </div>
                            <div className={Styles.correctAnswersContainer}>
                                <WhiteBox>
                                    <div className={Styles.graphTitle}>
                                        <GraphTitle title="ACCURACY - CORRECTLY ANSWERED QUESTIONS" />
                                    </div>
                                    <div className={Styles.progressBarContainer1}>
                                        <div className={Styles.progressBarPercent}>{state.dataForHorizontalProgressBar3.score}</div>
                                        <div className={Styles.progressBarPercent1}>%</div>
                                        <div className={Styles.progressBar1}>
                                            <HorizontalProgressBar {...state.dataForHorizontalProgressBar3} />
                                        </div>
                                    </div>
                                </WhiteBox>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default NonSimPerformanceTab