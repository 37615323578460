import React,{useEffect} from 'react';
import FormControl from '@material-ui/core/FormControl';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Styles from './selectDropdown.module.css'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {useDispatch,useSelector}  from 'react-redux'
import {handleFilters} from '../../../actions/actionCreator'
import {UPDATE_FILTER_ITEM} from '../../../constants/actions'
import {createMuiTheme,ThemeProvider} from "@material-ui/core/styles";
import {useParams} from 'react-router-dom'


function SelectDropdown(props) {
  const allLabel= props.parameters[1].params[0]
  const [checkList, setCheckList] = React.useState([allLabel])
  const [dropdownList,setDropdownList] = React.useState([allLabel])
  const headerTitle = props.parameters[0].header
  const dispatch = useDispatch()
  const { id } = useParams()

  useEffect(() => {
    setCheckList([allLabel])
  }, [id]);
  
  const select = createMuiTheme({
    overrides: {
      MuiSelect: {
        select: {
          "&:focus": {
            backgroundColor: "#ffffff",                
          }
        }
      }
    }
  });

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 171,
        width: 222,          
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    getContentAnchorEl: null
  };
  
  const handleChange = (event) => {
    
    let options = [...event.target.value]
    if(headerTitle === 'Batch' && props.course === 'All Courses'){
      return
    }
    else{      
      if(options.includes(allLabel) && options.indexOf(allLabel) != 0){
          options = [allLabel]
        }
      if((options.includes(allLabel) && options.length == 2 )) {
        let item_index = options.indexOf(allLabel)
        options.splice(item_index,1)
      }
      if((!options.includes(allLabel) && !options.length) ){
        options = [allLabel]
      }    
      setCheckList(options)
       
    }
    // let temp = []
    // temp = [...temp,...options]
    //   if(temp.includes('VLT')){
    //     let index = temp.indexOf('VLT')
    //     temp[index] = 'VILT'
    //     setDropdownList(temp)
        
    //   }
    // console.log(options,temp)
    
  };

  const handleFilterItemUpdate = () =>{
    if(headerTitle === 'Deal'){
      return handleFilters(checkList, UPDATE_FILTER_ITEM, 'deals')
    }
    if(headerTitle === 'Batch'){
      return handleFilters(checkList, UPDATE_FILTER_ITEM, 'batches')
    }
    if(headerTitle === 'Learning Format'){
      return handleFilters(checkList, UPDATE_FILTER_ITEM, 'learningFormats')
    }    

    return 
  }
  
  useEffect(() => {
    dispatch(handleFilterItemUpdate())       
  }, [checkList]);
  return (
    <div className={Styles.body}>
      <div>
        <label className={Styles.header_title}>{headerTitle}</label>
      </div>
      <ThemeProvider theme={select}>      
        <FormControl className ={Styles.formControl}>
          <Select
            IconComponent = {ExpandMoreOutlinedIcon}
            multiple
            value={checkList}
            onChange={handleChange}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
            className={Styles.select} 
          >
            
            {props.parameters[1].params.map((name) => (            
              <MenuItem key={name} value={name}  className={Styles.menu_item} classes={{selected : Styles.menu_item_selected}}>
                <div className={checkList.includes(name) ? Styles.checkedd : 'null'}>        
                  <input type="checkbox" id={name} value={name} className={Styles.check} checked={checkList.includes(name)}/>
                  <span className={Styles.checklabel} >{name === 'VLT' ? 'VILT' : name}</span>
                </div>
              </MenuItem>
            ))}
            
          </Select>
        </FormControl>  
      </ThemeProvider>   
    </div>
  );
}
export default SelectDropdown