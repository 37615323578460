import React from "react"
import ContentLoader from "react-content-loader"

const HorizontalBenchGroupLoader = (props) => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width =  props.width;
    const height = props.height; 
    
    return(
        <ContentLoader 
            speed={2}
            width={width}
            height={height}
            viewBox={"0 0 "+width+" "+height}
            backgroundColor="#f3f3f3"
            foregroundColor="#dcdbdb"
        >
            <rect x={20*unit} y={20*unit} rx={5*unit} ry={5*unit} width={width - (50*unit)} height={12*unit} />
            <rect x={20*unit} y={65*unit} rx={10*unit} ry={10*unit} width={width/4 - (50*unit)} height={25*unit} />

            <rect x={width/4 - (12*unit)} y={height/2.5} width={2*unit} height={height/2 + (5*unit)} />

            <rect x={(width/2)-(40/6*unit)} y={(height/4)+50*unit} rx="0" ry="0" width={5*unit} height={10*unit} />
            <rect x={(width/2)+(20/3*unit)} y={(height/4)+40*unit} rx="0" ry="0" width={5*unit} height={20*unit} />
            <rect x={(width/2)+(40/2*unit)} y={(height/4)+45*unit} rx="0" ry="0" width={5*unit} height={15*unit} />
            <rect x={(width/2)+(100/3*unit)} y={(height/4)+30*unit} rx="0" ry="0" width={5*unit} height={30*unit} />
            <rect x={(width/2)+(280/6*unit)} y={(height/4)+40*unit} rx="0" ry="0" width={5*unit} height={20*unit} />
            <rect x={(width/2)-(20*unit)} y={(height/4)+60*unit} rx="0" ry="0" width={80*unit} height={2*unit} />
            
        </ContentLoader>
    )
}

export default HorizontalBenchGroupLoader;