import React from "react"
import ContentLoader from "react-content-loader"
import Styles from "./FeedbackAll.module.css"

const WordCloud = (props) => (
    <div className={Styles.responses}>
        <ContentLoader 
            speed={2}
            width={670}
            height={325}
            viewBox="0 0 670 325"
            backgroundColor="#f3f3f3"
            foregroundColor="#dcdbdb"
            {...props}
        >
            <rect x="257" y="186" rx="0" ry="0" width="7" height="5" /> 
            <rect x="268" y="178" rx="0" ry="0" width="7" height="13" /> 
            <rect x="253" y="190" rx="0" ry="0" width="62" height="2" /> 
            <rect x="280" y="167" rx="0" ry="0" width="7" height="23" /> 
            <rect x="291" y="172" rx="0" ry="0" width="7" height="18" /> 
            <rect x="300" y="182" rx="0" ry="0" width="7" height="9" /> 
            <rect x="30" y="14" rx="7" ry="7" width="210" height="10" />
        </ContentLoader>
  </div>
)

export default WordCloud
