import React from 'react' ;
import Styles from './WhiteBox.module.css'

function WhiteBox(props) {
    return (
        <div className={Styles.whitebox_container}>
            {props.children}
        </div>
    )
}

export default WhiteBox ;