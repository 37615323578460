import React, { useRef, useState, useEffect } from 'react'
import Styles from './Landing.module.css'
import landing1 from '../../assets/img/landing1.png'
import landing2 from '../../assets/img/landing2.png'
import userIcon from '../../assets/img/landing3.png'
import appIcon from '../../assets/img/landing4.png'
import knolskapeLogo from '../../assets/img/landing5.png'
import orgIcon from '../../assets/img/landing6.png'
import landing8 from '../../assets/img/landing8.png'
import landing9 from '../../assets/img/landing9.png'
import landing10 from '../../assets/img/landing10.png'
import WhiteBox from '../WhiteBox/WhiteBox'
import StarRating from '../StarRating/StarRating'
import landing16 from '../../assets/img/landing16.png'
import landing17 from '../../assets/img/landing17.png' 
import HeroImage from '../../assets/img/heroimage.png'
import HorizontalStackedBar from '../../commonComponents/GraphComponents/HorizontalStackedBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import HorizontalProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import Milestone from '../../commonComponents/textComponent/Milestone/Milestone'
import LandingTitle from '../LandingTitle/Title'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { graphql } from 'react-apollo';
import {flowRight as compose} from 'lodash';
import landingQueries from './LandingQueries'
import PageLoader from '../../bower_components/SVG-Loaders/svg-loaders/bars.svg'
import MidLoader from '../../commonComponents/Loaders/Landing/MidDataLoader'
import OrgSearch from '../OrgSearch/OrgSearch'
import { UPDATE_ORG_NAME, UPDATE_ORG_TOTAL_USERS, UPDATE_LANDING_PAGE_ITEMS, UPDATE_LANDING_MID_CONTAINER_ITEMS, UPDATE_NOT_REQUIRED_LANDING_ITEMS } from '../../constants/actions'

function Landing(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const { orgId } = useParams()
    const [showOrgList, setShowOrgList] = useState(false)

    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    const landingState = useSelector(state => state.orgLevelReducer)

    const handleTabClick = (e) => {
        history.push(`/${orgId}/${e.target.dataset.value.toLowerCase()}/All Courses/all/1`)
    }

    const handleOrgList = (e) => {
        setShowOrgList(true)
    }

    const { loading: preLoading, error: preError, data: preData } = useQuery(landingQueries.rootQuery, { variables: { orgId: orgId }});
    const { loading: midLoading, error: midError, data: midData } = useQuery(landingQueries.midContainerQuery, { variables: { orgId: orgId }});
    const {  loading: notReqLoading, error: notReqError, data: notReqData } = useQuery(landingQueries.notRequiredQuery, { variables: { orgId: orgId }}) ;

    useEffect(() => {
        if(!preLoading && !preError && preData!=null){
            dispatch({type: UPDATE_LANDING_PAGE_ITEMS, payload: preData})
        }
    }, [preLoading, preError, preData, props.org_id])

    useEffect(() => {
        if(!midLoading && !midError && midData!=null) {
            dispatch({type: UPDATE_LANDING_MID_CONTAINER_ITEMS, payload:midData})
        }   
    }, [midLoading, midData, midError, props.org_id])

    useEffect(() => {
        if(!notReqLoading && !notReqError && notReqData!=null) {
            dispatch({type: UPDATE_NOT_REQUIRED_LANDING_ITEMS, payload:notReqData})
        }   
    }, [notReqLoading, notReqError, notReqData, props.org_id])

    if(preLoading) {
        console.log('Loading')
        return (
            <div className={Styles.preLoader}>
                <img src={PageLoader} className={Styles.loaderImage} />
            </div>
        )
    }

    if(preError) {
        console.log(preError)
        return <div>Error..</div>
    }
    
    //console.log(data.allJourneyUser)
    //console.log(midLoading)

    return (
        <div className={Styles.landing_container} data-aos="fade" data-aos-once="true">
            <div className={Styles.landingVectorBackground}>
                <img src={landing1} alt="Right Corner" className={Styles.landing_rightcorner} />
                <img src={landing2} alt="Left Corner" className={Styles.landing_leftcorner} />
            </div>
            {
                showOrgList && 
                    <div className={Styles.showOrgComponent}>
                        <OrgSearch showOrgList={showOrgList} setShowOrgList={setShowOrgList} org_id={props.org_id} set_org_id={props.set_org_id} orgName={props.orgName} setOrgName={props.setOrgName} />
                    </div>    
            }
            <div className={Styles.landingContentContainer}>
                <div className={Styles.landingHeader}>
                        <div className={Styles.landing_logocontainer}>
                            <div className={Styles.logotext}>A product by</div>
                            <img src={knolskapeLogo} alt="Knolskape Logo" className={Styles.knolskapelogo} />
                        </div>
                        <div className={Styles.landing_orgcontainer}>
                            <img src={landingState.landingPage.orgLogo} alt="Org Icon" className={Styles.orglogo} />
                            <div className={Styles.org_textcontiner}>
                                <div className={Styles.orgtext1}>{landingState.landingPage.orgName}</div>
                                <div className={Styles.orgtext2}><b>TALENT </b>INTELLIGENCE</div>
                            </div>
                        </div>
                        <div className={Styles.rightIconsContainer}>
                            <img src={appIcon} alt="App Icon" className={Styles.landing_icon1} />
                            <img src={userIcon} alt="User Icon" className={Styles.landing_icon2} />
                        </div>
                </div>
                {
                    midLoading ? ( <div className={Styles.midLoader}>
                        <MidLoader />
                    </div>) : (
                        <div className={Styles.landingMidContainer}>
                        <div className={Styles.landingLeftMidContainer}> 
                            <div className={Styles.vectorContainer}>
                                <img src={landing8} alt="Vector1" className={Styles.landing_vector1} />
                                <img src={landing9} alt="Vector2" className={Styles.landing_vector2} />
                            </div>
                            <div className={Styles.imgTextContainer} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                                <img src={HeroImage} alt="Landing Page" className={Styles.landing_mainimg} />
                                <div className={Styles.landing_welcome}>Welcome, {landingState.landingPage.orgName}</div>
                                <div className={Styles.landing_para}>{landingState.landingPage.orgDescription}</div>
                            </div>
                        </div>
                        <div className={Styles.landingWhiteboxContainer} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                            <div className={Styles.landingWhiteboxShadow1}>
                                <div className={Styles.landingWhitebox1}>
                                    <WhiteBox>
                                        <div className={Styles.inWhiteboxContainer1}> 
                                            <div className={Styles.courseContainer}>
                                                <div className={Styles.numberOfCourses}>{landingState.landingMidContainer.courses}</div>
                                                <div className={Styles.containerTitle}>Courses</div>
                                            </div>
                                            <div className={Styles.batchesContainer}>
                                                <div className={Styles.numberOfCourses}>{landingState.landingMidContainer.batchesCorrectCount}</div>
                                                <div className={Styles.containerTitle}>Batches</div>
                                            </div>
                                            <div className={Styles.usersContainer}>
                                                <div className={Styles.numberOfCourses}>{landingState.landingMidContainer.uniqueUsers}</div>
                                                <div className={Styles.containerTitle}>Enrolled</div>
                                                <div className={Styles.containerTitle}>Learners</div>
                                            </div>
                                            <div className={Styles.verticalLine}></div>
                                            <div className={Styles.licensesContainer}>
                                                <div className={Styles.licensesTitle}>LICENSES</div>
                                                <HorizontalStackedBar {...landingState.landingMidContainer.licensesGraph} />
                                            </div>
                                            <div className={Styles.verticalLine}></div>
                                            <div className={Styles.contractContainer}>
                                                <div className={Styles.contractTitle}>CONTRACT DATES</div>
                                                <div className={Styles.dateContainer}>
                                                    <div className={Styles.startDateContainer}>
                                                        <div className={Styles.startDateTitle}>START</div>
                                                        <div className={Styles.startDate}>{landingState.landingMidContainer.licensesStartDate}</div>
                                                    </div>
                                                    <div className={Styles.endDateContainer}>
                                                        <div className={Styles.startDateTitle}>EXPIRY</div>
                                                        <div className={Styles.startDate}>{landingState.landingMidContainer.licensesEndDate}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>  
                                    </WhiteBox>   
                                </div>
                                <div className={Styles.whitebox1Shadow1}>
                                </div>
                                <div className={Styles.whitebox1Shadow2}>
                                </div>
                            </div>
                            <div className={Styles.landingWhiteboxShadows}>
                                <div className={Styles.landingWhiteboxShadow2}>
                                    <div className={Styles.landingWhitebox2}>
                                        <WhiteBox>
                                            <div className={Styles.inWhiteboxContainer2}>
                                                <div className={Styles.whitebox2Vectors}>
                                                    <img src={landing16} alt="Vector3" className={Styles.whitebox2Vector1} />
                                                    <img src={landing17} alt="Vector4" className={Styles.whitebox2Vector1} />
                                                </div>
                                                <div className={Styles.landingWhitebox2Title}>
                                                    <GraphTitle title="AVERAGE FEEDBACK RATING" infoBody="Average of the feedback ratings given by the learners of this organization across all the courses" />
                                                </div>
                                                <div className={Styles.starRatingComponent} data-aos="fade" data-aos-once="true" data-aos-delay="700">
                                                    <StarRating ratingVal={landingState.landingMidContainer.averageFeedbackRating} />
                                                </div>
                                            </div>
                                        </WhiteBox>
                                    </div>
                                    <div className={Styles.whitebox2Shadow1}>
                                    </div>
                                    <div className={Styles.whitebox2shadow2}>  
                                    </div>
                                </div>
                                <div className={Styles.landingWhiteboxShadow2}>
                                    <div className={Styles.landingWhitebox2}>
                                        <WhiteBox>
                                            <div className={Styles.inWhiteboxContainer2}>
                                                <div className={Styles.whitebox2Vectors}>
                                                    <img src={landing16} alt="Vector3" className={Styles.whitebox2Vector1} />
                                                    <img src={landing17} alt="Vector4" className={Styles.whitebox2Vector1} />
                                                </div>
                                                <div className={Styles.landingWhitebox2Title}>
                                                    <GraphTitle title="COMPLETION RATE" infoBody="Percentage of enrolled learners in this organization who have completed atleast one course" />
                                                </div>
                                                <div className={Styles.completionRateContainer}>
                                                    <div className={Styles.completionRateNumber}>{landingState.landingMidContainer.completionRate}</div>
                                                    <div className={Styles.completionPercent}>%</div>
                                                    <div className={Styles.horizontalProgressBarComponent}>
                                                        <HorizontalProgressBar {...landingState.landingMidContainer.completionRateGraph} /> 
                                                    </div>
                                                </div>                                        
                                            </div>
                                        </WhiteBox>
                                    </div>
                                    <div className={Styles.whitebox2Shadow1}>
                                    </div>
                                    <div className={Styles.whitebox2shadow2}>  
                                    </div>
                                </div>
                            </div>
                            <div className={Styles.landingWhiteboxShadow3}>
                                <div className={Styles.landingWhitebox3}>
                                    <Milestone data={landingState.landingMidContainer.milestonesData} />
                                </div>
                                <div className={Styles.whitebox3Shadow1}></div>
                                <div className={Styles.whitebox3Shadow2}></div>
                            </div>
                        </div>
                    </div>
                    )
                }

                <div className={Styles.landingBottomContainer}>
                    <div className={Styles.landing_line}></div>
                    <div className={Styles.landing_tabcontainer}>
                        <div data-value="ACTIVITY" className={Styles.tab} onClick={handleTabClick}>
                            <div data-value="ACTIVITY" className={Styles.tabText}>ACTIVITY</div>
                        </div>
                        <div data-value="PERFORMANCE" className={Styles.tab} onClick={handleTabClick}>
                            <div data-value="PERFORMANCE" className={Styles.tabText}>PERFORMANCE</div>
                        </div>
                        <div data-value="EXPERIENCE" className={Styles.tab} onClick={handleTabClick}>
                            <div data-value="EXPERIENCE" className={Styles.tabText}>EXPERIENCE</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Landing ;