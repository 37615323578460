import React, { useState } from 'react'
import Styles from './Dashboard.module.css'
import Landing from '../Landing/Landing'
import HeaderFilterTab from '../HeaderFilterTab/HeaderFilterTab'
import AOS from 'aos';
import 'aos/dist/aos.css';

function Dashboard(props) {
    
    const [selectedTab, setSelectedTab] = useState('PERFORMANCE')
    const [dropdownItem, setDropdownItem] = useState('All Courses')
    const [dropdownId, setDropdownId] = useState(1)
    const [showComponent, setShowComponent] = useState('Landing')
    const [org_id, set_org_id] = useState(337)
    const [orgName, setOrgName] = useState('Amul Test EM Org 4')

    let conditionalComponent 

    if(showComponent==='Landing') {
        conditionalComponent = <div className={Styles.dashboardLandingContainer}>
                                    <Landing selectedTab={selectedTab} setSelectedTab={setSelectedTab} showComponent={showComponent} setShowComponent={setShowComponent} org_id={org_id} set_org_id={set_org_id} orgName={orgName} setOrgName={setOrgName} />
                                </div>
    }

    if(showComponent==='Dashboard') {
        conditionalComponent = <HeaderFilterTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} dropdownItem={dropdownItem} setDropdownItem={setDropdownItem} dropdownId={dropdownId} setDropdownId={setDropdownId} showComponent={showComponent} setShowComponent={setShowComponent} />

    }

    return (
        <div>
            {conditionalComponent}
        </div>
    )
}

export default Dashboard