import { gql } from "apollo-boost";

export const BannerQuery = gql`
  query MyQuery($orgId : bigint!, $courseId: bigint!) {
    banner: dashboard_filters(distinct_on: journey_instance_id, where: {organization_id: {_eq: $orgId}, _and:{ journey_instance_id: {_eq: $courseId}}})
        {
          journey_domain
          journey_categories
          filterString{
            langvalue
          }
          filterStringName{
            langvalue
          }
        }
      }
`   

export const AverageFeedbackQuery = gql`
query MyQuery($users : [bigint!]! ,  $batches: [bigint!]!,$benchmarkusers : [bigint!]!, $benchmarkbatches : [bigint!]!) {
  averageFeedback: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, userid: {_in: $users}, batchid: {_in: $batches}, isdeleted: {_eq: "0"}}) {
            aggregate {
              avg {
                rating
              }
            }
          }
        
          benchMark: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, isdeleted: {_eq: "0"}, userid: {_in: $benchmarkusers},batchid: {_in: $benchmarkbatches}}) {
            aggregate {
              avg {
                rating
              }
            }
          }
        
          couseFeedbackSatisfaction: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
            aggregate {
              avg {
                rating
              }
            }
          }
        
          couseFeedbackEngagement:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "impact"}, isdeleted: {_eq: "0"} userid: {_in: $users}, batchid: {_in: $batches}}) {
            aggregate {
                avg {
                rating
                }
            }
        }
        
        couseFeedbackPlatform:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "platform"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
            aggregate {
                avg {
                rating
                }
            }
        }
        
        couseFeedbackContent:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
            aggregate {
                avg {
                rating
                }
            }
        }
  }
`
export const ResponsesQuery = gql`
    query MyQuery($users: [bigint!]!, $batches: [bigint!]!) {
      likes: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
        aggregate {
          sum {
            likescount
          }
        }
      }
      disLikes: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, isdeleted: {_eq: "0"}, userid: {_in: $users}, batchid: {_in: $batches}}) {
        aggregate {
          sum {
            dislikescount
          }
        }
      }
    }
    ` 

export const WordCloudQuery = gql`
    query MyQuery($users : [bigint!]!, $batches: [bigint!]!) {        
        wordCloud: feedback_user_tag_enitity_wise_feedback_data(where: { tagname: {_eq: "suggestion"}, isdeleted: {_eq: "0"},userid: {_in: $users}, batchid: {_in: $batches}}) {
            textvalue
          }
    }
` 
