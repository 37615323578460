import { gql } from "apollo-boost";
export const ExperinceTabBackgroundQueries = gql`
query MyQuery($orgId:bigint!) {  
    DealUsers : journey_deal(where: {organizationid: {_eq: $orgId}}) {
      dealid
      name
      DealUserArrayRelation(distinct_on: userid, order_by: {userid: asc}) {
        userid
      }
    }
    FormatBatches : journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}}) {
      BatchJourneyInstance(distinct_on: batchid) {
        batchid
      }
      JourneyJourneyInstance(distinct_on: format) {
        format
      }
    }
    batches: journey_batch(distinct_on: name, where: {journeybatchinstance: {createdbyorgid: {_eq: $orgId} }}) {
      name
      batchid
    }
}
`

  