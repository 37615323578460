import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import SelectDropdown from './selectDropdownFilter/selectDropdownFilter'
import CheckboxFilter from './checkBoxFilter/checkBoxFilter'
import RadioButtonFilter from './tabedCheckFilter/tabedCheckFilter'
import DownloadReport from './downloadReport/downloadReport'
import Footer from './footer/footer'
import Styles from './filterPanel.module.css'
import Divider from '@material-ui/core/Divider'
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLess';
import IleadFT from '../FeedbackTabIlead/FeedbackTabIlead'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery} from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { UPDATE_FILTERS_DEFAULT,UPDATE_FILTERPANEL_API_ITEMS, UPDATE_DEMOGRAPHICS_USERS,UPDATE_COURSE_FILTERPANEL_ITEMS_LIST,UPDATE_NOT_REQUIRED_LANDING_ITEMS, UPDATE_FILTER_USERS,UPDATE_BENCHMARK_FILTER_USERS, UPDATE_DOMAIN_DEMOGRAPHICS_FILTERS} from '../../constants/actions'
import Loader from '../../bower_components/SVG-Loaders/svg-loaders/bars1.svg'
import FilterLoader from '../../commonComponents/Loaders/FilterPanel/FilterPanelLoader'
import {ExperinceTabBackgroundQueries} from './ExperienceTabBackgroundQueries'
import {handleFilterUsersObj,handleBenchmarkFilterUsersObj} from '../../actions/filterUsersAction'



function FilterPanel(props){
    function arrayTostr(array){       
        let newArray = array.join('","')
        return `"${newArray}"`

    }    
    const {deals,batches,learningFormats,industries,jobCategories,workExperiences,peopleManagementExperiences} = useSelector(state=>state.orgLevelReducer.filtersPanelCheckedList)
    const {selectedCourse} = useSelector(state=>state.orgLevelReducer.header)
    const { orgId,id } = useParams()
    const dispatch = useDispatch()
    const [demographicsState,setDemographicsState] = React.useState(false)
    const filterState = useSelector(state => state.orgLevelReducer)
    const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)

    let batchToSearch = []
        let batchToSearchString = [] 
        batchToSearch = filterCheckedListState.batches;
        if(batchToSearch[0] === "All Batches"){
            batchToSearch = Object.assign([], filterState.filtersPanelApi.batches[1].params);
            batchToSearch.indexOf(null) !== -1 && batchToSearch.splice(batchToSearch.indexOf(null), 1)
            batchToSearch.indexOf('All Batches') !== -1 && batchToSearch.splice(batchToSearch.indexOf('All Batches'), 1)
            batchToSearch.forEach((eachBatch) =>{
                batchToSearchString.push('"'+eachBatch+'"')
            })
        }
        else{
            batchToSearch.forEach((eachBatch) =>{
                batchToSearchString.push('"'+eachBatch+'"')
            })
        }

    const handleDemographics = () => {
        setDemographicsState(!demographicsState)
    }

    function handleFilterUsers()
    {   
        let orgClause = `organization_id: {_eq: "${orgId}"} ,`
        let courseClause = id == 1 ? ' ': `journey_instance_id : {_eq: "${id}"} ,`
        let dealClause = deals[0] ==='All Deals' ? '' :`deal_name: {_in : [${arrayTostr(deals)}]} ,`
        let batchClause = batches[0] ==='All Batches' ? '': `batch_name: {_in: [${arrayTostr(batches)}]} ,`
        let formatClause = learningFormats[0] ==='All Formats' ? '': `journey_format: {_in: [${arrayTostr(learningFormats)}]} ,`
        let industryClause = industries.length ? `user_industry: {_in: [${arrayTostr(industries)}]} ,` : ''
        let jobClause = jobCategories.length ? `user_category: {_in: [${arrayTostr(jobCategories)}]},` : ''   
        let workClause = workExperiences[0] === 'All' ? '' : `user_experience_level: {_in: [${arrayTostr(workExperiences)}]},`
        let managementClause = peopleManagementExperiences[0] === 'All' ? '' : `user_managing_experience: {_in: [${arrayTostr(peopleManagementExperiences)}]}`
        
        const FILTER_USERS_QUERY =  gql `
                query Myquery{               
                    filterUsers: dashboard_filters(where: {${orgClause} ${courseClause} ${dealClause} ${batchClause} ${formatClause} ${industryClause} ${jobClause} ${workClause} ${managementClause} }, distinct_on: [user_id,batch_id]) {
                        user_id,
                        batch_id,
                        journey_tags,
                        journey_instance_id,
                        journey_domain,
                        journey_categories
                      }
                }
                
            `       
        
        return FILTER_USERS_QUERY 
    }    

    function handleCourseBenchmarkFilterUsers()
    {   let courseClause,dealClause,batchClause,formatClause,industryClause,jobClause,workClause,managementClause = ''
        if(id === 1){             
             industryClause = industries.length ? `user_industry: {_in: [${arrayTostr(industries)}]} ,` : ''
             jobClause = jobCategories.length ? `user_category: {_in: [${arrayTostr(jobCategories)}]},` : ''   
             workClause = workExperiences[0] === 'All' ? '' : `user_experience_level: {_in: [${arrayTostr(workExperiences)}]},`
             managementClause = peopleManagementExperiences[0] === 'All' ? '' : `user_managing_experience: {_in: [${arrayTostr(peopleManagementExperiences)}]}`
        
        }
        else{
             courseClause = id == 1 ? ' ': `journey_instance_id : {_eq: "${id}"} ,`
             dealClause = deals[0] ==='All Deals' ? '' :`deal_name: {_in : [${arrayTostr(deals)}]} ,`
             batchClause = batches[0] ==='All Batches' ? '': `batch_name: {_in: [${arrayTostr(batches)}]} ,`
             formatClause = learningFormats[0] ==='All Formats' ? '': `journey_format: {_in: [${arrayTostr(learningFormats)}]} ,`
             industryClause = industries.length ? `user_industry: {_in: [${arrayTostr(industries)}]} ,` : ''
             jobClause = jobCategories.length ? `user_category: {_in: [${arrayTostr(jobCategories)}]},` : ''   
             workClause = workExperiences[0] === 'All' ? '' : `user_experience_level: {_in: [${arrayTostr(workExperiences)}]},`
             managementClause = peopleManagementExperiences[0] === 'All' ? '' : `user_managing_experience: {_in: [${arrayTostr(peopleManagementExperiences)}]}`
        }
        
        const BENCHMARK_FILTER_USERS_QUERY =  gql `
                query Myquery{               
                    filterUsers: dashboard_filters(where: {${courseClause} ${dealClause} ${batchClause} ${formatClause} ${industryClause} ${jobClause} ${workClause} ${managementClause} } , distinct_on: [user_id,batch_id]) {
                        user_id,
                        batch_id,
                        journey_tags
                      }
                }
                
            `
                 
        
        return BENCHMARK_FILTER_USERS_QUERY 
    }    

    function handleFiltersQuery () {        
        let courseClause = selectedCourse != 'All Courses' ? `,StringJourneyInstance: {langvalue: {_eq: "${selectedCourse}" }}` : ''        
        const FILTERS_QUERY = gql`
            query Myquery($orgId:bigint!) {
                dealName: dashboard_filters(distinct_on: deal_name, where: {organization_id: {_eq: $orgId}}) {
                    deal_name
                    journey_tags
                  }
                batchesArray: dashboard_filters(distinct_on: [journey_instance_id batch_name], where: {organization_id: {_eq: $orgId}}) {
                    journey_instance_id
                    batch_name
                    journey_tags
                  }
                formatArray: dashboard_filters(distinct_on: [journey_instance_id journey_format], where: {organization_id: {_eq: $orgId}}) {
                    journey_instance_id
    				journey_format
                    journey_tags
                  }
            }
        `
        return FILTERS_QUERY       
        
    }    

    const demographicsQuery = gql`
    query Myquery($orgId:bigint!) {
          industryArray: dashboard_filters(distinct_on: [journey_instance_id, user_industry], where: {organization_id: {_eq: $orgId}, batch_name: {_in: [${batchToSearchString}]}}) {
            journey_instance_id
            batch_name
            user_industry
            journey_tags
          }
        jobCategoryArray: dashboard_filters(distinct_on: [journey_instance_id, user_category], where: {organization_id: {_eq: $orgId}, batch_name: {_in: [${batchToSearchString}]}}) {
            user_category
            journey_instance_id
            journey_tags
          }
        wexperienceArray: dashboard_filters(distinct_on: [journey_instance_id, user_experience_level], where: {organization_id: {_eq: $orgId}, batch_name: {_in: [${batchToSearchString}]}}) {
            user_experience_level
            journey_instance_id
            journey_tags
          }
        mexperienceArray: dashboard_filters(distinct_on: [journey_instance_id, user_managing_experience], where: {organization_id: {_eq: $orgId}, batch_name: {_in: [${batchToSearchString}]}}) {
            user_managing_experience
            journey_instance_id
            journey_tags
          }
    }
    `
    const { loading, error, data } = useQuery(handleFiltersQuery(), { variables: { orgId: orgId}}, {fetchPolicy: 'no-cache'}) 
    const { loading: demographicsLoader, error: demographicsError, data: demographicsData } = useQuery(demographicsQuery, { variables: { orgId: orgId}})
    const {  loading: notReqLoading, error: notReqError, data: notReqData } = useQuery(ExperinceTabBackgroundQueries, { variables: { orgId: orgId }}) ;
    const { loading:FilterUsersLoading, error: FilterUsersError, data:FilterUsersData } = useQuery(handleFilterUsers()) ;
    const { loading:BenchmarkFilterUsersLoading, error: BenchmarkFilterUsersError, data:BenchmarkFilterUsersData } = useQuery(handleCourseBenchmarkFilterUsers()) ;

    //console.log(batchToSearchString)
    /*useEffect(() => {
        dispatch({type: UPDATE_FILTERS_DEFAULT})
    }, [id])*/

    useEffect(() => {
        if(!loading && !error && data!=null){
            //console.log(data.dealName)
            //console.log(1)
            dispatch({type: UPDATE_FILTERPANEL_API_ITEMS, payload: data, item: id})
        }
    }, [loading, error, data, id]) 
    
    useEffect(() => {
        if(!demographicsLoader && !demographicsError && demographicsData!=null)
        {
            dispatch({ type: UPDATE_DOMAIN_DEMOGRAPHICS_FILTERS, payload:demographicsData, item: id })
        }
    }, [demographicsLoader, demographicsError, demographicsData, id])
    
    useEffect(() => {
        if(!notReqLoading && !notReqError && notReqData!=null) {
            dispatch({type: UPDATE_NOT_REQUIRED_LANDING_ITEMS, payload:notReqData})
        }   
    }, [notReqLoading, notReqError, notReqData])

    useEffect(() => {
        if(!FilterUsersLoading && !FilterUsersError && FilterUsersData!=null) {
            dispatch(handleFilterUsersObj(FilterUsersData.filterUsers,UPDATE_FILTER_USERS))
        }   
    }, [FilterUsersLoading, FilterUsersError, FilterUsersData])

    useEffect(() => {
        if(!BenchmarkFilterUsersLoading && !BenchmarkFilterUsersError && BenchmarkFilterUsersData!=null) {
            dispatch(handleBenchmarkFilterUsersObj(BenchmarkFilterUsersData.filterUsers,UPDATE_BENCHMARK_FILTER_USERS))
        }   
    }, [BenchmarkFilterUsersLoading, BenchmarkFilterUsersError, BenchmarkFilterUsersData])

    if(loading) {
        return (<div className={Styles.filterLoader}>
            <FilterLoader />
        </div>)
    }
    if(error) {
        console.log(error)
        return <div>Error..</div>
    }
    return(
        <div className={Styles.body}>
            <div className={Styles.filterpanel}>
                <div className={Styles.dropdown_panel}>
                    <SelectDropdown parameters={filterState.filtersPanelApi.deals} /> 
                    <div className={props.course === 'All Courses' ? Styles.disable_batches : null}>
                        <SelectDropdown parameters={filterState.filtersPanelApi.batches} course={props.course}/>
                    </div>
                    <SelectDropdown parameters={filterState.filtersPanelApi.formats} />

                    <div onClick={handleDemographics} className={Styles.demographics}>
                        <div className={Styles.demographics_title}>
                            <span>DEMOGRAPHICS </span>
                        </div>
                        <div className={Styles.demographics_arrow_icon}>
                            { demographicsState ?<ExpandLessOutlinedIcon/> : <ExpandMoreOutlinedIcon />}
                        </div>                        
                    </div>
                    { !demographicsState ? <div><Divider/></div> : null }
                </div>

                <div className={`${Styles.checkbox_panel} ${!demographicsState ? Styles.demo_not_disp : null}`}>
                    <div className={Styles.insidepanel}>
                        <CheckboxFilter parameters={filterState.demographisFilters.industries}/>
                        <CheckboxFilter parameters={filterState.demographisFilters.jobCategories}/>
                        <RadioButtonFilter parameters={filterState.demographisFilters.workExperiences}/>
                        <RadioButtonFilter parameters={filterState.demographisFilters.peopleManagementExperiences}/>
                    </div>
                </div>
                   

                <div className={Styles.download_footer_panel}>
                    
                    <Divider/>  
                    <Footer/>                  
                </div>
            </div>  
        </div>

    );  
}
export default FilterPanel