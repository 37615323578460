import _, { forIn } from 'lodash'
import uniq from 'lodash.uniq'
//import {UPDATE_ORG_NAME, UPDATE_ORG_LOGO, UPDATE_ORG_LIST ,UPDATE_ORG_DESCRIPTION , UPDATE_LANDING_MID_CONTAINER_ITEMS, UPDATE_NOT_REQUIRED_LANDING_ITEMS,UPDATE_ORG_TOTAL_USERS ,UPDATE_LANDING_PAGE_ITEMS,UPDATE_HEADER_ITEMS,UPDATE_FILTERPANEL_API_ITEMS,UPDATE_FILTER_ITEM} from '../../constants/actions'
// import {UPDATE_ORG_NAME,UPDATE_ORG_LIST, UPDATE_ORG_LOGO ,UPDATE_ORG_DESCRIPTION , UPDATE_LANDING_MID_CONTAINER_ITEMS, UPDATE_NOT_REQUIRED_LANDING_ITEMS,UPDATE_ORG_TOTAL_USERS ,UPDATE_LANDING_PAGE_ITEMS,UPDATE_HEADER_ITEMS,UPDATE_FILTERPANEL_API_ITEMS,UPDATE_FILTER_ITEM,UPDATE_DEMOGRAPHICS_USERS,UPDATE_SELECTED_COURSE,UPDATE_COURSE_FILTERPANEL_ITEMS_LIST} from '../../constants/actions'
import {UPDATE_DOMAIN_DEMOGRAPHICS_FILTERS,UPDATE_FILTERS_DEFAULT ,UPDATE_ORG_NAME,UPDATE_ORG_LIST, UPDATE_ORG_ID,UPDATE_ORG_LOGO ,UPDATE_ORG_DESCRIPTION , UPDATE_LANDING_MID_CONTAINER_ITEMS, UPDATE_NOT_REQUIRED_LANDING_ITEMS,UPDATE_ORG_TOTAL_USERS ,UPDATE_LANDING_PAGE_ITEMS,UPDATE_HEADER_ITEMS,UPDATE_FILTERPANEL_API_ITEMS,UPDATE_FILTER_ITEM,UPDATE_DEMOGRAPHICS_USERS,UPDATE_SELECTED_COURSE,UPDATE_COURSE_FILTERPANEL_ITEMS_LIST,UPDATE_FILTER_USERS,UPDATE_BENCHMARK_FILTER_USERS, UPDATE_HEADER_ORG_DETAILS} from '../../constants/actions'
 
let dataForHorizontalStackedBar = {
    height: 15 ,
    width: 175,
    data: [
        {Consumed: 160, 'Total Licenses': 40 },
    ]
}

let dataForHorizontalProgressBar = { width : 200, height :10, Total: 100, score: 90 };

if(window.screen.width>=1920) {
    dataForHorizontalStackedBar = {
        height: 18 ,
        width: 250,
        data: [
            {Consumed: 160, 'Total Licenses': 40 },
        ]
    }
    dataForHorizontalProgressBar = { width : 200, height :12.5, Total: 100, score: 90 }
}

if(window.screen.width>=2560) {
    dataForHorizontalStackedBar = {
        height: 22 ,
        width: 350,
        data: [
            {Consumed: 160, 'Total Licenses': 40 },
        ]
    }   
    dataForHorizontalProgressBar = { width : 325, height :15, Total: 100, score: 90 };
}

const initialState = {
    organizationId: {
        organizationId: 337
    },
    landingPage:{    
        orgName: 'ORG NAME',
        orgLogo: '',
        orgDescription:  `KNOLSKAPE’s Talent Intelligence dashboard provides in-depth analytics and reporting with detailed insights for your organization to effectively manage your talent pool. 
         Deep analytics, insights and reports power our two-fold talent intelligence mechanism, allowing learners to recognize their leadership competence and preparedness, while helping organizations gauge their learners’ engagement levels, and their performance and potential as leaders.` ,
        orgId: 337
    },
    landingMidContainer: {
        courses: 5,
        batchesCorrectCount: 0,
        batchesArray: [],
        batchesCount: 0,
        uniqueUsers: 1600,
        totalUsers: 90,
        usersWithProgress: 100,
        averageFeedbackRating: 4.5,
        completionRate: 90,
        licensesGraph : dataForHorizontalStackedBar,
        completionRateGraph: dataForHorizontalProgressBar,
        usersWithProgressArray: [],
        totalUsersArray: [],
        timeSpentArray: [],
        maxLearnersArray: [],
        learnersWhoAccessed: 10,
        milestonesData : [
            {
                title:'Course With Highest Completion Rate',
                course:'',
                percent:0,
            },
            {
                title:'Average Time Spent On The Platform',
                hour:3,
                min:22,
            },
            {
                title:'Course With Maximum Enrolled Learners',
                course:'',
                count:0
            }
        ],
        licensesStartDate: '',
        licensesEndDate: ''
    },
    landingNotRequired: {
        journeyInstanceDeal: [],
        formatJourneyInstance: [],
        demographicsUsers: [],
        orgList: [{'organizationid': 1, 'name': 'org1'}]
    },
    headerOrg: {
        orgName: 'AAAA',
        orgLogo: ''
    },
    header:{
        dropDownAraay : [{id: 1, name: "All Courses", tag: 'all'}, {id:2, name: "Leading Now", tag: 'now'}, {id:3, name: "Leading Next", tag: 'next'}],
        courses: [{leadingNowArray: [{id:6, name: "BYB"}]}, {leadingNextArray: [{id:7, name:"Clearing the Digital BLUR"}]}],
        selectedCourse: 'All Courses'
    },
    filtersPanelApi: {
        deals: [{header:'Deal'}, {params:['All Deals']}],
        dealsArray: [],
        batchesArray: [],
        formatArray: [],
        batches: [{header:'Batch'}, {params:['All Batches']}],
        formats: [{header:'Learning Format'}, {params:['All Formats']}],

    },
    demographisFilters: {
        industries: [{header:'Industry'},{params:['Retail Oerations', 'Health Care','Education','IT','Food Services']},{divider:true}],
        jobCategories: [{header:'Job Category'},{params:['Individual Contributors', 'First-Time Managers','Middle Managers','Executive Leaders','Others','Individua Contributors', 'First-Time Manaers','Middle Mangers','Executve Leaders','Oters']},{divider:true}],
        workExperiences: [{header:'work Experience'},{params:['0-2','2-5','5-12']},{divider:true}],
        peopleManagementExperiences: [{header:'People Management Experience'},{params:['0-2','2-5']},{divider:false}]

    },
    filtersPanelCheckedList: {
        deals: [],
        batches: [],
        learningFormats: [],
        industries: [],
        jobCategories: [],
        workExperiences: [],
        peopleManagementExperiences: [],   
    },
    courseSpecificfiltersPanelApi: {  
        courseDeals: [{header:'Deal'}, {params:['All Deals']}],        
        courseBatches: [{header:'Batch'}, {params:['All Batches']}],
        courseFormats: [{header:'Learning Format'}, {params:['All Formats']}],
        
    },
    filterUsersAndJourneyList:{
        filterUsers: [],
        filterBatches: [],
        filterJourneyInstances: [],
        journeyDomainCategories: {}
    },
    benchmarkFilterUsersList:{
        benchmarkFilterUsers: [],
        benchmarkFilterBatches: []
    }
}

const converTimeToDate = (secs) => {
    let d = new Date(secs*1000)
    return d
}

const calculateBatches = (dataArray) => {
    let sumOfBatches=0
    for(let i=0 ; i<dataArray.length ; i++)
    {
        sumOfBatches = sumOfBatches + dataArray[i].BatchJourneyInstance_aggregate.aggregate.count
    }
    return sumOfBatches
}

const calculateStartDate = (dataArray) => {
    if(dataArray.length===0)
    {
        return ''
    }
    let sdArray = []
    let sd
    for(let i=0 ; i<dataArray.length ; i++)
    {
        sdArray.push(dataArray[i].startdate)
    }
    sdArray.sort(function(a, b){return a - b})
    sd = converTimeToDate(sdArray[0])
    let dd = String(sd.getDate()).padStart(2, '0')
    let mm = String(sd.getMonth() + 1).padStart(2, '0')
    let yy = String(sd.getFullYear())
    let finalSd = `${dd}/${mm}/${yy[2]}${yy[3]}`
    return finalSd
}

const calculateEndDate = (dataArray) => {
    if(dataArray.length===0)
    {
        return ''
    }
    let edArray =[]
    let ed
    for(let i=0 ; i<dataArray.length ; i++)
    {
        edArray.push(dataArray[i].enddate)
    }
    edArray.sort(function(a, b){return a - b})
    ed = converTimeToDate(edArray[edArray.length-1])
    let dd = String(ed.getDate()).padStart(2, '0')
    let mm = String(ed.getMonth() + 1).padStart(2, '0')
    let yy = String(ed.getFullYear())
    let finalEd = `${dd}/${mm}/${yy[2]}${yy[3]}`
    return finalEd
}

const calculateLicensesGraph = (dataArray, val) => {
    if(dataArray.length===0)
    {
        dataForHorizontalStackedBar.data[0].Consumed = 1
        dataForHorizontalStackedBar.data[0]['Total Licenses'] = 2
    }
    else  {
        let total=0
        let remaining=0
        let consumed
        for(let i=0 ; i<dataArray.length ; i++) {
            total = total + dataArray[i].totallicenses
            remaining = remaining + dataArray[i].licensesremaining
        }
        consumed = total - remaining
        dataForHorizontalStackedBar.data[0].Consumed = val
        dataForHorizontalStackedBar.data[0]['Total Licenses'] = total
    }
    return dataForHorizontalStackedBar
}

const calculateMilestones = (dataArray1, dataArray2, dataArray3, dataArray4) => {
    let avgTimeSpent=0
    if(dataArray1.length==0 || dataArray2.length==0)
    {
        initialState.landingMidContainer.milestonesData[0].course = " "
        initialState.landingMidContainer.milestonesData[0].percent = 0.5
    }
    else {
        for(let i=0 ; i<dataArray1.length ; i++) {
            if(((dataArray1[i].UserJourneyInstance_aggregate.aggregate.count/dataArray2[i].UserJourneyInstance_aggregate.aggregate.count)*100) > initialState.landingMidContainer.milestonesData[0].percent) {
                initialState.landingMidContainer.milestonesData[0].course = dataArray1[i].StringJourneyInstance.langvalue
                initialState.landingMidContainer.milestonesData[0].percent = Math.round((dataArray1[i].UserJourneyInstance_aggregate.aggregate.count/dataArray2[i].UserJourneyInstance_aggregate.aggregate.count)*100)
            }
        }
    }
    for(let i=0 ; i<dataArray3.length ; i++)
    {
        avgTimeSpent = avgTimeSpent + dataArray3[i].UserJourneyInstance_aggregate.aggregate.avg.journey_instance_timespent
    }
    let total3 = dataArray3.length
    if(dataArray3.length==0)
    {
        initialState.landingMidContainer.milestonesData[1].hour = '0'
        initialState.landingMidContainer.milestonesData[1].min = '0'
    }
    else {
        avgTimeSpent = avgTimeSpent/dataArray3.length
        initialState.landingMidContainer.milestonesData[1].hour = Math.floor(avgTimeSpent / 3600)
        initialState.landingMidContainer.milestonesData[1].min = Math.round((avgTimeSpent % 3600) / 60)
    }    
    if(dataArray4.length==0) 
    {
        initialState.landingMidContainer.milestonesData[2].course = " "
        initialState.landingMidContainer.milestonesData[2].count = '0'
    }
    else {
        for(let i=0 ; i<dataArray4.length ; i++)
        {
            if(dataArray4[i].UserJourneyInstance_aggregate.aggregate.count > initialState.landingMidContainer.milestonesData[2].count)
            {
                initialState.landingMidContainer.milestonesData[2].course = dataArray4[i].StringJourneyInstance.langvalue
                initialState.landingMidContainer.milestonesData[2].count = dataArray4[i].UserJourneyInstance_aggregate.aggregate.count
            }
        }
    }
    return initialState.landingMidContainer.milestonesData
}

const calculateAverageRating = (val) => {
    let lessValue = Math.floor(val)
    let highValue = Math.ceil(val)
    let middleValue = (lessValue + highValue)/2
    let diff = val - lessValue
    if(diff<0.25)
        return lessValue
    else if(diff>0.75)
        return highValue
    else 
        return middleValue
}

const checkTags = (dataArray) => {
    let isPresent = false
    if(dataArray)
    {
        for(let i=0 ; i<dataArray.length ; i++)
        {
            if(dataArray[i].name.toLowerCase()=='passport')
            {
                isPresent = true
                break
            }
        }
    }
    return isPresent
}

const listDeals = (dataArray) => {
    let deals = [{header:'Deal'}, {params:['All Deals']}]
    for(let i=0 ; i<dataArray.length ; i++) 
    {
        if(checkTags(dataArray[i].journey_tags))
            deals[1].params.push(dataArray[i].deal_name)
    }
    return deals
}

const listBatches = (dataArray, id) => {
    let batches = [{header:'Batch'}, {params:['All Batches']}]
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].batch_name!=null)
        {
            if(checkTags(dataArray[i].journey_tags))
            {
                if(id==1)
                {
                    batches[1].params.push(dataArray[i].batch_name)
                }
                else{
                    if(dataArray[i].journey_instance_id==id)
                    {
                        batches[1].params.push(dataArray[i].batch_name)
                    }
                }
            }
        }
    }
    return batches
}

const listFormats = (dataArray, id) => {
    let formats = [{header:'Learning Format'}, {params:['All Formats']}]
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].journey_format!=null)
        {
            if(checkTags(dataArray[i].journey_tags))   
            {
                if(id==1)
                {
                    formats[1].params.push(dataArray[i].journey_format)
                }
                else {
                    if(dataArray[i].journey_instance_id==id)
                    {
                        formats[1].params.push(dataArray[i].journey_format)
                    }
                }
            }
        }
    }
    let p = _.uniq(formats[1].params, false)
    formats[1].params = p

    return formats
}

const listCourses = (dataArray) => {
    let courses = [{leadingNowArray: []}, {leadingNextArray: []}]
    let reference = [
        {key: "cq", values: ["cq", "changequest"]},
        {key: "agile", values: ["agile", "agilesim", "agilesimualtion"]},
        {key: "ilead", values: ["ilead", "leadershipsim"]},
        {key: "trust", values: ["trust", "trustsim", "trustsimulation"]}
    ]
    for(let i=0 ; i<dataArray.length; i++)
    {
            if(dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_NOW' || dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_SELF' || dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_TEAMS' || dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_BUSINESS')
            {
                let arr = JSON.parse(dataArray[i].JourneyJourneyInstance[0].tags)
                let tag='notag'
                checkTags:
                for(let j=0 ; j<arr.length ; j++)
                {
                    for(let k=0 ; k<reference.length ; k++)
                    {
                        for(let l=0 ; l<reference[k].values.length ; l++)
                        {
                            if(arr[j].name && arr[j].name.toLowerCase()==reference[k].values[l])
                            {
                                tag = reference[k].key
                                break checkTags
                            }
                        }
                    }
                }
                courses[0].leadingNowArray.push({id: dataArray[i].journeyinstanceid, name: dataArray[i].StringJourneyInstance.langvalue, tag: tag})
            }
            else if(dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_NEXT' || dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_DIGITAL_EXECUTION' || dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_DIGITAL_TRANSFORMATION' || dataArray[i].JourneyJourneyInstance[0].domain==='LEADING_IN_THE_DIGITAL_WORLD')
            {
                let arr = JSON.parse(dataArray[i].JourneyJourneyInstance[0].tags)
                let tag='notag'
                checkTags:
                for(let j=0 ; j<arr.length ; j++)
                {
                    for(let k=0 ; k<reference.length ; k++)
                    {
                        for(let l=0 ; l<reference[k].values.length ; l++)
                        {
                            if(arr[j].name && arr[j].name.toLowerCase()==reference[k].values[l])
                            {
                                tag = reference[k].key
                                break checkTags
                            }
                        }
                    }
                }
                courses[1].leadingNextArray.push({id: dataArray[i].journeyinstanceid, name: dataArray[i].StringJourneyInstance.langvalue, tag: tag})
            }
            /*else {
                let arr = JSON.parse(dataArray[i].JourneyJourneyInstance[0].tags)
                let tag='notag'
                checkTags:
                for(let j=0 ; j<arr.length ; j++)
                {
                    for(let k=0 ; k<reference.length ; k++)
                    {
                        for(let l=0 ; l<reference[k].values.length ; l++)
                        {
                            if(arr[j].name && arr[j].name.toLowerCase()==reference[k].values[l])
                            {
                                tag = reference[k].key
                                break checkTags
                            }
                        }
                    }
                }
                courses[2].othersArray.push({id: dataArray[i].journeyinstanceid, name: dataArray[i].StringJourneyInstance.langvalue, tag: tag})
            } */
    }
    //console.log(courses)
    /*courses[0].leadingNowArray = _.uniq(courses[0].leadingNowArray)
    courses[1].leadingNextArray = _.uniq(courses[1].leadingNextArray)*/
    return courses
}

const checkBatches = (name, array) => {
    console.log(array)
    for(let i=0 ; i<array.length ; i++)
    {
        if(('"'+name+'"')==array[i])
        {
            return true
        }
    } 
    return false
}

const listIndustries = (dataArray, id) => {
    
    let industries = [{header:'Industry'},{params:[]},{divider:true}]
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].user_industry!=null && dataArray[i].user_industry.length!=0)
        {
            if(checkTags(dataArray[i].journey_tags))
            {
                if(id==1)
                {
                    /*if(checkBatches(dataArray[i].batch_name, batches))*/
                        industries[1].params.push(dataArray[i].user_industry)                
                }
                else {
                    if(dataArray[i].journey_instance_id==id)
                    {
                        /*if(checkBatches(dataArray[i].batch_name, batches))*/
                            industries[1].params.push(dataArray[i].user_industry)                    
                    }
                }
            }
                
        }
    }
    let p = _.uniq(industries[1].params, false)
    p.sort()
    industries[1].params = p
    
    return industries
}

const listJobCategories = (dataArray, id) => {
    let jobCategories = [{header:'Job Category'},{params:[]},{divider:true}]
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].user_category!=null && dataArray[i].user_category.length!=0)
        {
            if(checkTags(dataArray[i].journey_tags))
            {
                if(id==1)
                {
                    jobCategories[1].params.push(dataArray[i].user_category)             
                }
                else {
                    if(dataArray[i].journey_instance_id==id)
                    {
                        jobCategories[1].params.push(dataArray[i].user_category)                   
                    }
                }
            }
        }
    }
    let p = _.uniq(jobCategories[1].params, false)
    p.sort()
    jobCategories[1].params = p
    return jobCategories
}

const sortExperience = (dataArray) => {
    let array = dataArray
    let arr = []
    let k = array.indexOf("No relevant experience") 
    if(k>=0)
    {
        arr.push("No relevant experience")
        array.splice(k,1)
    }
   
    for(let i=0 ; i<array.length ; i++)
    {
        //console.log(array[i].indexOf("Less"), "index", i)
        if(array[i].indexOf("Less")>=0)
        {
            arr.push(array[i])
            array.splice(i,1)
            break 
        }
    }
    
    let more = {flag: 0,  value: ''}
    for(let i=0 ; i<array.length ; i++)
    {
        if(array[i].indexOf("More")>=0)
        {
            more.flag = 1
            more.value = array[i]
            array.splice(i,1)
            break
        }
    }
    let remainingArray = []
    for(let i=0 ; i<array.length ; i++)
    {
        let parts = array[i].split('-')
        let num = Number(parts[0])
        remainingArray.push({ 'value': array[i], 'sortNum': num })
    }
    remainingArray.sort((a, b) => {
        return a.sortNum - b.sortNum;
    });
    for(let i=0 ; i<remainingArray.length ; i++)
    {
        arr.push(remainingArray[i].value)
    }
    if(more.flag)
    {
        arr.push(more.value)
    }
    return arr
}

const listWorkExperience = (dataArray,id) => {
    let wex = [{header:'work Experience'},{params:[]},{divider:true}]
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].user_experience_level!=null && dataArray[i].user_experience_level.length!=0)
        {
            if(checkTags(dataArray[i].journey_tags))
            {
                if(id==1)
                {
                    wex[1].params.push(dataArray[i].user_experience_level)                
                }
                else {
                    if(dataArray[i].journey_instance_id==id)
                    {
                        wex[1].params.push(dataArray[i].user_experience_level)                    
                    }
                }
            }
        }
    }
    let p = sortExperience(_.uniq(wex[1].params, false))  
    wex[1].params = p
    return wex
}

const listManagementExperience = (dataArray, id) => {
    let mex = [{header:'People Management Experience'},{params:[]},{divider:false}]
    for(let i=0 ; i<dataArray.length ; i++)
    {
        if(dataArray[i].user_managing_experience && dataArray[i].user_managing_experience.length!=0)
        {
            if(checkTags(dataArray[i].journey_tags))
            {
                if(id==1)
                {
                    mex[1].params.push(dataArray[i].user_managing_experience)             
                }
                else {
                    if(dataArray[i].journey_instance_id==id)
                    {
                        mex[1].params.push(dataArray[i].user_managing_experience)                   
                    }
                }
            }
        }
    }
    let p = sortExperience(_.uniq(mex[1].params, false)) 
    mex[1].params = p

    return mex
}

const listDealUsers = (dataArray) => {
    const dealsWithUsers = {}
    for(let i =0 ;i<dataArray.length;i++){
        let prevAllDeals = !dealsWithUsers["All Deals"] ?  [] : dealsWithUsers["All Deals"]
        let usersList = []
        let dealName = dataArray[i].name
        for (let j=0; j<dataArray[i].DealUserArrayRelation.length;j++){
            usersList.push(dataArray[i].DealUserArrayRelation[j].userid)
        }        
        dealsWithUsers[dealName]= usersList  
        dealsWithUsers["All Deals"] = [...prevAllDeals,...usersList]        
    }
    return dealsWithUsers 
}

const listFormatBatches = (dataArray) => {
    const formatWithBatches = {}    
    for(let i=0;i<dataArray.length;i++){
        let formatName = dataArray[i].JourneyJourneyInstance[0].format
        let prevBatches = !formatWithBatches[formatName] ? [] : formatWithBatches[formatName] 
        let prevAllFormats = !formatWithBatches["All Formats"] ? [] : formatWithBatches["All Formats"]
        let batchList = []
        for(let j=0 ;j<dataArray[i].BatchJourneyInstance.length;j++){
            batchList.push(dataArray[i].BatchJourneyInstance[j].batchid)
        }
        formatWithBatches[formatName]= [...prevBatches,...batchList]  
        formatWithBatches["All Formats"] = [...prevAllFormats,...batchList]        
    }   
    return formatWithBatches
}

const listBatchids = (dataArray) =>{
    let batchesWithIds = {}
    batchesWithIds['All Batches'] =[]
    for(let i=0;i<dataArray.length;i++){
        batchesWithIds[dataArray[i].name] = [dataArray[i].batchid]
        batchesWithIds['All Batches'].push(dataArray[i].batchid)

    }
    return batchesWithIds
}

const listDemographicsUsers = (dataArray) => {
    let demographicUsers = []
    for(let i=0; i<dataArray.length; i++){
        demographicUsers.push(dataArray[i].userid)
    }
    console.log(dataArray)
    return demographicUsers
}

const calculateCompletionRate = (obj1, obj2) => {
    let total = obj2.aggregate.count
    if(total==0)
    {
        total=1
    }
    return Math.round((obj1.aggregate.count/total)*100)
}

const calculateCompletionRateGraph = (obj1, obj2) => {
    let total = obj2.aggregate.count
    if(total==0)
    {
        total=1
    }
    initialState.landingMidContainer.completionRateGraph.score = Math.round((obj1.aggregate.count/total)*100)
    return initialState.landingMidContainer.completionRateGraph
}

export function orgLevelReducer(state=initialState, action){
    switch(action.type){
        case UPDATE_ORG_ID:
            return {
                ...state,
                organizationId: {
                    ...state.organizationId,
                    organizationId: action.payload
                }
            }
        
        case UPDATE_LANDING_PAGE_ITEMS:            
            return {
                ...state,
                landingPage:{
                        ...state.landingPage,
                        orgName: action.payload.orgName[0].name,
                        orgLogo: action.payload.orgName[0].logo
                    }
            }
        
        case UPDATE_LANDING_MID_CONTAINER_ITEMS:
            return {
                ...state,
                landingMidContainer: {
                    ...state.landingMidContainer,
                    batchesCorrectCount: action.payload.batchesCorrectCount.aggregate.count,
                    courses: action.payload.coursesCount.aggregate.count,
                    uniqueUsers: action.payload.uniqueLearnersCount.aggregate.count,
                    batchesArray: action.payload.batchesArray,
                    batchesCount: calculateBatches(action.payload.batchesArray),
                    completionRate: calculateCompletionRate(action.payload.usersWithProgress, action.payload.totalUsers) ,
                    completionRateGraph:calculateCompletionRateGraph(action.payload.usersWithProgress, action.payload.totalUsers) ,
                    usersWithProgressArray: action.payload.usersWithProgressArray,
                    totalUsersArray: action.payload.totalUsersArray,
                    timeSpentArray: action.payload.timeSpentArray,
                    maxLearnersArray: action.payload.maxLearnersArray,
                    licensesStartDate: calculateStartDate(action.payload.licensesArray),
                    licensesEndDate: calculateEndDate(action.payload.licensesArray),
                    licensesGraph: calculateLicensesGraph(action.payload.licensesArray, action.payload.uniqueLearnersCount.aggregate.count),
                    milestonesData: calculateMilestones(action.payload.usersWithProgressArray, action.payload.totalUsersArray, action.payload.timeSpentArray, action.payload.maxLearnersArray),
                    averageFeedbackRating:  Math.round(action.payload.averageFeedback.aggregate.avg.rating*100)/100 /*calculateAverageRating(action.payload.averageFeedback.aggregate.avg.rating)*/,
                }
            }    

        case UPDATE_ORG_LIST: 
            return {
                ...state,
                landingOrgList: {
                    ...state.landingOrgList,
                    orgList: initialState.landingOrgList.orgList
                }
            }

        case UPDATE_NOT_REQUIRED_LANDING_ITEMS:
            return {
                ...state,
                landingNotRequired: {
                    ...state.landingNotRequired,
                    journeyInstanceDeal: action.payload.journeyInstanceDeal,
                    formatJourneyInstance: action.payload.formatJourneyInstance,
                    orgList: action.payload.orgList,
                    dealUsers: listDealUsers(action.payload.DealUsers),
                    formatBatches: listFormatBatches(action.payload.FormatBatches),
                    batchIds: listBatchids(action.payload.batches)
                }
            }

        case UPDATE_HEADER_ORG_DETAILS: 
            return {
                ...state, 
                headerOrg: {
                    ...state.headerOrg,
                    orgName: action.payload.orgName[0].name,
                    orgLogo: action.payload.orgName[0].logo 
                }
            }    

        case UPDATE_HEADER_ITEMS:            
            return {
                ...state,
                header:{
                        ...state.header,
                        courses : listCourses(action.payload.dropdown)
                    }
            }
        
        case UPDATE_FILTERPANEL_API_ITEMS:            
            return {
                ...state,
                filtersPanelApi:{
                        ...state.filtersPanelApi,
                        dealsArray: action.payload.dealName,
                        deals: listDeals(action.payload.dealName),
                        batchesArray: action.payload.batchesArray,
                        batches: listBatches(action.payload.batchesArray, action.item),
                        formatArray: action.payload.formatArray,
                        formats: listFormats(action.payload.formatArray, action.item),
                    }
            }
        case UPDATE_DOMAIN_DEMOGRAPHICS_FILTERS: 
            return {
                ...state,
                demographisFilters: {
                    ...state.demographisFilters,
                    industries: listIndustries(action.payload.industryArray, action.item),
                    jobCategories : listJobCategories(action.payload.jobCategoryArray, action.item),
                    workExperiences: listWorkExperience(action.payload.wexperienceArray, action.item),
                    peopleManagementExperiences: listManagementExperience(action.payload.mexperienceArray, action.item)
                }
            }
        case UPDATE_FILTERS_DEFAULT:
            return {
                ...state,
                filtersPanelCheckedList: {
                    deals: ["All Deals"],
                    batches: ["All Batches"],
                    learningFormats: ["All Formats"],
                    industries: [],
                    jobCategories: [],
                    workExperiences: ["All"],
                    peopleManagementExperiences: ["All"], 
                }
            }    

        case UPDATE_FILTER_ITEM:
            const selectedItems= action.payload
            const variable= action.variable
            return {
                ...state,
                filtersPanelCheckedList:{
                        ...state.filtersPanelCheckedList,
                        [variable] : selectedItems
                    }
            }

        case UPDATE_DEMOGRAPHICS_USERS:           
            return {
                ...state,
                landingNotRequired: {
                    ...state.landingNotRequired,
                    demographicsUsers: listDemographicsUsers(action.payload.demographics)

                }
            }
        case UPDATE_SELECTED_COURSE:           
            return {
                ...state,
                header:{
                    ...state.header,
                    selectedCourse : action.payload
                }
            }
        case UPDATE_COURSE_FILTERPANEL_ITEMS_LIST:            
            return {
                ...state,
                courseSpecificfiltersPanelApi:{
                    ...state.courseSpecificfiltersPanelApi,                   
                    courseDeals: listDeals(action.payload.dealName),
                    courseBatches: listBatches(action.payload.batchesArray),
                    courseFormats: listFormats(action.payload.formatArray)
                    
                }
            }
        case UPDATE_FILTER_USERS:            
            return {
                ...state,
                filterUsersAndJourneyList: action.payload
        }
        case UPDATE_BENCHMARK_FILTER_USERS:            
            return {
                ...state,
                benchmarkFilterUsersList: action.payload
        }
        default:
            return state
    }
}
