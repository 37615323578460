import React, { useEffect, useState } from 'react'
import Styles from './PerformanceTabCQ.module.css'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import NoOfUsers from '../../commonComponents/textComponent/NoOfUsers/User'
import WhiteBox from '../WhiteBox/WhiteBox'
import BarGraph from '../../commonComponents/GraphComponents/BarGraph'
import ProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GroupHorizontalBar from '../../commonComponents/GraphComponents/GroupHorizontalBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import CompetencyList from '../../commonComponents/textComponent/CompetenciesList/CourseCompitency'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import BarBenchmarkGroup from '../../commonComponents/GraphComponents/BarBenchmarkGroup'
import BarWithLineChart from '../../commonComponents/GraphComponents/BarWithLineGraph'
import HorizontalBenchmark from '../../commonComponents/GraphComponents/HorizontalBenchmarkLess'
import Metric from '../../commonComponents/textComponent/MetricBox/Metric'
import Action from '../../commonComponents/textComponent/ActionBox/Action'
import { useSelector, useDispatch } from 'react-redux'
import GroupIdeal from '../../commonComponents/GraphComponents/GroupIdealComponent/GroupIdeal'
import TitleDropdown from '../../commonComponents/textComponent/TitleDropdown/TitleDropdown'
import AOS from 'aos';
import { useQuery } from '@apollo/react-hooks'
import { useHistory, useParams } from 'react-router-dom'
import { gql } from "apollo-boost";
import 'aos/dist/aos.css';
import BannerLoader from '../../commonComponents/Loaders/PerformanceTabAll/BannerLoader'
import UsersLoader from '../../commonComponents/Loaders/PerformanceTabAll/UsersLoader'
import AverageBenchmarkLoader from '../../commonComponents/Loaders/PerformanceTabAll/AverageBenchmarkLoader'
import DistributionWhiteboxLoader from '../../commonComponents/Loaders/PerformanceTabAll/DistributionWhiteboxLoader'
import DistributionLoader from '../../commonComponents/Loaders/PerformanceTabAll/DistributionLoader'
import RowWiseLoader from '../../commonComponents/Loaders/PerformanceTabAll/RowWiseLoader'
import { UPDATE_CQ_BANNER_FIRST, UPDATE_CQ_GROUP_BENCHMARK, UPDATE_CQ_COMPETENCY_SCORES, UPDATE_CQ_DISTRIBUTIONS, UPDATE_CQ_DIFFUSION, UPDATE_CQ_STAKEHOLDERS } from '../../constants/actions'

function PerformanceTabCQ(props) {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { orgId, id } = useParams()
    const state = useSelector(state => state.performanceTab)
    const orgLevelState = useSelector(state => state.orgLevelReducer)
	const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)

    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    useEffect(() => {
        window.scrollTo(0,0)
    },[])


    let formatQuery = ''
	let formatToSearch = []
	let formatToSearchString = [] 
	formatToSearch = filterCheckedListState.learningFormats;
	if(formatToSearch[0] === "All Formats"){
		// i.e. when 'All Formats' is selected in Learning Format
		formatQuery = ''
	}
	else{
		formatToSearch.forEach((eachFormat) =>{
			formatToSearchString.push('"'+eachFormat+'"')
		})
		formatQuery = `_in: [${formatToSearchString}]`
	}

	let dealQuery = ''
	let dealToSearch = []
	let dealToSearchString = [] 
	dealToSearch = filterCheckedListState.deals;
	if(dealToSearch[0] === "All Deals"){
		// i.e. when 'All Deals' is selected in Deal
		dealQuery = ''
	}
	else{
		dealToSearch.forEach((eachDeal) =>{
			dealToSearchString.push('"'+eachDeal+'"')
		})
		dealQuery = `_in: [${dealToSearchString}]`
	}

	let batchQuery = ''
	let batchToSearch = []
	let batchToSearchString = [] 
	batchToSearch = filterCheckedListState.batches;
	if(batchToSearch[0] === "All Batches"){
		// i.e. when 'All Batches' is selected in Batch
		batchQuery = ''
	}
	else{
		batchToSearch.forEach((eachBatch) =>{
			batchToSearchString.push('"'+eachBatch+'"')
		})
		batchQuery = `_in: [${batchToSearchString}]`
	}

	let industryQuery = ''
	let industryToSearch = []
	let industryToSearchString = [] 
	industryToSearch = filterCheckedListState.industries;
	if(industryToSearch[0] === undefined){ 
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > INDUSTRY
		industryQuery = ''
	}
	else{
		industryToSearch.forEach((eachIndustry) =>{
			industryToSearchString.push('"'+eachIndustry+'"')
		})
		industryQuery = `_in: [${industryToSearchString}]`
	}

	let jobCatagoryQuery = ''
	let jobCatagoryToSearch = []
	let jobCatagoryToSearchString = [] 
	jobCatagoryToSearch = filterCheckedListState.jobCategories;
	if(jobCatagoryToSearch[0] === undefined){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > JOB CATAGORY
		jobCatagoryQuery = ''
	}
	else{
		jobCatagoryToSearch.forEach((eachjobCatagory) =>{
			jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
		})
		jobCatagoryQuery = `_in: [${jobCatagoryToSearchString}]`
	}

	let workExpQuery = ''
	let workExpToSearch = []
	let workExpToSearchString = [] 
	workExpToSearch = filterCheckedListState.workExperiences;
	if(workExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > Work Experience
		workExpQuery = ''
	}
	else{
		workExpToSearch.forEach((eachworkExp) =>{
			workExpToSearchString.push('"'+eachworkExp+'"')
		})
		workExpQuery = `_in: [${workExpToSearchString}]`
	}

	let manageExpQuery = ''
	let manageExpToSearch = []
	let manageExpToSearchString = [] 
	manageExpToSearch = filterCheckedListState.peopleManagementExperiences;
	if(manageExpToSearch[0] === "All"){
		// i.e. when 'ALL' is selected in DEMOGRAPHICS > People Management Experience
		manageExpQuery = ''
	}
	else{
		manageExpToSearch.forEach((eachManageExp) =>{
			manageExpToSearchString.push('"'+eachManageExp+'"')
		})
		manageExpQuery = `_in: [${manageExpToSearchString}]`
	}

    const bannerFirstQuery = gql`
    query MyQuery {
        bannerFirstDetails: dashboard_filters(distinct_on: journey_instance_id, where: {organization_id: {_eq: ${orgId}}, journey_instance_id: {_eq: ${id}}})
        {
          journey_domain
          journey_categories
          filterString{
            langvalue
          }
          filterStringName{
            langvalue
          }
        }
      }
    `

    const groupBenchmarkQuery = gql`
	query MyQuery {
		learnersCompleted: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}, _and: {filtersRelation: {organization_id: {_eq: "${orgId}"}, _and: {journey_format: {${formatQuery}}, _and: {deal_name: {${dealQuery}}, _and: {user_industry: {${industryQuery}}, _and: {user_category: {${jobCatagoryQuery}}, _and: {user_experience_level: {${workExpQuery}}, _and: {user_managing_experience: {${manageExpQuery}}, _and: {journey_instance_id: {_eq: "${id}"}, _and: {batch_name: {${batchQuery}}}}}}}}}}}, _and: {isdeleted: {_eq: "0"}, _and: {journeyIDTagRelation: {tags: {_ilike: "%Passport%"}}}}}}) {
			aggregate {
				count(columns: user_id, distinct: true)
			}
		}
	}`

    // const groupBenchmarkQuery = gql`
    // query MyQuery {
    //     users: dashboard_sim_performance(distinct_on: [user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
    //       user_id
    //       simPerformanceFilters{
    //         journey_tags
    //       }
    //     }
    //   }
    // `

    const competencyScoresQuery = gql`
    query MyQuery {
        groupAvg: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            overall_competency_score
            simPerformanceFilters{
              journey_tags
            }
          }
        benchmarkAvg: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}}) {
            overall_competency_score
            simPerformanceFilters{
              journey_tags
            }
          }
          groupGraph: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            competencies
            simPerformanceFilters{
                journey_tags
              }
          }
          benchmarkGraph: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}}) {
            competencies
            simPerformanceFilters{
                journey_tags
              }
          }
          adoptionGroup: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
          adoptionBenchmark: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
      }
    `

    const distributionsQuery = gql`
    query MyQuery {
        competencyOverallArray: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            competencies
            simPerformanceFilters{
                journey_tags
              }
          }
        mostFrequentArray: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
          scoreBenchmark: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
      }
      
    `

    const diffusionQuery = gql`
    query MyQuery {
        diffusionCurve: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
       }       
    `

    const stakeholdersQuery = gql`
    query MyQuery {
        timingArray: dashboard_sim_performance(distinct_on: [batch_id, user_id], where: {activity_is_optional: {_eq: false}, is_deleted: {_eq: "0"}, product_service_name: {_eq: "cq-v2"}, simPerformanceFilters: {is_deleted: {_eq: "0"}, organization_id: {_eq: ${orgId}}, deal_name: {${dealQuery}}, batch_name: {${batchQuery}}, journey_format: {${formatQuery}}, user_industry: {${industryQuery}}, user_category: {${jobCatagoryQuery}}, user_experience_level: {${workExpQuery}}, user_managing_experience: {${manageExpQuery}}}}) {
            scores
            simPerformanceFilters{
                journey_tags
              }
          }
    }      
    `

    const { loading: bannerFirstLoading, error: bannerFirstError, data: bannerFirstData } = useQuery(bannerFirstQuery ,{fetchPolicy: 'no-cache'})
    const { loading: groupBenchmarkLoading, error: groupBenchmarkError, data: groupBenchmarkData } = useQuery(groupBenchmarkQuery, {fetchPolicy: 'no-cache'})
    const { loading: competencyScoresLoading, error: competencyScoresError, data: competencyScoresData } = useQuery(competencyScoresQuery, {fetchPolicy: 'no-cache'})
    const { loading: distributionsLoading, error: distributionsError, data: distributionsData } = useQuery(distributionsQuery, {fetchPolicy: 'no-cache'})
    const { loading: diffusionLoading, error: diffusionError, data: diffusionData } = useQuery(diffusionQuery, {fetchPolicy: 'no-cache'})
    const { loading: stakeholdersLoading, error: stakeholdersError, data: stakeholdersData } = useQuery(stakeholdersQuery, {fetchPolicy: 'no-cache'})

    useEffect(() => {
        if(!bannerFirstLoading && !bannerFirstError && bannerFirstData!=null)
        {
            dispatch({ type: UPDATE_CQ_BANNER_FIRST, payload: bannerFirstData })
        }
    }, [bannerFirstLoading, bannerFirstError, bannerFirstData])

    useEffect(() => {
        if(!groupBenchmarkLoading && !groupBenchmarkError && groupBenchmarkData!=null)
        {
            dispatch({ type: UPDATE_CQ_GROUP_BENCHMARK, payload: groupBenchmarkData })
        }
    }, [groupBenchmarkLoading, groupBenchmarkError, groupBenchmarkData])
    
    useEffect(() => {
        if(!competencyScoresLoading && !competencyScoresError && competencyScoresData!=null)
        {
            dispatch({ type: UPDATE_CQ_COMPETENCY_SCORES, payload: competencyScoresData })
        }
    }, [competencyScoresLoading, competencyScoresError, competencyScoresData])

    useEffect(() => {
        if(!distributionsLoading && !distributionsError && distributionsData!=null)
        {
            dispatch({ type: UPDATE_CQ_DISTRIBUTIONS, payload: distributionsData })
        }
    }, [distributionsLoading, distributionsError, distributionsData])

    useEffect(() => {
        if(!diffusionLoading && !diffusionError && diffusionData!=null)
        {
            dispatch({ type: UPDATE_CQ_DIFFUSION, payload: diffusionData })
        }
    }, [diffusionLoading, diffusionError, diffusionData])

    useEffect(() => {
        if(!stakeholdersLoading && !stakeholdersError && stakeholdersData!=null)
        {
            dispatch({ type: UPDATE_CQ_STAKEHOLDERS, payload: stakeholdersData })
        }
    }, [stakeholdersLoading, stakeholdersError, stakeholdersData])


    if(bannerFirstError, groupBenchmarkError, competencyScoresError, distributionsError, diffusionError, stakeholdersError)
    {
        return <div>Error</div>
    }
    //console.log(state.groupBenchmarkCQ.totalLearners)
    //console.log(bannerFirstData.bannerFirstDescription[0].JourneyInstanceDescriptionString.langvalue)
    
    return (
        <div className={Styles.gridWrapper}>
            <div data-aos="fade-up" data-aos-once="true">
                {
                    bannerFirstLoading && 
                    <BannerLoader />
                }
                {
                    !bannerFirstLoading && 
                    <SimpleBanner title={state.bannerFirstCQ.name} domainCategoryArr={state.bannerFirstCQ.domainCategory} description={state.bannerFirstCQ.description} />
                }
            </div>        
            <div className={Styles.gridWrapper1} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                <div>
                    {
                        groupBenchmarkLoading && 
                        <UsersLoader />
                    }
                    {
                        !groupBenchmarkLoading && 
                        <NoOfUsers infoBody="Total number of learners who have completed this course" title="COMPLETED LEARNERS" count={state.groupBenchmarkCQ.totalLearners} />
                    }
                </div>
                <div className={Styles.averageWhitebox}>
                    <WhiteBox>
                        <div className={Styles.averageContainer}>
                            {
                                competencyScoresLoading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !competencyScoresLoading && 
                                <>
                                    <div className={Styles.groupAverageHeading0}>
                                        <GraphTitle title="GROUP AVERAGE" infoBody="Average score of all the learners of this organization in this specific course" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.competencyScoresCQ.dataForHorizontalProgressBar1.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.competencyScoresCQ.dataForHorizontalProgressBar1} />
                                    </div>
                                </>
                            }
                            <div className={Styles.lineDifferentiator}></div>
                            {
                                competencyScoresLoading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !competencyScoresLoading && 
                                <>
                                    <div className={Styles.groupAverageHeading}>
                                        <GraphTitle title="BENCHMARK" infoBody="Average score of all the learners of the Universe in this specific course.*Universe refers to the entire set of learners who have accessed this course until now" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{state.competencyScoresCQ.dataForHorizontalProgressBar2.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...state.competencyScoresCQ.dataForHorizontalProgressBar2} />
                                    </div>
                                </>
                            }
                        </div>
                    </WhiteBox>
                </div>
                <div className={Styles.competencyList}>
                    {
                        loading && 
                        <DistributionWhiteboxLoader />
                    }
                    {
                        !loading && 
                        <CompetencyList course={state.cq.competencyArray} />
                    }
                </div>
            </div>
            <div data-aos="fade-up" data-aos-once="true" data-aos-delay="700">
                {
                    loading && 
                    <BannerLoader />
                }
                {
                    !loading && 
                    <BannerWithBackground  title="CHANGEQUEST SIMULATION" />
                }
            </div>        
            {
                competencyScoresLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !competencyScoresLoading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle infoBody="Shows the scores of the course competencies of this group in comparison to the universe" />
                                </div>
                                <div className={Styles.groupHorizontalComponent}>
                                    <GroupHorizontalBar {...state.competencyScoresCQ.dataForGroupHorizontalBar} />
                                </div>

                            </WhiteBox>
                        </div>
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title={state.cq.graphTitle1} infoBody="Shows the average number of adoptions of this group in comparison to the universe.Shows the average adoption levels scored by this group in comparison to the universe" />
                                </div>
                                <div className={Styles.barBenchmarkComponent1}>
                                    <HorizontalBenchmark {...state.competencyScoresCQ.dataForAdoptors} />
                                </div>
                                <div className={Styles.lineDifferentiator}></div>
                                <div className={Styles.adoptionText}>( Adoption % )</div>
                                <div>
                                    <PieChart {...state.competencyScoresCQ.dataForPieChart} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                </>
            }
            {
                distributionsLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !distributionsLoading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true">
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title="DISTRIBUTION OF LEARNERS ACROSS COMPETENCIES" infoBody="Distribution (%) of the learners in each of the five proficiency levels in each of the competencies of this course" />
                                </div>
                                <div className={Styles.titleDropdown}>
                                    <TitleDropdown arr={['Influencing Skills']} />
                                </div>
                                <div className={Styles.barGraphComponent1}> 
                                    <BarGraph {...state.distributionsCQ.dataForBarGraph1} />
                                </div>
                            </WhiteBox>
                        </div>
                        <div className={Styles.gridWrapper4}>
                            <div className={Styles.gridWrapper5}>
                                <div>
                                    <Action infoBody="Most preferred action of this group of learners" text={state.distributionsCQ.actionText1} />
                                </div>
                                <div>
                                    <Metric infoBody="Average % of networks discovered by this group in comparison to the universe" groupPercent={state.distributionsCQ.metricGroup1} benchmarkPercent={state.distributionsCQ.metricBenchmark1} />
                                </div>
                            </div>
                            <div className={Styles.gridWrapper5}>
                                <div>
                                    <Action infoBody="Least preferred action of this group of learners" title={state.cq.actionTitle1} text={state.distributionsCQ.actionText2} />
                                </div>
                                <div>
                                    <Metric infoBody="% of all the actions taken by this group that had a positive outcome in comparison to the universe" title={state.cq.metricTitle1} groupPercent={state.distributionsCQ.metricGroup2} benchmarkPercent={state.distributionsCQ.metricBenchmark2} />
                                </div>
                            </div>
                        </div>
                    </div>   
                </>
            }
            
            <div className={Styles.headingType} data-aos="fade-up" data-aos-once="true">KNOWING YOUR STAKEHOLDERS</div>
            {
                stakeholdersLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }     
            {
                !stakeholdersLoading && 
                <>
                    <div className={Styles.gridWrapper3} data-aos="fade-up" data-aos-once="true">
                        <div className={`${Styles.groupIdealDiv}`}>
                            <GroupIdeal data={state.stakeholdersCQ.timingofstakeholderinteractionsProps} infoBody="Knowing when to take a particular action on a stakeholder is just as important as knowing how often you need to interact with that stakeholder.The below figure shows the timing of the stakeholder interactions of this group in comparison to how it should have been done ('Ideal')" />
                        </div>
                        <div className={`${Styles.groupIdealDiv}`}>
                            <GroupIdeal data={state.stakeholdersCQ.frequencyofstakeholderinteractionsProps} infoBody="Knowing when to take a particular action on a stakeholder is just as important as knowing how often you need to interact with that stakeholder.The below figure shows the frequency of the stakeholder interactions of this group in comparison to how it should have been ('Ideal')" />
                        </div>
                    </div>
                </>
            }
            {
                diffusionLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !diffusionLoading && 
                <>
                    <div className={Styles.gridWrapper3} data-aos="fade-up" data-aos-once="true">
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title={state.cq.graphTitle3} infoBody="By knowing which part of the Innovation Diffusion Curve a stakeholder belongs to, your group would have been able to come up with the right actions to be taken at the right time. You can use this graph to analyze whether the time your group spent on any category of stakeholders was disproportional to the number of stakeholders in that category in the simulation" />
                                </div>
                                <div className={Styles.barLineComponent}>
                                    <BarWithLineChart {...state.diffusionCQ.dataForBarWithLineChart} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>               
                </>
            }
        </div>
    )
}

export default PerformanceTabCQ