import { batch } from "react-redux"

export function handleFilterUsersObj(payload,type){   
    let usersList = []
    let batchList = [] 
    let journeyInstanceIdList = []
    let journeyDomainCategories = {}  
    for(let i=0; i<payload.length;i++){
        if(JSON.stringify(payload[i].journey_tags).toLowerCase().includes("passport")){
            usersList.push(payload[i].user_id)
            batchList.push(payload[i].batch_id)

            if(!journeyInstanceIdList.includes(payload[i].journey_instance_id)){
                journeyInstanceIdList.push(payload[i].journey_instance_id)
            }
            let domainCategories = [payload[i].journey_domain,payload[i].journey_categories]
            journeyDomainCategories[payload[i].journey_instance_id]= domainCategories
        }
    }
    return{
        type: type,
        payload: {"filterUsers": usersList,"filterBatches":batchList,"filterJourneyInstance":journeyInstanceIdList,"journeyDomainCategories": journeyDomainCategories }
    }

}
export function handleBenchmarkFilterUsersObj(payload,type){   
    let usersList = [] 
    let batchList = []
    
    for(let i=0; i<payload.length;i++){
        if(JSON.stringify(payload[i].journey_tags).toLowerCase().includes("passport")){
            usersList.push(payload[i].user_id)       
            batchList.push(payload[i].batch_id)            

        }
    }
    return{
        type: type,
        payload: {"benchmarkFilterUsers" : usersList, "benchmarkFilterBatches": batchList}
    }

}