import React from 'react';
import { Area, AreaClosed, Line } from '@vx/shape';
import { curveMonotoneX } from '@vx/curve';
import { scaleLinear } from '@vx/scale';
import { LinearGradient } from '@vx/gradient';
import { AxisBottom , AxisLeft } from '@vx/axis';
import { GridRows } from '@vx/grid';
import Styles from './GraphComponents.module.css';
import { useTooltip, Tooltip } from '@vx/tooltip';
import { localPoint } from "@vx/event";

function LineChart(props) {
    const data = props['data']
    const width= props.width;
    const height= props.height;
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            //for FHD Screens
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            //for QHD Screens
            unit = 2;
        }
    const margin = { top: 10*unit, bottom: 50*unit, left: 40*unit, right: 10*unit };
    const xMin = 0 + margin.left;
    const xMax = width - margin.right;
    const yMin = 0 + margin.top;
    const yMax = height - margin.bottom;

    const xAxisLable = Object.keys(data[0])[0];
    const yAxisLable = Object.keys(data[0])[1];

    const xAxis = d =>  d[xAxisLable];
    const yAxis = d =>  d[yAxisLable];

    // scales
    const xScale = scaleLinear({
        range: [xMin, xMax],
        domain: [data[0].Days, data[data.length -1].Days],
        nice: true
    });

    const yScale = scaleLinear({
        range: [yMax, yMin],
        domain: [0, 100],
        nice: true
    });

    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, data, xScale, yScale) => {
        const { x } = localPoint(event.target.ownerSVGElement, event);
        const x0 = Math.round(xScale.invert(x));
        let chartData = data.filter((val, i) => xAxis(val) === x0)[0];
        const tooltipData = {...chartData};
        let tooltipLeft = xScale(tooltipData[xAxisLable]) ;
        let tooltipTop = yScale(tooltipData[yAxisLable]) - 30*unit;
        if(tooltipLeft < 75*unit){
            tooltipLeft = 75*unit;
        }else if(tooltipLeft + 44*unit > width){
            tooltipLeft = width -  44*unit;
        }
        if(tooltipTop <= margin.top){
            tooltipTop = margin.top ;
        }
        
        showTooltip({
            tooltipLeft: tooltipLeft,
            tooltipTop: tooltipTop,
            tooltipData: tooltipData
        });
    };
    
    return(
        <div style={{justifyContent: 'center', display: 'flex'}}>
            <svg width={width} height={height}>
                <LinearGradient from="rgba(148, 76, 210, 0.15)" to="rgba(148, 76, 210, 0)" id="lineChartGrad1" vertical={true} />
                <LinearGradient from="rgba(255, 139, 114, 0.15)" to="rgba(255, 139, 114, 0)" id="lineChartGrad2" vertical={true} />
                <GridRows
                    left={ xMin}
                    scale={yScale}
                    width={xMax - xMin}
                    height={yMax}
                    numTicks={6}
                />
                <AxisBottom
                    top={height - margin.bottom + 10*unit}
                    scale={xScale}
                    hideAxisLine= {true}
                    hideTicks={true}
                    //hideZero
                    tickLabelProps={(value, index) => ({
                        fontSize: 10*unit,
                        textAnchor: 'middle',
                        fill: '#2F4585',
                        fillOpacity: 0.4,
                        fontFamily: 'Nunito',
                        fontStyle: 'normal'
                    })}
                    numTicks={5}
                    labelClassName={Styles.leftlabel}
                    label={xAxisLable}
                />
                <AxisLeft
                    left={margin.left}
                    scale={yScale}
                    hideAxisLine= {true}
                    hideTicks={true}
                    tickLabelProps={(value, index) => ({
                        fontSize: 10*unit,
                        textAnchor: 'middle',
                        fill: '#2F4585',
                        fillOpacity: 0.4,
                        fontFamily: 'Nunito',
                        fontStyle: 'normal'
                    })}
                    numTicks={5}
                    labelClassName={Styles.leftlabel}
                    label={'% Of Learners'}
                    labelOffset={20*unit}
                />
                {tooltipOpen &&
                    (<g>
                        <Line
                            from={{x:xScale(tooltipData[xAxisLable]), y:yScale(tooltipData[yAxisLable])}}
                            to={{x:xScale(tooltipData[xAxisLable]), y:yScale(0)}}
                            className={Styles.tooltipLine}
                        />
                    </g>)
                }
                <Area
                    data={data}
                    x={d => xScale(xAxis(d))}
                    y={d => yScale(yAxis(d))}
                    yScale={yScale}
                    stroke={'#944CD2'}
                    strokeWidth={'1px'}
                    fill={'url(#lineChartGrad1)'}
                    curve={curveMonotoneX}
                />
                <AreaClosed
                    data={data}
                    x={d => xScale(xAxis(d))}
                    y={d => yScale(yAxis(d))}
                    yScale={yScale}
                    fill={'url(#lineChartGrad1)'}
                    curve={curveMonotoneX}
                    onMouseMove={event =>
                        handleMouseOver(
                            event,
                            data,
                            xScale,
                            yScale
                    )}
                    onMouseLeave={hideTooltip}
                    onMouseOut={hideTooltip}
                />
                {tooltipOpen && (
                    <> 
                    <rect
                        y={tooltipTop }
                        x={tooltipLeft - 44*unit}
                        width = {88*unit} 
                        height = {20*unit} 
                        fill = 'rgba(250, 250, 250, 0.9)'
                        stroke = 'rgba(0, 0, 0, 0.1)'
                        className={Styles.tooltipBlur}
                        rx={4}
                    />
                    <text
                        textAnchor = 'start'
                        x = {tooltipLeft - 40*unit}
                        y = {tooltipTop + 14*unit}
                        className={Styles.tooltipText}
                    >
                        {tooltipData[yAxisLable]}% on day {tooltipData[xAxisLable]}
                    </text>
                    </>
                )}
            </svg>
        </div>
    );
}

export default LineChart;