import React from "react"
import ContentLoader from "react-content-loader"
import Styles from "./FeedbackAll.module.css"



const Aggregate = (props) => (
    <div className={Styles.responses}>
        <ContentLoader 
        speed={2}
        width={436}
        height={375}
        viewBox="0 0 436 375"
        backgroundColor="#f3f3f3"
        foregroundColor="#dcdbdb"
        {...props}
    >
         <rect x="30" y="14" rx="7" ry="7" width="210" height="10" /> 
        <rect x="30" y="58" rx="7" ry="7" width="210" height="8" /> 
        <circle cx="58" cy="111" r="25" /> 
        <rect x="106" y="109" rx="7" ry="7" width="210" height="8" /> 
        <rect x="30" y="169" rx="7" ry="7" width="210" height="8" /> 
        <circle cx="58" cy="222" r="25" /> 
        <rect x="106" y="220" rx="7" ry="7" width="210" height="8" /> 
        <rect x="30" y="281" rx="7" ry="7" width="210" height="8" /> 
        <circle cx="58" cy="334" r="25" /> 
        <rect x="106" y="332" rx="7" ry="7" width="210" height="8" />
        
    </ContentLoader>
    </div>
    )
    
    
    export default Aggregate
    
    
    