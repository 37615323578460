import React from "react"
import ContentLoader from "react-content-loader"
import Styles from "./FeedbackAll.module.css"



const AverageFeedback = (props) => (
  <div className={Styles.average_feedback}>
      <ContentLoader 
      speed={2}
      width={476}
      height={500}
      viewBox="0 0 316 500"
      backgroundColor="#f3f3f3"
      foregroundColor="#dcdbdb"
      {...props}
    >
      <rect x="30" y="5" rx="7" ry="7" width="133" height="14" /> 
    <rect x="30" y="30" rx="7" ry="7" width="78" height="10" /> 
    <rect x="30" y="49" rx="7" ry="7" width="51" height="16" /> 
    <circle cx="107" cy="57" r="8" /> 
    <circle cx="134" cy="57" r="8" /> 
    <circle cx="159" cy="57" r="8" /> 
    <circle cx="184" cy="57" r="8" /> 
    <circle cx="208" cy="57" r="8" /> 
    <rect x="30" y="93" rx="0" ry="0" width="172" height="1" /> 
    <rect x="30" y="120" rx="7" ry="7" width="133" height="10" /> 
    <rect x="30" y="150" rx="7" ry="7" width="51" height="16" /> 
    <circle cx="107" cy="157" r="8" /> 
    <circle cx="128" cy="157" r="8" /> 
    <circle cx="153" cy="157" r="8" /> 
    <circle cx="178" cy="157" r="8" /> 
    <circle cx="202" cy="157" r="8" /> 
    <rect x="30" y="193" rx="0" ry="0" width="172" height="1" /> 
    <rect x="30" y="215" rx="7" ry="7" width="142" height="10" /> 
    <rect x="30" y="250" rx="7" ry="7" width="78" height="10" /> 
    <rect x="30" y="270" rx="7" ry="7" width="36" height="16" /> 
    <circle cx="107" cy="277" r="8" /> 
    <circle cx="134" cy="277" r="8" /> 
    <circle cx="159" cy="277" r="8" /> 
    <circle cx="184" cy="277" r="8" /> 
    <circle cx="208" cy="277" r="8" /> 
    <rect x="30" y="321" rx="7" ry="7" width="78" height="10" /> 
    <rect x="30" y="341" rx="7" ry="7" width="36" height="16" /> 
    <circle cx="107" cy="348" r="8" /> 
    <circle cx="134" cy="348" r="8" /> 
    <circle cx="159" cy="348" r="8" /> 
    <circle cx="186" cy="348" r="8" /> 
    <circle cx="210" cy="348" r="8" /> 
    <rect x="30" y="389" rx="7" ry="7" width="78" height="10" /> 
    <rect x="30" y="409" rx="7" ry="7" width="36" height="16" /> 
    <circle cx="90" cy="419" r="8" /> 
    <circle cx="117" cy="419" r="8" /> 
    <circle cx="142" cy="419" r="8" /> 
    <circle cx="167" cy="419" r="8" /> 
    <circle cx="191" cy="419" r="8" />
      
    </ContentLoader>
  </div>
  )
  
  
  export default AverageFeedback


