import React from 'react';
import { AxisBottom,AxisLeft } from '@vx/axis';
import { scaleBand, scaleLinear } from '@vx/scale';
import getRoundedRect from '../../utils/GraphUtils';
import { Text } from '@vx/text';
import Styles from './GraphComponents.module.css';
import { Group } from '@vx/group';
import { useTooltip, Tooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';

const colorPalette = ['#9F7DFF', '#FF8B72']

function HorizontalBenchmarkGroupBar (props) {
    const data = props.data;
    
    const width = props.width;  
    const height = props.height; 
    let unit = 1;
            if(window.screen.width > 1910 && window.screen.width < 2550){
                //for FHD Screens
                unit = 1.5;
            }else if(window.screen.width >= 2551){
                //for QHD Screens
                unit = 2;
            }
    const margin = { top: 0*unit, bottom: 20*unit, left: 62*unit, right: 35*unit };
    
    // Then we'll create some bounds
    const xMax = width - margin.right;
    const yMax = height  - margin.bottom;
    const xMin = margin.left;
    const yMin = margin.top;
    
    // We'll make some helpers to get at the data we want
    const x = d => d['% Of Users'];
    const y = d => d.Title;
    
    const xScale = scaleLinear({
        rangeRound: [xMin, xMax],
        domain: [0 , 100],
    });
    const yScale = scaleBand({
        rangeRound: [yMax, yMin],
        domain: data.map(y).reverse() ,
    });

    const compose = (scale, accessor) => data => scale(accessor(data));
    const xPoint = compose(xScale, x);
    //const yPoint = compose(yScale, y);

    const {
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, datum) => {
        showTooltip({
            tooltipLeft,
            tooltipTop,
            tooltipData: datum
        });
    };
    
    return(
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height}
            onMouseOver={handleMouseOver}
            onMouseOut={hideTooltip}
        >
            <line x1={xMin} x2={xMin} y1={yMin} y2={yMax} stroke="#EAECF3" />
            <line x1={xMin} x2={xMax} y1={yMax} y2={yMax} stroke="#EAECF3" />
            <AxisBottom
                top={height - margin.bottom -20}
                scale={xScale}
                hideAxisLine= {true}
                hideTicks={true}
                tickLabelProps={(value, index) => ({
                    opacity: 0,
                })}
                label={'(% Of Learners)'}
                labelClassName={Styles.leftlabel}
            /> 
            <AxisLeft
                left={margin.left}
                scale={yScale}
                hideAxisLine= {true}
                hideTicks={true}
                tickLabelProps={(value, index) => ({
                    fontSize: 10*unit,
                    textAnchor: 'end',
                    verticalAnchor: 'middle',
                    fill: '#2F4585',
                    fontFamily: 'Nunito',
                    fontStyle: 'normal'
                })}
            /> 
            {data.map((d, i) => {
                const barHeight = 6*unit;
                const borderRad = 2*unit;
                return (
                    <Group key={`bar-${i}`}>
                       
                        <Spring
                            delay={500 + i*300}
                            config={{duration: 1500}}
                            from={{ value: getRoundedRect(xMin, (i + 1/2)*yScale.bandwidth(), 0, barHeight, 0, 'right' ) }}
                            to={{ value: getRoundedRect(xMin, (i + 1/2)*yScale.bandwidth(), xPoint(d) - xMin, barHeight, borderRad, 'right' ) }}
                        >
                            {props =>
                            <>
                                <Text 
                                    textAnchor = 'start'
                                    x = {xPoint(d) + 5*unit  }
                                    y = {(i + 1/2)*yScale.bandwidth() - 1*unit}
                                    fontSize = {11*unit}
                                    fill = {colorPalette[i]}
                                    fontFamily= {'Nunito'}
                                    fontWeight= {600}
                                    verticalAnchor = {'start'}
                                >
                                    {d['% Of Users']+"%"}
                                </Text>
                                <path
                                    id={`GroupBar-${i}`}
                                    d={props.value}
                                    style={{ fill: colorPalette[i] }}
                                />
                                </>
                            }
                        </Spring>
                    </Group>
                );
            })}
        </svg>
        {tooltipOpen && (
            <Tooltip
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft}
                className={Styles.tooltipBlur}
            >
                <div className={Styles.tooltipText}>
                    {data[0].Title}: {data[0]['% Of Users']}% <br/>
                    {data[1].Title}: {data[1]['% Of Users']}%
                </div> 
            </Tooltip>
        )}
        </div>
    );
}

export default HorizontalBenchmarkGroupBar;