import React from 'react';
import { Pie } from '@vx/shape'
import { Group } from '@vx/group'

const ringColor = '#EAECF3';

function CircularTextRing(props) {
    const width = props.width;  
    const height = props.height; 
    const margin = { top: 0, bottom: 0, left: 0, right: 0 };
    const radius = Math.min(width,height)/2
    const centerY = height / 2;
    const centerX = width / 2;
    const text = String(props.count);
    const fontSizeNumber = width/3;
    //let fontSizeMinutes = width/8.5;
    //const translationPosition = "translate(" + 0 + "," + fontSizeNumber/2 + ")";

    return (
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height} >
            <Group top={centerY - margin.top} left={centerX} >
                <g fill={'#2F4585'} fontFamily={'Nunito'}>
                    <text 
                        alignmentBaseline={'middle'}
                        text-anchor={'middle'}
                        fontWeight={'600'}
                        fontSize={fontSizeNumber} 
                    >
                        {text}
                    </text>
                </g>
                <Pie
                    data={[1]}
                    outerRadius={radius}
                    innerRadius={radius - 2}
                    padAngle={0}
                    fill={ringColor}
                ></Pie>
            </Group>
        </svg>
        </div>
    );
}

export default CircularTextRing