import React from "react";
import Styles from "./BannerWithBackground.module.css";

function BannerWithBackground(props){
    return(
        <div className={Styles.outerBox}>
            <div className={Styles.title}>
                {props.title ||''}
            </div>
            <div className={Styles.text}>
                {props.description}
            </div>
        </div>
    )
}

export default BannerWithBackground;
