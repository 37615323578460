import React from "react"
import ContentLoader from "react-content-loader"

const TripleLineCardLoader = (props) => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width =  props.width;
    const height = props.height; 
    const thirdRectY = props.thirdRectY || 65*unit;
    
    return(
        <ContentLoader 
            speed={2}
            width={width}
            height={height}
            viewBox={"0 0 "+width+" "+height}
            backgroundColor="#f3f3f3"
            foregroundColor="#dcdbdb"
        >
            <rect x={20*unit} y={20*unit} rx={5*unit} ry={5*unit} width={width - (50*unit)} height={12*unit} />
            <rect x={20*unit} y={40*unit} rx={5*unit} ry={5*unit} width={width/2 - (22*unit)} height={12*unit} />
            <rect x={20*unit} y={thirdRectY} rx={10*unit} ry={10*unit} width={width/3 - (10*unit)} height={18*unit} />
            
        </ContentLoader>
    )
}

export default TripleLineCardLoader;