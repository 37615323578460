import { gql } from "apollo-boost";

export const BannerQuery = gql`
query MyQuery($orgId : bigint!, $courseName: String!) {
    banner : journey_journey_instance(where: {StringJourneyInstance: {langvalue: {_eq: $courseName}}, OrgJourneyInstance: {organizationid: {_eq: $orgId}}}) {
        name
        description
        JourneyJourneyInstance {
          domain
          categories
          
        }
    }

}
`    
export const AverageFeedbackQuery = gql`
    query MyQuery($deals : [bigint!]!, $formats: [bigint!]!) {
        averageFeedback: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, userid: {_in: $deals}, batchid: {_in: $formats} , usertagentityBatchRelation: {journeybatchinstance: {StringJourneyInstance: {langvalue: {_eq: "CQ"}}}}}) {
            aggregate {
              avg {
                rating
              }
            }
          }
        
        benchMark:feedback_user_tag_enitity_wise_feedback_data_aggregate(where:{tagname: {_eq: "satisfaction"}}){
            aggregate {
                avg {
                rating
                }
            }
        }
        
        couseFeedbackSatisfaction: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "satisfaction"}, userid: {_in: $deals}, batchid: {_in: $formats}}) {
            aggregate {
              avg {
                rating
              }
            }
        }
        
        couseFeedbackEngagement:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "engagement"}, userid: {_in: $deals}, batchid: {_in: $formats}}) {
            aggregate {
                avg {
                rating
                }
            }
        }
        
        couseFeedbackPlatform:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "platform"}, userid: {_in: $deals}, batchid: {_in: $formats}}) {
            aggregate {
                avg {
                rating
                }
            }
        }
        
        couseFeedbackContent:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, userid: {_in: $deals}, batchid: {_in: $formats}}) {
            aggregate {
                avg {
                rating
                }
            }
        }
        
  }
`
export const ResponsesQuery = gql`
    query MyQuery($deals : [bigint!]!, $formats: [bigint!]!) {        
        likes:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, userid: {_in: $deals}, batchid: {_in: $formats}}) {
            aggregate {
              sum {
                likescount
              }
            }
        }
          
        disLikes: feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {tagname: {_eq: "content"}, userid: {_in: $deals}, batchid: {_in: $formats}}) {
            aggregate {
                sum {
                dislikescount
                }
            }
        }
    }
` 

export const WordCloudQuery = gql`
    query MyQuery($deals : [bigint!]!, $formats: [bigint!]!) {        
        wordCloud: feedback_user_tag_enitity_wise_feedback_data(where: {batchid: {_in: $formats }, userid: {_in: $deals}}) {
            textvalue
          }
    }
` 
