export const UPDATE_PROGRESS_BAR1_PF = 'UPDATE_PROGRESS_BAR_PF'
export const UPDATE_PROGRESS_BAR2_PF = 'UPDATE_PROGRESS_BAR2_PF'
export const UPDATE_BAR_GRAPH_PF = 'UPDATE_BAR_GRAPH_PF'
export const UPDATE_TITLE1_PF = 'UPDATE_TITLE1_PF'
export const UPDATE_TITLE2_PF = 'UPDATE_TITLE2_PF'
export const UPDATE_COMPETENCY_TITLE_PF = 'UPDATE_COMPETENCY_TITLE_PF'
export const UPDATE_PROGRESS_BAR1_PF_BYB = 'UPDATE_PROGRESS_BAR1_PF_BYB'
export const UPDATE_PROGRESS_BAR2_PF_BYB = 'UPDATE_PROGRESS_BAR2_PF_BYB'
export const UPDATE_HORIZONTAL_BAR_PF_BYB = 'UPDATE UPDATE_HORIZONTAL_BAR_PF_BYB'
export const UPDATE_BAR_GRAPH_PF_BYB = 'UPDATE_BAR_GRAPH_PF_BYB'
export const UPDATE_PIE_CHART_PF_BYB = 'UPDATE_PIE_CHART_PF_BYB'
export const UPDATE_BENCHMARK1_PF_BYB = 'UPDATE_BENCHMARK1_PF_BYB'
export const UPDATE_BENCHMARK2_PF_BYB = 'UPDATE_BENCHMARK2_PF_BYB'
export const UPDATE_BENCHMARK3_PF_BYB = 'UPDATE_BENCHMARK3_PF_BYB'
export const UPDATE_BENCHMARK4_PF_BYB = 'UPDATE_BENCHMARK4_PF_BYB'
export const UPDATE_PROGRESS_BAR1_PF_CQ = 'UPDATE_PROGRESS_BAR1_PF_CQ'
export const UPDATE_PROGRESS_BAR2_PF_CQ = 'UPDATE_PROGRESS_BAR2_PF_CQ'
export const UPDATE_HORIZONTAL_BAR_PF_CQ = 'UPDATE_HORIZONTAL_BAR_PF_CQ'
export const UPDATE_BENCHMARK1_PF_CQ = 'UPDATE_BENCHMARK1_PF_CQ'
export const UPDATE_BAR_GRAPH_PF_CQ = 'UPDATE_BAR_GRAPH_PF_CQ'
export const UPDATE_BAR_LINE_PF_CQ = 'UPDATE_BAR_LINE_PF_CQ'
export const UPDATE_PROGRESS_BAR1_PF_IL = 'UPDATE_PROGRESS_BAR1_PF_IL'
export const UPDATE_PROGRESS_BAR2_PF_IL = 'UPDATE_PROGRESS_BAR2_PF_IL'
export const UPDATE_BAR_GRAPH1_PF_IL = 'UPDATE_PROGRESS_BAR1_PF_IL'
export const UPDATE_BAR_GRAPH2_PF_IL = 'UPDATE_BAR_GRAPH2_PF_IL'
export const UPDATE_BAR_GRAPH3_PF_IL = 'UPDATE_BAR_GRAPH3_PF_IL'
export const UPDATE_HORIZONTAL_BAR_PF_IL = 'UPDATE_HORIZONTAL_BAR_PF_IL'
export const UPDATE_VERTICAL_BAR_PF_IL = 'UPDATE_VERTICAL_BAR_PF_IL'
export const UPDATE_PIE_CHART_PF_IL = 'UPDATE_PIE_CHART_PF_IL'
export const UPDATE_BAR_ACCURACY_PF_IL = 'UPDATE_BAR_ACCURACY_PF_IL'
export const UPDATE_PROGRESS_BAR1_PF_TS = 'UPDATE_PROGRESS_BAR1_PF_TS'
export const UPDATE_PROGRESS_BAR2_PF_TS = 'UPDATE_PROGRESS_BAR2_PF_TS'
export const UPDATE_HORIZONTAL_BAR_PF_TS = 'UPDATE_HORIZONTAL_BAR_PF_TS'
export const UPDATE_PIE_CHART1_PF_TS = 'UPDATE_PIE_CHART1_PF_TS'
export const UPDATE_BAR_GRAPH1_PF_TS = 'UPDATE_BAR_GRAPH1_PF_TS'
export const UPDATE_PIE_CHART_FILLED_PF_TS = 'UPDATE_PIE_CHART_FILLED_PF_TS'
export const UPDATE_BAR_BENCHMARK_PF_TS = 'UPDATE_BAR_BENCHMARK_PF_TS'
export const UPDATE_PIE_CHART2_PF_TS = 'UPDATE_PIE_CHART2_PF_TS'
export const UPDATE_BAR_ACCURACY_PF_TS = 'UPDATE_BAR_ACCURACY_PF_TS'
export const UPDATE_FILTER_ITEM = 'UPDATE_FILTER_ITEM'
export const UPDATE_INDUSTRY = 'UPDATE_INDUSTRY'
export const UPDATE_JOB_CATEGORY = 'UPDATE_JOB_CATEGORY'
export const UPDATE_WORK_EXPERIENCE = 'UPDATE_WORK_EXPERIENCE'
export const UPDATE_PEOPLE_MANAGEMENT_EXPERIENCE = 'UPDATE_PEOPLE_MANAGEMENT_EXPERIENCE'
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING='UPDATE_AVERAGE_COURSE_FEEDBACK_RATING';
export const UPDATE_BENCHMARK_RATING='UPDATE_BENCHMARK_RATINGG';
export const UPDATE_RESPONSE='UPDATE_RESPONSE';
export const UPDATE_COURSEFEEDBACK='UPDATE_COURSEFEEDBACK';
export const UPDATE_AGGREGATESTATS='UPDATE_AGGREGATESTATS';
export const UPDATE_COURSERATING='UPDATE_COURSERATING';
export const UPDATE_PROGRESS_BAR1_PF_CG = 'UPDATE_PROGRESS_BAR_PF_CG'
export const UPDATE_PROGRESS_BAR2_PF_CG = 'UPDATE_PROGRESS_BAR2_PF_CG'
export const UPDATE_BAR_GRAPH_PF_CG = 'UPDATE_BAR_GRAPH_PF_CG'
export const UPDATE_TITLE1_PF_CG = 'UPDATE_TITLE1_PF_CG'
export const UPDATE_TITLE2_PF_CG = 'UPDATE_TITLE2_PF_CG'
export const UPDATE_COMPETENCY_TITLE_PF_CG = 'UPDATE_COMPETENCY_TITLE_PF_CG'
export const UPDATE_PROGRESS_BAR1_PF_NS = 'UPDATE_PROGRESS_BAR1_PF_NS'
export const UPDATE_PROGRESS_BAR2_PF_NS = 'UPDATE_PROGRESS_BAR2_PF_NS'
export const UPDATE_PROGRESS_BAR3_PF_NS = 'UPDATE_PROGRESS_BAR3_PF_NS'
export const UPDATE_BAR_GRAPH_PF_NS = 'UPDATE_BAR_GRAPH_PF_NS'
export const UPDATE_COURSE_DROPDOWN_PF_NS = 'UPDATE_COURSE_DROPDOWN_PF_NS'
export const UPDATE_PIE_CHART_PF_NS = 'UPDATE_PIE_CHART_PF_NS'
export const UPDATE_TOTAL_QUESTIONS_PF_NS = 'UPDATE_TOTAL_QUESTIONS_PF_NS'
export const UPDATE_ORG_NAME='UPDATE_ORG_NAME';
export const UPDATE_ORG_LOGO = 'UPDATE_ORG_LOGO';
export const UPDATE_ORG_DESCRIPTION = 'UPDATE_ORG_DESCRIPTION';
export const UPDATE_LANDING_PAGE_ITEMS='UPDATE_LANDING_PAGE_ITEMS';
export const UPDATE_LANDING_MID_CONTAINER_ITEMS = 'UPDATE_LANDING_MID_CONTAINER_ITEMS';
export const UPDATE_NOT_REQUIRED_LANDING_ITEMS = 'UPDATE_NOT_REQUIRED_LANDING_ITEMS';
export const UPDATE_HEADER_ITEMS='UPDATE_HEADER_ITEMS';
export const UPDATE_FILTERPANEL_API_ITEMS='UPDATE_FILTERPANEL_API_ITEMS';
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_NS='UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_NS';
export const UPDATE_BENCHMARK_FT_NS='UPDATE_BENCHMARK_FT_NS';
export const UPDATE_RESPONSE_FT_NS='UPDATE_RESPONSE_FT_NS';
export const UPDATE_COURSEFEEDBACK_FT_NS='UPDATE_COURSEFEEDBACK_FT_NS';
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CG='UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CG';
export const UPDATE_BENCHMARK_FT_CG='UPDATE_BENCHMARK_FT_CG';
export const UPDATE_RESPONSE_FT_CG='UPDATE_RESPONSE_FT_CG';
export const UPDATE_COURSEFEEDBACK_FT_CG='UPDATE_COURSEFEEDBACK_FT_CG';
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_IL='UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_IL';
export const UPDATE_BENCHMARK_FT_IL='UPDATE_BENCHMARK_FT_IL';
export const UPDATE_RESPONSE_FT_IL='UPDATE_RESPONSE_FT_IL';
export const UPDATE_COURSEFEEDBACK_FT_IL='UPDATE_COURSEFEEDBACK_FT_IL'
export const UPDATE_COMPETENCY_PF_BYB = 'UPDATE_COMPETENCY_PF_BYB'
export const UPDATE_COMPETENCY_PF_CQ = 'UPDATE_COMPETENCY_PF_CQ'
export const UPDATE_COMPETENCY_PF_IL = 'UPDATE_COMPETENCY_PF_IL'
export const UPDATE_COMPETENCY_PF_TS = 'UPDATE_COMPETENCY_PF_TS'
export const UPDATE_ORG_TOTAL_USERS = 'UPDATE_ORG_TOTAL_USERS'
export const UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB = 'UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB'
export const UPDATE_LINE_CHART_ACTIVITY_TAB = 'UPDATE_LINE_CHART_ACTIVITY_TAB'
export const UPDATE_PIE_CHART_ACTIVITY_TAB = 'UPDATE_PIE_CHART_ACTIVITY_TAB'
export const UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB = 'UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB'
export const UPDATE_HORIZONTAL_BENCHMARKGROUPBAR2_ACTIVITY_TAB = 'UPDATE_HORIZONTAL_BENCHMARKGROUPBAR2_ACTIVITY_TAB'
export const UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB = 'UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB'
export const UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB = 'UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB'
export const UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB = 'UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB'
export const UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB = 'UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB'
export const UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB = 'UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB'
export const UPDATE_NO_OF_LEARNERS_WHOACCESSED_ACTIVITY_TAB = 'UPDATE_NO_OF_LEARNERS_WHOACCESSED_ACTIVITY_TAB'
export const UPDATE_AVG_NO_OF_COURSES_ACCESSED_ACTIVITY_TAB = 'UPDATE_AVG_NO_OF_COURSES_ACCESSED_ACTIVITY_TAB'
export const UPDATE_AVG_NO_OF_COURSES_COMPLETED_ACTIVITY_TAB = 'UPDATE_AVG_NO_OF_COURSES_COMPLETED_ACTIVITY_TAB'
export const UPDATE_LEARNERS_ALL_ORG_ACTIVITY_TAB = 'UPDATE_LEARNERS_ALL_ORG_ACTIVITY_TAB'
export const UPDATE_DEMOGRAPHICS_USERS = 'UPDATE_DEMOGRAPHICS_USERS'
export const UPDATE_SELECTED_COURSE = 'UPDATE_SELECTED_COURSE'
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CQ = 'UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CQ'
export const UPDATE_BENCHMARK_FT_CQ = 'UPDATE_BENCHMARK_FT_CQ'
export const UPDATE_COURSEFEEDBACK_FT_CQ = 'UPDATE_COURSEFEEDBACK_FT_CQ'
export const UPDATE_RESPONSE_FT_CQ = 'UPDATE_RESPONSE_FT_CQ'
export const UPDATE_WORDCLOUD_FT_CQ = 'UPDATE_WORDCLOUD_FT_CQ'
export const UPDATE_BANNER_FT_CQ = 'UPDATE_BANNER_FT_CQ'
export const UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_CQ = 'UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_CQ' 
export const UPDATE_LINE_CHART_ACTIVITY_TAB_CQ = 'UPDATE_LINE_CHART_ACTIVITY_TAB_CQ'
export const UPDATE_PIE_CHART_ACTIVITY_TAB_CQ = 'UPDATE_PIE_CHART_ACTIVITY_TAB_CQ'
export const UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_CQ = 'UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_CQ'
export const UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_CQ = 'UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_CQ'
export const UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_CQ = 'UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_CQ'
export const UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_CQ = 'UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_CQ'
export const UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_CQ = 'UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_CQ'
export const UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_CQ = 'UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_CQ'
export const UPDATE_CQ_BANNER_FIRST = 'UPDATE_CQ_BANNER_FIRST'
export const UPDATE_CQ_GROUP_BENCHMARK = 'UPDATE_CQ_GROUP_BENCHMARK'
export const UPDATE_CQ_BENCHMARK = 'UPDATE_CQ_BENCHMARK'
export const UPDATE_CQ_COMPETENCY_SCORES = 'UPDATE_CQ_COMPETENCY_SCORES'
export const UPDATE_CQ_DISTRIBUTIONS = 'UPDATE_CQ_DISTRIBUTIONS'
export const UPDATE_CQ_DIFFUSION = 'UPDATE_CQ_DIFFUSION'
export const UPDATE_CQ_STAKEHOLDERS = 'UPDATE_CQ_STAKEHOLDERS'
export const UPDATE_ORG_LIST = 'UPDATE_ORG_LIST'
export const UPDATE_COURSE_FILTERPANEL_ITEMS_LIST = 'UPDATE_COURSE_FILTERPANEL_ITEMS_LIST'

export const UPDATE_BANNER_FT_IL = 'UPDATE_BANNER_FT_IL'
export const UPDATE_WORDCLOUD_FT_IL = 'UPDATE_WORDCLOUD_FT_IL'
export const UPDATE_BANNER_FT_TRUST = 'UPDATE_BANNER_FT_TRUST'
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_TRUST = 'UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_TRUST'
export const UPDATE_BENCHMARK_FT_TRUST = 'UPDATE_BENCHMARK_FT_TRUST'
export const UPDATE_COURSEFEEDBACK_FT_TRUST = 'UPDATE_COURSEFEEDBACK_FT_TRUST'
export const UPDATE_RESPONSE_FT_TRUST = 'UPDATE_RESPONSE_FT_TRUST'
export const UPDATE_WORDCLOUD_FT_TRUST = 'UPDATE_WORDCLOUD_FT_TRUST'

export const UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_IL = 'UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_IL' 
export const UPDATE_LINE_CHART_ACTIVITY_TAB_IL = 'UPDATE_LINE_CHART_ACTIVITY_TAB_IL'
export const UPDATE_PIE_CHART_ACTIVITY_TAB_IL = 'UPDATE_PIE_CHART_ACTIVITY_TAB_IL'
export const UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_IL = 'UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_IL'
export const UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_IL = 'UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_IL'
export const UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_IL = 'UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_IL'
export const UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_IL = 'UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_IL'
export const UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_IL = 'UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_IL'
export const UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_IL = 'UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_IL'
export const UPDATE_BANNER_DESC_ACTIVITY_TAB_CQ = 'UPDATE_BANNER_DESC_ACTIVITY_TAB_CQ'
export const UPDATE_ORG_ID = 'UPDATE_ORG_ID'
export const UPDATE_IL_COMPETENCY_SCORES = 'UPDATE_IL_COMPETENCY_SCORES'
export const UPDATE_IL_DISTRIBUTIONS = 'UPDATE_IL_DISTRIBUTIONS'
export const UPDATE_IL_BANNER_FIRST = 'UPDATE_IL_BANNER_FIRST'
export const UPDATE_IL_LEADERSHIP = 'UPDATE_IL_LEADERSHIP'
export const UPDATE_IL_LEARNERS = 'UPDATE_IL_LEARNERS'
export const UPDATE_TS_COMPETENCY_SCORES = 'UPDATE_TS_COMPETENCY_SCORES'
export const UPDATE_TS_DISTRIBUTIONS = 'UPDATE_TS_DISTRIBUTIONS'
export const UPDATE_TS_ACCURACIES = 'UPDATE_TS_ACCURACIES'
export const UPDATE_TS_BANNER_FIRST = 'UPDATE_TS_BANNER_FIRST'
export const UPDATE_TS_LEARNERS = 'UPDATE_TS_LEARNERS'

export const UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_Trust = 'UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_Trust' 
export const UPDATE_LINE_CHART_ACTIVITY_TAB_Trust = 'UPDATE_LINE_CHART_ACTIVITY_TAB_Trust'
export const UPDATE_PIE_CHART_ACTIVITY_TAB_Trust = 'UPDATE_PIE_CHART_ACTIVITY_TAB_Trust'
export const UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_Trust = 'UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_Trust'
export const UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_Trust = 'UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_Trust'
export const UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_Trust = 'UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_Trust'
export const UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_Trust = 'UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_Trust'
export const UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_Trust = 'UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_Trust'
export const UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_Trust = 'UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_Trust'

export const UPDATE_BANNER_FT_COACHINGSIM = 'UPDATE_BANNER_FT_COACHINGSIM'
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_COACHINGSIM = 'UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_COACHINGSIM'
export const UPDATE_BENCHMARK_FT_COACHINGSIM = 'UPDATE_BENCHMARK_FT_COACHINGSIM'
export const UPDATE_COURSEFEEDBACK_FT_COACHINGSIM = 'UPDATE_COURSEFEEDBACK_FT_COACHINGSIM'
export const UPDATE_RESPONSE_FT_COACHINGSIM = 'UPDATE_RESPONSE_FT_COACHINGSIM'
export const UPDATE_WORDCLOUD_FT_COACHINGSIM = 'UPDATE_WORDCLOUD_FT_COACHINGSIM'

export const UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_COACHINGSIM' 
export const UPDATE_LINE_CHART_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_LINE_CHART_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_PIE_CHART_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_PIE_CHART_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_COACHINGSIM = 'UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_COACHINGSIM'
export const UPDATE_CS_COMPETENCY_SCORES = 'UPDATE_CS_COMPETENCY_SCORES'
export const UPDTAE_CS_DISTRIBUTIONS = 'UPDTAE_CS_DISTRIBUTIONS'
export const UPDATE_CS_GROW = 'UPDATE_CS_GROW'

export const UPDATE_FILTER_USERS = 'UPDATE_FILTER_USERS'
export const UPDATE_BENCHMARK_FILTER_USERS = 'UPDATE_BENCHMARK_FILTER_USERS'


export const UPDATE_AC_COMPETENCY_SCORES = 'UPDATE_AC_COMPETENCY_SCORES'
export const UPDATE_AC_DISTRIBUTIONS = 'UPDATE_AC_DISTRIBUTIONS'
export const UPDATE_AC_TOP_PERFORMERS = 'UPDATE_AC_TOP_PERFORMERS'
export const UPDATE_AC_COMPETENCIES = 'UPDATE_AC_COMPETENCIES' 
export const UPDATE_AC_USERS = 'UPDATE_AC_USERS'

export const UPDATE_AS_BANNER_FIRST = 'UPDATE_AS_BANNER_FIRST'
export const UPDATE_AS_COMPETENCY_SCORES = 'UPDATE_AS_COMPETENCY_SCORES'
export const UPDATE_AS_DISTRIBUTIONS = 'UPDATE_AS_DISTRIBUTIONS'
export const UPDATE_AS_SKILLS = 'UPDATE_AS_SKILLS'
export const UPDATE_AS_USERS = 'UPDATE_AS_USERS'

export const UPDATE_BANNER_FT_AGILE = 'UPDATE_BANNER_FT_AGILE'
export const UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_AGILE = 'UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_AGILE'
export const UPDATE_BENCHMARK_FT_AGILE = 'UPDATE_BENCHMARK_FT_AGILE'
export const UPDATE_COURSEFEEDBACK_FT_AGILE = 'UPDATE_COURSEFEEDBACK_FT_AGILE'
export const UPDATE_RESPONSE_FT_AGILE = 'UPDATE_RESPONSE_FT_AGILE'
export const UPDATE_WORDCLOUD_FT_AGILE = 'UPDATE_WORDCLOUD_FT_AGILE'

export const UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_AGILE = 'UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_AGILE'
export const UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_AGILE = 'UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_AGILE'
export const UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_AGILE = 'UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_AGILE'
export const UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_AGILE = 'UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_AGILE'
export const UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_AGILE = 'UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_AGILE'
export const UPDATE_PIE_CHART_ACTIVITY_TAB_AGILE = 'UPDATE_PIE_CHART_ACTIVITY_TAB_AGILE'
export const UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_AGILE  ='UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_AGILE'
export const UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_AGILE ='UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_AGILE'
export const UPDATE_LINE_CHART_ACTIVITY_TAB_AGILE ='UPDATE_LINE_CHART_ACTIVITY_TAB_AGILE'
export const UPDATE_BANNER_DESC_ACTIVITY_TAB_AGILE ='UPDATE_BANNER_DESC_ACTIVITY_TAB_AGILE'

export const UPDATE_HEADER_ORG_DETAILS = 'UPDATE_HEADER_ORG_DETAILS'
export const UPDATE_FILTERS_DEFAULT = 'UPDATE_FILTERS_DEFAULT'
export const UPDATE_DOMAIN_COMPETENCY_ID = 'UPDATE_DOMAIN_COMPETENCY_ID'
export const UPDATE_DOMAIN_DEMOGRAPHICS_FILTERS = 'UPDATE_DOMAIN_DEMOGRAPHICS_FILTERS' 