import React, {useEffect} from 'react'
import Styles from './ActivityTabTrustSim.module.css'
import WhiteBox from '../WhiteBox/WhiteBox'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import LineChart from '../../commonComponents/GraphComponents/LineChart'
import HorizontalProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import HorizontalBenchmarkGroupBar from '../../commonComponents/GraphComponents/HorizontalBenchmarkGroupBar'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import { useSelector, useDispatch  } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_Trust,UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_Trust,UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_Trust,
	UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_Trust,UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_Trust,UPDATE_PIE_CHART_ACTIVITY_TAB_Trust,UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_Trust,
	UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_Trust, UPDATE_LINE_CHART_ACTIVITY_TAB_Trust
} from '../../constants/actions'
import PieChartLoader from '../../commonComponents/Loaders/ActivityTabAll/PieChartLoader'
import LineChartLoader from '../../commonComponents/Loaders/ActivityTabAll/LineChartLoader'
import TripleLineCardLoader from '../../commonComponents/Loaders/ActivityTabAll/TripleLineCardLoader'
import DoubleLineCardLoader from '../../commonComponents/Loaders/ActivityTabAll/DoubleLineCardLoader'
import HorizontalBenchGroupLoader from '../../commonComponents/Loaders/ActivityTabAll/HorizontalBenchGroupLoader'

let unit = 1;
if(window.screen.width > 1910 && window.screen.width < 2550){
	unit = 1.5;
}else if(window.screen.width >= 2551){
	unit = 2;
}

function ActivityTabTrustSim(props) {
	const activityTabTrustState = useSelector(state => state.activityTabReducer.Trust)
	const orgLevelState = useSelector(state => state.orgLevelReducer)
	const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)

	/*Filters*/
	let orgID =  orgLevelState.landingPage.orgId;
	let formatToSearch = []
	let formatToSearchString = [] 
	formatToSearch = filterCheckedListState.learningFormats;
	if(formatToSearch[0] === "All Formats"){
		formatToSearch = Object.assign([], orgLevelState.filtersPanelApi.formats[1].params);
		formatToSearch.indexOf(null) !== -1 && formatToSearch.splice(formatToSearch.indexOf(null), 1)
		formatToSearch.indexOf('All Formats') !== -1 && formatToSearch.splice(formatToSearch.indexOf('All Formats'), 1)
		formatToSearch.forEach((eachFormat) =>{
			formatToSearchString.push('"'+eachFormat+'"')
		})
	}
	else{
		formatToSearch.forEach((eachFormat) =>{
			formatToSearchString.push('"'+eachFormat+'"')
		})
	}

	let dealToSearch = []
	let dealToSearchString = [] 
	dealToSearch = filterCheckedListState.deals;
	if(dealToSearch[0] === "All Deals"){
		dealToSearch = Object.assign([], orgLevelState.filtersPanelApi.deals[1].params);
		dealToSearch.indexOf(null) !== -1 && dealToSearch.splice(dealToSearch.indexOf(null), 1)
		dealToSearch.indexOf('All Deals') !== -1 && dealToSearch.splice(dealToSearch.indexOf('All Deals'), 1)
		dealToSearch.forEach((eachDeal) =>{
			dealToSearchString.push('"'+eachDeal+'"')
		})
	}
	else{
		dealToSearch.forEach((eachDeal) =>{
			dealToSearchString.push('"'+eachDeal+'"')
		})
	}

	let batchToSearch = []
	let batchToSearchString = [] 
	batchToSearch = filterCheckedListState.batches;
	if(batchToSearch[0] === "All Batches"){
		batchToSearch = Object.assign([], orgLevelState.filtersPanelApi.batches[1].params);
		batchToSearch.indexOf(null) !== -1 && batchToSearch.splice(batchToSearch.indexOf(null), 1)
		batchToSearch.indexOf('All Batches') !== -1 && batchToSearch.splice(batchToSearch.indexOf('All Batches'), 1)
		batchToSearch.forEach((eachBatch) =>{
			batchToSearchString.push('"'+eachBatch+'"')
		})
	}
	else{
		batchToSearch.forEach((eachBatch) =>{
			batchToSearchString.push('"'+eachBatch+'"')
		})
	}

	let industryToSearch = []
	let industryToSearchString = [] 
	industryToSearch = filterCheckedListState.industries;
	if(industryToSearch[0] === undefined){
		industryToSearch = Object.assign([], orgLevelState.filtersPanelApi.industries[1].params);
		industryToSearch.indexOf(null) !== -1 && industryToSearch.splice(industryToSearch.indexOf(null), 1)
		industryToSearch.forEach((eachIndustry) =>{
			industryToSearchString.push('"'+eachIndustry+'"')
		})
	}
	else{
		industryToSearch.forEach((eachIndustry) =>{
			industryToSearchString.push('"'+eachIndustry+'"')
		})
	}

	let jobCatagoryToSearch = []
	let jobCatagoryToSearchString = [] 
	jobCatagoryToSearch = filterCheckedListState.jobCategories;
	if(jobCatagoryToSearch[0] === undefined){
		jobCatagoryToSearch = Object.assign([], orgLevelState.filtersPanelApi.jobCategories[1].params);
		jobCatagoryToSearch.indexOf(null) !== -1 && jobCatagoryToSearch.splice(jobCatagoryToSearch.indexOf(null), 1)
		jobCatagoryToSearch.forEach((eachjobCatagory) =>{
			jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
		})
	}
	else{
		jobCatagoryToSearch.forEach((eachjobCatagory) =>{
			jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
		})
	}

	let workExpToSearch = []
	let workExpToSearchString = [] 
	workExpToSearch = filterCheckedListState.workExperiences;
	if(workExpToSearch[0] === "All"){
		workExpToSearch = Object.assign([], orgLevelState.filtersPanelApi.workExperiences[1].params);
		workExpToSearch.indexOf(null) !== -1 && workExpToSearch.splice(workExpToSearch.indexOf(null), 1)
		workExpToSearch.forEach((eachworkExp) =>{
			workExpToSearchString.push('"'+eachworkExp+'"')
		})
	}
	else{
		workExpToSearch.forEach((eachworkExp) =>{
			workExpToSearchString.push('"'+eachworkExp+'"')
		})
	}

	let manageExpToSearch = []
	let manageExpToSearchString = [] 
	manageExpToSearch = filterCheckedListState.peopleManagementExperiences;
	if(manageExpToSearch[0] === "All"){
		manageExpToSearch = Object.assign([], orgLevelState.filtersPanelApi.peopleManagementExperiences[1].params);
		manageExpToSearch.indexOf(null) !== -1 && manageExpToSearch.splice(manageExpToSearch.indexOf(null), 1)
		manageExpToSearch.forEach((eachManageExp) =>{
			manageExpToSearchString.push('"'+eachManageExp+'"')
		})
	}
	else{
		manageExpToSearch.forEach((eachManageExp) =>{
			manageExpToSearchString.push('"'+eachManageExp+'"')
		})
	}

	console.log(manageExpToSearch)
	console.log(manageExpToSearchString)

	/*Queries*/
	const completedUserQuery = gql`
	query MyQuery {
		totalLearnersAllOrg: journey_org_user_aggregate {
			aggregate {
				count(columns: userid)
			}
		}
		learnersCompletedAllOrg: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}}, distinct_on: user_id) {
			aggregate {
				count(columns: user_id)
			}
		}
		learnersSignedUp: journey_complete_user_details_aggregate(where: {user_is_invitation_accepted: {_eq: "1"}, _and: {createdbyorgidRelation: {createdbyorgid: {_eq: "${orgID}"}, _and:{StringJourneyInstance:{langvalue: {_eq: "Trust Sim"}}}}, _and: {journeyIDTagRelation: {format: {_in: [${formatToSearchString}] }}, _and: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {batchRelation: {name: {_in: [${batchToSearchString}]}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}}, distinct_on: user_id) {
			aggregate {
				count(columns: user_id)
			}
		}

		learnersSignedUpAllOrg: journey_complete_user_details_aggregate(where: {user_is_invitation_accepted: {_eq: "1"}}, distinct_on: user_id) {
			aggregate {
				count(columns: user_id)
			}
		}
	}`


	const totalOrgUsersQuery = gql`
	query MyQuery {
		totalLearnersOfOrg: journey_org_user_aggregate(where: {organizationid: {_eq: ${orgID}}, _and: {UserIDRelation: {journeyIDTagRelation: {format: {_in: [${formatToSearchString}]}}, _and: {dealUserIDRelation: {DealUserRelation:{name:{_in:[${dealToSearchString}]}}}, _and: {createdbyorgidRelation: {StringJourneyInstance: {langvalue: {_eq: "Trust Sim"}}}, _and: {batchRelation: {name: {_in: [${batchToSearchString}]}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]},_and:{usercategory:{_in:[${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}} }}}}}}}}}}) {
		  aggregate {
			count(columns: userid)
		  }
		}
	  }`

	const completedLearnersQuery = gql`
	query MyQuery {
		learnersCompleted: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}, _and: {createdbyorgidRelation: {createdbyorgid: {_eq: "${orgID}"}, _and:{StringJourneyInstance:{langvalue:{_eq:"Trust Sim"}}}}, _and: {journeyIDTagRelation: {format: {_in: [${formatToSearchString}] }}, _and: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {batchRelation: {name: {_in: [${batchToSearchString}]}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}}, distinct_on: user_id) {
			aggregate {
				count(columns: user_id)
			}
		}
	}`

	const avgCourseProgressQuery = gql`
	query MyQuery {
		avgCourseProgress: journey_complete_user_details_aggregate(where: {createdbyorgidRelation: {createdbyorgid: {_eq: ${orgID}}, _and:{StringJourneyInstance:{langvalue:{_eq:"Trust Sim"}}}}, _and: {journeyIDTagRelation: {format: {_in: [${formatToSearchString}]}}, _and: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {batchRelation: {name: {_in: [${batchToSearchString}]}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}) {
		  aggregate {
			avg {
			  journey_progress
			}
		  }
		}
	  }`

	const avgTimeSpentQuery = gql`
	query MyQuery {
		avgTimeSpent: journey_complete_user_details_aggregate(where: {createdbyorgidRelation: {createdbyorgid: {_eq: "${orgID}"}, _and:{StringJourneyInstance:{langvalue:{_eq:"Trust Sim"}}}}, _and: {journeyIDTagRelation: {format: {_in: [${formatToSearchString}]}}, _and: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {batchRelation: {name: {_in: [${batchToSearchString}]}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}) {
			aggregate {
				avg {
					journey_instance_timespent
				}
			}
		}
	}`

	const completionDateQuery = gql`
	query MyQuery {
		completionDate: journey_completion_by_date(where: {createdbyorgid: {_eq: "${orgID}"}}, order_by: {journey_instance_completed_time: asc}) {
			journey_instance_completed_time
			user_id
		}
	}`
	const dispatch = useDispatch()
	const { loading, error, data } = useQuery(completedUserQuery,{fetchPolicy: 'no-cache'});
	const { loading:CLLoading, error:CLError, data:CLData } = useQuery(completedLearnersQuery,{fetchPolicy: 'no-cache'});
	const { loading:ATSLoading, error:ATSError, data:ATSData } = useQuery(avgTimeSpentQuery,{fetchPolicy: 'no-cache'});
	const { loading:CDLoading, error:CDError, data:CDData } = useQuery(completionDateQuery,{fetchPolicy: 'no-cache'});
	const { loading:TLLoading, error:TLError, data:TLData } = useQuery(totalOrgUsersQuery,{fetchPolicy: 'no-cache'});
	const { loading:ACPLoading, error:ACPError, data:ACPData } = useQuery(avgCourseProgressQuery,{fetchPolicy: 'no-cache'});

	useEffect(() => {
        if(!loading && !error && data!=null){
			dispatch({type: UPDATE_NO_OF_SIGNEDUP_LEARNERS_ACTIVITY_TAB_Trust, payload: data.learnersSignedUp.aggregate.count});
			let totalEnrolledLernersTrustwithFilter = activityTabTrustState.enrolledLearnersTrust;
			if (totalEnrolledLernersTrustwithFilter == 0)  totalEnrolledLernersTrustwithFilter = 1;
			dispatch({type: UPDATE_PIE_CHART_ACTIVITY_TAB_Trust, 
				payload: [
					Math.round((activityTabTrustState.noOfCompletedLearnersTrust/totalEnrolledLernersTrustwithFilter)*100),
					Math.round((data.learnersCompletedAllOrg.aggregate.count/data.totalLearnersAllOrg.aggregate.count)*100)
				]
			});
			dispatch({type: UPDATE_HORIZONTAL_BENCHMARKGROUPBAR_ACTIVITY_TAB_Trust, 
				payload: [
					Math.round((data.learnersSignedUp.aggregate.count/totalEnrolledLernersTrustwithFilter)*100),
					Math.round((data.learnersSignedUpAllOrg.aggregate.count/data.totalLearnersAllOrg.aggregate.count)*100)
				]
			});
		}
	}, [loading, error, data, activityTabTrustState.enrolledLearnersTrust, activityTabTrustState.noOfCompletedLearnersTrust])

	useEffect(() => {
        if(!TLLoading && !TLError && TLData!=null){			
			dispatch({type: UPDATE_ENROLLED_LEARNERS_ACTIVITY_TAB_Trust, payload: TLData.totalLearnersOfOrg.aggregate.count});
		}
	}, [TLLoading, TLError, TLData])
	useEffect(() => {
        if(!CLLoading && !CLError && CLData!=null){		
			dispatch({type: UPDATE_NO_OF_COMPLETED_LEARNERS_ACTIVITY_TAB_Trust, payload: CLData.learnersCompleted.aggregate.count});
		}
	}, [CLLoading, CLError, CLData])
	useEffect(() => {
        if(!ACPLoading && !ACPError && ACPData!=null){
			dispatch({type: UPDATE_AVG_COURSE_PROGRESS_ACTIVITY_TAB_Trust, payload: Math.round(ACPData.avgCourseProgress.aggregate.avg.journey_progress)});
			dispatch({type: UPDATE_HORIZONTAL_PROGRESS_BAR_ACTIVITY_TAB_Trust, payload: Math.round(ACPData.avgCourseProgress.aggregate.avg.journey_progress)});
		}
	}, [ACPLoading, ACPError, ACPData])
	useEffect(() => {
        if(!ATSLoading && !ATSError && ATSData!=null){
			dispatch({type: UPDATE_AVG_TIME_SPENT_ACTIVITY_TAB_Trust, payload: Math.round(ATSData.avgTimeSpent.aggregate.avg.journey_instance_timespent/60)});
		}
	}, [ATSLoading, ATSError, ATSData])

	let completionDateObj = {};
	let completionDatePayload = [];
	useEffect(() => {
        if(!CDLoading && !CDError && CDData!=null){
			let cumulative = 0
			let dayZero = CDData.completionDate[0].journey_instance_completed_time;
			CDData.completionDate.forEach(
				(eachObj) =>{
					if(eachObj.journey_instance_completed_time != null ){
						let date = Math.floor((eachObj.journey_instance_completed_time- dayZero)/86400);
						if (!(date in completionDateObj))
						{
							completionDateObj[date] = cumulative + 1;
							cumulative += 1
						}
						else{
							completionDateObj[date] = completionDateObj[date] + 1;
							cumulative += 1
						}
					}
				}
			);
			
			for(const dates in completionDateObj){
				completionDatePayload.push({'Days':parseInt(dates, 10), '% Of Users': parseInt(completionDateObj[dates], 10)})
			}
			dispatch({type: UPDATE_LINE_CHART_ACTIVITY_TAB_Trust, 
				payload: completionDatePayload
			});
		}
	}, [CDLoading, CDError, CDData])


	return (
        <div className={Styles.mainContainer}>
            <div className={Styles.headerItem} data-aos="fade-up" data-aos-duration="350"  data-aos-once="true">
                <SimpleBanner title="INFLUENCING SKILLS FOR LEADING WITHOUT AUTHORITY"/>
            </div>

            <div className={Styles.row1Container} data-aos="fade-up" data-aos-duration="350" data-aos-delay="350" data-aos-once="true">
                <div className={Styles.twoRowsSpan}>
					<WhiteBox>
						{loading &&
							<PieChartLoader {...activityTabTrustState.dataForPieChartTrust}/>
						}
						{!loading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title={<span> COMPLETION <br/> (% OF LEARNERS)</span>}/>
							</div>
							<div>
								<PieChart {...activityTabTrustState.dataForPieChartTrust}/>
								<br/>
							</div>
						</>
						}
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						{CLLoading &&
							<TripleLineCardLoader width = {230*unit} height = {110*unit} thirdRectY = {65*unit}/>
						}
						{!CLLoading &&
							<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="NO. OF LEARNERS WHO COMPLETED"/>
							</div>
							<div className={Styles.text1}>
								{activityTabTrustState.noOfCompletedLearnersTrust}
							</div>
							</>
						}
					</WhiteBox>
				</div>
				<div className= {Styles.otherStatsContainer} >
					<div className={ Styles.otherStatsHeader }>
						&emsp;OTHER STATISTICS
					</div>
					<div>
						<WhiteBox>
							{ TLLoading &&
								<DoubleLineCardLoader />
							}
							{ !TLLoading &&
							<>
								<div className={Styles.graphTitle}>
									<GraphTitle title="ENROLLED LEARNERS"/>
								</div>
								<div className={Styles.text1}>
									{activityTabTrustState.enrolledLearnersTrust}
								</div>
							</>
							}
						</WhiteBox>
					</div>
					<div>
						<WhiteBox>
							{ ATSLoading &&
								<DoubleLineCardLoader />
							}
							{ !ATSLoading &&
							<>
								<div className={Styles.graphTitle}>
									<GraphTitle title="AVERAGE TIME SPENT"/>
								</div>
								<div className={Styles.text1}>
									{activityTabTrustState.averageTimeSpentTrust}<text style={{fontSize: '10px'}}>mins</text>
								</div>
							</>
							}
						</WhiteBox>
					</div>
				</div>
				<div>
					<WhiteBox>
						{ACPLoading &&
							<TripleLineCardLoader width = {230*unit} height = {110*unit} thirdRectY = {65*unit}/>
						}
						{!ACPLoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="AVERAGE COURSE PROGRESS"/>
							</div>
							<div className={Styles.insideWhiteBox4Columns}>
								<div className={Styles.text1}>
									<text>{activityTabTrustState.avgCourseProgressTrust}</text>
									<text style={{fontSize: '10px'}}>%</text>
								</div>
								<div className={Styles.threeColumnsSpan}>
									<HorizontalProgressBar {...activityTabTrustState.dataForHorizontalProgressBarTrust}/>
								</div>
							</div>
						</>
						}
					</WhiteBox>
				</div>
				<div>
					<WhiteBox>
						{loading &&
							<HorizontalBenchGroupLoader width = {440*unit} height = {115*unit}/>
						}
						{!loading &&
						<>
						<div className={Styles.graphTitle}>
							<GraphTitle title="LEARNERS WHO SIGNED UP"/>
						</div>
						<div className={Styles.insideWhiteBox4Columns}>
							<div className={Styles.text1}>
								{activityTabTrustState.noOfLearnersSignedUpTrust}
							</div>
							<div className={Styles.threeColumnsSpan} style={{borderLeft: '2px solid #F5EFFB'}}>
								<HorizontalBenchmarkGroupBar {...activityTabTrustState.dataForHorizontalBenchmarkGroupBarTrust}/>
							</div>
						</div>
						</>
						}
					</WhiteBox>
				</div>
            </div>

			<div className={Styles.row2Container}data-aos="fade-up" data-aos-duration="350" data-aos-delay="700" data-aos-once="true">
				<div className={Styles.twoColumnsSpan}>
					<WhiteBox>
						{CDLoading &&
							<LineChartLoader {...activityTabTrustState.dataForLineChartTrust} />
						}
						{!CDLoading &&
						<>
							<div className={Styles.graphTitle}>
								<GraphTitle title="TREND OF LEARNER CONSUMPTION"/>
							</div>
							<br/>
							<div>
								<LineChart {...activityTabTrustState.dataForLineChartTrust} />
							</div>
							<br/>
						</>
						}
					</WhiteBox>
				</div>
            </div>
        </div>
        )
}

export default ActivityTabTrustSim;