import React, { Component, useEffect } from "react";
import Styles from './Rated.module.css'
import {Component2} from "./Rated";
import Title from "../GraphTitle/Title";
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import { useHistory, useParams } from 'react-router-dom'
import CompetencyLoader from '../../Loaders/PerformanceTabAll/CompetencyLoader'

var lead=[
    'Leading next',
    'Leading Business'
]

function MainModule(props){
    const { orgId } = useParams()
    let arrr = []
    const COMPETENCY_DOMAIN_QUERY = gql`
        query MyQuery {
            domainComp: dashboard_filters(distinct_on: journey_instance_id, where: {organization_id: {_eq: ${orgId}}, journey_instance_id: {_eq: ${props.jid}}})
            {
              journey_domain
              journey_categories
            }
          }
            `

    const { loading, error, data } = useQuery(COMPETENCY_DOMAIN_QUERY)        

    if(!loading && data && data.domainComp[0])
    {
        arrr.push(data.domainComp[0].journey_domain)
        arrr.push(data.domainComp[0].journey_categories)
    }

    if(loading)
    {
        return <div>Loading..</div>
    }

    if(error && loading==false)
    {
        return <div>Error..</div>
    }

    return (
        <div className={Styles.outerBox}>
            <Title title={props.title||'Contextual leadership'} show={false} />
            <Component2 arr={arrr}/>
        </div>
    )
}

export default MainModule