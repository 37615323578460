import React from 'react';
import { Group } from '@vx/group';
import { GridRows, GridColumns } from '@vx/grid';
import { AxisBottom,AxisLeft } from '@vx/axis';
import {LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend'
import { scaleBand, scaleOrdinal } from '@vx/scale';
import getRoundedRect from '../../utils/GraphUtils'
import Styles from './GraphComponents.module.css'
import { useTooltip, Tooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';

const colorPalette = ['#9F7DFF', '#FF8B72']

function GroupHorizontalBar (props) {
    const data = props.data;
    const width = props.width;  
    const height = props.height; 
    let unit = 1;
            if(window.screen.width > 1910 && window.screen.width < 2550){
                //for FHD Screens
                unit = 1.5;
            }else if(window.screen.width >= 2551){
                //for QHD Screens
                unit = 2;
            }
    const margin = { top: 0*unit, bottom: 45*unit, left: 125*unit, right: 0*unit };
    
    // Then we'll create some bounds
    const xMax = width - margin.right;
    const yMax = height  - margin.bottom;
    const xMin = margin.left;
    const yMin = margin.top;
    
    // We'll make some helpers to get at the data we want
    const x1 = d => d.Group;
    const x2 = d => d.Benchmark
    const y = d => d.Title;
    
    const xScale = scaleBand({
        rangeRound: [xMin, xMax],
        domain: [' ','Novice','Emerging','Competent','Proficient','Role Model'],
    });
    const yearScale = scaleBand({
        rangeRound: [xMin, xMax],
        domain: [' ', '(1,2)', '(3,4)', '(5,6)', '(7,8)', '(9,10)'],
    });
    const yScale = scaleBand({
        rangeRound: [yMax, yMin],
        domain: data.map(y).reverse() ,
    });

    const ordinalColorScale = scaleOrdinal({
        domain: ['Group', 'Benchmark'],
        range: colorPalette
    });

    const compose = (scale, accessor) => data => scale(accessor(data));
    const xPoint1 = compose(xScale, x1);
    const xPoint2 = compose(xScale, x2);
    const yPoint = compose(yScale, y);

    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, data, i) => {
        let tooltipLeft = xScale(' ') + xScale.bandwidth()/2;
        let tooltipTop = (i*2 + 1)*(yScale.bandwidth()/2) ;
        const tooltipData = {...data};
        
        showTooltip({
            tooltipLeft: tooltipLeft,
            tooltipTop: tooltipTop,
            tooltipData: tooltipData
        });
    };
    
    return(
        <div>
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height}>
            <GridRows
                left={margin.left}
                scale={yScale}
                width={xMax - xMin - xScale.bandwidth()}
                height={yMax - yMin}
                stroke="#EAECF3"
                numTicks={6}
            />
            <GridColumns
                top={margin.top}
                scale={xScale}
                width={xMax - xMin}
                height={yMax - yMin}
                stroke="#EAECF3"
                numTicks={6}
            />
            {/* <line x1={xMax} x2={xMax} y1={yMin} y2={yMax} stroke="#EAECF3" /> */}
            <line x1={xMin} x2={xMax - xScale.bandwidth()} y1={yMax} y2={yMax} stroke="#EAECF3" />
            <AxisBottom
                left={0 - xScale.bandwidth()/2}
                top={height - margin.bottom + 9*unit}
                scale={xScale}
                hideAxisLine= {true}
                hideTicks={true}
                tickLabelProps={(value, index) => ({
                    fontSize: 11*unit,
                    textAnchor: 'middle',
                    fill: '#2F4585',
                    fontFamily: 'Nunito',
                    fontStyle: 'normal'
                })}
            /> 
            { props.show &&
            <AxisBottom
                top={height - margin.bottom -4*unit}
                left={0 - xScale.bandwidth()/2}
                scale={yearScale}
                hideAxisLine= {true}
                hideTicks={true}
                tickLabelProps={(value, index) => ({
                    fontSize: 11*unit,
                    textAnchor: 'middle',
                    fill: '#2F4585',
                    opacity: '0.4',
                    fontFamily: 'Nunito',
                    fontStyle: 'normal'
                })}
            />
        }
            <AxisLeft
                left={margin.left}
                scale={yScale}
                hideAxisLine= {true}
                hideTicks={true}
                tickLabelProps={(value, index) => ({
                    fontSize: 10*unit,
                    textAnchor: 'end',
                    verticalAnchor: 'middle',
                    fill: '#2F4585',
                    fontFamily: 'Nunito',
                    fontStyle: 'normal'
                })}
            /> 
            {data.map((d, i) => {
                let barHeight = 4*unit;
                let borderRad = 2*unit;
                
                return (
                    <Group key={`bar-${i}`}
                        onMouseMove={event =>
                            handleMouseOver(
                                event,
                                data[i],
                                i
                        )}
                        onMouseLeave={hideTooltip}
                        onMouseOut={hideTooltip}
                    >
                        <Spring
                            delay={i*500}
                            config={{duration: 500}}
                            from={{ value: getRoundedRect(xMin, yPoint(d) + (2*yScale.bandwidth()/5), 0 , barHeight, 0, 'right') }}
                            to={{ value: getRoundedRect(xMin, yPoint(d) + (2*yScale.bandwidth()/5), xPoint1(d) - xMin + 1*unit, barHeight, borderRad, 'right') }}
                        >
                            {props =>
                                <path
                                    id={`GroupBar-${i}`}
                                    d={props.value}
                                    style={{ fill: colorPalette[0] }}
                                />
                            }
                        </Spring>
                        <path
                            id={`HiddenBar-${i}`}
                            d={getRoundedRect(xMin , yPoint(d) + (2*yScale.bandwidth()/5), xMax , 3*barHeight, 0, 'right')}
                            style={{ opacity: '0' }}
                        />
                        <Spring
                            delay={i*500 + 200}
                            config={{duration: 500}}
                            from={{ value: getRoundedRect(xMin, yPoint(d) + (3*yScale.bandwidth()/5), 0 , barHeight, 0, 'right') }}
                            to={{ value: getRoundedRect(xMin , yPoint(d) + (3*yScale.bandwidth()/5), xPoint2(d) - xMin + 1*unit, barHeight, borderRad, 'right') }}
                        >
                            {props =>
                                <path
                                    id={`BenchBar-${i}`}
                                    d={props.value}
                                    style={{ fill: colorPalette[1] }}
                                />
                            }
                        </Spring>
                    </Group>
                );
            })}
            {tooltipOpen && (
                <>
                <rect
                    y={tooltipTop - 8*unit}
                    x={tooltipLeft}
                    width = {250*unit} 
                    height = {22*unit } 
                    fill = 'rgba(250, 250, 250, 0.9)'
                    stroke = 'rgba(0, 0, 0, 0.1)'
                    className={Styles.tooltipBlur}
                    rx={4*unit}
                />
                <text 
                    textAnchor = 'start'
                    x = {tooltipLeft + 10*unit}
                    y = {tooltipTop + 7*unit}
                    className={Styles.tooltipText}
                >
                    Group : {tooltipData.Group} &nbsp; &nbsp; &nbsp; &nbsp;
                    Benchmark : {tooltipData.Benchmark} 
                </text>
                </>
            )}
        </svg>
        </div>
        <LegendOrdinal scale={ordinalColorScale}>
        {labels => {
            return (
                <div style={{justifyContent:'center', display: 'flex', flexDirection: 'rows'}}>
                    {labels.map((label, i) => {
                    const size = 8*unit;
                    return (
                        <LegendItem
                            key={`legend-quantile-${i}`}
                            margin={`0 ${5*unit}px`}
                        >
                        <svg width={size} height={size}>
                            <circle cx={size/2} cy={size/2} r={size/2}  fill={colorPalette[i]} />
                        </svg>
                            <LegendLabel
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: `${10*unit}px`,
                                    color: '#2F4585',
                                    marginLeft: `${10*unit}px`
                                }}
                            >
                                {label.text}
                            </LegendLabel>
                        </LegendItem>
                    );
                    })}
                </div>
            );
        }}
        </LegendOrdinal>
        </div>
    );
}

export default GroupHorizontalBar