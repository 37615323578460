import { combineReducers, createStore } from 'redux'
import {activityTabReducer} from './activityTab/activityTabReducer'
import {orgLevelReducer} from './orgLevel/orgLevel'
import { performanceTab } from './performanceTab/performanceTab'
import { experienceTab } from "./experienceTab/experienceTabReducer.js"
import { nonSimFeedbackTab } from './nonSim/nonSimFeedbackTab'
import {courseGroupFeedbackTab} from './courseGroup/courseGroupFeedback'
import {ileadFeedbackTab} from './ilead/ileadFeedbackTab'
import {CQFeedbackTab} from './CQ/cqFeedbackTab'
import {trustFeedbackTab} from './trustSim/trustFeedbackTab'
import {CoachingSimFeedbackTab} from './CoachingSim/CoachingSimFeedback'
import {AgileFeedbackTab} from './Agile/AgileFeedbackTab'





const rootReducer = combineReducers({
    orgLevelReducer,
    performanceTab,    
    experienceTab,
    nonSimFeedbackTab,
    courseGroupFeedbackTab,
    ileadFeedbackTab,
    activityTabReducer,
    CQFeedbackTab,
    trustFeedbackTab,
    CoachingSimFeedbackTab,
    AgileFeedbackTab
})

export default rootReducer

