import React,{useEffect} from 'react'
import Styles from './tabedCheck.module.css'
import Divider from '@material-ui/core/Divider'
import {useDispatch} from 'react-redux'
import {handleFilters} from '../../../actions/actionCreator'
import {UPDATE_FILTER_ITEM} from '../../../constants/actions'
import {useParams} from 'react-router-dom'


function TabedCheckFilter(props){
    const header = props.parameters[0].header    
    const [tabChecklist, setTabChecklist] = React.useState(['All'])
    const dispatch= useDispatch()
    const { id } = useParams()

    useEffect(() => {
        setTabChecklist(['All'])
    }, [id]);
    
    const handleChange = (e) => {        
        let options = [...tabChecklist]
        if((e.target.value === 'All' && e.target.checked) || (options.length == 1 && !e.target.checked)){
            options =['All']
        }
        else{           
            if(options.includes('All')){
                let all_index = options.indexOf('All')
                options.splice(all_index, 1)
            }            
            if(e.target.checked){                
                options.push(e.target.value)                              
            }
            else{                                
                let value_index= options.indexOf(e.target.value)
                options.splice(value_index,1)                    
            }
        }       
        setTabChecklist(options)          
    }  

    const handleClearAll = () =>{
        setTabChecklist(['All'])
    } 

    useEffect(() => {
        dispatch(header === 'work Experience' ? handleFilters(tabChecklist,UPDATE_FILTER_ITEM, 'workExperiences') : handleFilters(tabChecklist,UPDATE_FILTER_ITEM, 'peopleManagementExperiences'))               
    }, [tabChecklist]); 
     
    return(
        <div className={Styles.body}>
            
            <div className={Styles.header}>
                <span className={Styles.header_title}>{header}</span>
                {tabChecklist.length ? <span className={Styles.clear} onClick={handleClearAll}>Clear</span>  :  <span className={Styles.no_action_clear}>Clear</span>}
            </div>

            <ul className={Styles.ul}>
                <li className={Styles.li}>
                    <input type='checkbox' value='All'  id={`All${header}`} className={Styles.checkbox} name={`All${header}`} checked={tabChecklist.includes('All')} onChange={handleChange}/>
                    <label for={`All${header}`} className={Styles.all_label}>All</label>
                </li>
                {
                    props.parameters[1].params.map(years => (
                        <li className={Styles.li}>
                            <input type='checkbox' value={years}  id={`${years}${header}`} className={Styles.checkbox} name={`${years}${header}`} checked={tabChecklist.includes(years)} onChange={handleChange}/>
                            <label for={`${years}${header}`} className={Styles.label}>{`${years}`}</label>
                        </li>
                    ))                
                }
            </ul>
            {props.parameters[2].divider ? <Divider/> : null}
            
        </div>
    )
}
export default TabedCheckFilter