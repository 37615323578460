import { gql } from "apollo-boost";

const landingQueries = {
    rootQuery: gql`
    query MyQuery($orgId:bigint!) {
      orgName: journey_org(where: {organizationid: {_eq: $orgId}}) {
          logo
          name
        }
    }
    `,
    midContainerQuery: gql`
    query MyQuery($orgId:bigint!) {
      maxLearnersArray: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}, UserJourneyInstance: {isdeleted: {_eq: "0"}}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}, order_by: {journeyinstanceid: asc}) {
        journeyinstanceid
        name
        StringJourneyInstance {
          langvalue
        }
        UserJourneyInstance_aggregate(distinct_on: user_id, where: {isdeleted: {_eq: "0"}}) {
          aggregate {
            count
          }
        }
      }
      coursesCount:journey_journey_instance_aggregate(distinct_on: journeyinstanceid, where: {createdbyorgid: {_eq: $orgId}, isdeleted: {_eq: "0"}, journeyinstancejourney: {_or: {tags: {_ilike: "%passport%"}}}}) {
        aggregate {
          count
        }
      }
      uniqueLearnersCount: journey_complete_user_details_aggregate(where: {isdeleted: {_eq: "0"}, filtersRelation: {organization_id: {_eq: $orgId}}, journeyIDTagRelation: {tags: {_ilike: "%passport%"}}}){
        aggregate {
          count(columns: user_id, distinct: true)
          }
      }
      batchesCorrectCount: journey_batch_aggregate(distinct_on: batchid, where: {journeybatchinstance: {createdbyorgid: {_eq: $orgId}, isdeleted: {_eq: "0"}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}, isdeleted: {_eq: "0"}}) {
        aggregate {
          count
        }
      }
      batchesArray: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}}){
          journeyinstanceid
          BatchJourneyInstance_aggregate(distinct_on:batchid){
            aggregate {
              count
            }
          }
          BatchJourneyInstance(distinct_on: batchid)
        {
              batchid
        }
        }
        usersWithProgress: journey_complete_user_details_aggregate(distinct_on: [user_id], where: {journey_progress: {_eq: "100"}, _and: {createdbyorgidRelation: {createdbyorgid: {_eq: $orgId}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}, isdeleted: {_eq: "0"}}, isdeleted: {_eq: "0"}}}) {
          aggregate {
            count(columns: user_id)
          }
        }
      totalUsers: journey_complete_user_details_aggregate(distinct_on: [user_id], where: {createdbyorgidRelation: {createdbyorgid: {_eq: $orgId}, isdeleted: {_eq: "0"}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}, isdeleted: {_eq: "0"}}) {
        aggregate {
          count(columns: user_id)
        }
      }
      usersWithProgressArray: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}, UserJourneyInstance: {isdeleted: {_eq: "0"}}, isdeleted: {_eq: "0"}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}, order_by: {journeyinstanceid: asc}) {
        journeyinstanceid
        UserJourneyInstance_aggregate(distinct_on: [user_id, batch_id], where: {journey_progress: {_eq: 100}, isdeleted: {_eq: "0"}}) {
          aggregate {
            count
          }
        }
        StringJourneyInstance {
          langvalue
        }
      }
      totalUsersArray: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}, UserJourneyInstance: {isdeleted: {_eq: "0"}}, isdeleted: {_eq: "0"}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}, order_by: {journeyinstanceid: asc}) {
        journeyinstanceid
        UserJourneyInstance_aggregate(distinct_on: [user_id, batch_id], where: {isdeleted: {_eq: "0"}}) {
          aggregate {
            count
          }
        }
        StringJourneyInstance {
          langvalue
        }
      }
      timeSpentArray: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}, UserJourneyInstance: {isdeleted: {_eq: "0"}}, isdeleted: {_eq: "0"}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}) {
        journeyinstanceid
        UserJourneyInstance_aggregate(distinct_on: user_id, where: {isdeleted: {_eq: "0"}}) {
          aggregate {
            avg {
              journey_instance_timespent
            }
          }
        }
      }
      licensesArray: journey_deal(where: {organizationid: {_eq: $orgId}, isdeleted: {_eq: "0"}}) {
        totallicenses
        licensesremaining
        startdate
        enddate
      }
        averageFeedback:feedback_user_tag_enitity_wise_feedback_data_aggregate(where: {usertagentityBatchRelation: {journeybatchinstance: {OrgJourneyInstance: {organizationid: {_eq: $orgId}}, journeyinstancejourney: {tags: {_ilike: "%passport%"}}}}, _and: {tagname: {_eq: "satisfaction"}, isdeleted: {_eq: "0"}}}) {
          aggregate {
            avg {
              rating
            }
          }
        }
    }
    `,
    notRequiredQuery: gql`
    query MyQuery($orgId:bigint!) {
      journeyInstanceDeal: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}}){
          journeyinstanceid
          JourneyDealInstance{
            dealid
          }
        }
        formatJourneyInstance: journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}}){
          journeyinstanceid
          JourneyJourneyInstance(distinct_on: format) {
            format
          }
        }
        orgList: journey_org {
          organizationid
          name
        }
        DealUsers : journey_deal(where: {organizationid: {_eq: $orgId}}) {
          dealid
          name
          DealUserArrayRelation(distinct_on: userid, order_by: {userid: asc}) {
            userid
          }
        }
        FormatBatches : journey_journey_instance(where: {createdbyorgid: {_eq: $orgId}}) {
          BatchJourneyInstance(distinct_on: batchid) {
            batchid
          }
          JourneyJourneyInstance(distinct_on: format) {
            format
          }
        }
        batches: journey_batch(distinct_on: name, where: {journeybatchinstance: {createdbyorgid: {_eq: $orgId} }}) {
          name
          batchid
        }
    }
    `
}

export default landingQueries ;