import React from "react"
import ContentLoader from "react-content-loader"
import WhiteBox from '../../../components/WhiteBox/WhiteBox'

const BannerLoader = () => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width = 750*unit;
    const height = 70*unit; 
    
    return(
        <WhiteBox>
            <ContentLoader 
                speed={2}
                width={width}
                height={height}
                viewBox={"0 0 "+width+" "+height}
                backgroundColor="#f3f3f3"
                foregroundColor="#dcdbdb"
            >
                <rect x={20*unit} y={20*unit} rx={5*unit} ry={5*unit} width={width - (50*unit)} height={10*unit} />
                <rect x={20*unit} y={45*unit} rx={5*unit} ry={5*unit} width={width - (200*unit)} height={8*unit} />
                
            </ContentLoader>
        </WhiteBox>
        
    )
}

export default BannerLoader;