import React from 'react';
import { Group } from '@vx/group';
import { GridRows, GridColumns } from '@vx/grid';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { scaleLinear, scaleBand, scaleOrdinal } from '@vx/scale';
import {LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend'
import { Bar } from '@vx/shape';
import getRoundedRect from '../../utils/GraphUtils';
import Styles from './GraphComponents.module.css';
import { useTooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';

const colorPalette = ['#464796', '#735DB4', '#9F7DFF']

function BarGraphLegend1(props) { 
    const width = props.width;  
    const height = props.height;
    const data = props.data; 
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            //for FHD Screens
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            //for QHD Screens
            unit = 2;
        }
    const margin = { top: 10*unit, bottom: 30*unit, left: 40*unit, right: 10*unit };
    const barWidth = 18*unit;
    const borderRad = 4*unit
    const textSize = 11*unit;
    
    const xMin = margin.left
    const yMin = margin.top;
    const xMax = width - margin.right;
    const yMax = height - margin.bottom;

    const yLabel = Object.keys(data[0])[1];
    const x = d => d.exp;
    const y = d => d[yLabel];
    const year = d => d.years;
    

    const xScale = scaleBand({
        rangeRound: [xMin, xMax],
        domain: data.map(x),
    });
    const yearScale = scaleBand({
        rangeRound: [xMin, xMax],
        domain: data.map(year),
    });
        
    const yScale = scaleLinear({
        rangeRound: [yMax, yMin],
        domain: [0, 100],
    });

    const ordinalColorScale = scaleOrdinal({
        domain: ['Low', 'Med', 'High'],
        range: colorPalette
    });

    const compose = (scale, accessor) => data => scale(accessor(data));
    const xPoint = compose(xScale, x);
    const yPoint = compose(yScale, y);

    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, data, x, y) => {
        const tooltipTop = y - 11;
        const tooltipLeft = x;
        const tooltipData = data;
        
        showTooltip({
            tooltipLeft: tooltipLeft,
            tooltipTop: tooltipTop,
            tooltipData: tooltipData
        });
    };

  return (
    <div>
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height}>
                <GridRows
                    left={margin.left}
                    scale={yScale}
                    width={xMax - xMin}
                    height={yMax - yMin}
                    stroke="#EAECF3"
                    numTicks={6}
                />
                <GridColumns
                    top={margin.top}
                    scale={xScale}
                    width={xMax - xMin}
                    height={yMax - yMin}
                    stroke="#EAECF3"
                    numTicks={6}
                />
            <line x1={xMax} x2={xMax} y1={yMin} y2={yMax} stroke="#EAECF3" />
            <AxisLeft
                left={margin.left}
                scale={yScale}
                hideAxisLine= {true}
                hideTicks={true}
                hideZero={false}
                tickLabelProps={(value, index) => ({
                    fontSize: 10*unit,
                    textAnchor: 'end',
                    verticalAnchor: 'middle',
                    fill: '#2F4585',
                    fillOpacity: 0.4,
                    fontFamily: 'Nunito',
                    fontStyle: 'normal'
                })}
                label={yLabel}
                labelClassName={Styles.leftlabel}
                labelOffset={20*unit}
                numTicks={4}
            /> 
            <AxisBottom
                top={height - margin.bottom + 9*unit}
                left={0}
                scale={xScale}
                hideAxisLine= {true}
                hideTicks={true}
                tickLabelProps={(value, index) => ({
                    fontSize: 11*unit,
                    textAnchor: 'middle',
                    fill: '#2F4585',
                    fontFamily: 'Nunito',
                    fontStyle: 'normal',
                    width: 96
                })}
            />
        { yLabel === '% Of Users' &&
                <AxisBottom
                    top={height - margin.bottom -4*unit}
                    left={0}
                    scale={yearScale}
                    hideAxisLine= {true}
                    hideTicks={true}
                    tickLabelProps={(value, index) => ({
                        fontSize: 10*unit,
                        textAnchor: 'middle',
                        fill: '#2F4585',
                        opacity: '0.4',
                        fontFamily: 'Nunito',
                        fontStyle: 'normal'
                    })}
                />
            }
            {tooltipOpen && (
                <>
                <Bar
                    x = {tooltipLeft - 2*unit - xScale.bandwidth()/2}
                    y = {tooltipTop - textSize - 2*unit}
                    width={xScale.bandwidth() + 4*unit}
                    height={2*textSize + 5*unit}
                    fill = 'rgba(255, 255, 255, 0.6)'
                    rx={4*unit}
                /> 
                <text
                    textAnchor = 'middle'
                    x = {tooltipLeft}
                    y = {tooltipTop - 2*unit}
                    fontSize = {textSize}
                    fill = '#2F4585'
                    fontWeight= '600'
                    fontFamily = 'Nunito'
                    fontStyle = 'normal'
                    
                >
                    {tooltipData}%
                </text><br/>
                <text
                    textAnchor = 'middle'
                    x = {tooltipLeft}
                    y = {tooltipTop + textSize}
                    fontSize = {textSize}
                    fill = '#2F4585'
                    fontWeight= '600'
                    fontFamily = 'Nunito'
                    fontStyle = 'normal'
                    opacity = '0.6'
                >
                </text>
                </>
            )}
            {data.map((d, i) => {
                const barHeight = yMax - yPoint(d);
                return (
                    <Group key={`bar-${i}`}
                        onMouseMove={event =>
                            handleMouseOver(
                                event,
                                d[yLabel],
                                xPoint(d) +  (xScale.bandwidth()/2) ,
                                yPoint(d) - 10*unit
                        )}
                        onMouseLeave={hideTooltip}
                        onMouseOut={hideTooltip}
                    >
                        <Spring
                            delay={i*750}
                            config={{duration: 750}}
                            from={{ value: getRoundedRect(xPoint(d) + (xScale.bandwidth() - barWidth)/2 , yMax , barWidth, 0, 0, 'top') }}
                            to={{ value: getRoundedRect(xPoint(d) + (xScale.bandwidth() - barWidth)/2 , yPoint(d), barWidth, barHeight, borderRad, 'top') }}
                        >
                            {props => 
                                <path
                                    id={`bar-${i}`}
                                    d={props.value}
                                    style={{ fill: d['exp'] == 'Low' ? colorPalette[0] : d['exp'] == 'High' ? colorPalette[2] : colorPalette[1]}}
                                />
                            }
                        </Spring>
                    </Group>
                );
            })}
        </svg>
        </div>
        <div style={{justifyContent: 'center', display: 'flex', marginTop: 10}}>
            <LegendOrdinal scale={ordinalColorScale}>
            {labels => {
                return (
                    <div style={{justifyContent:'center', display: 'flex', flexDirection: 'rows'}}>
                        {labels.map((label, i) => {
                        const size = 8*unit;
                        return (
                            <LegendItem
                                key={`legend-quantile-${i}`}
                                margin={`0 ${5*unit}px`}
                            >
                            <svg width={size} height={size}>
                                <circle cx={size/2} cy={size/2} r={size/2}  fill={colorPalette[i]} />
                            </svg>
                                <LegendLabel
                                    style={{
                                        fontFamily: 'Nunito',
                                        fontSize: `${10*unit}px`,
                                        color: '#2F4585',
                                        marginLeft: `${10*unit}px`
                                    }}
                                >
                                    {label.text}
                                </LegendLabel>
                            </LegendItem>
                        );
                        })}
                    </div>
                );
                }}
            </LegendOrdinal>
        </div>
    </div>
  );
}

export default BarGraphLegend1