import React from "react"
import ContentLoader from "react-content-loader"

const FilterPanelLoader = (props) => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width = 250;
    const height = 250; 
    const svgWidth = width + (60*unit)
    const svgHeight = height + (60*unit)
    
    return(
        <ContentLoader 
            speed={2}
            width={width}
            height={height}
            viewBox={"0 0 "+svgWidth+" "+svgHeight}
            backgroundColor="#f3f3f3"
            foregroundColor="#dcdbdb"
        > 
        <circle cx={(svgWidth/2)+(2*unit)} cy={(svgHeight/2)+(5*unit)} r={25*unit}/> 
            
        </ContentLoader>
    )
}

export default FilterPanelLoader;