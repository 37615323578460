import React from "react"
import ContentLoader from "react-content-loader"
import WhiteBox from '../../../components/WhiteBox/WhiteBox'

const DistributionLoader = () => {
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const width = 400*unit;
    const height = 200*unit; 
    
    return(
            <ContentLoader 
                speed={2}
                width={width}
                height={height}
                viewBox={"0 0 "+width+" "+height}
                backgroundColor="#f3f3f3"
                foregroundColor="#dcdbdb"
            >
                <rect x={15*unit} y={20*unit} rx={5*unit} ry={5*unit} width={width -(30*unit)} height={10*unit} />
                <rect x={(width/2)-15*unit+(80/6*unit)} y={(height/2)+50*unit} rx="0" ry="0" width={5*unit} height={10*unit} />
                <rect x={(width/2)-15*unit+(80/3*unit)} y={(height/2)+40*unit} rx="0" ry="0" width={5*unit} height={20*unit} />
                <rect x={(width/2)-15*unit+(80/2*unit)} y={(height/2)+30*unit} rx="0" ry="0" width={5*unit} height={30*unit} />
                <rect x={(width/2)-15*unit+(160/3*unit)} y={(height/2)+40*unit} rx="0" ry="0" width={5*unit} height={20*unit} />
                <rect x={(width/2)-15*unit+(400/6*unit)} y={(height/2)+50*unit} rx="0" ry="0" width={5*unit} height={10*unit} />
                <rect x={(width/2)-15*unit} y={(height/2)+60*unit} rx="0" ry="0" width={80*unit} height={2*unit} />
            </ContentLoader>
        
    )
}

export default DistributionLoader;