import React from "react"
import ContentLoader from "react-content-loader"
import Styles from "./FeedbackAll.module.css"



const CourseFeedback = (props) => (
    <div className={Styles.responses}>
        <ContentLoader 
        speed={2}
        width={436}
        height={500}
        viewBox="0 0 436 500"
        backgroundColor="#f3f3f3"
        foregroundColor="#dcdbdb"
        {...props}
    >
        <rect x="30" y="14" rx="7" ry="7" width="150" height="10" /> 
    <rect x="30" y="44" rx="7" ry="7" width="150" height="12" /> 
    <rect x="400" y="47" rx="7" ry="7" width="26" height="12" /> 
    <rect x="71" y="91" rx="0" ry="0" width="1" height="1" /> 
    <rect x="30" y="107" rx="7" ry="7" width="200" height="12" /> 
    <rect x="400" y="110" rx="7" ry="7" width="26" height="12" /> 
    <rect x="35" y="151" rx="0" ry="0" width="400" height="1" /> 
    <rect x="76" y="154" rx="0" ry="0" width="1" height="1" /> 
    <rect x="30" y="91" rx="0" ry="0" width="400" height="1" /> 
    <rect x="30" y="170" rx="7" ry="7" width="150" height="12" /> 
    <rect x="400" y="173" rx="7" ry="7" width="26" height="12" /> 
    <rect x="76" y="217" rx="0" ry="0" width="1" height="1" /> 
    <rect x="30" y="217" rx="0" ry="0" width="400" height="1" /> 
    <rect x="30" y="234" rx="7" ry="7" width="200" height="12" /> 
    <rect x="400" y="237" rx="7" ry="7" width="26" height="12" /> 
    <rect x="30" y="278" rx="0" ry="0" width="400" height="1" /> 
    <rect x="81" y="281" rx="0" ry="0" width="1" height="1" /> 
    <rect x="30" y="294" rx="7" ry="7" width="150" height="12" /> 
    <rect x="400" y="297" rx="7" ry="7" width="26" height="12" /> 
    <rect x="79" y="341" rx="0" ry="0" width="1" height="1" /> 
    <rect x="30" y="341" rx="0" ry="0" width="400" height="1" /> 
    <rect x="30" y="358" rx="7" ry="7" width="200" height="12" /> 
    <rect x="400" y="361" rx="7" ry="7" width="26" height="12" /> 
    <rect x="30" y="402" rx="0" ry="0" width="400" height="1" /> 
    <rect x="86" y="405" rx="0" ry="0" width="1" height="1" /> 
    <rect x="30" y="419" rx="7" ry="7" width="150" height="12" /> 
    <rect x="400" y="422" rx="7" ry="7" width="26" height="12" /> 
    <rect x="81" y="466" rx="0" ry="0" width="1" height="1" /> 
    <rect x="30" y="466" rx="0" ry="0" width="400" height="1" /> 
    <rect x="42" y="500" rx="7" ry="7" width="200" height="12" />
        
        
    </ContentLoader>
    </div>
    )
    
    
    export default CourseFeedback
    
    
    