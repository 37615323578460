import React from 'react';
import Styles from './GraphComponents.module.css'
import { Pie } from '@vx/shape'
import { Group } from '@vx/group'
import {LegendItem, LegendLabel, LegendOrdinal } from '@vx/legend'
import { scaleOrdinal } from '@vx/scale'
import { useTooltip, Tooltip } from '@vx/tooltip';
import { Spring } from 'react-spring/renderprops';

function PieChartFilled(props) {
    const width = props.width; 
    const height = props.height; 
    let unit = 1;
        if(window.screen.width > 1910 && window.screen.width < 2550){
            unit = 1.5;
        }else if(window.screen.width >= 2551){
            unit = 2;
        }
    const margin = { top: 30*unit, bottom: 30*unit, left: 30*unit, right: 30*unit };
    const data = props.data[0];
    let datakeys = [];
    for(let k in data) datakeys.push(k); //To get all the keys in json

    const colorPalette = ['#464796' ,  '#B888CB' , '#FFFFFF'] ;
    const ordinalColorScale = scaleOrdinal({
        domain: datakeys,
        range: colorPalette
    });

    const radiusOuter = Math.min(width,height)/2 - margin.left
    const centerY = (height - margin.top + margin.bottom) / 2;
    const centerX = (width - margin.left + margin.right)/ 2;
    
    const {
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();

    const handleMouseOver = (event, datum) => {
        showTooltip({
            tooltipLeft,
            tooltipTop,
            tooltipData: datum
        });
    };

    return (
        <div>
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height}
            onMouseOver={handleMouseOver}
            onMouseOut={hideTooltip}
        >
                <Group top={centerY} left={centerX}>
                <Spring
                    config={{ duration: 2500 }}
                    from={{ value1 : 0, value2:0, value3: data[datakeys[0]] + data[datakeys[1]]  }}
                    to={{ value1 : data[datakeys[0]], value2:data[datakeys[1]], value3: 0 }}
                >
                {transform =>
                    <Pie
                        data={[ transform.value1, transform.value2, transform.value3 ]}
                        outerRadius={radiusOuter}
                        cornerRadius={0}
                        padAngle={0}
                        pieSort={null}
                        pieSortValues={null}
                    >
                        {pie => {
                            return pie.arcs.map((arc, i) => {
                            return (
                                <g key={`progress-${i}`}  >
                                    <path d={pie.path(arc)} fill={colorPalette[i]} />
                                </g>
                            );
                            });
                        }}   
                    </Pie> 
                }
                </Spring>
                <Pie
                    data={Object.values(data)}
                    outerRadius={radiusOuter + 20*unit}
                    innerRadius={radiusOuter + 10*unit}
                    cornerRadius={0}
                    padAngle={0}
                    pieSort={null}
                    pieSortValues={null}
                >
                    {pie => {
                        return pie.arcs.map((arc, i) => {
                            const [centroidX, centroidY] = pie.path.centroid(arc);
                            return (
                                <g key={`progressText-${i}`}  >
                                    <path d={pie.path(arc)} opacity={0} />
                                    <text
                                        style={{textAlign:'start'}}
                                        fontFamily= {'Nunito'}
                                        fill={colorPalette[i]}
                                        x={centroidX}
                                        y={centroidY}
                                        fontSize={11*unit}
                                        textAnchor="middle"
                                        fontWeight={600}
                                        transform={`translate(0, ${4*unit})`}
                                    >
                                        { data[datakeys[i]] + "%" }
                                    </text>
                                </g>
                            );
                        });
                    }}   
                </Pie>                  
            </Group>
        </svg>
        {tooltipOpen && (
            <Tooltip
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft}
                className={Styles.tooltipBlur}
            >
                <div className={Styles.tooltipText}>
                    {datakeys[0]}: {data[datakeys[0]]}% <br/>
                    {datakeys[1]}: {data[datakeys[1]]}%
                </div> 
            </Tooltip>
        )}
        </div>
        <LegendOrdinal scale={ordinalColorScale}>
        {labels => {
            return (
                <div style={{justifyContent:'center', display: 'flex', flexDirection: 'rows'}}>
                    {labels.map((label, i) => {
                    const size = 8*unit;
                    return (
                        <LegendItem
                            key={`legend-quantile-${i}`}
                            margin={`0 ${5*unit}px`}
                        >
                        <svg width={size} height={size}>
                            <circle cx={size/2} cy={size/2} r={size/2}  fill={colorPalette[i]} />
                        </svg>
                            <LegendLabel
                                style={{
                                    fontFamily: 'Nunito',
                                    fontSize: `${10*unit}px`,
                                    color: '#2F4585',
                                    marginLeft: `${10*unit}px`
                                }}
                            >
                                {label.text}
                            </LegendLabel>
                        </LegendItem>
                    );
                    }).reverse()}
                </div>
            );
        }}
        </LegendOrdinal>
        </div>
    );
}

export default PieChartFilled;