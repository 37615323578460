import React from 'react'
import Styles from './User.module.css'
import people from "../../../assets/img/Regular.png";
import Title from '../GraphTitle/Title';

function User(props){
    
    return(
        <div className={Styles.container}>
            <div className={Styles.title}>
            <Title title={props.title || 'COMPLETED LEARNERS'} infoBody={props.infoBody} />
            </div>
            <div className={Styles.exceptTitleContainer}> 
                <div className={Styles.count}>
                    {props.count}
                </div>

                <img src={people} className={Styles.image}/>
            </div>
        </div>
    )
}

export default User;