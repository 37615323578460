import React from 'react';
import { Bar } from '@vx/shape'
import { LinearGradient } from '@vx/gradient';
import { useSpring, animated } from 'react-spring';

const colorInnerBar = '#F1EFF8';

function HorizontalProgressBar(props) {
    const width = props.width; 
    const height = props.height;
    //const margin = { top: 0, bottom: 0, left: 0, right: 0 };

    const outerBarWidth = props.score / props.Total * width;
    const heightForOuterBar = height;
    const heightForInnerBar = 3;
    const yPointForOuterBar = 0
    const yPointForInnerBar = yPointForOuterBar + (heightForOuterBar/2) - (heightForInnerBar - 2)

    const barAnimation = useSpring({
        config:{duration: 1500},
        delay:500,
        from: {
            width: 0
        },
        width: outerBarWidth
    })
    return (
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <svg width={width} height={height} >
            <LinearGradient from="#2F4585" to="#9F7DFF" id="linearG" vertical={false}  />
            <Bar
                x={0}
                y={yPointForInnerBar}
                width={width}
                height={heightForInnerBar}
                fill={colorInnerBar}
                rx = {4}
            />
            <animated.rect
                x={0}
                y={yPointForOuterBar}
                width={outerBarWidth}
                height={heightForOuterBar}
                fill={`url(#linearG)`}
                rx = {4}
                style = {barAnimation}
            />
        </svg>
        </div>
    );
}

export default HorizontalProgressBar