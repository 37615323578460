import React from "react";
import Styles from "./Action.module.css";
import Title from "../GraphTitle/Title";
function Action(props){
    return(
        <div className={Styles.outerBox}>
            <div className={Styles.graphTitle}>
                <Title title={props.title || 'MOST PREFERRED ACTION'} infoBody={props.infoBody} />
            </div>
            <div className={Styles.text}>
                {props.text}
            </div>
        </div>
    )
}

export default Action;