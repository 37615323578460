import React, { useEffect } from 'react' ;
import { useHistory, useParams } from 'react-router-dom'
import Styles from './Header.module.css'
import HeaderDropdown from '../HeaderDropdown/HeaderDropdown'
import { useQuery } from '@apollo/react-hooks'
import { useSelector, useDispatch } from 'react-redux'
import { gql } from "apollo-boost";
import orgIcon from '../../assets/img/landing6.png'
import header1 from '../../assets/img/header1.png'
import header2 from '../../assets/img/header2.png'
import { UPDATE_HEADER_ORG_DETAILS } from '../../constants/actions';

function Header(props) {
    /*useEffect(() => {
        props.setSelectedTab(props.selectedTab)
    }, [props.selectedTab, props.dropdownItem])*/
    const { orgId, tag } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const orgState = useSelector(state => state.orgLevelReducer)
    
    const headerQuery = gql`
    query MyQuery {
        orgName: journey_org(where: {organizationid: {_eq: ${orgId}}}) {
            logo
            name
          }
      }
    `

    const { loading: headerLoading, error: headerError, data:  headerData } = useQuery(headerQuery, {fetchPolicy: 'no-cache'})  

    useEffect(() => {
        if(!headerLoading && !headerError && headerData!=null)
        {
            dispatch({ type: UPDATE_HEADER_ORG_DETAILS, payload: headerData })
        }
    }, [headerLoading, headerError, headerData])

    if(headerError)
    {
        return <div>Error</div>
    }

    if(headerLoading) 
    {
        return <div>Loading..</div>
    }

    const headerTabArray = [
        {
            "id": 2,
            "tabTitle": "ACTIVITY"
        },
        {
            "id": 1,
            "tabTitle": "PERFORMANCE"
        },
        {
            "id": 3,
            "tabTitle": "EXPERIENCE"
        }
    ]

    const handleTabClick = (e) => {
        //props.setSelectedTab(e.target.dataset.value)
        history.push(`/${orgId}/${e.target.dataset.value.toLowerCase()}/${props.dropdownItem}/${tag}/${props.id}`)
    }

    const goToLanding = () => {
        history.push(`/${orgId}`)
    }

    return (
            <div className={Styles.headerContainer}>
                <div className={Styles.landing_orgcontainer} onClick={goToLanding}>
                    <img src={orgState.headerOrg.orgLogo} alt="Org Icon" className={Styles.orglogo} />
                    <div className={Styles.org_textcontiner}>
                        <div className={Styles.orgtext1}>{orgState.headerOrg.orgName}</div>
                        <div className={Styles.orgtext2}><b>TALENT </b>INTELLIGENCE</div>
                    </div>
                </div>
                <div className={Styles.headerDropdown}>
                    <HeaderDropdown dropdownItem={props.dropdownItem} setDropdownItem={props.setDropdownItem} dropdownId={props.dropdownId} setDropdownId={props.setDropdownId} selectedTab={props.selectedTab} listOpen={props.listOpen} setListOpen={props.setListOpen} />
                </div>
                <div className={Styles.headerTabs}>
                    {
                        headerTabArray.map(item => {
                            return props.selectedTab===item.tabTitle.toLowerCase() ?
                            <div className={Styles.singleTabContainer} key={item.id}>
                                <div className={Styles.insideSingleTabContainerActive}> 
                                    <div data-value={item.tabTitle} className={Styles.headerTabActive} key={item.id} onClick={handleTabClick}>{item.tabTitle}</div>
                                </div>
                            </div>    
                            :
                            <div className={Styles.singleTabContainer} key={item.id}>
                                <div className={Styles.insideSingleTabContainer}> 
                                    <div data-value={item.tabTitle} className={Styles.headerTab} key={item.id} onClick={handleTabClick}>{item.tabTitle}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
                <div className={Styles.headerIconsContainer}>
                <div className={Styles.backToSummary}>
                    <img src={header1} alt="App Icon" className={Styles.headerIcon1} onClick={goToLanding} />
                    <span><div className={Styles.summaryText}>Summary</div></span>
                </div>
                <div>
                    <img src={header2} alt="User Icon" className={Styles.headerIcon2} />
                </div>
                </div>
            </div>
    )
}

export default Header ;