import React, { useEffect, useState } from 'react'
import Styles from './PerformanceTabIlead.module.css'
import BannerWithBackground from '../../commonComponents/textComponent/Banner/BannerWithBackground'
import NoOfUsers from '../../commonComponents/textComponent/NoOfUsers/User'
import WhiteBox from '../WhiteBox/WhiteBox'
import BarGraph from '../../commonComponents/GraphComponents/BarGraph'
import ProgressBar from '../../commonComponents/GraphComponents/HorizontalProgressBar'
import GroupHorizontalBar from '../../commonComponents/GraphComponents/GroupHorizontalBar'
import GraphTitle from '../../commonComponents/textComponent/GraphTitle/Title'
import TargetAchieved from '../../commonComponents/textComponent/TargetAchieved/TargetAchievedPercent'
import GroupVerticalBar from '../../commonComponents/GraphComponents/GroupVerticalBar'
import PieChart from '../../commonComponents/GraphComponents/PieChart'
import SimpleBanner from '../../commonComponents/textComponent/Banner/SimpleBanner'
import CompetencyList from '../../commonComponents/textComponent/CompetenciesList/CourseCompitency'
import BarAccuracy from '../../commonComponents/GraphComponents/BarAccuracyProportionFrequency'
import TitleDropdown from '../../commonComponents/textComponent/TitleDropdown/TitleDropdown' 
import BannerLoader from '../../commonComponents/Loaders/PerformanceTabAll/BannerLoader'
import UsersLoader from '../../commonComponents/Loaders/PerformanceTabAll/UsersLoader'
import AverageBenchmarkLoader from '../../commonComponents/Loaders/PerformanceTabAll/AverageBenchmarkLoader'
import DistributionWhiteboxLoader from '../../commonComponents/Loaders/PerformanceTabAll/DistributionWhiteboxLoader'
import { useQuery } from '@apollo/react-hooks'
import { gql } from "apollo-boost";
import RowWiseLoader from '../../commonComponents/Loaders/PerformanceTabAll/RowWiseLoader'
import { useSelector, useDispatch } from 'react-redux'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { UPDATE_IL_COMPETENCY_SCORES, UPDATE_IL_DISTRIBUTIONS, UPDATE_IL_BANNER_FIRST, UPDATE_IL_LEADERSHIP, UPDATE_IL_LEARNERS } from '../../constants/actions'


function PerformanceTabIlead(props) {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    useEffect(() => {
        window.scrollTo(0,0)
    },[])

    const state = useSelector(state => state.performanceTab.iLead)
    const ilState = useSelector(state => state.performanceTab)
    const orgLevelState = useSelector(state => state.orgLevelReducer)
	const filterCheckedListState = useSelector(state => state.orgLevelReducer.filtersPanelCheckedList)
    const [titleCourse, setTitleCourse] = useState('Ability To Motivate')

    let conditionalDistribution 
        if(titleCourse==='Ability To Motivate')
        {   
            //console.log(1)
            conditionalDistribution = <BarGraph {...ilState.distributionsIL.dataForBarGraphMotivate} />
        }
        else if(titleCourse==='Ability to Upskill')
        {
            //console.log(2)
            conditionalDistribution = <BarGraph {...ilState.distributionsIL.dataForBarGraphUpskill} />
        }
        else if(titleCourse==='Enabling Performance')
        {
            //console.log(3)
            conditionalDistribution = <BarGraph {...ilState.distributionsIL.dataForBarGraphPerformance} />
        }
        else if(titleCourse==='Drive For Results')
        {
            //console.log(4)
            conditionalDistribution = <BarGraph {...ilState.distributionsIL.dataForBarGraphResults} />
        }
        else {
            //console.log(5)
            conditionalDistribution = <BarGraph {...ilState.distributionsIL.dataForBarGraphLeadership} />
        }

        let formatToSearch = []
        let formatToSearchString = [] 
        formatToSearch = filterCheckedListState.learningFormats;
        if(formatToSearch[0] === "All Formats"){
            formatToSearch = Object.assign([], orgLevelState.filtersPanelApi.formats[1].params);
            formatToSearch.indexOf(null) !== -1 && formatToSearch.splice(formatToSearch.indexOf(null), 1)
            formatToSearch.indexOf('All Formats') !== -1 && formatToSearch.splice(formatToSearch.indexOf('All Formats'), 1)
            formatToSearch.forEach((eachFormat) =>{
                formatToSearchString.push('"'+eachFormat+'"')
            })
        }
        else{
            formatToSearch.forEach((eachFormat) =>{
                formatToSearchString.push('"'+eachFormat+'"')
            })
        }
    
        let dealToSearch = []
        let dealToSearchString = [] 
        dealToSearch = filterCheckedListState.deals;
        if(dealToSearch[0] === "All Deals"){
            dealToSearch = Object.assign([], orgLevelState.filtersPanelApi.deals[1].params);
            dealToSearch.indexOf(null) !== -1 && dealToSearch.splice(dealToSearch.indexOf(null), 1)
            dealToSearch.indexOf('All Deals') !== -1 && dealToSearch.splice(dealToSearch.indexOf('All Deals'), 1)
            dealToSearch.forEach((eachDeal) =>{
                dealToSearchString.push('"'+eachDeal+'"')
            })
        }
        else{
            dealToSearch.forEach((eachDeal) =>{
                dealToSearchString.push('"'+eachDeal+'"')
            })
        }
    
        let batchToSearch = []
        let batchToSearchString = [] 
        batchToSearch = filterCheckedListState.batches;
        if(batchToSearch[0] === "All Batches"){
            batchToSearch = Object.assign([], orgLevelState.filtersPanelApi.batches[1].params);
            batchToSearch.indexOf(null) !== -1 && batchToSearch.splice(batchToSearch.indexOf(null), 1)
            batchToSearch.indexOf('All Batches') !== -1 && batchToSearch.splice(batchToSearch.indexOf('All Batches'), 1)
            batchToSearch.forEach((eachBatch) =>{
                batchToSearchString.push('"'+eachBatch+'"')
            })
        }
        else{
            batchToSearch.forEach((eachBatch) =>{
                batchToSearchString.push('"'+eachBatch+'"')
            })
        }
    
        let industryToSearch = []
        let industryToSearchString = [] 
        industryToSearch = filterCheckedListState.industries;
        if(industryToSearch[0] === undefined){
            industryToSearch = Object.assign([], orgLevelState.filtersPanelApi.industries[1].params);
            industryToSearch.indexOf(null) !== -1 && industryToSearch.splice(industryToSearch.indexOf(null), 1)
            industryToSearch.forEach((eachIndustry) =>{
                industryToSearchString.push('"'+eachIndustry+'"')
            })
        }
        else{
            industryToSearch.forEach((eachIndustry) =>{
                industryToSearchString.push('"'+eachIndustry+'"')
            })
        }
    
        let jobCatagoryToSearch = []
        let jobCatagoryToSearchString = [] 
        jobCatagoryToSearch = filterCheckedListState.jobCategories;
        if(jobCatagoryToSearch[0] === undefined){
            jobCatagoryToSearch = Object.assign([], orgLevelState.filtersPanelApi.jobCategories[1].params);
            jobCatagoryToSearch.indexOf(null) !== -1 && jobCatagoryToSearch.splice(jobCatagoryToSearch.indexOf(null), 1)
            jobCatagoryToSearch.forEach((eachjobCatagory) =>{
                jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
            })
        }
        else{
            jobCatagoryToSearch.forEach((eachjobCatagory) =>{
                jobCatagoryToSearchString.push('"'+eachjobCatagory+'"')
            })
        }
    
        let workExpToSearch = []
        let workExpToSearchString = [] 
        workExpToSearch = filterCheckedListState.workExperiences;
        if(workExpToSearch[0] === "All"){
            workExpToSearch = Object.assign([], orgLevelState.filtersPanelApi.workExperiences[1].params);
            workExpToSearch.indexOf(null) !== -1 && workExpToSearch.splice(workExpToSearch.indexOf(null), 1)
            workExpToSearch.forEach((eachworkExp) =>{
                workExpToSearchString.push('"'+eachworkExp+'"')
            })
        }
        else{
            workExpToSearch.forEach((eachworkExp) =>{
                workExpToSearchString.push('"'+eachworkExp+'"')
            })
        }
    
        let manageExpToSearch = []
        let manageExpToSearchString = [] 
        manageExpToSearch = filterCheckedListState.peopleManagementExperiences;
        if(manageExpToSearch[0] === "All"){
            manageExpToSearch = Object.assign([], orgLevelState.filtersPanelApi.peopleManagementExperiences[1].params);
            manageExpToSearch.indexOf(null) !== -1 && manageExpToSearch.splice(manageExpToSearch.indexOf(null), 1)
            manageExpToSearch.forEach((eachManageExp) =>{
                manageExpToSearchString.push('"'+eachManageExp+'"')
            })
        }
        else{
            manageExpToSearch.forEach((eachManageExp) =>{
                manageExpToSearchString.push('"'+eachManageExp+'"')
            })
        }

    const bannerFirstQuery = gql`
        query MyQuery {
            bannerFirstDescription: journey_journey_instance(where: {createdbyorgid: {_eq: "337"}, _and: {journeyinstanceid: {_eq: "34804"}}}) {
                description
                name
                JourneyInstanceDescriptionString {
                    langvalue
                }
            }   
        }
    `    

    const usersQuery = gql`
        query MyQuery {
            learnersGroupCompleted: journey_complete_user_details_aggregate(where: {journey_progress: {_eq: "100"}, _and: {createdbyorgidRelation: {createdbyorgid: {_eq: 337}, _and: {journeyinstanceid: {_eq: 34804}}}, _and: {journeyIDTagRelation: {format: {_in: [${formatToSearchString}]}}, _and: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {batchRelation: {name: {_in: [${batchToSearchString}]}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}}, distinct_on: user_id) {
                aggregate {
                  count(columns: user_id)
                }
              }
            learnersGroupNotCompleted: journey_complete_user_details_aggregate(where: {journey_progress: {_lt: "100"}, _and: {createdbyorgidRelation: {createdbyorgid: {_eq: 337}, _and: {journeyinstanceid: {_eq: 34804}}}, _and: {journeyIDTagRelation: {format: {_in: [${formatToSearchString}]}}, _and: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {batchRelation: {name: {_in: [${batchToSearchString}]}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}}, distinct_on: user_id) {
                aggregate {
                  count(columns: user_id)
                }
              }
        }
    `

    const competencyScoresQuery = gql`
    query MyQuery {
        groupAvg: sims_sim_ilead_report_aggregate(where: {ileadUlidata: {ulidataCommondbgroups: {batchCommondb: {journeybatchinstance: {OrgJourneyInstance: {organizationid: {_eq: "461"}}, _and: {completeUserJoutneyInstance: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {journeyIDTagRelation: {format: {_eq: null}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}, _and: {name: {_in: [${batchToSearchString}]}}}}}}) {
          aggregate {
            avg {
              abilitytoupskillcompetencyscore
              abilitytomotivatecompetencyscore
              contextualleadershipcompetencyscore
              enablingperformancecompetencyscore
              driveforresultcompetencyscore
            }
          }
        }
        benchmarkAvg: sims_sim_ilead_report_aggregate {
            aggregate {
              avg {
                abilitytoupskillcompetencyscore
                abilitytomotivatecompetencyscore
                contextualleadershipcompetencyscore
                enablingperformancecompetencyscore
                driveforresultcompetencyscore
              }
            }
          }
        achievedTargetArray: sims_sim_ilead_report(distinct_on: userlicenseid, where: {ileadUlidata: {ulidataCommondbgroups: {batchCommondb: {journeybatchinstance: {OrgJourneyInstance: {organizationid: {_eq: "461"}}, _and: {completeUserJoutneyInstance: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {journeyIDTagRelation: {format: {_eq: null}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}, _and: {name: {_in: [${batchToSearchString}]}}}}}}) {
            actualrevenue
            targetrevenue
          }
      }
    `
    
    const distributionsQuery = gql`
      query MyQuery {
        distributionsArray: sims_sim_ilead_report(where: {ileadUlidata: {ulidataCommondbgroups: {batchCommondb: {journeybatchinstance: {OrgJourneyInstance: {organizationid: {_eq: "461"}}, _and: {completeUserJoutneyInstance: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {journeyIDTagRelation: {format: {_eq: null}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}, _and: {name: {_in: [${batchToSearchString}]}}}}}}) {
            abilitytoupskillcompetencyscore
            abilitytomotivatecompetencyscore
            contextualleadershipcompetencyscore
            enablingperformancecompetencyscore
            driveforresultcompetencyscore
          }
        leadershipGroup : sims_sim_ilead_report_aggregate(where: {ileadUlidata: {ulidataCommondbgroups: {batchCommondb: {journeybatchinstance: {OrgJourneyInstance: {organizationid: {_eq: "461"}}, _and: {completeUserJoutneyInstance: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {journeyIDTagRelation: {format: {_eq: null}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}, _and: {name: {_in: [${batchToSearchString}]}}}}}}) {
            aggregate {
              avg {
                overallleadershipadaptability
              }
            }
          }
        leadershipBenchmark : sims_sim_ilead_report_aggregate {
            aggregate {
              avg {
                overallleadershipadaptability
              }
            }
          }  
      }
    `

    const leadershipQuery = gql`
      query MyQuery {
        consistency: sims_sim_ilead_report_aggregate(where: {ileadUlidata: {ulidataCommondbgroups: {batchCommondb: {journeybatchinstance: {OrgJourneyInstance: {organizationid: {_eq: "461"}}, _and: {completeUserJoutneyInstance: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {journeyIDTagRelation: {format: {_eq: null}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}, _and: {name: {_in: [${batchToSearchString}]}}}}}}) {
            aggregate {
              avg {
                desiredvsactualdeviation
                desiredvsintentdeviation
                intentvsactualdeviation
              }
            }
          }
        timeSpent: sims_sim_ilead_report_aggregate(where: {ileadUlidata: {ulidataCommondbgroups: {batchCommondb: {journeybatchinstance: {OrgJourneyInstance: {organizationid: {_eq: "461"}}, _and: {completeUserJoutneyInstance: {dealUserIDRelation: {DealUserRelation: {name: {_in: [${dealToSearchString}]}}}, _and: {journeyIDTagRelation: {format: {_eq: null}}, _and: {commondbUsersCompleteUsers: {industry: {_in: [${industryToSearchString}]}, _and: {usercategory: {_in: [${jobCatagoryToSearchString}]}, _and: {experiencelevel: {_in: [${workExpToSearchString}]}, _and: {managingexperience: {_in: [${manageExpToSearchString}]}}}}}}}}}}, _and: {name: {_in: [${batchToSearchString}]}}}}}}) {
            aggregate {
              avg {
                bottomperformertimespentpercentage
                averageperformertimespentpercentage
                topperformertimespentpercentage
              }
            }
          }
      }
    `

    const { loading: bannerFirstLoading, error: bannerFirstError, data: bannerFirstData } = useQuery(bannerFirstQuery, {fetchPolicy: 'no-cache'})  
    const { loading: usersLoading, error: usersError, data: usersData } = useQuery(usersQuery, {fetchPolicy: 'no-cache'})
    const { loading: competencyScoresLoading, error: competencyScoresError, data: competencyScoresData } = useQuery(competencyScoresQuery, {fetchPolicy: 'no-cache'})
    const { loading: distributionsLoading, error: distributionsError, data: distributionsData } = useQuery(distributionsQuery, {fetchPolicy: 'no-cache'})
    const { loading: leadershipLoading, error: leadershipError, data: leadershipData } = useQuery(leadershipQuery, {fetchPolicy: 'no-cache'})  

    useEffect(() => {
        if(!competencyScoresLoading && !competencyScoresError && competencyScoresData!=null)
        {
            dispatch({ type: UPDATE_IL_COMPETENCY_SCORES, payload: competencyScoresData })
        }
    }, [competencyScoresLoading, competencyScoresError, competencyScoresData])

    useEffect(() => {
        if(!usersLoading && !usersError && usersData!=null)
        {
            dispatch({ type: UPDATE_IL_LEARNERS, payload: usersData })
        }
    }, [usersLoading, usersError, usersData])

    useEffect(() => {
        if(!distributionsLoading && !distributionsError && distributionsData!=null) 
        {
            dispatch({ type: UPDATE_IL_DISTRIBUTIONS, payload: distributionsData })
        }
    }, [distributionsLoading, distributionsError, distributionsData])

    useEffect(() => {
        if(!bannerFirstLoading && !bannerFirstError && bannerFirstData!=null) 
        {
            dispatch({ type: UPDATE_IL_BANNER_FIRST, payload: bannerFirstData })
        }
    }, [bannerFirstLoading, bannerFirstError, bannerFirstData])

    useEffect(() => {
        if(!leadershipLoading && !leadershipError &&  leadershipData!=null) 
        {
            dispatch({ type: UPDATE_IL_LEADERSHIP, payload: leadershipData })
        }
    }, [leadershipLoading, leadershipError, leadershipData])

    if(competencyScoresError, distributionsError, bannerFirstError, leadershipError, usersError)
    {
        return <div>Error...</div>
    }
    //console.log(titleCourse)
    //console.log(ilState.distributionsIL.dataForBarGraphMotivate)

    return (
        <div className={Styles.gridWrapper}>
            <div data-aos="fade-up" data-aos-once="true">
                {
                    bannerFirstLoading &&
                    <BannerLoader />
                }
                {
                    !bannerFirstLoading && 
                    <SimpleBanner title={state.headingTitle1} description={ilState.bannerFirstIL.description} />
                }
            </div>
            <div className={Styles.gridWrapper1} data-aos="fade-up" data-aos-once="true" data-aos-delay="350">
                <div>
                    {
                        loading &&
                        <UsersLoader />
                    }
                    {
                        !loading &&
                        <NoOfUsers title="TOTAL LEARNERS" count={ilState.totalLearnersIL.users} />
                    }
                </div>
                <div className={Styles.averageWhitebox}>
                    <WhiteBox>
                        <div className={Styles.averageContainer}>
                            {
                                competencyScoresLoading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !competencyScoresLoading && 
                                <>
                                    <div className={Styles.groupAverageHeading0}>
                                        <GraphTitle title="GROUP AVERAGE" />
                                    </div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{ilState.competencyScoresIL.dataForHorizontalProgressBar1.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...ilState.competencyScoresIL.dataForHorizontalProgressBar1} />
                                    </div>
                                </>
                            }
                            <div className={Styles.lineDifferentiator}></div>
                            {
                                competencyScoresLoading && 
                                <AverageBenchmarkLoader />
                            }
                            {
                                !competencyScoresLoading && 
                                <>
                                    <div className={Styles.groupAverageHeading}>UNIVERSE AVERAGE</div>
                                    <div className={Styles.groupAverageScore}>
                                        <div className={Styles.scoreReceived}>{ilState.competencyScoresIL.dataForHorizontalProgressBar2.score}</div>
                                        <div className={Styles.totalScore}>/10</div>
                                    </div>
                                    <div className={Styles.progressBar}>
                                        <ProgressBar {...ilState.competencyScoresIL.dataForHorizontalProgressBar2} />
                                    </div>
                                </>
                            }
                        </div>
                    </WhiteBox>
                </div>
                <div className={Styles.competencyList}>
                    {
                        loading && 
                        <DistributionWhiteboxLoader />
                    }
                    {
                        !loading &&
                        <CompetencyList course={state.competencyArray} />
                    }
                </div>
            </div>
            <div data-aos="fade-up" data-aos-once="true" data-aos-delay="700">
                {
                    loading && 
                    <BannerLoader />
                }
                {
                    !loading && 
                    <BannerWithBackground title={state.headingTitle2} />   
                }
            </div>
            {
                competencyScoresLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !competencyScoresLoading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true" data-aos-delay="1050">
                    <div className={Styles.horizontalGraphContainer}>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle />
                            </div>
                            <div className={Styles.groupHorizontalComponent}>
                                <GroupHorizontalBar {...ilState.competencyScoresIL.dataForGroupHorizontalBar} />
                            </div>
                        </WhiteBox>
                    </div>
                    <div className={Styles.gridWrapper3}>
                        <div>
                            <TargetAchieved percent={ilState.competencyScoresIL.targetAchieved} />
                        </div>
                        <div className={Styles.targetsContainer}>
                            
                        </div>
                    </div>
                </div>
                </>
            }
            {
                distributionsLoading && 
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !distributionsLoading && 
                <>
                    <div className={Styles.gridWrapper2} data-aos="fade-up" data-aos-once="true">
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title="DISTRIBUTION OF LEARNERS ACROSS COMPETENCIES" />
                            </div>
                            <div className={Styles.titleDropdown}>
                                <TitleDropdown arr={state.competencyArray} titleCourse={titleCourse} setTitleCourse={setTitleCourse} />
                            </div>
                            <div className={Styles.barGraphComponent1}> 
                                {conditionalDistribution}
                            </div>
                        </WhiteBox>
                    </div>
                    <div>
                        <WhiteBox>
                            <div className={Styles.graphTitle}>
                                <GraphTitle title={state.graphTitle3} />
                            </div>
                            <div className={Styles.pieChartComponent}>
                                <PieChart {...ilState.distributionsIL.dataForPieChart} />
                            </div>
                        </WhiteBox>
                    </div>
                </div>
                </>
            }
            {
                leadershipLoading &&
                <>
                    <div data-aos="fade-up" data-aos-once="true">
                        <RowWiseLoader />
                    </div>
                </>
            }
            {
                !leadershipLoading && 
                <>
                    <div className={Styles.gridWrapper4} data-aos="fade-up" data-aos-once="true">
                        <div>
                            
                        </div>
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title={state.graphTitle5} />
                                </div>
                                <div className={Styles.barGraphComponent2}>
                                    <BarGraph  {...ilState.leadershipIL.dataForBarGraph2} />
                                </div>
                            </WhiteBox>
                        </div>
                        <div>
                            <WhiteBox>
                                <div className={Styles.graphTitle}>
                                    <GraphTitle title={state.graphTitle6} />
                                </div>
                                <div className={Styles.barGraphComponent2}>
                                    <BarGraph {...ilState.leadershipIL.dataForBarGraph3} />
                                </div>
                            </WhiteBox>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default PerformanceTabIlead