import React from "react";
import Styles from "./SimpleBanner.module.css";
import designUp from "../../../assets/img/designUp.png";
import designDown from "../../../assets/img/designDown.png";
import { Component2 } from "../Competency/Rated";
import ShowMoreText from 'react-show-more-text';
import parse from 'html-react-parser';

var lead=[
    'Leading next',
    'Leading Business'
]

let unit = 1;
if(window.screen.width > 1910 && window.screen.width < 2550){
	unit = 1.5;
}else if(window.screen.width >= 2551){
	unit = 2;
}

function SimpleBanner(props){
    let conditionalComponent='' ;
    let conditionShow = <ShowMoreText className={Styles.text} lines={2}  more='Show more' less='Show less' expanded={false} anchorClass={Styles.anchorText}>
                            {parse(props.description)}
                        </ShowMoreText>
    if(props.domainCategoryArr && props.domainCategoryArr.length>0){
        conditionalComponent = <Component2 className={Styles.compitency} arr={props.domainCategoryArr || lead}/>
        if(props.noshow)
        {
            conditionShow = <div className={Styles.text1}>{parse(props.description)}</div> 
        }
    }

    return(
        <div className={Styles.outerBox}>
            <div>
                <div className={Styles.innerBox}>
                    <div className={Styles.title}>
                        {props.title}
                    </div>
                    <div className={Styles.leadingComponent}>
                        {conditionalComponent}
                    </div>
                </div> 
                {conditionShow}
            </div>
            <div className={Styles.image1}>
                <img src={designDown} className={Styles.image1}/>
                <img src={designUp} className={Styles.image2}/>  
            </div>
        </div>
    )
}

export default SimpleBanner;
