import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import Styles from './HeaderFilterTab.module.css'
import Header from '../Header/Header'
import PerformanceTab from '../PerformanceTab/PerformanceTab'
import PerformanceTabIlead from '../PerformanceTabIlead/PerformanceTabIlead'
import ActivityTab from '../ActivityTab/ActivityTab'
import ActivityTabSims from '../ActivityTabSims/ActivityTabSims'
import ActivityTabiLead from '../ActivityTabiLead/ActivityTabiLead'
import ActivityTabCQ from '../ActivityTabCQ/ActivityTabCQ'
import ActivityTabNonSim from '../ActivityTabNonSim/ActivityTabNonSim'
import ActivityTabCourseGroup from '../ActivityTabCourseGroup/ActivityTabCourseGroup'
import PerformanceTabTrustSim from '../PerformanceTabTrustSim/PerformanceTabTrustSim'
import PerformanceTabBYB from '../PerformanceTabBYB/PerformanceTabBYB'
import PerformanceTabCQ from '../PerformanceTabCQ/PerformanceTabCQ'
import PerformanceTabCoaching from '../PerformanceTabCoaching/PerformanceTabCoaching'
import performanceTab1 from '../../assets/img/performanceTab1.png'
import performanceTab2 from '../../assets/img/performanceTab2.png'
import FilterPanel from '../filterPanel/filterPanel'
import ExperienceTab from '../ExperienceTab/ExperienceTab'
import FeedbackTabIlead from '../FeedbackTabIlead/FeedbackTabIlead'
import CourseGroupPerformance from '../CourseGroupPerformance/CourseGroupPerformance'
import NonSimPerformanceTab from '../NonSimPerformanceTab/NonSimPerformanceTab'
import FeedbackTabNonSim from '../FeedbackTabNonSim/FeedbackTabNonSim'
import FeedbackTabCourseGroup from '../FeedbackTabCourseGroup/FeedbackTabCourseGroup'
import FeedbackTabCQ from '../FeedbackTabCQ/FeedbackTabCQ'
import FeedbackTabTrustSim from '../FeedbackTrustSim/FeedbackTrustSim'
import ActivityTabTrustSim from '../ActivityTabTrustSim/ActivityTabTrustSim'
import ActivityTabCoachingSim from '../ActivityTabCoachingSim/ActivityTabCoachingSim'
import FeedbackTabCoachingSim from '../FeedbackTabCoachingSim/FeedbackTabCoachingSim'
import PerformanceTabAgile from '../PerformanceTabAgile/PerformanceTabAgile'
import FeedbackTabAgile from '../FeedbackTabAgile/FeedbackTabAgile'
import ActivityTabAgile from '../ActivityTabAgile/ActivityTabAgile'




import AOS from 'aos';


function HeaderFilterTab(props) {
    const history = useHistory()
    const { tab,course ,id, tag } = useParams()
    const [dropdownItem, setDropdownItem] = useState(course)
    const [dropdownId, setDropdownId] = useState(id)
    const [listOpen, setListOpen] = useState(false)
    AOS.init(
        {   duration: '350',	         
            easing: 'ease-in-sine'
        }
    )

    const handleCloseList = () => {
        if(listOpen)
        {
            setListOpen(false)
        }
    }

    let conditionalComponent=''
    if(tab==='performance')
    {
        if(tag==='all')
        {
            conditionalComponent=<PerformanceTab />
        }
        else if(tag==='cq')
        {
            conditionalComponent=<PerformanceTabCQ />    
        }
        else if(tag==='ilead')
        {
            conditionalComponent=<PerformanceTabIlead />
        }
        else if(tag==='trust')
        {
            conditionalComponent=<PerformanceTabTrustSim />
        }
        else if(dropdownItem==='BYB')
        {
            conditionalComponent=<PerformanceTabBYB />
        }
        else if(tag==='agile') {
            conditionalComponent=<PerformanceTabAgile />
        }
        else if(dropdownItem==='Coaching Sim' || dropdownItem==='Journey with all items optional')
        {
            conditionalComponent = <PerformanceTabCoaching />
        }
        else if(tag==='now' || tag==='next')
        {
            conditionalComponent= <CourseGroupPerformance dropdownItem={dropdownItem} />
        }
        else if(tag==='digital'){
            conditionalComponent = <NonSimPerformanceTab dropdownItem={dropdownItem} />
        }
        else {
            conditionalComponent="No Specific Tag"
        }
    }
    else if(tab==='activity')
    {
        if(tag==='all')
        {
            conditionalComponent= <ActivityTab />
        }
        else if(tag==='cq')
        {
            conditionalComponent= <ActivityTabCQ />  
        }
        else if(tag==='ilead')
        {
            conditionalComponent= <ActivityTabiLead />
        }
        else if(tag==='trust')
        {
            conditionalComponent= <ActivityTabTrustSim title="BUILDING TRUST" />
        }
        else if(dropdownItem==='BYB')
        {
            conditionalComponent= <ActivityTabSims title="DEVELOPING BUSINESS ACUMEN" />
        }
        else if(tag==='now' || tag==='next')
        {
            conditionalComponent= <ActivityTabCourseGroup title={dropdownItem} />
        }
        else if(dropdownItem.toLowerCase()==='coaching sim')
        {
            conditionalComponent= <ActivityTabCoachingSim title="DEVELOPING BUSINESS ACUMEN" />
        }
        else if(tag==='digital'){
            conditionalComponent= <ActivityTabNonSim title="CLEARING THE DIGITAL BLUR" />
        }
        else if(tag==='agile'){
            conditionalComponent= <ActivityTabAgile title="CLEARING THE DIGITAL BLUR" />
        }
        else {
            conditionalComponent="No Specific Tag"
        }
    }
    else if(tab==='experience')
    {
        if(tag==='all')
        {
            conditionalComponent=<ExperienceTab />
        }
        else if(tag==='cq')
        {
            conditionalComponent=<FeedbackTabCQ/>
        }
        else if(tag==='ilead')
        {
            conditionalComponent=<FeedbackTabIlead />
        }
        else if(tag==='now' || tag==='next')
        {
            conditionalComponent= <FeedbackTabCourseGroup title={dropdownItem} />
        }
        else if(tag==='trust')
        {
            conditionalComponent=<FeedbackTabTrustSim />
        }
        else if(dropdownItem.toLowerCase() ==='coaching sim')
        {
            conditionalComponent=<FeedbackTabCoachingSim />
        }
        else if(tag==='digital'){
            conditionalComponent=<FeedbackTabNonSim />
        }
        else if(tag ==='agile')
        {
            conditionalComponent=<FeedbackTabAgile />
        }
        else {
            conditionalComponent="No Specific Tag"
        }
    }
        
    return (
        <div className={`${Styles.mainContainer}`} onClick={handleCloseList}>
            <div className={Styles.headerContainer} data-aos="fade" data-aos-once="true">
                <Header selectedTab={tab} dropdownItem={dropdownItem} setDropdownItem={setDropdownItem} dropdownId={dropdownId} setDropdownId={setDropdownId} id={id} listOpen={listOpen} setListOpen={setListOpen} />
            </div>
            <div className={Styles.filterTabContainer} data-aos="fade" data-aos-once="true">
                <div className={Styles.vectorBackground}>
                    <img src={performanceTab1} alt="Vector1" className={Styles.backgroundVector1} />
                    <img src={performanceTab2} alt="Vector2" className={Styles.backgroundVector2} />
                </div>
                <div className={Styles.filterComponent}>
                    <FilterPanel course={dropdownItem}/>
                </div>
                {conditionalComponent}
             </div>   
        </div>
    )
}

export default HeaderFilterTab
