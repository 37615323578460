import {UPDATE_BANNER_FT_CQ,UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CQ, UPDATE_BENCHMARK_FT_CQ, UPDATE_COURSEFEEDBACK_FT_CQ,UPDATE_RESPONSE_FT_CQ,UPDATE_WORDCLOUD_FT_CQ} from '../../constants/actions'
const initialState = {
    banner : {
        courseName: '',
        description: '',
    },
    
    averageCourseFeedback:0,
    benchMark: 0,
    courseFeedback:[
        {
            title:'Satisfaction',
            rating:0,
        },
        {
            title:'Engagement',
            rating:0,
        },
        {
            title:'Platform',
            rating:0,
        },
        
    ],

    response:{
        likes:0,
        dislikes:0,
    },
    wordCloud :[]
}

export function CQFeedbackTab(state=initialState, action){
    switch(action.type){
        case UPDATE_BANNER_FT_CQ:
            return{
                ...state,
                banner: action.payload
            }  
        case UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_CQ:
            return{
                ...state,
                averageCourseFeedback: action.payload
            }            
        case UPDATE_BENCHMARK_FT_CQ:
            return{
                ...state,
                benchMark: action.payload
            }
        case UPDATE_COURSEFEEDBACK_FT_CQ:
            return{
                ...state,
                courseFeedback: action.payload
            }
        case UPDATE_RESPONSE_FT_CQ:
            return{
                ...state,
                response: action.payload
            }
        case UPDATE_WORDCLOUD_FT_CQ:
            return{
                ...state,
                wordCloud: action.payload
            }

        default:
            return state
    }
}
