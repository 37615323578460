import {UPDATE_BANNER_FT_COACHINGSIM,UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_COACHINGSIM, UPDATE_BENCHMARK_FT_COACHINGSIM, UPDATE_COURSEFEEDBACK_FT_COACHINGSIM,UPDATE_RESPONSE_FT_COACHINGSIM,UPDATE_WORDCLOUD_FT_COACHINGSIM} from '../../constants/actions'
const initialState = {
    banner : {
        courseName: 'Leading Teams',
        description: 'Until recently, the prevailing view assumed lorem ipsum was born as a nonsense text. “It\'s not Latin, though it look like...',
    },
    
    averageCourseFeedback:4.5,
    benchMark: 4.5,
    courseFeedback:[
        {
            title:'Satisfaction',
            rating:4,
        },
        {
            title:'Engagement',
            rating:4.5,
        },
        {
            title:'Platform',
            rating:4,
        },
        
    ],

    response:{
        likes:825,
        dislikes:25,
    },
    wordCloud :[]
}

export function CoachingSimFeedbackTab(state=initialState, action){
    switch(action.type){
        case UPDATE_BANNER_FT_COACHINGSIM:
            return{
                ...state,
                banner: action.payload
            }  
        case UPDATE_AVERAGE_COURSE_FEEDBACK_RATING_FT_COACHINGSIM:
            return{
                ...state,
                averageCourseFeedback: action.payload
            }            
        case UPDATE_BENCHMARK_FT_COACHINGSIM:
            return{
                ...state,
                benchMark: action.payload
            }
        case UPDATE_COURSEFEEDBACK_FT_COACHINGSIM:
            return{
                ...state,
                courseFeedback: action.payload
            }
        case UPDATE_RESPONSE_FT_COACHINGSIM:
            return{
                ...state,
                response: action.payload
            }
        case UPDATE_WORDCLOUD_FT_COACHINGSIM:
            return{
                ...state,
                wordCloud: action.payload
            }

        default:
            return state
    }
}
